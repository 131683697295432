import React, {FunctionComponent, useEffect, useState} from 'react';
import {Redirect, Route, RouteComponentProps, Switch, useHistory} from "react-router";

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {ROUTE} from "../../services/RouteService/enums";
import {myForaRoutesMap} from "../../services/RouteService/RouteService";
import {setDocumentTitle} from "../../services/TitleService";
import {PROJECT} from "../../constants";
import {isTargetProject} from "../../utils";

import MyCards from "./MyCards/MyCards";
import MyInfo from "./MyInfo/MyInfo";
import BonusProtection from "./BonusProtection/BonusProtection";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import RechargeNumbers from "./RechargeNumbers/RechargeNumbers";
import getTemplateText from "../../services/TextService";

const links = [
	{route: ROUTE.CHANGE_PASSWORD, text: getTemplateText("settings.nav.change_password")},
	{route: ROUTE.MY_CARDS, text: getTemplateText("settings.nav.cards")},
	{route: ROUTE.RECHARGE_NUMBERS, text: getTemplateText("settings.nav.recharge_numbers")},
	{route: ROUTE.MY_INFO, text: getTemplateText("settings.nav.info")},
	{route: ROUTE.RECHARGE_NUMBERS, text: getTemplateText("settings.nav.recharge_numbers")},
	...(isTargetProject(PROJECT.SILPO) ? [{route: ROUTE.MY_BONUSES, text: getTemplateText("settings.nav.bonuses")}] : []),
	...(isTargetProject(PROJECT.SILPO) ? [{route: ROUTE.MY_SUBSCRIPTIONS, text: getTemplateText("settings.nav.subscription")}] : []),
	...(isTargetProject(PROJECT.FORA) ? [{route: ROUTE.BONUS_PROTECTION, text: getTemplateText("settings.nav.bonus_protection")}] : []),
];

const SettingsPage: FunctionComponent<RouteComponentProps> = () => {

	const [currentRoute, setCurrentRoute] = useState({});

	const history = useHistory();

	useEffect(() => {
		const getActiveMenuRoute = () => {
			const pathname = (history.location || {}).pathname || "";
			const innerSettingsIndex = links.findIndex(r => !!(r.text && r.route &&  pathname === myForaRoutesMap[r.route]));
			if (innerSettingsIndex !== -1) {
				const currentRoute =  links[innerSettingsIndex];
				setCurrentRoute( {[currentRoute.route]: currentRoute.text});
			} else {
				setCurrentRoute({});
			}
		};

		getActiveMenuRoute();
	}, [history.location.pathname, history.location]);

	setDocumentTitle(getTemplateText("settings.title"));
		return (
			<div className="settings-page">
				<BreadCrumbs
					labels={{
						[ROUTE.ROOT]: "Персональна сторінка",
						[ROUTE.PERSONAL_INFO]: getTemplateText("settings.title"),
						...currentRoute
					}}
				/>

				<Switch>
					<Redirect exact from={myForaRoutesMap[ROUTE.PERSONAL_INFO]} to={myForaRoutesMap[ROUTE.MY_INFO]}/>
					<Route exact path={myForaRoutesMap[ROUTE.MY_CARDS]} component={MyCards}/>
					<Route exact path={myForaRoutesMap[ROUTE.MY_INFO]} component={MyInfo}/>
					<Route exact path={myForaRoutesMap[ROUTE.CHANGE_PASSWORD]} component={ChangePassword}/>
					<Route exact path={myForaRoutesMap[ROUTE.RECHARGE_NUMBERS]} component={RechargeNumbers}/>
					<Route exact path={myForaRoutesMap[ROUTE.BONUS_PROTECTION]} component={BonusProtection}/>
				</Switch>
			</div>
		)
};


export default SettingsPage;
