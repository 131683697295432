import React, {Component} from "react";
import {compose, withProps} from "recompose";
import {Field, withFormik} from "formik";

import {FORMS} from "../enums";
import {DATA_TYPE} from "../../services/FormServices/constants";
import {normalizeFields, validate} from "../../services/FormServices/FormService";
import {IFormProps} from "../../services/FormServices/interfaces";
import getTemplateText from "../../services/TextService";
import {CardAddFormValues, InnerCardAddFormProps, OuterCardAddFormProps} from "./types";

import Form from "../../components/Form/Form";
import FormInput from "../../components/Form/FormInput";
import FormField from "../../components/Form/FormField";
import FormError from "../../components/Form/FormError/FormError";
import Button from "../../components/UI/Button/Button";


const form = FORMS.CARD_ADD_FORM,
	fields = {
		barcode: {
			name: "barcode",
			dataType: DATA_TYPE.barcode,
		},
};

const CardAddForm = compose<InnerCardAddFormProps & OuterCardAddFormProps, OuterCardAddFormProps>(
	withProps((props: any) => ({
		...props,
		form,
		fields: normalizeFields(fields,  form),
	})),
	withFormik<IFormProps & OuterCardAddFormProps, CardAddFormValues>({
		mapPropsToValues: () => {
			return {
				barcode: "",
				activationCode: "",
				submitError: ""
			};
		},
		validate,
		handleSubmit: (values: CardAddFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false)
		},
	})
)(class CardAddForm extends Component<InnerCardAddFormProps & OuterCardAddFormProps> {

	componentWillUnmount() {
		this.props.resetForm();
	}

	render() {
		const {isValid, isSubmitting, handleSubmit, form, errors} = this.props;
		const btnProps = {
			className: "btn-submit",
			primary: true,
			block: true,
			type: "submit",
			disabled: !isValid || isSubmitting,
			loading: isSubmitting,
			children: getTemplateText("cardAddForm.button")
		};

		const nameLabel = getTemplateText( "cardAddForm.label.barcode");

		return (
			<Form onSubmit={handleSubmit} className="card-add-form" name={form}>
				<h3>{getTemplateText("cardAddForm.heading")}</h3>

				<FormField className="field-username">
					<Field name="barcode" type="barcode" component={FormInput} label={nameLabel}/>
				</FormField>

				{errors.submitError && <FormError>{errors.submitError}</FormError>}

				<Button {...btnProps}/>
			</Form>
		)
	}
});

export default CardAddForm;