import React, {FC, useEffect, useState} from 'react'
import Modal from 'react-responsive-modal';
import moment from 'moment';
import Mutation from '../../../../graphql/Mutation';
import Query from '../../../../graphql/Query';
import client from '../../../../services/apollo';
import {parseErrorOnfly} from '../../../../utils';
import BonusHistoryFilter from '../../../BonusHistoryFilter/BonusHistoryFilter';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';

import "./ChangeTimeModal.styl";
import RadioBtnLikeList from '../../../RadioBtnLikeList/RadioBtnLikeList';

const DATE_DAY_SWITCH = [
	{
		title: "Сьогодні",
		value: 0,
		disabled: false
	}, {
		title: "Завтра",
		value: 1,
		disabled: false
	}, {
		title: "Післязавтра",
		value: 2,
		disabled: false
	}
];

function getTimeLabel({timeFrom, timeTo}) {
	const from = moment(timeFrom).format("HH:mm");
	const to = moment(timeTo).format("HH:mm");
	return "з " + from + " до " + to;
}

type ChangeTimeModalProps = {
	isOpen: boolean;
	onClose: () => any;
	orderId: string;
	activeTime: string;
	refetchData: () => any;
};

const ChangeTimeModal: FC<ChangeTimeModalProps> = ({isOpen, onClose, orderId, activeTime, refetchData}) => {
	const [active, setActive] = useState(-1);
	const [activeDay, setActiveDay] = useState(0);
	const [loading, setLoading] = useState(true);
	const [lastActive, setLastActive] = useState(-1);
	const [loadingAnswer, setLoadingAnswer] = useState(false);
	const [list, setList] = useState<any>([]);

	const updateActiveItem = (active)=> {
		setActive(active)
	}

	const updateActiveDay = (activeDay)=> {
		setActiveDay(activeDay)
	}

	const submit = () => {
		setLoadingAnswer(true);
		const variables = {
			orderId,
			timeSlotId: active
		};

		client.mutate({
			...Mutation.changeTimeSlotByBasketGuid,
			variables
		})
			.then(({errors}) => {
				if (errors) {
					throw errors;
				}
				if (typeof refetchData === "function") {
					refetchData();
				}
				setLoadingAnswer(false);
				onClose();
			})
			.catch((err) => {
				setLoadingAnswer(false);
				onClose();
				parseErrorOnfly(err, true);
			});
	};

	const getTimeSlots = (basketGuid, activeTime) => {
		if (!basketGuid) {
			return;
		}
		setLoading(true)
		client.query({
			query: Query.getAvailableTimeSlotsByBasketGuid.query,
			fetchPolicy: "network-only",
			variables: {
				orderId: basketGuid
			}
		})
		.then(({data, errors}) => {
			if (errors) {
				throw errors
			}
			return data.availableTimeSlots;
		})
		.then((data) => {
			let active = -1;
			let activeDay = 0;
			for (let i = 0; i < data.length; i++) {
				const day = data[i];
				const time = ((day || {}).time || []);
				for (const t of time) {
					const timeFrom = t.timeFrom + ".000Z";
					if(timeFrom === activeTime){
						activeDay = i;
						active = t.id;
					}
				}
			}
			setActiveDay(activeDay);
			setActive(active);
			setLastActive(active);
			setLoading(false);
			setList(data);
		})
	}

	useEffect(() => {
		getTimeSlots(orderId, activeTime)
		// eslint-disable-next-line
	}, [orderId])

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{"overlay": "modal-overlay", "modal": "modal-window login-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}>
			<div className="change-time-modal__dialog">
				<h3 className="change-time-modal__heading">
					Оберіть зручний час для отримання замовлення
				</h3>
				<BonusHistoryFilter
					active={activeDay}
					setActive={updateActiveDay}
					filterFields={DATE_DAY_SWITCH}
					className="change-time-modal__switcher"/>
				{loading ? <Loader/>
					: <RadioBtnLikeList
							list={((((list || [])[activeDay] || {}).time || []).filter(item => item.isAvailable))}
							active={active}
							valueKey="id"
							disabled={(item) => !item.isAvailable}
							renderBtnTitle={getTimeLabel}
							updateActive={updateActiveItem}/>}
				<div className="change-time-modal__buttons-group">
					<Button primary
						disabled={active === -1 || active === lastActive}
						className="btn-proceed"
						loading={loadingAnswer}
						onClick={(e) => {
							e.preventDefault();
							submit();
						}}>Підтвердити</Button>
				</div>
			</div>
		</Modal>
	)
}

export default ChangeTimeModal