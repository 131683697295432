import "./DropDownTitle.styl";

import React, {FunctionComponent} from "react";
import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";

interface DropDownTitleProps {
	children: any;
}

const DropDownTitle: FunctionComponent<DropDownTitleProps> = ({children, ...props}) => {
	return (
		<button type="button" {...props} className="drop-down-title">
			<div className="drop-down-title-value">{children}</div>
			<div className="drop-down-title-caret"><Icon icon={ICONS.accountDots}/></div>
		</button>
	)
};

export default DropDownTitle;