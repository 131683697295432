import React, {Component} from 'react';
import {graphql} from "react-apollo";
import {compose} from "recompose";

import {BonusPagePropsType, BonusPageStateType} from "./types";

import Query from "../../graphql/Query";
import {BONUS_FILTER_TYPE, BONUS_TYPE_LINES} from "../../constants";
import {setDocumentTitle} from "../../services/TitleService";

import LoadHolder from "../../components/UI/Loader/LoadHolder";
import BonusPreview from "../../components/BonusPreview/BonusPreview";
import NoData from "../../components/UI/NoData/NoData";
import BonusHistoryFilter from "../../components/BonusHistoryFilter/BonusHistoryFilter";
import BonusHistory from "../../components/BonusHistory/BonusHistory";

import "./BonusPage.styl";


const BonusPage = compose<BonusPagePropsType, {}>(
	graphql(Query.getBonus.query, {name: "bonusData"}),
)(class PersonalPage extends Component<BonusPagePropsType, BonusPageStateType> {
	constructor(props) {
		super(props);
		this.state = {
			activeFilter: 0
		};
	}

	setActiveFilter = (id) => {
		this.setState({
			activeFilter: +id
		})
	};

	render() {
		const {bonusData} = this.props;
		const {activeFilter} = this.state;
		const {getBonus} = bonusData;

		const bonusLines = (!!getBonus && !!getBonus.bonusLines && !!getBonus.bonusLines.length && getBonus.bonusLines) || [];
		const onlyCreations = bonusLines.filter(check => check.typeLine === BONUS_TYPE_LINES.creation);
		const onlyDebiting = bonusLines.filter(check => check.typeLine === BONUS_TYPE_LINES.debiting || check.typeLine === BONUS_TYPE_LINES.blocking || check.typeLine === BONUS_TYPE_LINES.charity);

		const filterFields = [
			{
				title: "Всі",
				value: BONUS_FILTER_TYPE.ALL,
				disabled: !bonusLines.length
			}, {
				title: "Нарахування",
				value: BONUS_FILTER_TYPE.EARNINGS,
				disabled: !onlyCreations.length
			}, {
				title: "Списання",
				value: BONUS_FILTER_TYPE.CHARGES,
				disabled: !onlyDebiting.length
			},
		];


		setDocumentTitle("Персональний бонус");

		return (
			<LoadHolder isLoading={bonusData.loading}>
				<>
					{!!bonusData.getBonus && !!bonusData.getBonus.balance &&
					<BonusPreview currentBonus={bonusData.getBonus.balance} withDescription={false}/>
					}
					<BonusHistoryFilter active={activeFilter}
															setActive={this.setActiveFilter}
															filterFields={filterFields}/>
					<div className="personal-page__history">

						{(!!getBonus && !!getBonus.bonusLines && !!getBonus.bonusLines.length)
							? <div>
								<BonusHistory
									list={getBonus}
									activeFilter={activeFilter}
								/>
							</div>
							: <NoData>Вибачте, не знайдено жодних покупок.</NoData>
						}
					</div>
				</>
			</LoadHolder>

		)
	}
});

export default BonusPage;
