import React, {FunctionComponent} from "react";
import {CollapseType} from "./types";

import "./Collapse.styl";

const Collapse: FunctionComponent<CollapseType> = (props) => {
	const {children, open, className} = props;

		return (
			<div className={`collapse-item ${open? "collapse-item__open" : ""} ${className ? className : ""}`}>
				{open?
					children :
					null
				}
			</div>
		)
};

export default Collapse;
