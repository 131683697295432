import "./DropDown.styl"

import React, {Component} from "react";
import cn from "classnames";
import DropDownMenu from "./DropDownMenu/DropDownMenu";
import DropDownTitle from "./DropDownTitle/DropDownTitle";


interface DropDownState {
	open:boolean;
}

interface DropDownProps {
	button: JSX.Element;
	title?: string;
	className?: string;
	preventClose?: boolean;
	defaultOpen?: boolean;
	disabled?: boolean;
	menuComponent?: string | React.ComponentType<any>;
	pullRight?: boolean;
	onOpen: () => void;
	onClose?: () => void;
}


class DropDown extends Component<DropDownProps, DropDownState> {
	constructor(props) {
		super(props);
		this.state = {
			open: props.defaultOpen || false
		};
	}

	componentDidMount() {
		if (this.state.open) this.props.onOpen();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.open !== this.state.open) {
			if (this.state.open) {
				this.props.onOpen();
			}
			// else {
			// 	this.props.onClose();
			// }
		}
	}

	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	// clickHandlers or eventHandlers
	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	close = () => {
		this.setState({open: false});
	};

	open = () => {
		this.setState({open: true});
	};

	toggle = () => {
		this.setState({open: !this.state.open});
	};

	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	// Optional render methods
	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	render() {
		const {open} = this.state;
		const {className, button, title, children, disabled, preventClose, menuComponent, pullRight} = this.props;

		const titleProps = {
				...(disabled ? {} : {onClick: this.toggle}),
				title,
				children: button,
			},
			menuProps = {
				hide: this.close,
				preventClose,
				children,
				component: menuComponent
			};

		return (
			<div className={cn("drop-down", className, {open, disabled, "pull-right": pullRight})}>
				<DropDownTitle {...titleProps}/>
				{open && <div>
					<div className="drop-down-menu-wrapper" onClick={this.toggle} onTouchStart={this.toggle}/>
					<DropDownMenu {...menuProps}/>
				</div>}
				<i className="drop-down-arrow"/>
			</div>
		)
	}
}

export default DropDown;
