import React, {Component} from "react";
import PropTypes from "prop-types";

import {IPasswordInputProps, IPasswordInputState} from "./types";
import {ICONS} from "../../UI/Icon/enums";

import Icon from "../../UI/Icon/Icon";
import Button from "../../UI/Button/Button";


import "./PasswordInput.styl";

class PasswordInput extends Component<IPasswordInputProps, IPasswordInputState> {

	static propTypes = {
		disabled: PropTypes.bool,
		active: PropTypes.bool,
		placeholder: PropTypes.string,
		value: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			isPasswordShows: false
		};
	}

	/**
	 * toggles showing password
	 */
	toggleView = () => {
		this.setState((oldValue) => ({
			isPasswordShows: !oldValue.isPasswordShows,
		}));
	};

	render() {
		const {name, value, active, form, innerRef, ...otherProps} = this.props;
		const isPasswordShows = this.state.isPasswordShows;
		const type = isPasswordShows? "text" : "password";
		const _value = value ? value.trim() : "";
		return <div className="password-panel">
			<div className="password-holder">
				<input
					{...otherProps}
					type={type}
					value={_value}
					name={name}
					id={"focused-" + name}
					autoComplete="off"
					ref={innerRef}
				/>
			</div>
			{!!_value && <Button className="password-button" onClick={this.toggleView}>
				{isPasswordShows ? <Icon icon={ICONS.openedEye}/> : <Icon icon={ICONS.closedEye}/>}
			</Button>}
		</div>;
	}
}


export default PasswordInput;