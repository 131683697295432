import React from 'react'
import {Field} from 'formik'
import isTouchDevice from "is-touch-device";

import FormField from '@components/Form/FormField'
import FormInput from '@components/Form/FormInput'
import InfoIcon from '@components/UI/InfoIcon/InfoIcon'
import getTemplateText from '@services/TextService'

const UserEmailField = ({onEmailChange}) => {
	const isTouch = isTouchDevice();

	return (
		<FormField className="email_form-field">
			<Field
				name="email"
				component={FormInput}
				label="Ел.пошта"
				onChange={onEmailChange}
				type="text"
			/>
			<div className="email-controls">
				<InfoIcon
					place={isTouch ? "bottom" : "right"}
					event={isTouch ? "click" : "mouseenter"}
					eventOff="mouseleave"
				>
					{getTemplateText("createPasswordWithEmailVerificationForm.email.tooltip")}
				</InfoIcon>
			</div>
		</FormField>
	)
}

export default UserEmailField