import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {HttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {from} from 'apollo-link';
import {getTokenData} from "./AppServices";
import {isDev, API_ENDPOINT} from "./EnvServices";
import {OPERATION_NAME} from "../constants";
import {sendError} from "./LogService";
import {clearUser} from "../actions/userActions";
import CookiesService from "./CookiesService";
import { Guid } from "guid-typescript";
import {getCorrelationId} from "./UserGuid";

const client = new ApolloClient({
	link : from([
		onError(({graphQLErrors, networkError, operation}) => {
			if (graphQLErrors) {
				if (JSON.stringify(graphQLErrors).indexOf("API Key") !== -1) {
					CookiesService.removeItem("accessToken");
				}
				isDev && console.log("graphQLErrors");
				isDev && console.error(graphQLErrors);
				if (operation.operationName !== "frontLog") {
					sendError(graphQLErrors[0], {operation});
				}
				if (!!graphQLErrors[0] && (graphQLErrors[0]["code"] === 403 || graphQLErrors[0]["code"] === 401)) {
					clearUser();
					window.location.replace("/");
				}
				//sendToLoggingService(graphQLErrors);
			}
			if (operation.operationName !== "frontLog" && networkError) {
				sendError(networkError, {operation});
			}
		}),
		new HttpLink({
			uri: API_ENDPOINT,
			fetch: async (input, init)  => {
				init = init || {headers: {}};
				let operationName = "";
				const tokenData = await getTokenData();

				if (typeof init.body === "string") {
					const initBody = JSON.parse(init.body) || {};
					operationName = initBody["operationName"] || "";
				}
				const accessToken = !OPERATION_NAME[operationName] && tokenData.isValid ? tokenData.accessToken: "";
				if (accessToken) {
					((init || {}).headers || {})["access-token"] = accessToken || "";
				}
				((init || {}).headers || {})["X-Correlation-Id"] = Guid.raw();
				((init || {}).headers || {})["X-Session-Id"] = getCorrelationId();

				return fetch(input, init);
			}
		})
	]),
	cache: new InMemoryCache(),

});

export default client;