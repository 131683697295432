import React, {useEffect, useRef} from 'react'
import {FormikValues, useFormikContext} from 'formik'

import Form from '@components/Form/Form'
import FormError from '@components/Form/FormError/FormError'
import Button from '@components/UI/Button/Button'
import UserBDayField from './components/UserBDayField'
import UserEmailField from './components/UserEmailField'
import UserNameFields from './components/UserNameFields'
import UserPasswordFields from './components/UserPasswordFields'
import {form} from './const'

const RegistrationInfoEntryForm = () => {
	const isMount = useRef(false);
	const {handleSubmit, isSubmitting, errors, isValid, setFieldValue, resetForm} = useFormikContext<FormikValues>();
	const error = !!errors && (errors.submitError || "");

	useEffect(() => {
		isMount.current = true;
		return () => {
			isMount.current = false;
			resetForm();
		}
		// eslint-disable-next-line
	}, [])

	const onEmailChange = ({target}) => {
		const value = target.value || "";
		setFieldValue("email", value.trim());
	};

	return (
		<Form onSubmit={handleSubmit} name={form} className="registration-personal-info-form">
			<UserNameFields />
			<UserBDayField />
			<UserPasswordFields />
			<UserEmailField onEmailChange={onEmailChange} />
			{error && <FormError>{error}</FormError>}
			<Button
				className="btn-submit"
				primary
				type="submit"
				disabled={!isValid || isSubmitting}
				loading={isSubmitting}>Продовжити</Button>
		</Form>
	)
}

export default RegistrationInfoEntryForm