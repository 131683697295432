import "./Form.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";

function submitDefault(e) {
	e.preventDefault();
}

type FormPropsType  = {
	className?:  string;
	onSubmit?: (e: any) => any;
	[key: string]: any;
}

const Form: FunctionComponent<FormPropsType> = ({className, onSubmit = submitDefault, ...restProps}: FormPropsType) => {
	const props = {
		...restProps,
		onSubmit,
		className: cn("form", className)
	};

	return <form {...props} />
};

export default Form;