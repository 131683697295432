import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';

import {ROUTE} from '../../services/RouteService/enums';
import getTemplateText from '../../services/TextService';
import {getQueryParams, queryParamsToSearchString} from '../../services/RouteService/RouteService';
import {QUERY_DATE_FORMAT, VOSTOK_SDK} from '../../constants';

import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import DatePicker from './components/DatePicker/DatePicker';
import Filters from './components/Filters/Filters';
import FiltersHeader from './components/FiltersHeader/FiltersHeader';
import PurchasesList from './components/PurchasesList/PurchasesList';
import Loader from '../../components/UI/Loader/Loader';

import "./PurchasesPage.styl";

const PurchasesPage = () => {
	const history = useHistory();
	const location = useLocation();
	const {to, from, ...other} = getQueryParams(location);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!window["EcomSDK"]) {
			let SDK = document.createElement("script");
			SDK.src = VOSTOK_SDK;
			document.head.append(SDK);
		}
	}, [])

	useEffect(() => {
		if (!to && !from) {
			history.replace({
				search: queryParamsToSearchString({
					...other,
					from: moment().subtract(1, "M").format(QUERY_DATE_FORMAT),
					to: moment().format(QUERY_DATE_FORMAT),
				})
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [])

	if (loading) {
		return <Loader />
	}

	return (
		<div className='purchases-page'>
			<BreadCrumbs labels={{
				[ROUTE.PERSONAL_PAGE]: "Персональна сторінка",
				[ROUTE.PURCHASES]: getTemplateText("myPurchases.title")
			}} />
			<div className='purchases-header'>
				<FiltersHeader />
				<div className='header-filters'>
					<DatePicker />
					<Filters />
				</div>
			</div>
			<PurchasesList />
		</div>
	)
}

export default PurchasesPage