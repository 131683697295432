import React, {FunctionComponent} from "react";
import Select, {Async, AsyncCreatable} from "react-select";
import Icon from "../Icon/Icon";
import {ICONS} from "../Icon/enums";
import cn from "classnames";
import "./Select.styl";

type SelectPropsType = {
	clearable?: boolean;
	searchable?: boolean;
	async?: boolean;
	arrowRenderer?: (onMouseDown: any) => any;
	[key: string]: any;
}

function ArrowRenderer({onMouseDown}) {
	return <span onMouseDown={onMouseDown} className="ui-select-arrow"><Icon icon={ICONS.selectArrow}/></span>
}

const UISelect: FunctionComponent<SelectPropsType> = ({async, creatable, className, components, ...props}: SelectPropsType) => {
	let Component: any = Select;

	const selectProps = {
		...props,
		className: cn(className, "Select"),
		components: {DropdownIndicator: ArrowRenderer, ...components},
		classNamePrefix: "Select"
	};

	if (creatable) {
		Component = AsyncCreatable;
	} else if (async) {
		Component = Async;
	}

	return <Component {...selectProps}/>
};


UISelect.defaultProps = {
	clearable: false,
	searchable: false,
	async: false,
	arrowRenderer: ArrowRenderer,
};

export default UISelect;