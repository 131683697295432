import "./ContactCenterModal.styl";

import React, {FunctionComponent} from "react";
import Modal from 'react-responsive-modal';

import {FormattedHTMLMessage} from "../../../services/TextService";

import Panel from "../../UI/Panel/Panel";
import {ContactCenterPropsType} from "./types";


export const ContactCenterModal: FunctionComponent<ContactCenterPropsType> = ({isOpen, onClose}) => {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{"overlay": "modal-overlay", "modal": "modal-window registration-contact-center__dialog", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
		>
			<Panel className="registration-contact-center__panel">
				<FormattedHTMLMessage id="registration.to_few_questions"/>
			</Panel>
		</Modal>
	);
};



