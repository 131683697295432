import React, {Component} from "react";
import {compose, withProps} from "recompose";

import "./PersonalInformationForm.styl";
import {FORMS} from "../enums";
import {DATA_TYPE, DATA_TYPE_RULES, RULE} from "../../services/FormServices/constants";
import {DATA_TYPE_PARAMS, normalizeFields, validate} from "../../services/FormServices/FormService";
import {Field, FormikProps, withFormik} from "formik";
import {IFormProps} from "../../services/FormServices/interfaces";
import FormField from "../../components/Form/FormField";
import FormLabel from "../../components/Form/FormLabel";
import {ENUM} from "../../constants";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import RadioButton from "../../components/Form/RadioButton";
import FormRow from "../../components/Form/FormRow";
import FormInput from "../../components/Form/FormInput";
import Icon from "../../components/UI/Icon/Icon";
import {ICONS} from "../../components/UI/Icon/enums";
import Button from "../../components/UI/Button/Button";
import Form from "../../components/Form/Form";
import FormError from "../../components/Form/FormError/FormError";


export type PersonalInformationFormValues = {
	firstName: string;
	lastName: string;
	middleName: string;
	gender: string;
	birthday: string;
	submitError: string;
}

type OuterPersonalInformationPropsType = {
	initialValues: Partial<PersonalInformationFormValues>
	onSubmit: (values: PersonalInformationFormValues, formProps: any) => any;
	showSuccess: boolean;
}

type InnerPersonalInformationPropsType = FormikProps<PersonalInformationFormValues> & IFormProps;


const form = FORMS.PERSONAL_INFORMATION_FORM,
	fields = {
		firstName: {
			name: "firstName",
			dataType: DATA_TYPE.name,
		},
		lastName: {
			name: "lastName",
			dataType: DATA_TYPE.name,
		},
		middleName: {
			name: "middleName",
			// dataType : DATA_TYPE.name,
			rules: DATA_TYPE_RULES[DATA_TYPE.name].filter(d => d !== RULE.required),
			params: DATA_TYPE_PARAMS[DATA_TYPE.name]
		},
		gender: {
			name: "gender",
			dataType: DATA_TYPE.bool,
		},
		birthday: {
			name: "birthday",
			dataType: DATA_TYPE.birthday,
		},
	};

const PersonalInformationForm = compose<InnerPersonalInformationPropsType & OuterPersonalInformationPropsType, OuterPersonalInformationPropsType>(
	withProps(() => {
		return {
			form,
			fields: normalizeFields(fields, form),
		}
	}),
	withFormik<IFormProps & OuterPersonalInformationPropsType, PersonalInformationFormValues>({
		mapPropsToValues: ({initialValues}) => {
			return {
				firstName: initialValues.firstName ? initialValues.firstName : "",
				lastName: initialValues.lastName ? initialValues.lastName : "",
				middleName: initialValues.middleName ? initialValues.middleName : "",
				gender: initialValues.gender ? initialValues.gender : "",
				birthday: initialValues.birthday ? initialValues.birthday : "",
				submitError: ""
			};
		},
		validate,
		enableReinitialize: true,
		handleSubmit: (values: PersonalInformationFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false);
		},
	})
)(class PersonalInformationForm extends Component<OuterPersonalInformationPropsType & InnerPersonalInformationPropsType, {}> {
	componentWillUnmount() {
		this.props.resetForm();
	}

	render() {
		const {isValid, isSubmitting, handleSubmit, errors, showSuccess, dirty} = this.props;
		const btnProps = {
			className: "btn-submit",
			gray: true,
			type: "submit",
			disabled: !isValid || isSubmitting || !dirty,
			loading: isSubmitting,
			// children  : formatHTMLMessage({id : "contactForm.btn_submit"}),
			children: "зберегти",
		};


		return (
			<Form onSubmit={handleSubmit} className="personal-information__form" name={form}>
				<div className="form__body">
					<h1 className="heading3">{getTemplateText("personalInformationForm.heading")}</h1>
					<hr/>
					<div className="form__text">
						{renderTemplateTextNode("personalInformationForm.info_text")}
					</div>

					<FormField>
						<FormLabel>Стать</FormLabel>
						<div className="radio-buttons radio-buttons__sex">
							{[
								{value: ENUM.MALE, children: renderTemplateTextNode("male.short")},
								{value: ENUM.FEMALE, children: renderTemplateTextNode("female.short")},
							].map(d => (
								<Field
									{...d}
									key={d.value}
									name="gender"
									checked={this.props.values.gender === d.value}
									component={RadioButton}
									type="radio"
								/>
							))}
						</div>
					</FormField>

					<FormRow>
						<FormField>
							<Field name="firstName" component={FormInput} label={"Ім’я"}/>
						</FormField>

						<FormField>
							<Field
								name="lastName"
								component={FormInput}
								label={"Прізвище"}
							/>
						</FormField>


					</FormRow>
					<FormRow>

						<FormField>
							<Field
								name="middleName"
								 component={FormInput}
								 label={"По батькові"}
							/>
						</FormField>

						<FormField>
							<Field
								name="birthday"
								component={FormInput}
								type="date"
								placeholder={"дд/мм/рррр"}
								label={"Дата народження"}
								className="field__input-birthday"
							/>
						</FormField>
					</FormRow>
				</div>

				<div className="form__footer">
					<div className="form__footer-status">
						{errors && errors.submitError && <FormError>{errors.submitError}</FormError>}

						{showSuccess && (
							<div className="form__footer-success">
								<Icon icon={ICONS.circleCheck}/>{getTemplateText("form.success")}
							</div>
						)}
					</div>

					<Button {...btnProps}/>
				</div>
			</Form>
		)
	}
});

export default PersonalInformationForm;
