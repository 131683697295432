import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";

import {HASH_MODALS} from "../../constants";
import {getActiveModalName} from "../../services/RouteService/RouteService";

import {ContactCenterModal} from "../Modals/ContactCenterModal/ContactCenterModal";
import ErrorModal from "../Modals/ErrorModal/ErrorModal";
import HowGetCardModal from "../Modals/HowGetCardModal/HowGetCardModal";
import {CardRecoveryModal} from "../Modals/СardRecoveryModal/СardRecoveryModal";
import InfoModal from "../Modals/InfoModal/InfoModal";
import WelcomeModal from "../Modals/WelcomeModal/WelcomeModal";
import SuccessPaymentModal from "../Modals/SuccessPaymentModal/SuccessPaymentModal";


interface HashModalState {
	open: boolean,
	barcode: string;
	registeredWithoutEmail: boolean;
}

interface HashModalProps {
}

type HashModalType = {
	mount: boolean;
}

class HashModal extends Component<RouteComponentProps<HashModalProps>, HashModalState> implements HashModalType {

	mount: boolean = false;

	state = {
		open: true,
		barcode: "",
		registeredWithoutEmail: false
	};

	componentWillMount() {
		this.mount = true;
	}

	componentWillReceiveProps(nextProps: RouteComponentProps<HashModalProps>, nextContext: any): void {
		if (nextProps.history.location.hash) {
			this.setState({open: true});
		}
	}

	componentWillUnmount() {
		this.mount = false;
	}

	onCloseModal = (): void => {
		const {history} = this.props;
		if (this.mount) {
			this.setState({open: false});
		}
		history.replace({...history.location, hash: ""});
	};

	/**
	 * handle register attempt when already registered
	 * @param alreadyRegisteredStateObj
	 */
	handleRegisteredUserRegisterAttempt = (alreadyRegisteredStateObj: object) => {
		this.setState(alreadyRegisteredStateObj);
	};

	getModalFromHash(history: {location: {hash: string}}, isOpen: boolean, onClose: () => void) {

		const name = getActiveModalName(history.location.hash);

		const defaultModalProps = {
			isOpen,
			onClose
		};

		if (!history.location.hash) {
			return null;
		}
		switch (name) {

			case HASH_MODALS.CARD_RECOVERY:

				return (
					<CardRecoveryModal {...defaultModalProps} />
				);

			case HASH_MODALS.CONTACT_CENTER:

				return (
					<ContactCenterModal {...defaultModalProps} />
				);

			case HASH_MODALS.ERROR_MESSAGE:

				return (
					<ErrorModal {...defaultModalProps} />
				);

			case HASH_MODALS.HOW_GET_CARD:

				return (
					<HowGetCardModal {...defaultModalProps} />
				);

			case HASH_MODALS.INFO:

				return (
					<InfoModal {...defaultModalProps} />
				);

			case HASH_MODALS.WELCOME:

				return (
					<WelcomeModal {...defaultModalProps} />
				);

			case HASH_MODALS.SUCCESS_PAYMENT:

				return (
					<SuccessPaymentModal {...defaultModalProps} />
				);
			default:
				return null
		}
	}

	render() {
		return (
			this.getModalFromHash(this.props.history, this.state.open, this.onCloseModal)
		)
	}
}

export default HashModal;



