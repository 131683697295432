import CookiesService from "./CookiesService";
import moment from "moment";
import {isString, browser} from "../utils";

let timeDiff = parseInt((localStorage.getItem("timeDiff") || "0"));

/**
 * get diff btw local time and server time in milliseconds
 * @param serverTime
 * @returns {number}
 */
function setLocalAndServerTimeDiff(serverTime) {
	const outerTime = moment(serverTime, "HH:mm:ss");
	const localTime = moment();
	timeDiff = localTime.diff(outerTime);
	localStorage.setItem("timeDiff", timeDiff ? "" + timeDiff : "0");
	return timeDiff;
}

let scrollbarsRef: any = null;

/**
 * @return vertical scroll position
 */
function getCurrentScrollPosition() {
	if (scrollbarsRef !== null) {
		return scrollbarsRef.getValues().scrollTop;
	}
}

/**
 * scroll to needed vertical position
 * @param top
 */
function customScrollTop(top) {
	if (scrollbarsRef !== null) {
		scrollbarsRef.scrollTop(top);
	}
}



/**
 * get time diff
 * @returns {number}
 */
function getLocalAndServerTimeDiff() {
	return timeDiff;
}

/***
 * Get access token object
 * @returns {{isValid: boolean, accessToken: *, expiresAt: *}}
 */
function getTokenData() {
	const accessToken = CookiesService.getItem("accessToken"),
		expiresAt = CookiesService.getItem("expiresAt"),
		diff = getLocalAndServerTimeDiff();

	return {
		accessToken,
		expiresAt,
		isValid: !!accessToken && !!expiresAt && moment(expiresAt).isValid() && moment().isSameOrBefore(moment(expiresAt).add(diff, "milliseconds"))
	}
}

/***
 * Get nearest store
 * @returns {Array}
 */
function getNearestStore() {
	const cookiesStorage = CookiesService.getItem("nearestStores");
	return isString(cookiesStorage) ? cookiesStorage.split(",") : []
}


/***
 * Get url from object
 * @param image
 * @param propName
 * @returns {*}
 */
function getUrl(image, propName) {
	return (image || {})[propName || "url"];
}
function getPageMaxScroll() {
	// Cross browser page height detection is ugly
	return Math.max(
		document.body.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.clientHeight,
		document.documentElement.scrollHeight,
		document.documentElement.offsetHeight
	) - window.innerHeight; // Subtract viewport height
}
/***
 * Place target element in same place in view port
 * @param target
 */
function fixElementInViewPort(target: HTMLDivElement): void {
	if (browser.isSafari) {
		return;
	}
	const oldTopElementPositionInViewPort = target.getBoundingClientRect().top;
	const update = function update() {
		const newTopElementPositionInViewPort = target.getBoundingClientRect().top;
		let top = document.documentElement.scrollTop - (oldTopElementPositionInViewPort - newTopElementPositionInViewPort);
		const maxScroll = getPageMaxScroll();
		if (top > maxScroll) {
			top = maxScroll;
		}
		window.scrollTo({top, behavior: "auto"})
	};

	setTimeout(update, 0);
}

/***
 * Verifies that the user has agreed to the use of cookies
 */
function checkAgreeUseCookies() {
	const agreeUseCookies = CookiesService.getItem("agreeUseCookies");
	return !!agreeUseCookies
}

export {
	fixElementInViewPort,
	getUrl,
	customScrollTop,
	getCurrentScrollPosition,
	getNearestStore,
	getTokenData,
	setLocalAndServerTimeDiff,
	checkAgreeUseCookies
}