import {AUTH_NEW_ENTRANCE_ACTIONS} from "@constants";
import {AccessTokenDataType, ContactUsedForOtpDataType, FlowResponseDataType} from "@graphql/types";

export type EntranceType = "ECOMMERCE" | "LOYALTY";

export enum ContactTypes {
	PHONE = "PHONE",
	EMAIL = "EMAIL"
}

export type StoredAuthData = {
	flowId: number,
	phone: string;
	email: string;
	contactType: ContactTypes;
	contactId: null | number;
	nextStep: null | AUTH_NEW_ENTRANCE_ACTIONS;
}

export type DataType =
	FlowResponseDataType &
	ContactUsedForOtpDataType &
	AccessTokenDataType;

export type FieldsDataType = Omit<FlowResponseDataType[keyof FlowResponseDataType], "__typename">
	& Omit<ContactUsedForOtpDataType[keyof ContactUsedForOtpDataType], "__typename">
	& {
		accessToken: string;
		expiresAt: string;
	};

export type AuthNewPropsType = {
	onClose?: () => void;
}

export enum AUTH_TYPE {
	ECOMMERCE = "ECOMMERCE",
	LOYALTY = "LOYALTY",
};

export type AuthFormPropsType = {
	determineNextStep: (nextAuthStep: AUTH_NEW_ENTRANCE_ACTIONS | null) => void;
	onClose?: () => void;
}

export type EntrancePropsType = {
	entranceType?: "ECOMMERCE" | "LOYALTY"
}