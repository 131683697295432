import {ACTION_TYPES} from "./enums";

export default function reducer(state, action) {
	switch (action.type) {
		case ACTION_TYPES.logout:
			return { ...state, user: {}, isLogin: false};
		case ACTION_TYPES.init:
		case ACTION_TYPES.login:
		case ACTION_TYPES.setUser:
			return { ...state, ...action.payload };
		case ACTION_TYPES.setGoogleCaptureKey:
			return {
				...state,
				user: {
					...(state.user || {}),
					capture_key: action.payload
				}
			};
		case ACTION_TYPES.resetGCapture:
			return {
				...state,
				user: {
					...(state.user || {}),
					capture_key: ""
				}
			};
		case ACTION_TYPES.SET_ACTIVE_NEAREST_STORE:
			return {
				...state,
				nearestStore: {
					...(state.nearestStore || {}),
					items: action.payload
				}
			};
		case ACTION_TYPES.SET_SIGH_UP_MODAL:
			return {
				...state,
				sighUpModal: action.payload,
			};
		default:
			return state;
	}
}