import React, {Component} from "react";
import {graphql} from "react-apollo";
import {compose} from "recompose";
import Toggle from "react-toggle"
import cn from "classnames";

import {MoneyboxProps, MoneyboxState} from "./types";

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {MONEYBOX_STATUS, ERRORS} from "../../constants";
import {FORMS} from "../../forms/enums";
import {ICONS} from "../UI/Icon/enums";
import {GA} from "../../services/Analitics";
import {FormattedHTMLMessage} from "../../services/TextService";

import Icon from "../UI/Icon/Icon";
import FormError from "../Form/FormError/FormError";
import MoneyboxControls from "./MoneyboxControls/MoneyboxControls";

import "./Moneybox.styl";


const Moneybox = compose<MoneyboxProps, {}>(
	graphql(Query.moneyBox.query, {name: "moneyboxData"}),
	graphql(Mutation.changeMoneyBoxStatus.mutation, {name: "moneyboxMutation"})
)( class Moneybox extends Component<MoneyboxProps, MoneyboxState>{
	constructor(props) {
		super(props);
		this.state = {
			mutationLoading: false,
			error: null,
			toggleState: null,
			showControlPanel: this.props.isMoneyboxPage || false,
		};
	}

	sendGA = (status) => {
		if (status === MONEYBOX_STATUS.DEACTIVATED) {
			GA("send", "event", "skarbnychka", "close", "finish");
		} else if (status === MONEYBOX_STATUS.ACTIVATED) {
			GA("send", "event", "skarbnychka", "option", "auto");
		} else if (status === MONEYBOX_STATUS.NOT_ACTIVATED) {
			GA("send", "event", "skarbnychka", "option", "manual");
		}
	};

	changeMoneyboxStatus = (status) => {
		this.setState({mutationLoading: true});

		return this.props.moneyboxMutation({
			variables: {
				status
			}
		})
			.then(resp => {
				this.setState({mutationLoading: false});
				const {data: {changeMoneyBoxStatus}} = resp;
				if (changeMoneyBoxStatus) {
					this.props.moneyboxData.refetch();
					this.setState({error: null})
				}
				this.sendGA(status);

				return resp;
			})
			.catch(err => {
				this.setState({mutationLoading: false});
				const {graphQLErrors} = err;
				if (graphQLErrors && graphQLErrors[0].code === ERRORS.CODE_USER_WITHOUT_EMAIL) {
					this.setState({	error: graphQLErrors[0].message })
				}

				return err;
			});
	};

	toggleVisibilityControlPanel = () => {
		this.setState(({showControlPanel}) => ({
			showControlPanel: !showControlPanel
		}))
	};


	render() {
		const {moneyboxData: {moneyBox}} = this.props;
		const {status = ""} = moneyBox || {};
		const {mutationLoading, error, toggleState, showControlPanel} = this.state;

		const isActivated = (status === MONEYBOX_STATUS.ACTIVATED || status === MONEYBOX_STATUS.NOT_ACTIVATED);

		return (
			<div className="moneybox_container">
				<div className="preview-panel">
					<Icon icon={ICONS.moneybox} raw/>

					<div className="title-line">
						<h2 className="moneybox-title heading3"><FormattedHTMLMessage id="personalPage.moneybox.heading"/></h2>
						<div className="switch-wrap">
							<Toggle
								className="moneybox-toggle"
								checked={toggleState !== null ? toggleState : isActivated}
								icons={false}
								onChange={() => {
									if (!mutationLoading) {
										this.setState({toggleState: !isActivated});
										this.changeMoneyboxStatus(isActivated ? MONEYBOX_STATUS.DEACTIVATED : MONEYBOX_STATUS.NOT_ACTIVATED)
									}
								}}
							/>
							<div className="moneybox-tooltip">
								<Icon className="info-icon moneybox-tooltip-icon">
									<div className="tooltip-massage"><FormattedHTMLMessage id="personalPage.moneybox.hint"/></div>
								</Icon>
							</div>
						</div>
					</div>

					{isActivated && <Icon className={cn({"open": showControlPanel})}
						onClick={this.toggleVisibilityControlPanel} icon={ICONS.chevronDown} raw/>}
				</div>

				{isActivated && <MoneyboxControls
					onSubmit={this.changeMoneyboxStatus}
					initialValues={{type: status}}
					mutationLoading={mutationLoading}
					form={FORMS.MONEY_BOX_FORM}
					isOpen={showControlPanel}
				/>}

				{!!error && <FormError className="-center">{error}</FormError>}
			</div>
		)
	}
});


export default Moneybox;