import "./FormError.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";

import {renderNode} from "../../../utils";

type FormErrorPropsType = {
	[key: string] : any
};

const FormError: FunctionComponent<FormErrorPropsType>  = ({className, children, ...props}: FormErrorPropsType) => (
	<div className={cn("form__error", className)}>
		{renderNode(children)}
	</div>
);

export default FormError;