import React, {FC} from 'react'
import getTemplateText from '@services/TextService'

type ChangeFlowButtonPropsType = {
	className: string;
	textId: string;
	onClick: (e) => void;
}

const ChangeFlowButton: FC<ChangeFlowButtonPropsType> = ({className, textId, onClick}) => {
	return (
		<div className={className} onMouseDown={onClick}>
			{getTemplateText(textId)}
		</div>
	)
}

export default ChangeFlowButton