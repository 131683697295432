import React, {FC} from 'react';
import classNames from 'classnames';
import {useMutation} from '@apollo/react-hooks';
import {FormikValues, useFormikContext} from 'formik';

import Mutation from '@graphql/Mutation';
import {getResponseFields, getStoredAuthData} from '../../helpers';
import {handleFormErrors} from '@services/FormServices/FormService';

import getTemplateText from '@services/TextService';
import Loader from '@components/UI/Loader/Loader';

import {GoToOtpLoginResponseType, GoToOtpLoginVariablesType} from '@graphql/types';
import {AuthFormPropsType} from '../../types';

const GoToOptBtn: FC<AuthFormPropsType & {isMobile?: boolean}> = ({determineNextStep, isMobile}) => {
	const {flowId, phone} = getStoredAuthData();
	const [goToOtpLogin, {loading: goToOtpLoading}] = useMutation<GoToOtpLoginResponseType, GoToOtpLoginVariablesType>(Mutation.goToOtpLogin.mutation);

	const {setSubmitting, setErrors} = useFormikContext<FormikValues>();

	const handleGoToOtp = (e) => {
		e.preventDefault();
		if (goToOtpLoading) {
			return;
		}

		return goToOtpLogin({variables: {flowId}})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const goToOtpLogin = data.goToOtpLogin;
				if (goToOtpLogin && !errors) {
					const {nextStep, flowId, contactType, contactValue} = getResponseFields(goToOtpLogin);
					localStorage.setItem("authData", JSON.stringify({flowId, nextStep, [contactType.toLowerCase()]: contactValue || phone, contactType}));
					determineNextStep(nextStep);
				}
			})
			.catch((err) => {
				handleFormErrors(err, {setSubmitting, setErrors});
			})
	};

	return (
		<div className={classNames("password-form__go_to_otp", {"mobile": !!isMobile})} onMouseDown={handleGoToOtp}>
			{getTemplateText("passwordForm.go_to_otp")}
			{goToOtpLoading && <Loader />}
		</div>
	)
}

export default GoToOptBtn