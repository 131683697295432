import {FORMS} from "js/forms/enums";
import {DATA_TYPE} from "@services/FormServices/constants";
import {normalizeFields, validate} from '@services/FormServices/FormService';

export const form = FORMS.CONTACT_RECOVERY_FORM;
export const fields = {
	password: {
		name: "contact",
		dataType: DATA_TYPE.bool,
	}
};

export const initialValues = {
	contact: "",
	submitError: ""
}

export const handleValidate = (values) =>
	validate(values, {fields: normalizeFields(fields, form), form});