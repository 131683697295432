import React, {Component} from "react";
import cn from "classnames";
import objectPath from "object-path";

import Select from "../UI/Select/Select";
import FormError from "./FormError/FormError";
import FormLabel from "./FormLabel";
import {FieldProps} from "formik";

type FormSelectPropsType = FieldProps & {
	label?: string;
	className?: string;
	disabled?: boolean;
	maxLength?: number;
	[key: string]: any;
}

type FormSelectStateType =  {
	focused: boolean;
}

class FormSelect extends Component<FormSelectPropsType, FormSelectStateType> {

	state = {
		focused: false
	};

	/**
	 * handles onfocus event
	 */
	handleFocus = () => {
		this.setState({focused: true})
	};

	/**
	 * handles onblur event
	 */
	onBlur = () => {
		this.setState({focused: false});
		this.props.form.setFieldTouched(this.props.field.name, true);
	};

	render() {
		const {field, form,  label, className, disabled, maxLength, ...fieldProps} = this.props;
		const {focused} = this.state;
		const {touched, errors={}} = form;
		const {name} = field;
		const error = objectPath.get(errors, name, null);
		const isTouched = objectPath.get(touched, name, null);
		const showError = !focused && !!error && isTouched;

		const labelProps = {
				className: cn("form__label--focusable form__label--select", {
					focused: focused,
					"not-empty": field.value,
					error: showError,
					disabled,
					searchable: fieldProps.isSearchable
				}),
				children: label,
			},
			inputProps = {
				className: cn("theme--violet", className, {error: showError}, {"is-focused": focused},{"has-value": field.value})
			};
		return (
			<div className={cn("form__input-holder form__select-holder", {disabled})}>
				{showError && <FormError>{error}</FormError>}
				{!!label && <FormLabel{...labelProps}/>}
				<div className="form__input">
					<Select
						key={field.value}
						{...inputProps}
						{...field}
						onBlur={this.onBlur}
						onFocus={this.handleFocus}
						{...fieldProps}
						disabled={disabled}
						placeholder={label}
						inputProps={{maxLength: maxLength || undefined}}
					/>
				</div>
			</div>
		)
	}
}

export default FormSelect;