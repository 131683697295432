import React, {Component} from "react";
import {ApolloQueryResult} from "apollo-client"
import Modal from 'react-responsive-modal';
import "moment/locale/uk";

import Query from "../../../graphql/Query";
import {CheckDetail, CheckReward} from "../../../graphql/types";
import client from "../../../services/apollo";
import Loader from "../../UI/Loader/Loader";
import {Currency, FormattedHTMLMessage} from "../../../services/TextService";
import {ICONS} from "../../UI/Icon/enums";
import {PAYMENT_TYPE} from "../../../constants";
import Icon from "../../UI/Icon/Icon";
import Button from "../../UI/Button/Button";
import Link from "../../UI/Link";
import {isDev} from "../../../services/EnvServices";
import Sharer from "./Sharer/Sharer";

import {HistoryCheckPropsType, HistoryCheckStateType} from "./types";

import "./HistoryCheck.styl";

const TO_MONEYBOX = "На копiйковий рахунок";

const DEV_RECEIPT_HOST = "https://receipt.test.fora.ua/";
const PROD_RECEIPT_HOST = "https://receipt.fora.ua/";
const isMobile = window.innerWidth <= 500;

class HistoryCheck extends Component<HistoryCheckPropsType, HistoryCheckStateType> {
	constructor(props) {
		super(props);
		this.state = {
			check: null,
			showSharer: false,
		};
	}

	componentWillMount() {
		const {storeId, loyaltyFactId} = this.props.checkInfo;
		if (storeId && loyaltyFactId) {
			client.query({
				...Query.cheque,
				variables: this.props.checkInfo
			}).then((res: ApolloQueryResult<{cheque: CheckDetail}>) => {
				this.setState({check: res.data.cheque})
			})
		}
	}

	toogleSharer = (isOpen) => {
		this.setState({showSharer: isOpen})
	}

	/***
	 * renders list of used personal proposals
	 * @param rewards
	 * @returns {*|boolean}
	 */
	renderRewards = (rewards: CheckReward[] = []) => (
		!!rewards && !!rewards.length &&
		<div>
			<div className="personal-proposal-caption">
				<div className="icon-holder">
					<Icon icon={ICONS.label} />
				</div>
				<FormattedHTMLMessage id="personalPage.history.proposals_title" />
			</div>
			<ul className="personal-proposal-list">
				{rewards.map((reward, i) => {
					if (!reward) {
						return null;
					}
					const {rewardTypeId, applyText, signText, rewardValue, unitText} = reward;

					return (
						<li key={rewardTypeId || i} className="personal-proposal-list-item">
							<div className="list-item--inner">
								{unitText !== "%"
									?
									<div className="personal-proposal-value">
										<div className="proposal-value-sign">
											<span>{signText}</span>
										</div>
										<div className="value-wrapper">
											<span className="proposal-value">{rewardValue}</span>
											<span className="proposal-value-unit">{unitText}</span>
										</div>
									</div>
									:
									<div className="personal-proposal-value">
										<span className="proposal-value">{rewardValue}{unitText}</span>
									</div>
								}
								<div className="personal-proposal-title">
									<span>{applyText}</span>
									<hr />
								</div>
							</div>
						</li>
					)
				})
				}
			</ul>
		</div>
	);

	renderProductList = (check: CheckDetail) => (
		<ul className="product-list">
			{((check && check.chequeLines) || []).map((item, i) => {
				const {lagerId, lagerNameUA, lagerUnit, kolvo, priceOut} = item;

				return (
					lagerNameUA !== TO_MONEYBOX
						?
						<li key={lagerId || i} className="product-list-item">
							<div className="product-title">
								<span>{`${lagerNameUA}, ${lagerUnit}`}</span>
								<span className="product-count--mobile">{` ˟ ${kolvo}`}</span>
							</div>
							<div className="product-count">
								<span>{`${kolvo} × `}</span>
								<Currency value={priceOut} />
							</div>
							<div className="product-cost">
								<Currency value={+(priceOut * kolvo).toFixed(2)} />
							</div>
						</li>
						:
						<li key={lagerId || i} className="product-list-item to-moneybox">
							<div className="product-title">
								<span>{`${lagerNameUA}:`}</span>
							</div>
							<div className="product-cost">
								<Currency
									value={+(priceOut * kolvo).toFixed(2)} />
							</div>
						</li>
				)
			})}
		</ul>
	);

	render() {
		const {check} = this.state;
		const {paymentType, checkNumber, fiscalNumber} = this.props;
		if (!check) return <Loader />;
		return (
			<>

				<div className="history-list__item-body">
					<div className="products">
						{!!checkNumber &&
							<div className="check-number">
								<span>{`Чек №${checkNumber}`}</span>
								{fiscalNumber &&
									<>
										<Link target="_blank" to={isDev ? `${DEV_RECEIPT_HOST}${fiscalNumber}` : `${PROD_RECEIPT_HOST}${fiscalNumber}`}>
											<Button primary className="button_e-check">
												<Icon icon={ICONS.receipt} />
												<span>Електронний чек</span>
											</Button>
										</Link>
										<Button onClick={() => this.toogleSharer(true)} primary outline className="buttton_share">
											<Icon icon={ICONS.share} />
											<span>Поділитися</span>
										</Button>
										{this.state.showSharer && !isMobile ?
											<Sharer checkNumber={checkNumber} toogleSharer={this.toogleSharer} link={isDev ? `${DEV_RECEIPT_HOST}${fiscalNumber}` : `${PROD_RECEIPT_HOST}${fiscalNumber}`} />
											: <Modal
												open={this.state.showSharer}
												onClose={() => this.toogleSharer(false)}
												center
												classNames={{"overlay": "modal-overlay sharer-modal", "modal": `modal-window modal-window-sharer`, "closeButton": "modal-closeButton", "closeIcon": "modal-closeIcon"}}
											>
												<Sharer checkNumber={checkNumber} toogleSharer={this.toogleSharer} link={isDev ? `${DEV_RECEIPT_HOST}${fiscalNumber}` : `${PROD_RECEIPT_HOST}${fiscalNumber}`} />
											</Modal>
										}
									</>
								}
							</div>
						}
						{this.renderProductList(check)}
					</div>
					{((!!check.chequeRewards && !!check.chequeRewards.length) || !!check.sumDiscount) &&
						<div className="personal-proposals">
							{!!check.sumDiscount &&
								<div className="discount">
									<div className="discount-title">
										<div className="icon-holder">
											<Icon icon={ICONS.percentSign} />
										</div>
										<FormattedHTMLMessage id="personalPage.history.discount" />
									</div>
									<div className="discount-sum">
										<span>{`-`}</span>
										<Currency
											value={check.sumDiscount} />
									</div>
								</div>}

							{this.renderRewards(check.chequeRewards)}
						</div>}
					{!!paymentType &&
						<div className="payment-type">
							<div className="payment-type-title">
								<div className="icon-holder">
									<Icon icon={ICONS.coins} raw />
								</div>
								<FormattedHTMLMessage id="personalPage.history.payment_type" />
							</div>
							<div className="payment-type-value">
								<span>{PAYMENT_TYPE[paymentType]}</span>
							</div>
						</div>}
				</div>
			</>
		)

	}
}

export default HistoryCheck;
