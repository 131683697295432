import "./InfoIcon.styl";

import React, {Component} from "react";
import cn from "classnames";
import {PlacesType} from "react-tooltip";

import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import {getUniqueId} from "../../../utils";

interface IInfoIcon {
	id: string
}

type InfoIconPropsType = {
	place?: PlacesType,
	[key:string]: any
}

class InfoIcon extends Component<InfoIconPropsType, {}>  implements  IInfoIcon {
	id: string = getUniqueId();

	static defaultProps = {
		place: "right",
		event: "click",
		globalEventOff: "click",
	};

	render() {
		const {children, className, ...props} = this.props;
		return (
			<span className={cn("info-icon__control", className)}>
                <Icon data-tooltip-id={this.id} className="info-icon"/>
                <Tooltip id={this.id} {...props}>{children}</Tooltip>
            </span>
		)
	}

}

export default InfoIcon;