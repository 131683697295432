import React, {FunctionComponent} from "react";
import cn from "classnames";

import {OLProps} from "./types";

import "./OL.styl";

const OL: FunctionComponent<OLProps> = ({className, children}) => (
	<ol className={cn("ordered-list", className)}>
		{children}
	</ol>
);

export default OL;