import React, {Component} from "react";
import {Field, FieldArray, FormikProps, withFormik} from "formik";
import cn from "classnames";

import {MoneyboxControlsProps, MoneyboxControlsValues} from "../types";

import {MONEYBOX_STATUS} from "../../../constants";
import {FormattedHTMLMessage} from "../../../services/TextService";

import Form from "../../Form/Form";
import FormError from "../../Form/FormError/FormError";
import RadioLabel from "../../Form/RadioLabel/RadioLabel";
import Button from "../../UI/Button/Button";

import "./MoneyboxControls.styl";


const controls = [
	{
		value: MONEYBOX_STATUS.ACTIVATED,
		title: "Застосувати автоматично",
		text: "Решта менше 1 грн автоматично додається до бонусу"
	},
	{
		value: MONEYBOX_STATUS.NOT_ACTIVATED,
		title: "Застосувати за бажанням",
		text: "Якщо хочете додати решту до бонусу, скажіть про це"
	},
];

const MoneyboxControls = withFormik<MoneyboxControlsProps , MoneyboxControlsValues>({
	mapPropsToValues: props => ({
		type: props.initialValues.type,
	}),
	enableReinitialize: true,
	handleSubmit: (values: MoneyboxControlsValues, formProps) => {
		formProps.props.onSubmit(values.type);
	},
})(class MoneyboxControls extends Component<FormikProps<MoneyboxControlsValues> & MoneyboxControlsProps, {}> {
	render() {
		const {form, mutationLoading, initialValues, isOpen, isValid, isSubmitting, handleSubmit, errors, values, setFieldValue} = this.props;
		const disableBtn = !isValid || isSubmitting || mutationLoading || (initialValues.type === values.type);

		return (
			<Form onSubmit={handleSubmit} name={form} className={cn("controls-panel", {"open": isOpen})}>

				<div className="switch-col">
					<FieldArray
						name="type"
						render = {() => controls.map(({value, title, text}) => (
							<Field key={value}
										 component={RadioLabel}
										 type="radio"
										 title={title}
										 name="type"
										 checked={values.type === value}
										 onChange={(e) => {
										 		e.persist();
										 		setFieldValue("type", value);
										 }}
							>
								{text}
							</Field>
						))}
					/>
					{errors && errors.type && <FormError className="-center">{errors.type}</FormError>}
				</div>

				<div className="description-col">
					<FormattedHTMLMessage id="personalPage.moneybox.description"/>
				</div>

				<div className="button-col">
					<Button
						primary
						type="submit"
						disabled={disableBtn}
						loading={isSubmitting || mutationLoading}
						className="moneybox-btn"
					>
						Зберегти
					</Button>
				</div>

			</Form>
		)
	}
});


export default MoneyboxControls;
