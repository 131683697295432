import jsCookie from "js-cookie";
import {isObject, tryParseJSON} from "../utils";
import {HOST} from "./EnvServices"

const __CookieFactory = {
	createInstance(attributes) {
		return {
			getItem(name) {
				return jsCookie.get(name);
			},
			setItem(name, value) {
				jsCookie.set(name, value, attributes);
			},
			removeItem(name) {
				jsCookie.remove(name, attributes);
			}
		}
	}
};

const __HostCookies = __CookieFactory.createInstance({
	domain: HOST.replace(/https?:\/\//g, "").replace(/\W\d*$/, "")
});


export const COOKIE = {
	SHOW_REG_SUCCESS: "showRegistrationSuccess"
};

const CookiesService = {
	getItem(name) {
		const item = __HostCookies.getItem(name);
		const object = tryParseJSON(item);

		return object || item;
	},
	removeItem(name) {
		return __HostCookies.removeItem(name);
	},
	setItem(name, item) {
		const value = isObject(item)
			? JSON.stringify(item)
			: item;

		return __HostCookies.setItem(name, value);
	},
};

function setCustomCookies(name, item, options={}) {
	const defaultOptions = {
		path: "/",
		domain: HOST.replace(/https?:\/\//g, "").replace(/\W\d*$/, ""),
		samesite: "Lax",
		secure:  false,
		expires: (new Date(Date.now() + 6048e5)).toUTCString(), //one week
	};
	const value = isObject(item) ? JSON.stringify(item) : encodeURIComponent(item);
	const opt = {...defaultOptions, ...options};
	let newCookie = `${encodeURIComponent(name)}=${value};`;
	for (let key in opt) {
		if(opt[key] !== false) {
			newCookie += `${key}=${opt[key]};`;
		}
	}

	document.cookie = newCookie;
}

export default CookiesService;
export {
	setCustomCookies,
}