import { Guid } from "guid-typescript";

export function getUserGuid(){
	let guid: string = sessionStorage.getItem("userGuid") || "";
	if (!guid) {
		guid = Guid.raw();
	}
	return guid;
}

export function getCorrelationId(){
	let guid: string = localStorage.getItem("correlationId") || "";
	if (!guid) {
		guid = Guid.raw();
		localStorage.setItem("correlationId", guid)
	}
	return guid;
}