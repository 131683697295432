import "./MyContactsRowForm.styl";

import React, {Component} from "react";
import {compose, withProps} from "recompose"
import {Field, FormikProps, withFormik} from "formik";

import FormRow from "../../components/Form/FormRow";
import FormField from "../../components/Form/FormField";
import FormInput from "../../components/Form/FormInput";
import Button from "../../components/UI/Button/Button";
import Icon from "../../components/UI/Icon/Icon";
import {ICONS} from "../../components/UI/Icon/enums";
import Form from "../../components/Form/Form";
import {normalizeFields, validate} from "../../services/FormServices/FormService";
import {IField, IFormProps} from "../../services/FormServices/interfaces";

type MyContactsRowFormValuesName = "mobilePhone" | "homePhone" | "email";

export type MyContactsRowFormValues = {
	[key in MyContactsRowFormValuesName]? : string;
}

type OuterMyContactsRowFormPropsType = {
	form: string;
	field: IField
	initialValues: object;
	onSubmit: (values: MyContactsRowFormValues, formProps: any) => any;
	onDismiss: () => void;
}

type InnerMyContactsRowFormPropsType = IFormProps & FormikProps<MyContactsRowFormValues>

const MyContactsRowForm = compose<InnerMyContactsRowFormPropsType & OuterMyContactsRowFormPropsType, OuterMyContactsRowFormPropsType>(
	withProps((props: OuterMyContactsRowFormPropsType) => {
		const {field} = props;
		return {
			...props,
			fields: normalizeFields({[field.name]: field}, props.form),
		}
	}),
	withFormik<InnerMyContactsRowFormPropsType & OuterMyContactsRowFormPropsType, MyContactsRowFormValues>({
		mapPropsToValues: ({initialValues, field}) => {
			return {
				[field.name]: initialValues[field.name]
			};
		},
		validate,
		handleSubmit: (values: MyContactsRowFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false);
		},
	})
)(class MyContactsRowFormComponent extends Component<InnerMyContactsRowFormPropsType & OuterMyContactsRowFormPropsType, {}> {
		componentWillUnmount() {
			this.props.resetForm();
		}

		render() {
			const {field, handleSubmit, form, onDismiss, isValid, isSubmitting} = this.props;
			return (
				<Form className="my-contacts-row-form" onSubmit={handleSubmit} name={form}>
					<FormRow className="inline-row">
						<FormField inline className="inline-row__field">
							<Field component={FormInput} label={field.label}  name={field.name} type={field.type}/>
						</FormField>
						<div className="inline-row__buttons">
							<Button
								className="inline-row__button"
								type="submit"
								disabled={!isValid || isSubmitting}
							>
								<Icon icon={ICONS.circleCheck}/>
							</Button>
							<Button
								onClick={onDismiss}
								className="inline-row__button"
							>
								<Icon icon={ICONS.circleCross}/>
							</Button>
						</div>
					</FormRow>
				</Form>
			)
		}
	}
);


export default MyContactsRowForm;