import React, {FC, useContext} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {useMutation} from 'react-apollo';

import {isFunction} from '@utils';
import {checkPasswordIdentity, handleFormErrors, normalizeFields, validate} from '@services/FormServices/FormService';
import {DATA_TYPE} from '@services/FormServices/constants';
import Mutation from '@graphql/Mutation';
import {fields as initialFields, form, initialValues} from './const';
import {getResponseFields, getStoredAuthData, handleAccessTokenUserFetch} from '../helpers';

import RecoveryPasswordAndEmailEntryForm from './RecoveryPasswordAndEmailEntryForm';
import {CreatePasswordEmailModalPropsType, CreatePasswordEmailVerificationFormValuesType} from './types';
import {RecoverAccessResponseType, RecoverAccessVariablesType} from '@graphql/types';
import {Store} from 'js/context/context';

import './RecoveryPasswordAndEmailEntry.styl';

const RecoveryPasswordAndEmailEntry: FC<CreatePasswordEmailModalPropsType> = ({determineNextStep, onClose, withEmail = false}) => {
	const {dispatch} = useContext<any>(Store);
	const {flowId} = getStoredAuthData();
	const [recoverAccess] = useMutation<RecoverAccessResponseType, RecoverAccessVariablesType>(Mutation.recoverAccess.mutation);

	const fields = {
		...initialFields,
		...(withEmail ? {
			email: {
				name: "email",
				dataType: DATA_TYPE.email,
			}
		} : {})
	};

	const handleSubmit = (values: CreatePasswordEmailVerificationFormValuesType, formProps: FormikHelpers<CreatePasswordEmailVerificationFormValuesType>) => {
		const {email, password} = values;
		return recoverAccess({variables: {email, flowId, password}})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const recoverAccess = data.recoverAccess;
				if (!errors && recoverAccess) {
					const {accessToken, expiresAt, nextStep} =  getResponseFields(recoverAccess);
					if (accessToken) {
						handleAccessTokenUserFetch(accessToken, expiresAt, dispatch);
						onClose && isFunction(onClose) && onClose();
					}
					determineNextStep(nextStep)
				}
			})
			.catch((e) => {
				handleFormErrors(e, formProps)
			});
	};

	const initialValid = (props) => {
		const errors = validate(props.initialValues, {fields: normalizeFields(fields, form), form});
		return Object.keys(errors).length === 0;
	}

	const handleValidate = (values: CreatePasswordEmailVerificationFormValuesType) =>
		validate(values, {
			fields: normalizeFields(fields, form),
			form,
			formValidate: checkPasswordIdentity
		})

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={initialValid} validate={handleValidate}>
			<RecoveryPasswordAndEmailEntryForm withEmail={withEmail} />
		</Formik>
	)
}

export default RecoveryPasswordAndEmailEntry;