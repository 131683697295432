import React, {FunctionComponent} from "react";
import Modal from 'react-responsive-modal';

import {ConfirmModalProps} from "./types";
import getTemplateText, {FormattedHTMLMessage} from "../../../services/TextService";

import Button from "../../UI/Button/Button";

import "./ConfirmModal.styl";

export const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({isOpen, onClose, onSubmit, title, snippet}) =>{
		return (
			<Modal
				open={isOpen}
				onClose={onClose}
				center
				classNames={{"overlay": "modal-overlay", "modal": "modal-window login-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
			>
				<h3 className="confirm-modal__heading"><FormattedHTMLMessage id={title}/></h3>
				<p className="confirm-modal__snippet"><FormattedHTMLMessage id={snippet}/></p>
				<div className="confirm-modal__buttons-group">
					<Button primary className="btn-proceed" onClick={onSubmit}>{getTemplateText("confirmModal.submit")}</Button>
					<Button primary className="btn-proceed button-no" onClick={onClose}>{getTemplateText("confirmModal.cancel")}</Button>
				</div>
			</Modal>
		);
};



