import "./ErrorModal.styl";

import React, {FC} from "react";
import Modal from "react-responsive-modal";

import Button from "../../UI/Button/Button";
import {FormattedHTMLMessage} from "../../../services/TextService";
import {HashModalProps} from "../../Modal/ModalInterfaces";


const ErrorModal: FC<HashModalProps>  = ({isOpen, onClose}) => {
		const message = sessionStorage["storedMessage"] ? sessionStorage["storedMessage"] :
			<FormattedHTMLMessage id="error.modalWindow.contentText-1"/>;
		if (localStorage["storedMessage"]) {
			localStorage.removeItem("storedMessage");
		}
		return (
			<Modal
				focusTrapped={false}
				open={isOpen}
				onClose={onClose}
				center
				classNames={{"overlay": "modal-overlay", "modal": "modal-window error__dialog", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
			>
				<div className={"error-modal__wrapper"}>
					<div className={"error-modal__content"}>
						<h3><FormattedHTMLMessage id="error.modalWindow.title"/></h3>
						{message}<br/>
						<Button primary className="btn-proceed button-ok" onClick={onClose}>
							<FormattedHTMLMessage id="common.modalWindow.button.good"/>
						</Button>
					</div>
				</div>
			</Modal>
		)
};

export default ErrorModal;
