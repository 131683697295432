import {useMutation} from '@apollo/react-hooks';
import {FormikValues, useFormikContext} from 'formik';

import Mutation from '@graphql/Mutation';
import {getResponseFields, getStoredAuthData} from '../../helpers';
import {handleFormErrors} from '@services/FormServices/FormService';

import {GoToRecoveryResponseType, GoToRecoveryVariablesType, SendOtpToContactResponseType, SendOtpToContactVariablesType} from '@graphql/types';

const useSendRecoveryOtp = () => {
	const {contactId, flowId, phone} = getStoredAuthData();
	const [sendOtpToContact] = useMutation<SendOtpToContactResponseType, SendOtpToContactVariablesType>(Mutation.sendOtpToContact.mutation);
	const [goToRecovery] = useMutation<GoToRecoveryResponseType, GoToRecoveryVariablesType>(Mutation.goToRecovery.mutation);
	const {resetForm, ...otherProps} = useFormikContext<FormikValues>();

	const sendRecoveryOtp = () => {
		if (contactId) {
			return sendOtpToContact({
				variables: {
					contactId,
					flowId,
				}
			}).then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const sendOtpToContact = data.sendOtpToContact;
				if (!errors && sendOtpToContact && contactId) {
					const {nextStep, flowId, contactType, contactValue} = getResponseFields(sendOtpToContact);
					localStorage.setItem("authData", JSON.stringify({...getStoredAuthData(), flowId, nextStep, contactType, [contactType.toLowerCase()]: contactValue || getStoredAuthData()[contactType], contactId}));
				}
				resetForm();
			})
				.catch((e) => {
					resetForm();
					handleFormErrors(e, otherProps);
				});

		} else {
			return goToRecovery({variables: {flowId}})
				.then(({data = {}, errors}) => {
					if (errors) {
						throw errors;
					}
					const goToRecovery = data.goToRecovery;
					if (goToRecovery && !errors) {
						const {nextStep, flowId, contactType, contactValue} = getResponseFields(goToRecovery);
						localStorage.setItem("authData", JSON.stringify({flowId, nextStep, [contactType.toLowerCase()]: contactValue || phone, contactType}));
					}
					resetForm();
				})
				.catch((err) => {
					resetForm();
					handleFormErrors(err, otherProps);
				})
		}
	}
	return sendRecoveryOtp;
}

export default useSendRecoveryOtp;