import React, {FunctionComponent} from "react";
import cn from "classnames";
import "./Radio.styl";
import {FieldProps} from "formik";

type RadioPropsType = FieldProps<any> & {
	[key: string]: any
}

const Radio: FunctionComponent<RadioPropsType> = ({className, form, field, value, ...props}: RadioPropsType) => {
	return (
		<span className={cn("radio", className)}>
        <input
					className="radio__input"
					{...props}
					name={field.name}
					type="radio"
				/>
        <i className="radio__icon"/>
    </span>
	);
};


export default Radio;