import getTemplateText from "@services/TextService";
import {tryParseJSON} from "@utils";
import {ContactTypes, DataType, FieldsDataType, StoredAuthData} from "./types";
import actions from "../../../context/actions";

export const getMessageId = (id, form) => {
	if (getTemplateText(form + "." + id) !== form + "." + id) {
		return form + "." + id;
	} else {
		console.warn(`Check form: ${form}, and messageId: ${id}`)
	}
	return "";
};

export const getResponseFields = (data: Partial<DataType>) => {
	let fields: FieldsDataType = {
		flowId: -1,
		currentPlace: "",
		nextStep: null,
		contactType: "",
		contactValue: "",
		accessToken: "",
		expiresAt: ""
	};
	if (data.flow) {
		fields = {...fields, ...data.flow};
	}
	if (data.contactUsedForOtp) {
		fields = {...fields, ...data.contactUsedForOtp};
	}
	if (data.accessToken) {
		fields = {...fields, ...data.accessToken};
	}
	return fields;
}

export const handleAccessTokenUserFetch = (accessToken: string, expiresAt: string, dispatch: any) => {
	dispatch(actions.login({
		accessToken:{
			accessToken,
			expiresAt
		},
		user: {}
	}, dispatch));
}

export const getStoredAuthData = (): StoredAuthData => {
	const authData = tryParseJSON(localStorage.getItem("authData")) || {};
	const {flowId = -1, phone = "", email = "", contactType = ContactTypes.PHONE, contactId = null, nextStep = null} = authData;
	return {flowId: +flowId, phone, email, contactType, contactId, nextStep}
}