import {Component} from "react";
import {FormikProps} from "formik";
import {FORMS} from "../../forms/enums";
import getTemplateText from "../../services/TextService";

type FormComponentOuterProps = {
	form: FORMS
}

type FormComponentPropsType = FormComponentOuterProps & FormikProps<any>

class FormComponent<P, S> extends Component<FormComponentPropsType & P , S> {

	/**
	 * changes field value
	 * @param field
	 * @param value
	 */
	changeFieldValue = (field, value) => {
		const {setFieldValue} = this.props;
		setFieldValue(field, value);
	};

	/**
	 * get field error
	 * @param field
	 */
	getFieldError = (field) => {
		const {errors} = this.props;
		return (errors || {})[field];
	};

	/**
	 * get label field message
	 * @param id
	 */
	getMessageId = (id) => {
		const {form} = this.props;
		if (getTemplateText(form + "." + id) !== form + "." + id) {
			return form + "." + id;
		} else {
			console.warn(`Check form: ${form}, and messageId: ${id}`)
		}

		return "";
	};

	/**
	 * get error field message
	 * @param id
	 */
	getErrorMessageId = (id) => {
		const {form} = this.props;
		if (getTemplateText(`error.${form}.${id}`) !== `error.${form}.${id}`) {
			return `error.${form}.${id}`;
		}
	};
}


export default FormComponent;
