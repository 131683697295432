import React, {FunctionComponent} from "react";
import cn from "classnames";

type FormHintPropsType = {
	className?: string;
}

const FormHint: FunctionComponent<FormHintPropsType> = ({className, children}) => {
	return (
		<div className={cn("form-hint", className)}>{children}</div>
	)
};

export default FormHint;