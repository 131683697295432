import "./Tooltip.styl";

import React, {FunctionComponent} from "react";
import {Tooltip as ReactTooltip, PlacesType} from "react-tooltip";
import cn from "classnames";


type TooltipPropsType = {
	effect?: any;
	place?: PlacesType;
	multiline?: boolean;
	[key:string]: any;
}

const Tooltip: FunctionComponent<TooltipPropsType> = ({className, ...props}) => {
	return (
		<ReactTooltip {...props} className={cn("ui-tooltip", className)}/>
	)
};

Tooltip.defaultProps = {
		effect: "solid",
		multiline: true,
		scrollHide: true,
		resizeHide: false,
};

export default Tooltip;