import React, {Component} from "react";
import {InnerMySubscriptionsPropsType, SubscriptionsFormValues} from "../../../forms/SubscriptionsForm/types";
import {compose} from "recompose";
import {graphql} from "react-apollo";
import Query from "../../../graphql/Query";
import {isArray} from "../../../utils";
import Modal from "react-responsive-modal";
import NearestStoreForm from "../../../forms/NearestStoreForm/NearestStoreForm";
import CookiesService from "../../../services/CookiesService";
import {getNearestStore} from "../../../services/AppServices";
import WithContext from "../../HOCs/WithContext/WithContext";
import actions from "../../../context/actions";

//todo: fix types
function normalizeStores(storeIds = [], stores: any) {
	return storeIds
		.map(sid => {
			return normalizeStore(sid, stores)
		})
		.filter(d => d.cid && d.sid);
}


export function normalizeStore(sid, stores) {
	const cid = ((stores.find(s => s.id === sid) || {}).city || {}).id;
	return {
		_id: `${cid}--${sid}`,
		sid, cid
	}
}

type nearestModalType = {
	open: boolean | any;
	onClose: () => void;
	initStores: () => void;
}


const NearestStoreModal = compose<nearestModalType, nearestModalType>(
	graphql(Query.citiesWithStores.query, {name: "citiesWithStores"}),
)(class MySubscriptions extends Component<nearestModalType & InnerMySubscriptionsPropsType> {

	/***
	 * getting form initial values
	 * @param cities
	 */
	getInitialValues = (cities) => {
		try {
			const nearestStore = getNearestStore();
			const
				storesWithCities = (cities || []).reduce((res, city) => ([
					...res,
					...(city.stores || []).map(s => ({
						...s,
						city: {id: city.id, title: city.title}
					}))
				]), []),
				stores = isArray(nearestStore) && nearestStore.length >= 1 && nearestStore[0]!=="" ?
					normalizeStores(nearestStore, storesWithCities)
					:
					[{_id: "__1"}];

			return {
				stores: stores,
			};

		} catch (err) {
			return {};
		}
	};

	/***
	 * onSubmit
	 * @param values
	 */
	onSubmit = (values: SubscriptionsFormValues) => {

		const storeIds = Object.keys(values.stores).map(storeId => {
				return values.stores[storeId].sid
			}),
			uniqueStores = Array.from(new Set(storeIds));

		if (uniqueStores) {
			if (uniqueStores.length && uniqueStores[0] !== undefined) {
				CookiesService.removeItem("nearestStores");
			}
			CookiesService.setItem("nearestStores", uniqueStores.toString());
		} else {
			CookiesService.removeItem("nearestStores");
		}
		this.props.initStores();
		this.props.onClose();
	};


	render() {
		const {citiesWithStores: {cities}, open, onClose} = this.props;
		return (
			<Modal focusTrapped={false} open={open} onClose={onClose}
						 classNames={{
							 "overlay": "modal-overlay",
							 "modal": "modal-window card-select-store-modal",
							 "closeButton": "modal-closeButton",
							 "closeIcon": "modal-closeIcon"
						 }}>
				<NearestStoreForm initialValues={this.getInitialValues(cities)}
													onSubmit={this.onSubmit} form="subscribe"
													citiesWithStores={cities}
				/>
			</Modal>
		)
	}
});


export default WithContext(
	(state) => ({
		nearestStore: state.nearestStore
	}),
	dispatch => ({
		initStores: () => dispatch(actions.initStores()),
	})
)(NearestStoreModal);