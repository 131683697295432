enum ROUTE {
	ROOT = "root",
	NOT_FOUND = "notFound",
	UNAVAILABLE = "unavailable",
	UPDATE = "update",
	OTHER = "other",
	LAYOUT = "layout",
	// my.fora
	LOGIN = "login",
	SUCCESS = 'success',
	ASSISTANT = 'assistant',
	PERSONAL_PAGE = "personalPage",
	BONUS = "bonus",
	SPECIAL_PROPOSALS = "specialProposals",
	PREVIOUS_PROPOSALS = "previousProposals",
	PERSONAL_INFO = "personal-info",
	PROPOSAL = "proposal",
	MY_ORDERS="my-orders",
	CHECKS="checks",
	HISTORY = "history",
	PURCHASES = "purchases",
	MONEYBOX = "moneybox",
	MY_CARDS = "myCards",
	MY_INFO = "myInfo",
	// MY_CONTACTS = "myContacts",
	MY_BONUSES = "myBonuses",
	MY_SUBSCRIPTIONS = "mySubscriptions",
	BONUS_PROTECTION = "bonusProtection",
	ACTIVATE_ACTION = "activateAction",
	CHANGE_PASSWORD = "changePassword",
	RECHARGE_NUMBERS = "rechargeNumbers",
	VERIFY_EMAIL = "verifyEmail",
	VERIFY_ID = ":verifyId",
	STATUS = ":status"
}

enum REG_STEPS {
	barcode = "barcode",
	info = "info",
	address = "address",
	password = "password",
	// success  = "success",

	identification = "identification",
	email = "email",
	passwordSent = "passwordSent",
	contactCenter = "contactCenter",
	blocked = "blocked"
}

enum CHECK_BARCODE_STATUS {
	REGISTERED= "REGISTERED", // Користувач авторизований і активований, необхідний логін
	UNREGISTERED= "UNREGISTERED", // Необхідна повна реєстрація
	UNIDENTIFIED= "UNIDENTIFIED", // Необхідна ідентифікація і авторизація
	TO_FEW_QUESTIONS= "TO_FEW_QUESTIONS", // show contact message
	REGISTERED_WITHOUT_EMAIL= "REGISTERED_WITHOUT_EMAIL", // Користувач зареєстрований без e-mail, необхідна авторизація
	REGISTERED_AND_EMAIL_SENT= "REGISTERED_AND_EMAIL_SENT", // Користувач автоматично активується і йому на пошту посилається пароль. Потрібна авторизація.

	// Secondary Statuses:
	LOCKED= "LOCKED",
	MEMBER_LOCKED= "MEMBER_LOCKED",
	NO_LINK_CARD_MEMBER= "NO_LINK_CARD_MEMBER",
	CARD_NOT_FOUND= "CARD_NOT_FOUND",
	ANSWER_QUESTIONS_BLOCK= "ANSWER_QUESTIONS_BLOCK"
};

export {ROUTE, REG_STEPS, CHECK_BARCODE_STATUS}