import React, {FC} from "react";
import cn from "classnames";

import "./RadioBtnLikeList.styl";

function onClick(value, update, isDisabled) {
	return function () {
		if (typeof update === "function" && !isDisabled) {
			update(value);
		}
	}
}

type RadioBtnLikeListProps = {
	list: any[];
	updateActive: (active: any) => void;
	valueKey: string;
	active: number;
	renderBtnTitle: ({ timeFrom, timeTo }: { timeFrom: any; timeTo: any; }) => string;
	className?:string;
	disabled: (item: any) => boolean;
};

const RadioBtnLikeList: FC<RadioBtnLikeListProps> = (props) => {
	const {list, active, valueKey, renderBtnTitle, updateActive, className, disabled} = props;
	return (<div className={cn("radio-btn-like-list", className)}>
		{list.map(item => {
			const value = item[valueKey];
			const isDisabled = disabled(item);
			const isActive = active === value;
			return (
				<div
					className={cn("radio-btn-like-item", {"radio-btn-like-disabled": isDisabled}, {"radio-btn-like-active": isActive})}
					key={item[valueKey]}
					onClick={onClick(value, updateActive, isDisabled)}
				>
					{renderBtnTitle(item)}
				</div>
			)
		})
		}
	</div>)
};

export default RadioBtnLikeList;