import React, {FC} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {FormikValues, useFormikContext} from 'formik';

import {handleFormErrors} from '@services/FormServices/FormService';
import Mutation from '@graphql/Mutation';
import {getResponseFields, getStoredAuthData} from '../../helpers';
import getTemplateText from '@services/TextService';

import Loader from '@components/UI/Loader/Loader';
import {GoToRecoveryResponseType, GoToRecoveryVariablesType} from '@graphql/types';
import {AuthFormPropsType} from '../../types';

const GoToRecoveryBtn: FC<AuthFormPropsType> = ({determineNextStep}) => {
	const {flowId, phone} = getStoredAuthData();
	const [goToRecovery, {loading: goToRecoveryLoading}] = useMutation<GoToRecoveryResponseType, GoToRecoveryVariablesType>(Mutation.goToRecovery.mutation);
	const {setSubmitting, setErrors,} = useFormikContext<FormikValues>();

	const handleRecoverPassword = (e) => {
		e.preventDefault();
		if (goToRecoveryLoading) {
			return;
		}

		return goToRecovery({variables: {flowId}})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const goToRecovery = data.goToRecovery;
				if (goToRecovery && !errors) {
					const {nextStep, flowId, contactType, contactValue} = getResponseFields(goToRecovery);
					localStorage.setItem("authData", JSON.stringify({flowId, nextStep, [contactType.toLowerCase()]: contactValue || phone, contactType}));
					determineNextStep(nextStep);
				}
			})
			.catch((err) => {
				handleFormErrors(err, {setSubmitting, setErrors});
			})
	};

	return (
		<div className="password-form__forgot-password" onMouseDown={handleRecoverPassword}>
			{getTemplateText("passwordForm.forgot_password")}
			{goToRecoveryLoading && <Loader />}
		</div>
	)
}

export default GoToRecoveryBtn