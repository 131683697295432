import React, {FC} from 'react'
import {useQuery} from '@apollo/react-hooks';
import {Field, FormikValues, useFormikContext} from 'formik';

import Query from '@graphql/Query';
import {form} from './const';
import {getStoredAuthData} from '../helpers';

import Form from '@components/Form/Form';
import FormError from '@components/Form/FormError/FormError';
import FormField from '@components/Form/FormField';
import RadioLabel from '@components/Form/RadioLabel/RadioLabel';
import Button from '@components/UI/Button/Button';
import Loader from '@components/UI/Loader/Loader';

import {Contact, GetRecoveryContactsResponseType, GetRecoveryContactsVariablesType} from '@graphql/types';
import {RecoveryContactSelectionFormPropsType} from './types';

const RecoveryContactSelectionForm: FC<RecoveryContactSelectionFormPropsType> = ({onContactChange}) => {
	const {flowId} = getStoredAuthData();
	const {isSubmitting, handleSubmit, dirty, isValid, values, errors, setFieldValue} = useFormikContext<FormikValues>();

	const {data, loading} = useQuery<GetRecoveryContactsResponseType, GetRecoveryContactsVariablesType>(Query.getRecoveryContacts.query, {variables: {flowId}});

	const handleContactChange = (contact: Contact) => () => {
		onContactChange(contact);
		setFieldValue("contact", contact.id);
	};

	if (loading) {
		return <div className="preloader-modal-wrapper">
			<Loader />
		</div>;
	}

	return (
		<Form onSubmit={handleSubmit} className="contact-recovery-form" name={form}>
			<FormField>
				<div className="radio-buttons__contact">
					{data && data.getRecoveryContacts && data.getRecoveryContacts.map((contact) => {
						const {id, contactHide} = contact;
						return (
							<Field
								key={id}
								name="contact"
								value={id.toString()}
								component={RadioLabel} type="radio"
								checked={values.contact === id}
								onChange={handleContactChange(contact)}
							>
								{contactHide}
							</Field>
						)
					})}
				</div>
			</FormField>
			{errors.submitError && <FormError className="form__footer-error">{errors.submitError}</FormError>}
			<Button
				className="contact-recovery-form-btn btn-submit"
				primary
				type="submit"
				disabled={!isValid || isSubmitting || !dirty}
				loading={isSubmitting}
			>відправити</Button>
		</Form>
	)
}

export default RecoveryContactSelectionForm