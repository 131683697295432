import {isString, isArray} from "../../utils";
import {isDev, SHOP_HOST} from "../EnvServices";

import {IRouteParams} from "./interfaces";
import {ROUTE} from "./enums";

let _location = {};
let _history = {};
let	routeParams: IRouteParams = {};

export const openShop = () => {
	window.location.href = SHOP_HOST + "/home";
}


const PATHS = {
	[ROUTE.ROOT]: "/",
	[ROUTE.NOT_FOUND]: "not-found",
	[ROUTE.UNAVAILABLE]: "unavailable",
	[ROUTE.UPDATE]: "update",
	[ROUTE.OTHER]: "*",

	// my.fora
	[ROUTE.LOGIN]: "login",
	[ROUTE.SUCCESS]: "success",
	[ROUTE.PERSONAL_INFO]: "personal-info",
	[ROUTE.ASSISTANT]: "assistant",
	[ROUTE.SPECIAL_PROPOSALS]: "special-proposals",
	[ROUTE.PREVIOUS_PROPOSALS]: "previous-proposals",
	[ROUTE.PROPOSAL]: ":proposalId",
	[ROUTE.PURCHASES]: "purchases",
	[ROUTE.MY_ORDERS]: "my-orders",
	[ROUTE.CHECKS]: "checks",
	[ROUTE.HISTORY]: "history",
	[ROUTE.MONEYBOX]: "moneybox",
	[ROUTE.BONUS]: "bonus",
	[ROUTE.MY_CARDS]: "cards",
	[ROUTE.MY_INFO]: "info",
	[ROUTE.CHANGE_PASSWORD]: "change-password",
	[ROUTE.RECHARGE_NUMBERS]: "recharge-numbers",
	// [ROUTE.MY_CONTACTS]: "contacts",
	[ROUTE.MY_BONUSES]: "bonuses",
	[ROUTE.MY_SUBSCRIPTIONS]: "subscriptions",
	[ROUTE.BONUS_PROTECTION]: "protection",
	[ROUTE.ACTIVATE_ACTION]: "activate-action",
	[ROUTE.VERIFY_EMAIL]: "verify_email",
	[ROUTE.VERIFY_ID]: ":verifyId",
	[ROUTE.STATUS]: ":status"
};


const myForaRoutesMap = {
	[ROUTE.ROOT] : `${PATHS[ROUTE.ROOT]}`,
	[ROUTE.OTHER] : `${PATHS[ROUTE.OTHER]}`,
	[ROUTE.UNAVAILABLE] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.UNAVAILABLE]}`,
	[ROUTE.NOT_FOUND] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.NOT_FOUND]}`,
	[ROUTE.ASSISTANT] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.ASSISTANT]}`,
	//[ROUTE.MY_SUBSCRIPTIONS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.MY_SUBSCRIPTIONS]}`,
	[ROUTE.BONUS_PROTECTION] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.BONUS_PROTECTION]}`,
	[ROUTE.MY_BONUSES] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.MY_BONUSES]}`,
	// [ROUTE.MY_CONTACTS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.MY_CONTACTS]}`,
	[ROUTE.CHANGE_PASSWORD] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.CHANGE_PASSWORD]}`,
	[ROUTE.RECHARGE_NUMBERS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.RECHARGE_NUMBERS]}`,
	[ROUTE.MY_ORDERS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.HISTORY]}/${PATHS[ROUTE.MY_ORDERS]}`,
	[ROUTE.CHECKS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.HISTORY]}/${PATHS[ROUTE.CHECKS]}`,
	[ROUTE.MY_INFO] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.MY_INFO]}`,
	[ROUTE.MY_CARDS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}/${PATHS[ROUTE.MY_CARDS]}`,
	[ROUTE.PERSONAL_INFO] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PERSONAL_INFO]}`,
	[ROUTE.MONEYBOX] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.MONEYBOX]}`,
	[ROUTE.BONUS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.BONUS]}`,
	[ROUTE.HISTORY] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.HISTORY]}`,
	[ROUTE.PROPOSAL] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.SPECIAL_PROPOSALS]}/${PATHS[ROUTE.PROPOSAL]}`,
	[ROUTE.PREVIOUS_PROPOSALS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.SPECIAL_PROPOSALS]}/${PATHS[ROUTE.PREVIOUS_PROPOSALS]}`,
	[ROUTE.PURCHASES] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.PURCHASES]}`,
	[ROUTE.SPECIAL_PROPOSALS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.SPECIAL_PROPOSALS]}`,
	[ROUTE.PERSONAL_PAGE] : `${PATHS[ROUTE.ROOT]}`,
	[ROUTE.SUCCESS] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.SUCCESS]}`,
	[ROUTE.LOGIN] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.LOGIN]}`,
	[ROUTE.VERIFY_EMAIL] : `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.VERIFY_EMAIL]}/${PATHS[ROUTE.VERIFY_ID]}?/${PATHS[ROUTE.STATUS]}?`
};

const EXCLUDED_ROUTES = [ROUTE.ROOT, ROUTE.OTHER];

/***
 * set new location it to variable "_location"
 * @param newLocation
 */
function setLocation(newLocation) {
	_location = newLocation;
}

/***
 * when location changed - runs setLocation function
 * @param nextState
 */
function onRouteChange(nextState) {
	setLocation(nextState.location);
}

/***
 * set new History it to variable "_history"
 * @param newHistory
 */
function setHistory(newHistory) {
	_history = newHistory;
}

/***
 * get modal window history
 */
function getHistory():any {
	return _history
}

/***
 * get modal window location
 * @param name
 */
function getModalLocation(name) {
	return {
		..._location,
		hash: name ? `#${name}` : ""
	}
}

/***
 * returns hash value
 * @param hash
 */
function getHashValue(hash) {
	if (!isString(hash)) return hash;

	return hash.substr(1)
}

/**
 * sets route params
 * @param params
 */
function setRouteParams(params) {
	routeParams = params;
}


/**
 * checks whether link is external
 * @param path
 * @returns boolean
 */
function checkIsExternalLink(path) {
	let isExternal = false;
	if (typeof path === 'string' && (path.indexOf('https://') !== -1 || path.indexOf('http://') !== -1)) {
		isExternal = true;
	}
	return isExternal;
}

/**
 * forms link to page
 * @param rid
 * @param _params
 * create and return path for linking into the site or path for linking to the external resource
 * @returns {{pathname, query: {}}}
 */
function urlTo(rid: string | ROUTE = ROUTE.ROOT, _params = {}) {
	rid = rid === '#' ? ROUTE.ROOT : rid;
	const params = {
		...routeParams,
		...Object.keys(_params).map(key => ({[":" === key[0] ? key.substr(1) : key]: _params[key]})).reduce((res, item) => ({...res, ...item}), {}),
	};

	const {search = ""} = params;
	// const routesFromMyFora = myForaRoutesMap[rid];

	const routes = myForaRoutesMap[rid];

	let pathname = PATHS[ROUTE.ROOT];

	if (routes && routes.length) {
		pathname = routes;

		Object.keys(params)
			.forEach(key => {
				pathname = pathname
					.replace(`:${key}`, params[key])
					.replace(`${key}`, params[key]);
			});

		pathname = pathname.toLowerCase()
		// remove all multiple /
			.replace(/[/]+/g, '/');
	} else if (checkIsExternalLink(rid)) {
		pathname = rid;
	} else {
		console.error("Can't get route for route: " + rid);
	}

	if (isDev && pathname) {
		// notify developer about missing parameters
		const urlMissedParams = pathname.split("/").filter(path => path[0] === ":");

		urlMissedParams.length &&
		console.warn("You missed parameters:", urlMissedParams, ". For route id", rid, ", and params:", params);
	}
	// if (isMySilpo && !routesFromMySilpo && routesFromSilpo) {
	// 	host = isDev ? SILPO_HOST + (port !== 80 ? ':' + port : '') : SILPO_HOST;
	// } else if (!isMySilpo && !routesFromSilpo && routesFromMySilpo) {
	// 	host = isDev ? MY_SILPO_HOST + (port !== 80 ? ':' + port : '') : MY_SILPO_HOST;
	// }

	return {
		pathname,
		search,
	};
}

/***
 * get modal name
 * @param hash
 */
function getActiveModalName(hash) {
	return (getHashValue(hash) || "").toLowerCase();
}

/***
 * function to decode and manage key value query params
 * @param pair
 */
function sliceProperty(pair): {[key:string] : string} {
	if(!pair) {
		return {}
	}
	const position = pair.indexOf("="),
		key = pair.slice(0, position),
		value = pair.slice(position + 1, pair.length);
	return { [key]: decodeURIComponent(value) }
}

/***
 * add ability to get query params like an object
 * @param location
 */
function getQueryParams(location): {[key:string] : string} {
	const splitParams = location.search.substr(1).split("&");
	const decodeProps = splitParams.map(sliceProperty);
	return Object.assign({},...decodeProps);
}

/***
 * Convert query object to search string
 * @param queryObj
 */
function queryParamsToSearchString(queryObj: {[key:string] : string}): string {
	const result: string[] = [];
	for (const p in queryObj) {
		if (queryObj.hasOwnProperty(p) && queryObj[p]) {
			result.push(encodeURIComponent(p) + "=" + encodeURIComponent(queryObj[p]));
		}
	}
	if (result.length === 0) {
		return ""
	}
	return "?" + result.join("&");
}

/***
 * Convert params to search query
 * @param _query
 */
function queryToUrl(_query) {
	const query = _query || {};
	const queryString = Object.keys(query)
		.map(key => {
			const value = query[key];

			if (isString(value)) {
				return `${key}=${value}`;
			} else if (isArray(value)) {
				return value.map(d => `${key}=${value[d]}`).join("&")
			}

			return false;
		})
		.filter(d => !!d)
		.join("&");

	return queryString ? "?" + queryString : "";
}

function hrefFromLocation(to: any) {
	if (isString(to)) return to;
	to = to? to : {host: "", pathname: "", query: "", hash: ""};
	const {host, pathname, query, hash} = to;

	return [host, pathname, queryToUrl(query), (hash ? hash : "")].filter(d => !!d).join("");
}

export {
	hrefFromLocation,
	queryParamsToSearchString,
	getQueryParams,
	setHistory,
	getHistory,
	setRouteParams,
	onRouteChange,
	getModalLocation,
	getActiveModalName,
	urlTo,
	PATHS,
	myForaRoutesMap,
	EXCLUDED_ROUTES
}

