import React, {useEffect, useState} from "react";
import moment from "moment";
import {DATE_FORMAT} from "./services/FormServices/constants";
import {isDev, PROJECT, LOGGER } from "./services/EnvServices";
import {HASH_MODALS, PROJECT as PRMap} from "./constants";
import {getHistory, queryParamsToSearchString} from "./services/RouteService/RouteService";
import {sendError} from "./services/LogService";
import {getImagePlaceholder} from "./services/ProposalService";

declare const InstallTrigger: any;

const objectType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);
const isDefined = (param) => typeof param !== "undefined";
const isUndefined = (param) => typeof param === "undefined";
const isFunction = (param) => typeof param === "function";
const isNumber = (param) => typeof param === "number" && !isNaN(param);
const isBoolean = (bool) => objectType(bool) === "Boolean";
const isString = (str) => objectType(str) === "String";
const isArray = (arr) => objectType(arr) === "Array";
const isObject = (arr) => objectType(arr) === "Object";
const isDate = (date) => objectType(date) === "Date";
const isValidDate = (date) => !isNaN(date.getTime());

function renderNode(node) {
	if (isString(node)) {
		return <span dangerouslySetInnerHTML={{__html: node}}/>
	}
	return node;
}

const needRecapture = () => {
	const {RECAPTURE} = process.env;
	return RECAPTURE === "true"
};

const browser = function checkBrowser(): {isOpera: boolean, isFirefox: boolean, isSafari: boolean, isIOS: boolean, isChrome: boolean, isIE: boolean} {
	const isOpera = !!window["opera"] || navigator.userAgent.indexOf(" OPR/") >= 0;
	const ua = navigator.userAgent.toLowerCase();
	return {
		isOpera   : isOpera,
		isFirefox : typeof InstallTrigger !== "undefined",
		isSafari  : (~ua.indexOf("safari") && !~ua.indexOf("chrome")) || Object.prototype.toString.call(window["HTMLElement"]).indexOf("Constructor") > 0,
		isIOS     : /iPad|iPhone|iPod/.test(navigator.userAgent) && !window["MSStream"],
		isChrome  : !!window["chrome"] && !isOpera,
		isIE      : /*@cc_on!@*/!!document["documentMode"] // At least IE6
	}
}();

function tryParseJSON(jsonString) {
	try {
		let o = JSON.parse(jsonString);
		// Handle non-exception-throwing cases:
		// JSON.parse(false) or JSON.parse(1234) throw errors.
		// But JSON.parse(null) returns "null", and typeof null === "object",
		// so we must check for that too.
		if (o && isObject(o)) return o;
	} catch (e) {

	}
	return false;
}

const isMobile = {
	/**
	 * @return {boolean}
	 */
	Windows: function () {
		return /IEMobile/i.test(navigator.userAgent);
	},
	/**
	 * @return {boolean}
	 */
	Android: function () {
		return /Android/i.test(navigator.userAgent);
	},
	/**
	 * @return {boolean}
	 */
	BlackBerry: function () {
		return /BlackBerry/i.test(navigator.userAgent);
	},
	iOS: function () {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	},
	IPhone: function () {
		return /iPhone/i.test(navigator.userAgent);
	},
	any: function () {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
	}
};

function getUniqueId() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	return "id-" + s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

function flatten(arr) {
	return arr.reduce(function (flat, toFlatten) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
	}, []);
}

function reduceTags(str) {
	if (isString(str)) {
		return str.replace(/<\/?[^>]+>/gi, " ");
	}
	return str;
}

/**
 * Check is current path is external link
 * @param path
 * @returns {boolean}
 */
function checkIsExternalLink(path) {
	let isExternal = false;
	if (typeof path === "string" && (path.indexOf("https://") !== -1 || path.indexOf("http://") !== -1)) {
		isExternal = true;
	}
	return isExternal;
}

/***
 *
 * @param element
 * @param parent
 */
function getElementTop(element, parent = document.body) {
	let parentRect = parent.getBoundingClientRect(),
		elementRect = element.getBoundingClientRect();

	return elementRect.top - parentRect.top;
}

/**
 * formats birthday
 * @param birthday
 */
const formatBirthday = (birthday) => {
	return moment.utc(birthday, DATE_FORMAT).set({hour: 0, minute: 0, second: 0}).toISOString();
};

function removeDuplicates(item, pos, self) {
	return self.indexOf(item) === pos
}


function arraysEqual(arr1: any[], arr2: any[]): boolean {
	if (arr1.length !== arr2.length)
		return false;
	for (let i = arr1.length; i--;) {
		if (arr1[i] !== arr2[i])
			return false;
	}

	return true;
}


function stopPropagation(e) {
	e.stopPropagation();
}

function getWindowScroll() {
	const doc = document.documentElement;
	try {
		return {
			left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
			top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
		}
	} catch (err) {
		isDev && console.error(err);
		return {
			left: 0,
			top: 0
		}
	}
}
/***
 * Show modal window with some text in message
 * @param message
 * @param withTimeout
 * @param timer
 */
function showErrorModal(message, withTimeout = false, timer = 0) {
	sessionStorage.setItem("storedMessage", message);

	function show() {
		window.location.hash = `#${HASH_MODALS.ERROR_MESSAGE}`;
	}

	if (withTimeout) {
		setTimeout(show, timer);
	} else {
		show();
	}
}

/***
 * Show info modal window with some text in message
 * @param title : string
 * @param message : string
 */
function showInfoModal(title, message) {
	const query = {title, message};
	getHistory().push({
		search: queryParamsToSearchString(query),
		hash:`#${HASH_MODALS.INFO}`
	});
}

/**
 * * Parse error and show error in massage if it need
 * @param err
 * @param withModal
 * @param location
 * @param shouldLogError
 */
function parseErrorOnfly(err = {}, withModal?: boolean, location?: string | null, shouldLogError: boolean = true) {
	LOGGER && shouldLogError && sendError(err);

	withModal = withModal? withModal : false;
	isDev && ((err instanceof Error) ? console.error(err) : console.log(err));
	const fullError = Array.isArray(err) && err[0] ? err[0] : err;
	let message = fullError.message ? fullError.message.replace("GraphQL error: ", "") : "";
	message = message.match(/[a-z]/i) ? "" : message;
	if (location) {
		// withModal && showErrorModalElsewhere(message, location, true, 500);
	} else {
		withModal && showErrorModal(message, true, 500);
	}
	return message;
}

/**
 * match passed value with current date in format
 * @param matchDate
 * @param template
 * @returns {boolean}
 */
function dateMatcher(matchDate, template="DD.MM"){
	const dobFormatted = moment(matchDate).format(template);
	const today = moment(new Date()).format(template);
	return dobFormatted === today;
}

function assignWithPath(keyPath, value) {
	let obj = {}, tempObj = obj;
	let lastKeyIndex = keyPath.length - 1;
	for (let i = 0; i < lastKeyIndex; ++i) {
		let key = keyPath[i];
		if (!(key in obj))
			tempObj[key] = {};
		tempObj = tempObj[key];
	}
	tempObj[keyPath[lastKeyIndex]] = value;

	return obj;
}

/**
 * determine whether current project is target project
 * @param project
 * @returns boolean
 */
export function isTargetProject (project: PRMap) {
	return PROJECT === project;
}

/***
 * Create and submit form
 * @param path
 * @param params
 */
function createOrderFromForm(path, params) {

	const method = "post";
	const form = document.createElement("form");
	form.setAttribute("method", method);
	form.setAttribute("action", path);

	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			const hiddenField = document.createElement("input");
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("name", key);
			hiddenField.setAttribute("value", params[key]);

			form.appendChild(hiddenField);
		}
	}

	document.body.appendChild(form);
	form.submit();
}

function useCheckImageUrl(imageUrl, placeholder?: string, notCheck = false) {
	const [checkedUrl, setCheckedUrl] = useState<string>(placeholder === ""? "" : placeholder || getImagePlaceholder());

	useEffect(() => {
		let isCancelled = false;
		!notCheck && checkUrl(imageUrl, typeof placeholder === "string" ? placeholder : getImagePlaceholder())
			.then(resp => {
				!isCancelled && setCheckedUrl(resp);
			});
		return () => {
			isCancelled = true;
		};
		// eslint-disable-next-line
	}, []);

	return checkedUrl;
}

/***
 * checks image url
 * @param img
 * @param placeholder
 * @returns Promise
 */
function checkUrl(img: any, placeholder: string): Promise<string> {
	return new Promise(resolve => {
		const imgSrc = getImageSrc(img);
		if (imgSrc) {
			const img = new Image();
			img.src = imgSrc;
			img.onload = () => {
				resolve(imgSrc);
			};
			img.onerror = () => {
				resolve(placeholder);
			};
		}
	});
}

/***
 * Get image source form the object
 * @param {{url: string}} image
 * @returns {*}
 */
function getImageSrc(image: string | { url?: string, icon?: { url: string } } | undefined): string | undefined {
	if (typeof image === "string") {
		return image;
	} else if (typeof image === "object" && image !== null) {
		if (!!image.url) {
			return image.url;
		} else if (!!image.icon) {
			return image.icon.url;
		}
	}
	return undefined;
}

function phoneNumberFormat (phone: string){
	return phone.replace(/^(\+\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1 $2 $3 $4 $5");
}

export {
	browser,
	dateMatcher,
	objectType,
	isArray,
	getWindowScroll,
	parseErrorOnfly,
	phoneNumberFormat,
	isBoolean,
	isDate,
	isDefined,
	isFunction,
	isNumber,
	isObject,
	isString,
	isUndefined,
	isValidDate,
	renderNode,
	tryParseJSON,
	isMobile,
	getUniqueId,
	flatten,
	reduceTags,
	stopPropagation,
	checkIsExternalLink,
	getElementTop,
	useCheckImageUrl,
	formatBirthday,
	arraysEqual,
	removeDuplicates,
	needRecapture,
	assignWithPath,
	showInfoModal,
	createOrderFromForm
}