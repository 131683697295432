import React from 'react'

import  './ButtonsLoader.styl';

const ButtonsLoader = () => {
	return (
		<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
	)
}

export default ButtonsLoader