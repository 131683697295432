import React from 'react'
import {Field, FormikValues, useFormikContext} from 'formik';

import {needRecapture} from '@utils';
import {Recapture} from '@services/RecaptchaService';
import getTemplateText from '@services/TextService';
import {form} from './const';
import useAutoFocus from '../../hooks/useAutoFocus';

import Form from '@components/Form/Form';
import FormError from '@components/Form/FormError/FormError';
import FormField from '@components/Form/FormField';
import FormInput from '@components/Form/FormInput';
import Button from '@components/UI/Button/Button';

import "./PhoneEntrance.styl";

const PhoneEntranceForm = () => {
	const {handleSubmit, isSubmitting, errors, isValid} = useFormikContext<FormikValues>();
	const phoneRef = useAutoFocus();

	return (
		<Form onSubmit={handleSubmit} className="united-exit-main-form" name={form}>
			<FormField>
				<Field
					name="phone"
					component={FormInput}
					type="phone"
					label={getTemplateText("initial-entrance.modalWindow.phone-label")}
					inputRef={input => phoneRef.current = input}
				/>
			</FormField>
			{needRecapture() && <Recapture />}
			{errors.submitError && <FormError>{errors.submitError}</FormError>}
			<Button
				className="btn-submit"
				primary
				block={false}
				type="submit"
				disabled={(!isValid && (errors && !errors.submitError)) || isSubmitting}
				loading={isSubmitting}
			>{getTemplateText("initial-entrance.modalWindow.button")}</Button>
		</Form>
	)
}

export default PhoneEntranceForm