import React, {FC, useState, useRef, useEffect, useLayoutEffect, useCallback} from "react";
import cn from "classnames";
import {Link} from "react-router-dom";

import WithContext from "../../../../HOCs/WithContext/WithContext";
import actions from "../../../../../context/actions";
import {FormattedHTMLMessage} from "../../../../../services/TextService";
import {ICONS} from "../../../../UI/Icon/enums";
import {NavigationBlockProps} from "./types"

import Icon from "../../../../UI/Icon/Icon";

import "./NavigationBlock.styl";


const HEIGHT_OF_LI = 31;

const NavigationBlock:FC<NavigationBlockProps> = ({location, links, parentSlug = "", className, logout, ...props}) => {
	const [activeDropdown, setActiveDropdown] = useState(null);
	const initialNestedULs: any[] = [];
	const _nestedULs = useRef(initialNestedULs);

	/***
	 * sets initial active dropdown (nested UL)
	 * @param location
	 */
	useLayoutEffect(()=>{
		const {pathname} = location;
		const indexOfEndRootRoute = pathname.indexOf("/", 1);
		if (~indexOfEndRootRoute) {
			setActiveDropdown(pathname.slice(0, indexOfEndRootRoute))
		}
	}, [location, location.pathname]);

	/***
	 * expands active dropdown (nested UL)
	 */
	useEffect(()=>{
		for (const ul of _nestedULs.current) {
			if (!ul) {
				return;
			}
			if (ul.classList.contains("open")) {
				const maxHeight = ul.childElementCount * HEIGHT_OF_LI;
				ul.style["max-height"] = maxHeight + "px";
			} else {
				ul.style["max-height"] = "0px";
			}
		}
	});

	/***
	 * returns function for GA
	 * @param link
	 * @returns {Function}
	 */
	const updateGa = useCallback((link) => {
		return function () {
			// GA("send", "event", link, "left_menu", location.pathname);
		}
	},[]);

	/***
	 * pushs node to _nestedULs
	 * @param node
	 */
	const getULRef = useCallback((node: any) => {
		_nestedULs.current.push(node)
	},[]);

	/***
	 * On logout
	 */
	const onLogout = useCallback(() => {
		logout();
	},[logout]);


	/***
	 * changes active dropdown (nested UL)
	 * @param id
	 */
	const changeActiveDropdown = (id) => {
		setActiveDropdown(activeDropdown => activeDropdown === id ? null : id )
	};


	/***
	 * renders dropdown menu
	 * @param links
	 * @param parentSlug
	 * @param className
	 * @returns {*}
	 */
	const renderNavListItem = (links, parentSlug, className = "menu__nav-link") => {
		const FORA_HOST = "https://fora.ua/";

		return links.map(({title, path: slug, link, tagGA, subLinks: nestedLinks}, i) => {
			const to = `${parentSlug}${slug === "/" ? slug : "/" + slug}`,
				isCurrentPath = link ? false : (to === location.pathname),
				Component: any = link ? "a" : (isCurrentPath ? "span" : Link),
				props = {
					href: link || undefined,
					target: link && !~link.indexOf(`${FORA_HOST}`) ? "_blank" : undefined,
					className: cn({active: isCurrentPath}),
					to,
					onClick: tagGA ? updateGa(tagGA) : () => {},
					children: title
				};

			if (nestedLinks && nestedLinks.length !== 0) {
				const nestedNavigationList = <ul ref={getULRef}
																				 className={cn("menu__nested-nav-list", {open: activeDropdown === to})}>
					{renderNavListItem(nestedLinks, to, "menu__nav-link menu__nested-nav-link")}
				</ul>;

				return (
					<li key={i} className={cn(className, {open: activeDropdown === to})}>
						<div className="dropdown-title">
							<Component {...props}/>
							<div className="dropdown-arrow"
									 onClick={() => changeActiveDropdown(to)}>
								<Icon icon={ICONS.faqArrow}/>
							</div>
						</div>
						{nestedNavigationList}
					</li>
				)
			}

			return <li key={i} className={className}><Component {...props}/></li>
		});
	};


	return (
		<nav className={cn("menu__nav", className)}>
			<ul className="menu__nav-list">
				{renderNavListItem(links, parentSlug, "menu__nav-link")}
				{	className === "additional-menu" && props.isLogin &&
						<li	className="menu__nav-link" onClick={onLogout}>
							<FormattedHTMLMessage id="sidebar_menu.exit"/>
						</li>
				}
			</ul>
		</nav>
	)
};


export default WithContext(
	(state) => ({
		isLogin: state.isLogin
	}),
	dispatch => ({logout: () => dispatch(actions.logout())})
)(NavigationBlock);