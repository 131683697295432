import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import cn from "classnames";
import "moment/locale/uk";

import {BONUS_FILTER_TYPE, BONUS_TYPE_LINES} from "../../constants";

import {Currency} from "../../services/TextService";
import {fixElementInViewPort} from "../../services/AppServices";
import {isFunction} from "../../utils";

import Icon from "../UI/Icon/Icon";
import Collapse from "../UI/Collapse/Collapse";
import HistoryCheck from "../HistoryList/HistoryCheck/HistoryCheck";
import {BonusHistoryPropsType} from "./types";
import {ICONS} from "../UI/Icon/enums";
import BalancePreview from "../BalancePreview/BalancePreview";

import "./BonusHistory.styl";


const renderList = (list, toggle, activeId, activeFilter) => {
	const filteredHistoryList = list.bonusLines.filter((check) => {
		const isEarnings = check.typeLine === BONUS_TYPE_LINES.creation;
		if (activeFilter === BONUS_FILTER_TYPE.ALL) {
			return true
		}
		if (activeFilter === BONUS_FILTER_TYPE.EARNINGS) {
			return isEarnings
		}
		if (activeFilter === BONUS_FILTER_TYPE.CHARGES) {
			return !isEarnings
		}
		return true;
	});
	return filteredHistoryList.map((item, i) => {
		if (!item) {
			return null;
		}
		const date = new Date(item.created),
			creationDate = `${item.created}.000Z`,
			expired = new Date(item.expired),
			active = activeId === i,
			isEarnings = item.typeLine === BONUS_TYPE_LINES.creation,
			checkInfo = {
				storeId: item.filialId,
				checkId: item.chequeId,
				creationDate: creationDate,
				loyaltyFactId: item.loyaltyFactId,
			},
			checkData = {
				paymentType: item.payType,
			};

			const isDisabled = item.typeLine === BONUS_TYPE_LINES.blocking || item.typeLine === BONUS_TYPE_LINES.charity


		return (
			<li key={i} className={cn("history-list__li", {isDisabled})}>
				<div className={cn("history-list__item", {active})}>
					<div className="history-list__item-head" onClick={toggle(i)}>
						<div className="history-list__item-city">
							{item.name}
						</div>
						<div className="history-list__item-date">
							<span>{moment(date).utc(false).format("DD.MM.YYYY")}</span>
							<span>{moment(date).utc(false).format("HH:mm")}</span>
						</div>
						<div className="history-list__item-date due-date">
							{isEarnings &&
							<span> Діє до {moment(expired).utc(false).format("DD.MM.YYYY")}</span>
							}
						</div>
						<div className="history-list__item-total-points">

							{item.signText}
							<Currency value={item.balance}/>
						</div>
						<div className="history-list__item-toggle">
							<Icon icon={ICONS.chevronDown}/>
						</div>
					</div>

					<Collapse className="history-list__item-body" open={active}>
						<div className="history-list__item-products">
							{
								isEarnings ?
									<BalancePreview currentBalance={{balance: item.balance, balanceLines: item.bonusOperations}}
																	isBalanceHistory={true}
									/>
									:
									<HistoryCheck checkInfo={checkInfo} {...checkData}/>
							}

						</div>
					</Collapse>
				</div>
			</li>
		)
	})
};

const BonusHistory: FC<BonusHistoryPropsType> = ({list, activeId, onToggle, activeFilter}) => {
	const [active, setActive] = useState(-1);

	const toggle = useCallback((id) => (e) => {
		fixElementInViewPort(e.currentTarget);
		if (!!onToggle && isFunction(onToggle)) {
			onToggle(id);
		} else {
			setActive(active !== id ? id : -1);
		}
	}, [onToggle, setActive, active]);

	useEffect(() => {
		setActive(-1);
	}, [activeFilter])

	const activeItem = (onToggle && activeId) || active;

	const memoList = useMemo(() => renderList(list, toggle, +activeItem, activeFilter),
		[list, activeItem, toggle, activeFilter]);

	return (<ul className="history-list">{memoList}</ul>)
};

export default BonusHistory;
