import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {myForaRoutesMap} from "./services/RouteService/RouteService";
import {ROUTE} from "./services/RouteService/enums";

import PersonalPage from "./pages/PersonalPage/PersonalPage";
import BonusPage from "./pages/BonusPage/BonusPage";
import PersonalProposalsPage from "./pages/PersonalProposalsPage/PersonalProposalsPage";
import MoneyBoxPage from "./pages/MoneyBoxPage/MoneyBoxPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import Layout from "./components/Layout/Layout";
import NotFound from "./pages/NotFound/NotFound";
import Unavailable from "./pages/Unavailable/Unavailable";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import HelpModal from "./components/Modals/HelpModal/HelpModal";
import PurchasesPage from './pages/PurchasesPage/PurchasesPage';

import './App.styl';


const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
				<Switch>
					<Layout exact path={myForaRoutesMap[ROUTE.PERSONAL_PAGE]} component={PersonalPage}/>
					<Layout exact path={myForaRoutesMap[ROUTE.SPECIAL_PROPOSALS]} component={PersonalProposalsPage}/>
					<Layout exact path={myForaRoutesMap[ROUTE.PURCHASES]} component={PurchasesPage}/>
					<Layout exact path={myForaRoutesMap[ROUTE.MONEYBOX]} component={MoneyBoxPage}/>
					<Layout exact path={myForaRoutesMap[ROUTE.BONUS]} component={BonusPage}/>
					<Layout path={myForaRoutesMap[ROUTE.PERSONAL_INFO]} component={SettingsPage}/>
					<Route path={myForaRoutesMap[ROUTE.UNAVAILABLE]} component={Unavailable}/>
					<Route path={myForaRoutesMap[ROUTE.NOT_FOUND]} component={Unavailable}/>
					<Route path={myForaRoutesMap[ROUTE.VERIFY_EMAIL]} component={VerifyEmail}/>
					<Route path="*" component={NotFound}/>
				</Switch>
				<CookieBanner/>
				<HelpModal/>
      </Router>
    </div>
  );
};

export default App;
