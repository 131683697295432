
import "./Intro.base.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";
import {renderNode} from "../../utils";

type IntroProps =  {
	title?: string;
	bgImage?: string;
	className?: string;
	hr?: boolean;
	text?: string;
	content?:any;
	bg?:string;
	colored?:boolean;
	color?: number;
}

const Intro: FunctionComponent<IntroProps> = ({className, title, text, hr, color, content, children, bg, colored, ...props}) =>  {
	const textProps = {
			className: "intro-text",
			children: renderNode(text)
		};
	const bgImage = bg ? `url(${bg})` : "none";
	return (
		<div {...props}
				 className={cn("intro", className, {"intro-colored": colored || bg}, color === 0 ? "white" : null)}
				 style={{backgroundImage: bgImage,  backgroundRepeat: "no-repeat"}}>
			<div className="intro-content">
				<h1 className="intro-title heading3">{renderNode(title)}</h1>
				{!!hr && <hr className="intro-hr"/>}
				{!!text && <div {...textProps}/>}
			</div>
			{children}
			{content}
		</div>
	)
};

export default Intro;
