import {reduceTags} from "../utils";

const TITLE_HOLDER = {
	title:document.title,
	time: ""
};

function setDocumentTitle(title){
	TITLE_HOLDER.title = reduceTags(title);
	changeTitle();
}

function setTime(time){
	TITLE_HOLDER.time = time;
	changeTitle();
}

function changeTitle(){
	let title = TITLE_HOLDER.title;
	if(typeof  TITLE_HOLDER.time === "string" && TITLE_HOLDER.time.length > 0){
		title = `${TITLE_HOLDER.time} - ${TITLE_HOLDER.title}`;
	}
	if(document.title !== title){
		document.title = title;
	}
}

export {setDocumentTitle, setTime};