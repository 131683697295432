import React, {FC, useCallback, useState} from 'react';

import Button from "../UI/Button/Button";
import CookieAgreementModal from "../Modals/CookieAgreementModal/CookieAgreementModal";

import {checkAgreeUseCookies} from "../../services/AppServices";
import {setCustomCookies} from "../../services/CookiesService";
import {FormattedHTMLMessage} from "../../services/TextService";

import "./CookieBanner.styl";


const CookieBanner: FC = () => {
	const [showCookieBanner, setShowCookieBanner] = useState(checkAgreeUseCookies());
	const [showCookieAgreementModal, setShowCookieAgreementModal] = useState(false);

	const toggleCookieAgreementModal = useCallback(() => {
		setShowCookieAgreementModal(showReadMorePopUp => !showReadMorePopUp)
	}, []);

	const agreeUseCookies = useCallback(() => {
		setCustomCookies("agreeUseCookies", Date.now(), {expires: new Date(2100, 0, 1).toUTCString()});
		setShowCookieBanner(checkAgreeUseCookies());
	}, []);

	if(showCookieBanner) {
		return null
	}

	return (
		<div className="cookie-banner">
			<span className="cookie-message"><FormattedHTMLMessage id="cookieBanner"/></span>

			<Button className="cookie-confirm-btn" primary outline onClick={agreeUseCookies}>
				<FormattedHTMLMessage id="common.modalWindow.button.good"/>
			</Button>
			<Button className="cookie-more-btn" primary reversed onClick={toggleCookieAgreementModal}>
				<FormattedHTMLMessage id="more"/>
			</Button>

			<CookieAgreementModal isOpen={showCookieAgreementModal} onClose={toggleCookieAgreementModal}/>
		</div>
	);
};


export default CookieBanner;