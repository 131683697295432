import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "react-router";

import PersonalInformation from "../../../components/PersonalInformation/PersonalInformation";
import MyContactsData from "../../../components/MyContactsData/MyContactsData";
import PersonalProposals from "../../../components/PersonalProposals/PersonalProposals";

import "./MyInfo.styl";



type MyInfoPropsType = {} & RouteComponentProps;

const MyInfo: FunctionComponent<MyInfoPropsType> = () => {
		return (
			<div className="my-info-page">

				<PersonalInformation/>

				<MyContactsData/>

				<PersonalProposals/>

			</div>
		)
};


export default MyInfo;