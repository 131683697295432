import {useEffect, useState, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import {PURCHASE_STATE, PURCHASE_TYPES} from '../../../../../constants';
import {getTokenData} from '../../../../../services/AppServices';
import {BASKET_API} from '../../../../../services/EnvServices';
import {getQueryParams} from '../../../../../services/RouteService/RouteService';
import {parseErrorOnfly} from '../../../../../utils';
import {GetOrdersListRequestData, GetOrdersListResp, OrderItemType} from './types';

const ORDERS_PER_PAGE = 10;

const useOrdersList = (ref: React.MutableRefObject<HTMLUListElement | null>, isPreview?: boolean ) => {
	const location = useLocation();
	const {to, from, status: stateStatus, type} = getQueryParams(location);

	const {accessToken} = getTokenData();
	const [loading, setLoading] = useState(false);
	const [hasLoadMore, setHasLoadMore] = useState(true);
	const [pagination, setPagination] = useState({limit: ORDERS_PER_PAGE, offset: 0});
	const [orders, setOrders] = useState<OrderItemType[]>([]);

	const handleScroll = useCallback(() => {
		if (
			ref.current &&
			window.innerHeight + document.documentElement.scrollTop >=
			ref.current.offsetTop + ref.current.offsetHeight - 50 &&
			!loading && hasLoadMore
		) {
			setPagination(prevPagination => ({
				...prevPagination,
				offset: prevPagination.offset + prevPagination.limit
			}));
		}
	}, [loading, hasLoadMore, ref]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [loading, hasLoadMore, handleScroll]);

	useEffect(() => {
		setPagination(({limit: ORDERS_PER_PAGE, offset: 0}));
		getOrdersList()
			.then(response => setOrders(response))
			.finally(() => setLoading(false));
		// eslint-disable-next-line
	}, [from, to, type, stateStatus])

	useEffect(() => {
		if (pagination.offset > 0 && !isPreview) {
			getOrdersList(ORDERS_PER_PAGE, pagination.offset)
				.then(response => setOrders(orders => ([...orders, ...response])))
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line
	}, [pagination, isPreview]);

	const getOrdersList = useCallback((limit?, offset?): Promise<OrderItemType[]> => {
		setLoading(true);
		const data: GetOrdersListRequestData = {
			...((+type === PURCHASE_TYPES.ONLINE || type === undefined) && stateStatus ? {state: +stateStatus} : {}), // (тільки якщо "purchaseType": 0)
			...(+stateStatus === PURCHASE_STATE.SHIPPED && type === undefined ? {purchaseType: null} : stateStatus && type === undefined ? {purchaseType: +PURCHASE_TYPES.ONLINE} : type !== undefined ? {purchaseType: +type} : {purchaseType: null}), //(не обов'язково, якщо не вказано вибираються всі, 0-онлайн, 1 - офлайн)
			...(from ? {dateFrom: moment(from, 'DD-MM-YYYY').startOf('day').format()} : {}), // (не обов'язково, якщо не вказано береться за останні 30 днів)
			...(to ? {dateTo: moment(to, 'DD-MM-YYYY').endOf('day').format()} : {}), // (не обов'язково, якщо не вказано береться поточна дата)
			limit: isPreview ? 5 : limit || ORDERS_PER_PAGE, // (не обов'язково, якщо не вказано береться макс. 100 штук),
			offset: offset || 0, // (не обов'язково, якщо не вказано то 0)
		}
		return axios.post<GetOrdersListResp>(BASKET_API, {
			method: "PurchaseHeadsList",
			data
		}, {headers: {"x-fora-authtoken": accessToken}})
			.then(({data: {data, eComError}}) => {
				if (!!eComError.errorCode) {
					throw new Error(eComError.errorMessage);
				}
				setHasLoadMore((data || []).length >= ORDERS_PER_PAGE)
				return data || [];
			})
			.catch(err => {
				parseErrorOnfly(err, true, null, true);
				return [];
			});

	}, [type, from, to, stateStatus, accessToken, isPreview]);

	return {orders, loading}
}

export default useOrdersList