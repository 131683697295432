import {isDev} from "./EnvServices";

/***
 * check ui version and return is this version are last one
 * @returns {Promise<{localVersion: *, isCorrectVersion: boolean, currentVersion: *}>}
 */
export function checkUIVersion() {
	const localVersion = (process.env || {}).VERSION;

	const headers = new Headers();
	headers.append("pragma", "no-cache");
	headers.append("cache-control", "no-cache");

	const config = {
		method: "GET",
		headers: headers,
	};
	fetch(new Request("/version.json"), config)
		.then(res => res.json())
		.then(function (response) {
			const currentVersion = response.version;
			return {
				isCorrectVersion: localVersion === currentVersion,
				localVersion,
				currentVersion
			}
		}).catch(() => {
			return {
				isCorrectVersion: false,
				localVersion,
				currentVersion: null
			}
		}).then(( {isCorrectVersion, currentVersion, localVersion}) => {
			if (!isCorrectVersion) {
				throw new Error(`У вас застаріла версія сайту, а саме: ${localVersion}. Остання версія ${currentVersion}`);
			} else {
				localStorage.removeItem("tryUpdate");
				console.log(`У вас остання версія сайту (${currentVersion})`);
			}
		}).catch((err) => {
			let count = + localStorage["tryUpdate"] || 0;
			if (count < 10) {
				console.log(err.message || `У вас застаріла версія сайту, оновлюємо`);
				localStorage["tryUpdate"] = count + 1;
				//TODO: refactor. force reload param is deprecated. try use it without force
				// @ts-ignore
				!isDev && window.location.reload(true);
				// history.push(getModalLocation(HASH_MODALS.UPDATE));
			} else {
				console.log(`У вас застаріла версія сайту, Спробуйте оновити самостійно натиснувши CTRL+F5 чи Command (⌘)-R`);
			}
		})
}