import React, {Component} from "react";
import {FieldProps} from "formik";

type WithFocusStateProps =  FieldProps & {
	[key: string]: any;
};

type WithFocusStateState = {
	focused: boolean;
}

export function WithFocusState  (TargetComponent) {
	return class WithFocusState extends Component<WithFocusStateProps, WithFocusStateState> {

		state = {
			focused: false
		};

		/**
		 * handle focus event
		 */
		handleFocus = () => {
			this.setState(this.toggleFocusState(true))
		};

		/***
		 * toggle focus state
		 * @param value
		 */
		toggleFocusState = (value: boolean) => {
			return {focused: value};
		};

		/**
		 * handle blur event
		 * @param e
		 */
		handleBlur = (e) => {
			e.persist();
			this.setState(this.toggleFocusState(false), () => {
				if (this.props.field && this.props.field.onBlur) {
					this.props.field.onBlur(e);
				}
				if (this.props.form) {
					this.props.form.validateForm(this.props.form.values)
				}
			});
		};

		render() {
			return <TargetComponent
				{...this.props}
				focused={this.state.focused}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
			/>;
		}
	}
}