import React, {FC} from 'react'
import {Field, FormikValues, useFormikContext} from 'formik';

import useAutoFocus from '../../hooks/useAutoFocus';
import {form} from './const';

import Form from '@components/Form/Form';
import FormField from '@components/Form/FormField';
import FormInput from '@components/Form/FormInput';
import Button from '@components/UI/Button/Button';
import FormError from '@components/Form/FormError/FormError';
import GoToOptBtn from './components/GoToOptBtn';
import GoToRecoveryBtn from './components/GoToRecoveryBtn';
import {AuthFormPropsType} from '../types';

const PasswordEntryForm: FC<AuthFormPropsType & {withOpt: boolean}> = ({determineNextStep, withOpt}) => {
	const {handleSubmit, isSubmitting, errors, dirty, isValid} = useFormikContext<FormikValues>();
	const passwordRef = useAutoFocus();

	return (
		<Form onSubmit={handleSubmit} className="password-form" name={form}>
			<FormField>
				<Field
					name="password"
					component={FormInput}
					type="password"
					label={"Введіть будь ласка, ваш пароль"}
					innerRef={passwordRef}
				/>
				<div className="password-form__links-wrapper">
					{withOpt && <GoToOptBtn determineNextStep={determineNextStep} />}
					<GoToRecoveryBtn determineNextStep={determineNextStep}/>
				</div>
			</FormField>
			{!!(errors && errors.submitError) && <FormError className="form__footer-error">{errors.submitError}</FormError>}
			<Button
				className="password-form-btn btn-submit"
				primary
				type="submit"
				disabled={isSubmitting || !dirty || !isValid}
				loading={isSubmitting}>увійти</Button>
			<div className="password-form__links-wrapper">
				{withOpt && <GoToOptBtn determineNextStep={determineNextStep} isMobile />}
			</div>
		</Form>
	)
}

export default PasswordEntryForm