import React, {FC} from "react";
import cn from "classnames";

import "./BonusHistoryFilter.styl";

type BonusHistoryFilterProps = {
	active?: number;
	setActive?: any,
	filterFields?: {
		title : string;
		value: number;
		disabled: boolean;
	}[]
	className?:string;
}

const BonusHistoryFilter: FC<BonusHistoryFilterProps> = ({active, setActive, filterFields, className}) => {

	return (
		<div className={cn("bonus-history-filter", className)}>
			<div className={`button-switch`}>
				{!!filterFields && filterFields.map(({title, value, disabled}) => {
					const isActiveItem = active === value;
					return (
						<div className={`button-switch-item ${isActiveItem && "active"} ${disabled && "disabled"}`}
								 onClick={(!disabled && !isActiveItem) ? () => (setActive(value)) : undefined}
								 key={value}
						>
							{title}
						</div>
					)
				})}
			</div>
		</div>
	)
};

export default BonusHistoryFilter;