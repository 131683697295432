import './Menu.styl';

import React, {Component} from 'react';

import {MenuProps, MenuState} from "./types";

import {isMobile} from "../../../utils";

import SidebarMenu from "./SidebarMenu/SidebarMenu";
import MenuHeader from "./MenuHeader/MenuHeader";

const MAX_INNER_WIDTH = 1101;

class Menu extends Component<MenuProps, MenuState> {
	constructor(props: any) {
		super(props);
		this.state = {
			isHidden: false
		};
	}


	componentDidMount() {
		window.addEventListener("resize", this.toggleSidebarOnResize);
		this.toggleSidebarOnResize();
	}

	componentWillReceiveProps(nextProps: Readonly<MenuProps>, nextContext: any): void {
		const {innerWidth} = window, {isHidden} = this.state;
		if (this.props.location.pathname !== nextProps.location.pathname) {
			isMobile.any() &&
			innerWidth < MAX_INNER_WIDTH &&
			isHidden === false &&
			this.setState({isHidden: true});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.toggleSidebarOnResize);
	}

	/***
	 * toggling sidebar on resize
	 */
	toggleSidebarOnResize = () => {
		const {innerWidth} = window, {isHidden} = this.state;
		if (innerWidth < MAX_INNER_WIDTH && isHidden === false) {
			this.setState({
				isHidden: true
			});
		} else if (innerWidth >= MAX_INNER_WIDTH && isHidden !== false) {
			this.setState({
				isHidden: false
			});
		}
	};

	/****
	 * toggle sidebar onclick
	 */
	toggleSidebar = () => {
		this.setState({isHidden: !this.state.isHidden});
	};


	render() {
		return (
			<div className="menu">
				<SidebarMenu
					isHidden={this.state.isHidden}
					toogle={this.toggleSidebar}
					location={this.props.location}
				/>
				<MenuHeader toogle={this.toggleSidebar}/>
			</div>
		)
	}
}

export default Menu;
