import "./NotFound.styl";
import React from "react";
import Logo from "../../components/UI/Logo/Logo";
import Button from "../../components/UI/Button/Button";
import img from "images/404.png"

const NotFound = () => {
	return (
		<div className="not_found-wrapper">
			<div className="not_found-holder">
				<Logo/>
				<img src={img} alt="not found" className="not_found-image"/>
				<div className="not_found-text">
					Тут немає інформації, яку ви шукаєте.
						<br/>
					Будь ласка, перевірте адресу сторінки або почніть
						<br/>
					з головної - там ви швидко зорінтуєтесь.
				</div>
				<Button primary to="https://fora.ua">
					<a href="https://fora.ua">На головну</a>
				</Button>
			</div>
		</div>
	)
};

export default NotFound