import React, {FunctionComponent} from "react";
import {Link as RRLink} from "react-router-dom";

import {isObject, isString} from "../../utils";
import {hrefFromLocation} from "../../services/RouteService/RouteService";

function isExternalLink(link) {
	return !!(isObject(link) && link.host)||(isString(link) && link.indexOf("http") !== -1);
}

type LinkPropsType = {
	to: string | {[key: string] : string} | any;
	onClick?: () => void;
	className?: string;
	target?: string;
}

const Link:FunctionComponent<LinkPropsType> = ({to, ...restProps}) => {
	const isExternal = isExternalLink(to);
	if (isExternal) {
		// eslint-disable-next-line
		return <a {...{href: hrefFromLocation(to), ...restProps}}/>
	} else {
		return <RRLink {...{to}} {...restProps}/>
	}
};

export default Link;