import React, {FunctionComponent} from "react";
import cn from "classnames";
import LazyLoad from "react-lazyload";

import {renderNode} from "../../utils";
import Link from "../../components/UI/Link";
import {urlTo} from "../../services/RouteService/RouteService";
import {BannerPropsType} from "./types"

import "./Banner.styl";

function lazyLoadHeight() {
	const width = window["width"];
	let height = "500px";
	if (width <= 500) {
		height = "0";
	} else if (width <= 768) {
		height = "0"
	}
	return height;
}

const Banner: FunctionComponent<BannerPropsType> = ({className, title, image, color, link}) => {
	const to = urlTo(link);
	const props = {
		className: cn("promo", {["theme-" + (color || "").toLowerCase()]: color}, className),
		to: to || "",
	};

	return (
		<Link {...props}>
			<LazyLoad height={lazyLoadHeight()} overflow={true}>
				<div className="banner__image"><img src={image} alt={title}/></div>
			</LazyLoad>
			<div className="promo__info">
				<div className="promo__info-content">
					<div className="promo__title">{renderNode(title)}</div>
				</div>
			</div>
		</Link>
	)
};

export default Banner;
