import React from 'react'
import {Field} from 'formik'
import isTouchDevice from "is-touch-device";

import getTemplateText from '@services/TextService'
import {getMessageId} from '../../helpers';
import {form} from '../const';

import FormField from '@components/Form/FormField'
import FormInput from '@components/Form/FormInput'
import InfoIcon from '@components/UI/InfoIcon/InfoIcon'

const EmailVerification = ({onEmailChange}) => {
	const isTouch = isTouchDevice();
	const emailLabel = getMessageId("label.email", form);

	return (
		<FormField className="email_from-field">
			<Field
				name="email"
				component={FormInput}
				label={emailLabel && getTemplateText(emailLabel)}
				onChange={onEmailChange}
				type="text"
			/>
			<div className="email-controls">
				<InfoIcon
					place={isTouch ?  "bottom" : "right"}
					event={isTouch ? "click" : "mouseenter"}
					eventOff="mouseleave"
				>
					{getTemplateText("createPasswordWithEmailVerificationForm.email.tooltip")}
				</InfoIcon>
			</div>
		</FormField>
	)
}

export default EmailVerification