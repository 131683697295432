import React, {FC, useEffect} from "react";
import InputElement from "react-input-mask";
import {FieldProps} from "formik";

import {
	BARCODE_FORMAT_CHARS,
	BARCODE_MASK,
	BARCODE_MASK_CHAR,
	BARCODE_ACTIVE,
	BARCODE_DEFAULT_VALUE
} from "../../services/FormServices/constants";
import {isTargetProject} from "../../utils";
import {PROJECT} from "../../constants";

type BarcodeInputPropsType = FieldProps & {
	mask?: string;
	maskChar?: string | null;
	formatChars?: {};
	active: boolean;
	value: string;
	[key: string]: any
}

const BarcodeWithoutMaskInput: FC<BarcodeInputPropsType> = ({form, mask, maskChar, formatChars, field, active, ...props}) => {

	useEffect(
		() => {
			const {value, name} = field;
			const {setFieldValue} = form;
			let clearNumber = value.replace(/\D+/g, "");
			clearNumber = clearNumber.length > 13 ? clearNumber.substr(0, 13) : clearNumber;
			setFieldValue(name, clearNumber);
			// Not fix this depends !!!
			// eslint-disable-next-line
		}, [field.value]);

	return <input
		type="phone"
		{...props}
		value={field.value}
		name={field.name}
	/>
};

const BarcodeMaskInput: FC<BarcodeInputPropsType> = ({form, mask = "", field, active, ...props}) => {

	useEffect(
		() => {
			const {setFieldValue, touched} = form;
			const {name, value} = field;
			let clearNumber = value.replace(/^\D+/g, "");
			clearNumber = clearNumber.length > 13 ? clearNumber.replace(/^0202/, "02") : clearNumber;
			clearNumber = clearNumber.length > 13 ? clearNumber.substr(0, 13) : clearNumber;
			clearNumber = clearNumber === "" && active ? "02" :
				(clearNumber === "02" && !active) || (clearNumber === "0" && !!touched[name]) ? "" : clearNumber;
			setFieldValue(name, clearNumber);
		},
		// Not fix this depends !!!
		// eslint-disable-next-line
		[field.value],
	);

	const {touched} = form;
	const {name, value} = field;
	const activeMask = !touched[name] ? '' : mask;
	return <InputElement {...props} value={value} mask={activeMask}/>

};

const BarcodeInput: FC<BarcodeInputPropsType> = (props) => {
	if (isTargetProject(PROJECT.FORA)) {
		return <BarcodeWithoutMaskInput {...props}/>
	}
	return <BarcodeMaskInput {...props}/>
};

BarcodeInput.defaultProps = {
	mask: BARCODE_MASK,
	maskChar: BARCODE_MASK_CHAR,
	formatChars: BARCODE_FORMAT_CHARS,
	active: BARCODE_ACTIVE,
	value: BARCODE_DEFAULT_VALUE,
};

export default BarcodeInput
