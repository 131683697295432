import React, {FC, useCallback, useState} from "react";
import cn from "classnames";

import {UnusedBalanceLinesType} from "./types";
import {ICONS} from "../UI/Icon/enums";
import {donutCircleColors, defaultColors} from "../../constants";
import {FormattedHTMLMessage} from "../../services/TextService";

import Icon from "../UI/Icon/Icon";

import "./UnusedBalanceLines.styl";


const UnusedBalanceLines: FC<UnusedBalanceLinesType> = ({currentBalance}) => {
	const {balanceLines} = currentBalance || [];
	const unusedBalanceLines = (balanceLines || []).filter(({balance})=> !balance);

	const [expandUnusedBalancePanel, setExpandUnusedBalancePanel] = useState(false);
	const toggleExpandUnusedBalancePanel = useCallback(() => {
		setExpandUnusedBalancePanel(!expandUnusedBalancePanel);
	}, [expandUnusedBalancePanel, setExpandUnusedBalancePanel]);

	const renderBalanceLines = useCallback(() => (
		unusedBalanceLines.map(({balanceName, codeName}, index) => {
			const balanceIconColor = donutCircleColors[codeName] || defaultColors[index];

			return (
				<li key={codeName} className="balance-line">
					<div className="balance-line_icon">
						<svg width="15px" height="15px" viewBox="0 0 40 40" className="icon-balance">
							<circle cx="20" cy="20" r="19" fill={balanceIconColor} />
						</svg>
					</div>
					<div className="balance-line_title">
						{balanceName}
					</div>
				</li>
			)
		})
	), [unusedBalanceLines]);


	if (!unusedBalanceLines.length) {
		return null;
	}

	return (
		<div className={cn("unused-balance-lines", {"open": expandUnusedBalancePanel})}>
			<div className="title-line">
				<h3 className="title" onClick={toggleExpandUnusedBalancePanel}>
					<FormattedHTMLMessage id="personalPage.unused_balance_lines.heading"/>
				</h3>
				<Icon	onClick={toggleExpandUnusedBalancePanel} icon={ICONS.chevronDown} raw/>
			</div>
			<ul className="content-line">
				{renderBalanceLines()}
			</ul>
		</div>
	);
};


export default UnusedBalanceLines;