import React, {Component} from 'react';
import {isMobile} from "../../../../../utils";
import appStore from "images/app-store.svg"
import googlePlay from "images/google-play.svg"

import "./AppsBlock.base.styl";


interface AppsBlockProps {
	zone?: string;
	appStoreLink : string;
	googlePlayLink: string;
	appStoreLinkSource?: string;
	appGooglePlayLinkSource?: string;
	title?: string;
}

class AppsBlock extends Component<AppsBlockProps> {
	/***
	 * GA btn press
	 */
	androidBtn = () => {
		return this.props.zone || "other";
	};
	/***
	 * GA btn press
	 */
	appleBtn = () => {
		return this.props.zone || "other";
	};

	render() {
		const {appStoreLink, googlePlayLink, appGooglePlayLinkSource, appStoreLinkSource} = this.props;
		if (!appStoreLink && !googlePlayLink) return null;
		const iOS = isMobile && isMobile.iOS();
		const android = isMobile && isMobile.Android();
		return (
			<div className={"mobile-apps-block"}>
				{!iOS && !android ?
					<div className={"mobile-apps-block__links-wrapper"}>
						{appStoreLink && <div className={"mobile-apps-block-link mobile-apps-block__iOS-link-block"}>
							<a href={appStoreLink} rel="noopener noreferrer" target="_blank" onClick={this.appleBtn}>
								<span dangerouslySetInnerHTML={{__html: appStore}}/>
							</a>
							{appStoreLinkSource && <div className={"mobile-apps-block__qr-code-wrapper"}>
								<img src={appStoreLinkSource} alt="App Store"/>
							</div>}
						</div>}

						{googlePlayLink && <div className={"mobile-apps-block-link mobile-apps-block__android-link-block"}>
							<a href={googlePlayLink} rel="noopener noreferrer" target="_blank" onClick={this.androidBtn}>
								<span dangerouslySetInnerHTML={{__html: googlePlay}}/>
								</a>
							{appGooglePlayLinkSource && <div className={"mobile-apps-block__qr-code-wrapper"}>
								<img src={appGooglePlayLinkSource} alt="Google Play"/>
							</div>}
						</div>}
					</div> : null}

				{iOS && <div className={"mobile-apps-block-link mobile-apps-block__iOS-link-block-mobile"}>
					<a href={appStoreLink} rel="noopener noreferrer" target="_blank" onClick={this.appleBtn}>
						<span dangerouslySetInnerHTML={{__html: appStore}}/>
						</a>
				</div>}
				{android && <div className={"mobile-apps-block-link mobile-apps-block__android-link-block-mobile"}>
					<a href={googlePlayLink} rel="noopener noreferrer" target="_blank" onClick={this.androidBtn}>
						<span dangerouslySetInnerHTML={{__html: googlePlay}}/>
					</a>
				</div>}
			</div>
		)
	}
}

export default AppsBlock;
