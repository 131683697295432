import React, {FunctionComponent} from "react";
import cn from "classnames";
import {browser} from "../../utils";
import {FormLabelProps} from "./types";

const FormLabel: FunctionComponent<FormLabelProps> = ({children, className, component}: FormLabelProps) => {
	const Component = component;
	return (
		<Component className={cn("form__label", className)}>
			<span className={`form__label-value${browser.isSafari ? ' safari-reset-animation' : ''}`}>{children}</span>
		</Component>
	)
};

FormLabel.defaultProps = {
	component: "label",
};

export default FormLabel;