import React, {FC, useState} from "react";
import Modal from "react-responsive-modal";
import moment from "moment";

import Link from "../../UI/Link";
import {FormattedHTMLMessage} from "../../../services/TextService";
import CookiesService, {setCustomCookies} from "../../../services/CookiesService";

import "./HelpModal.styl";

const HELP_MODAL_COOKIE = "helpModalShown";

const HelpModal:FC = () => {
	const [isOpen, setIsOpen] = useState(!CookiesService.getItem(HELP_MODAL_COOKIE));
	const onClose = () => {
		setIsOpen(false);
		// const oneDay = (new Date(Date.now() + (60 * 60 * 24 * 1000 * 1))).toUTCString();
		const oneMounth =  moment(moment().format()).add(30, "days");
		setCustomCookies(HELP_MODAL_COOKIE, true, {expires: oneMounth}) ;
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{
				"modal": "modal-window help-modal",
				"closeButton": "modal-closeButton",
				"closeIcon": "modal-closeIcon"
			}}
		>
			<h3 className="modal-heading"><FormattedHTMLMessage id={"helpModal.title"}/>
				<img className="flag" src="/images/fora/help-modal/flag.webp" alt="flag"/>
			</h3>
			<div className="help-modal__body">
				<Link to="https://bank.gov.ua/ua/about/support-the-armed-forces" target="_blank" onClick={onClose}>
					<div className="item-wrapper">
						<img src="/images/fora/help-modal/army.svg" alt="army"/>
						<div className="text"><FormattedHTMLMessage id={"helpModal.supportArmy"}/></div>
					</div>
				</Link>
				<Link to="https://bank.gov.ua/ua/about/humanitarian-aid-to-ukraine" target="_blank" onClick={onClose}>
					<div className="item-wrapper">
						<img src="/images/fora/help-modal/humanist.svg" alt="humanist"/>
						<div className="text"><FormattedHTMLMessage id={"helpModal.supportHumanist"}/></div>
					</div>
				</Link>
			</div>
		</Modal>
	)
};

export default HelpModal;