import React, {FunctionComponent} from "react";
import cn from "classnames";

import {renderNode} from "../../services/TextService";
import {IconTextListPropsType, ItemType} from "./types";

import "./IconTextList.styl";

/***
 * Menage image source
 * @param srcImage
 */
function getItemImage(srcImage: string): string | JSX.Element | null {
	const maxLength = 150;
	if (!srcImage) {
		return null;
	}
	if (srcImage.length < maxLength ) {
		return <div className="icon-text-list__item-icon"><img src={srcImage} alt=""/></div>
	}
	return <div className="icon-text-list__item-icon" dangerouslySetInnerHTML={{__html: srcImage}} />;
}

const IconTextList: FunctionComponent<IconTextListPropsType> = ({component, className, items, ...props}) => {
	const Component = component || "ul";

	return (
		<Component className={cn("icon-text-list", className)}>
			{(items || []).map((item: ItemType, i) => (
				<li key={item.id || i} className="icon-text-list__li">
					<div className="icon-text-list__item">
						{getItemImage(item.image)}
						<div className="icon-text-list__item-text">{renderNode(item.text || item.description || "")}</div>
					</div>
				</li>
			))}
		</Component>
	)
};


export default IconTextList;