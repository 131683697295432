/**
 * Service get data from global object and return value of text by it id
 */

import React from "react";
import moment from "moment";
import {CASUAL_DATE_FORMAT} from "../constants";

interface AssociativeArray {
	[key: string]: string;
}


/***
 * Get pure string text
 * @param id
 */
export default function getTemplateText(id: string): string {
	const textCollection: AssociativeArray = window["TEMPLATE_TEXT"] || {};
	return textCollection[id] || id;
}

/***
 * Render text in span or div
 * @param text
 * @param isDiv
 */
export function renderNode(text: string, isDiv: boolean = false): JSX.Element | null {
	if (typeof text === "string") {
		return isDiv ? <div dangerouslySetInnerHTML={{__html: text}}/>
			: <span dangerouslySetInnerHTML={{__html: text}}/>
	}
	return null;
}

/***
 * Render text from the global text object and place it dom element
 * @param text
 * @param isDiv
 */
export function renderTemplateTextNode(text: string, isDiv: boolean = false): JSX.Element | null {
	return renderNode(getTemplateText(text), isDiv);
}

/***
 * Render text to span with some values
 * @param props
 * @constructor
 */
export function FormattedHTMLMessage(props: { id: string, values?: { [key: string]: any | undefined } }): JSX.Element | null {

	const values = props.values;
	if (!values) {
		return renderTemplateTextNode(props.id);
	} else {
		let template = getTemplateText(props.id);
		const valueKeys = Object.keys(values);
		for (const key of valueKeys) {
			const templateSign = `{${key}}`;
			template = template.replace(templateSign, values[key] || "");
			template = pluralCount(template, key, values[key]);
		}
		return renderNode(template);
	}
}

/***
 * Replace spacial template like {points, plural, one {# бал} few {# бали} other {# балів}} with value
 * @param template
 * @param varName
 * @param varValue
 */
function pluralCount(template: string, varName: string, varValue?: number | string) {

	const findInTemplate = `{${varName}, plural,`;
	const templateStartPosition = template.indexOf(findInTemplate);

	// eslint-disable-next-line
	if (templateStartPosition === -1 || !varValue && varValue !== 0) {
		return template;
	}
	const convertToNumber = +varValue;

	const templateEndPosition = findClosingBracketMatchIndex(template, templateStartPosition);

	if (templateEndPosition === -1 || isNaN(convertToNumber)) {
		return template;
	}

	const dataBetweenBracket = template.slice(templateStartPosition, templateEndPosition + 1);
	if (dataBetweenBracket) {
		const matchingKeyValue = {
			"one {": function (n) {
				return n === 1;
			},
			"two {": function (n) {
				if (10 < n && n < 20) {
					return;
				}
				const lastIndex = (n.toString()).slice(-1);
				return lastIndex === 2;
			},
			"few {": function (n) {
				if (10 < n && n < 20) {
					return;
				}
				const lastIndex = (n.toString()).slice(-1);
				return 1 < lastIndex && lastIndex < 5;
			},
			"other {": function () {
				return true;
			}
		};

		const lastIndexOfValue = +(convertToNumber.toString().slice(-2));
		const keys = Object.keys(matchingKeyValue);
		for (const key of keys) {
			const startIndex = dataBetweenBracket.indexOf(key);
			if (startIndex !== -1) {
				if (matchingKeyValue[key](lastIndexOfValue)) {
					const endOfKey = dataBetweenBracket.lastIndexOf(key) + key.length;
					let templateValue = dataBetweenBracket.substring(
						endOfKey,
						findClosingBracketMatchIndex(dataBetweenBracket, dataBetweenBracket.lastIndexOf(key) + key.length)
					);
					templateValue = templateValue.replace("#", "" + convertToNumber);
					const result = template.replace(dataBetweenBracket, templateValue);
					return result;
				}
			}

		}

	}
	return template;
}

/***
 * Find closing bracket
 * @param str
 * @param pos
 */
function findClosingBracketMatchIndex(str, pos) {
	let depth = 1;
	for (let i = pos + 1; i < str.length; i++) {
		switch (str[i]) {
			case '{':
				depth++;
				break;
			case '}':
				if (--depth === 0) {
					return i;
				}
				break;
		}
	}
	return -1;
}

/***
 * Format date to CASUAL_DATE_FORMAT
 * @param date
 */
export function toCasualDate(date?: string | Date): string {
	date = date ? date : new Date();
	return moment(date).format(CASUAL_DATE_FORMAT)
}

/***
 * convert date to casual format and wrap it with span
 * @param props
 * @constructor
 */
export function FormattedDate(props: { value: string | Date }): JSX.Element | null {
	return renderNode(toCasualDate(props.value))
}

/***
 * Add currency to number or replace some symbols to "грн"
 * @param props
 * @constructor
 */
export function Currency(props: { value: string | number }): JSX.Element | null {
	let result = "" + props.value;

	if (typeof props.value === "string") {
		result = result.replace(/(грн.|₴)/g, "грн");
		if (!result.includes("грн")){
			result = `${result} грн`;
		}
	} else {
		result = `${result} грн`;
	}

	return <span>{result}</span>
}