import React, {FC} from 'react'
import {Formik, FormikHelpers} from 'formik'

import {handleValidate, initialValid, initialValues} from './const';
import useConfirmAccessCode from './hooks/useConfirmRecoveryAccessCode';
import OtpEntryForm from './OtpEntryForm';

import {OtpFormValuesType, OTPFormWrapperPropsType} from './types';
import {AccessCodeRequestName} from '@graphql/types';

const OtpEntry: FC<OTPFormWrapperPropsType> = ({determineNextStep, onSendAgain, entranceType = "LOYALTY", isRecovery = false, withPassword = false, onClose}) => {
	const confirmRecoveryAccessCode = useConfirmAccessCode(AccessCodeRequestName.ConfirmRecoveryAccessCode);
	const confirmAccessCode = useConfirmAccessCode(AccessCodeRequestName.ConfirmAccessCode);
	const confirmLoginAccessCode = useConfirmAccessCode(AccessCodeRequestName.ConfirmLoginAccessCode);

	const handleSubmit = (values: OtpFormValuesType, actions: FormikHelpers<OtpFormValuesType>) => {
		if (isRecovery) {
			confirmRecoveryAccessCode(values, actions, determineNextStep);
		} else if (withPassword) {
			confirmLoginAccessCode(values, actions, onClose);
		} else {
			confirmAccessCode(values, actions, determineNextStep);
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={initialValid} validate={handleValidate}>
			<OtpEntryForm onSendAgain={onSendAgain} entranceType={entranceType} determineNextStep={determineNextStep} isRecovery={isRecovery} withPassword={withPassword} />
		</Formik>
	)
}

export default OtpEntry;