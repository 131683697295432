import React, {Component} from "react";
import InputElement from "react-input-mask";

import {DATE_FORMAT_CHARS, DATE_MASK, DATE_MASK_CHAR} from "../../services/FormServices/constants";

type DateInputProps =  {
	[key: string]: any;
}

class DateInput extends Component<DateInputProps, {}> {

	static defaultProps = {
		mask: DATE_MASK,
		maskChar: DATE_MASK_CHAR,
		formatChars: DATE_FORMAT_CHARS
	};

	render() {
		const {mask, form, field: {onBlur, ...fieldProps},  ...props} = this.props;
		return <InputElement {...props} {...fieldProps} mask={mask}/>
	}
}

export default DateInput;
