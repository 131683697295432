import {FORMS} from "js/forms/enums";
import {DATA_TYPE} from "@services/FormServices/constants";
import {PhoneEntranceFormValuesType} from "./types";
import {normalizeFields, validate} from '@services/FormServices/FormService';
import {phoneNumberFormat} from "@utils";

export const form = FORMS.MAIN_FORM;
export const fields = {
	phone: {
		name: "phone",
		dataType: DATA_TYPE.phone,
	}
};

export const initialValues: PhoneEntranceFormValuesType = {
	phone: phoneNumberFormat(localStorage.getItem("lastPhoneEntered") || ""),
	submitError: ""
};

export const initialValid = (props) => {
	const errors = validate(props.initialValues, {fields: normalizeFields(fields, form), form});
	return Object.keys(errors).length === 0;
}

export const handleValidate = (values: PhoneEntranceFormValuesType) =>
	validate(values, {fields: normalizeFields(fields, form), form})