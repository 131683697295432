import {isTargetProject} from "../../utils";
import {PROJECT} from "../../constants";

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
	namePattern = /^(?!(-))(([а-еж-щьюяґєії]+('?|’?)[а-еж-щьюяґєії]+)|([а-еж-щьюяґєії]+('?|’?)[а-еж-щьюяґєії]+-[а-еж-щьюяґєії]+('?|’?)[а-еж-щьюяґєії]+))$/i,
	// eslint-disable-next-line
	internationalNamePattern = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ `'\-]+$/i,
	fullnamePattern = /^(?!(-))(([а-еж-щьюяґєії]*('?|’?)[а-еж-щьюяґєії]+-?[а-еж-щьюяґєії]+('?|’?)[а-еж-щьюяґєії]*\s?)){1,3}$/i,
	phonePattern = /^\+38 \d{3} \d{3} \d{2} \d{2}/i,
	phoneCodePattern = /^\+38 (039|050|063|066|067|068|073|077|091|092|093|094|095|096|097|098|099) \d{3} \d{2} \d{2}/i ,
	phoneTitlePattern = /^[а-яїґєіёъ\d\-\s']+$/i,
	hoursPattern = /[01][0-9–]|2[0-4–]|––/,
	minutesPattern = /[0-5–][\d–]/,
	// eslint-disable-next-line
	passwordPattern =  /^(?=.*[a-zA-Z])(?=.*[0-9!@#$%&*(){}_?~+=<>.,:;|?\/\\'"-])[a-zA-^0-9!@#$%&*(){}_?~+=<>.,:;|?\/\\'"-]+$/m,
	sameCharactersPattern = /(.)\1{2,}/,
	appartmentPattern = /^((?!(0))[0-9]{1,5}[А-В]?)$/,
	appartmentSeparatorPattern = /^(?!(0))[0-9]{1,8}[-/]((?!(0))[0-9]{1,8}|[А-В])$/,
	buildingPattern = /(^(?!(0))[0-9]{1,4}[А-Я]{0,2}$)|(^(?!(0))[0-9]{1,2}-?(?!(0))[0-9]{1,2}$)/,
	buildingSeparatorPattern = /(^(?!(0))[0-9]{1,3}[А-Я]?\/(?!(0))[0-9]{1,3}[А-Я]?$)|(^(?!(0))[0-9]{1,2}\/(?!(0))[0-9]{1,2}-?(?!(0))[0-9]{1,2}$)/;


//TODO: on prod remove extra masks for fora
const pattern: Record<string, RegExp> = {
	email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
	name: /^[а-яїґєіёъ\-"\s]+$/i,
	phone: /^\+38 \d{3} \d{3} \d{2} \d{2}/i,
	phoneTitle: /^[а-яїґєіёъ\d\-\s"]+$/i,
	hours: /[01][0-9–]|2[0-4–]|––/,
	minutes: /[0-5–][\d–]/,
	// eslint-disable-next-line
	password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-^0-9!@#$%&*(){}_?~+=<>.,:;|/\\'"-]+$/,
	barcode: isTargetProject(PROJECT.SILPO) ? /^((029[0,1]{1}\d{9})|(024{1}\d{10}))$/ : /^(029[0,1]{1}\d{9})|(024{1}\d{10})|(0294[0,1]{1}\d{8})|(027{1}\d{10})$/
};

function patternValidate(pattern: RegExp, val) {
	if (!!pattern) {
		return pattern.test(val);
	}
	return true;
}

export default {
	pattern,
	patternValidate,
	emailPattern,
	namePattern ,
	internationalNamePattern ,
	fullnamePattern,
	phonePattern ,
	phoneCodePattern,
	phoneTitlePattern,
	hoursPattern,
	minutesPattern ,
	passwordPattern ,
	sameCharactersPattern,
	appartmentSeparatorPattern,
	appartmentPattern,
	buildingSeparatorPattern,
	buildingPattern
}