import gql from "graphql-tag";
import * as Fragment from "./Fragment";

const Query: {[key: string]: any} = {};

Query.time = {
	query: gql`
		query time {
 		 time
	}
	`
};

Query.shortLink = {
	query: gql`
		query shortUrl($id:String){
			shortUrl(id: $id){
				url
			}
	}`
};

Query.getBasket = {
	query: gql`
        query getLager($filialId:Int){
          getLager(filialId: $filialId){
           ...BasketFragment
      }
    }
    ${Fragment.BasketFragment}`
};

Query.getFreeTime = {
	query: gql`
        query getFreeTime($filialId:Int){
          getFreeTime(filialId: $filialId){
            begin
            interval
            freeName
            freeMinutes
            __typename
          }
        }
    `
};

Query.status = {
	query: gql`
        query status{
          status{
            id
            name
            priority
            __typename
          }
        }
    `
};

Query.checkVoucherSecureCode = {
	query: gql`
		query checkVoucherSecureCode {
			checkVoucherSecureCode
		}`
};

Query.statusNext = {
	query: gql`
        query statusNext($status: String){
          statusNext(status:$status){
            statusId
            statusName
            nextStatusId
            nextStatusName
            __typename
          }
        }
    `
};
Query.getAssortment = {
	query: gql`
       query getAssortement($filialId:Int){
          getAssortement(filialId: $filialId){
            categories{
              id
              title
              imgUrl
              __typename
            }
            assortment{
              number
              name
              composition
              nutritionalValue
              energyValue
              image
              bubble
              categories
            }
            __typename
          }
        }
   `
};

Query.getLagers = {
	query: gql`
        query getLager($filialId:Int){
            getLager(filialId: $filialId){
                expiredDate
                filialId
                totalCount
                totalPrice
                lagers{
                    id
                    name
                    lagerUnit
                    remnant
                    items{
                        id
                        unit
                        price
                        count
                        added
                    }
                }
            __typename
        }
    }`
};
Query.getBasket = {
	query: gql`
        query getBasket{
          getBasket{
           ...BasketFragment
          }
        }
        ${Fragment.BasketFragment}`
};

Query.problems = {
	query: gql`
        query problems {
            problems {
            id,
            name,
            feedback,
            sendSms,
            hotline,
            comment
        }
    }`
};

Query.qrLinks = {
	query: gql`
        query qRLinks($slug: String!) {
            qRLinks(slug: $slug) {
            name
            googlePlayLink
            appStoreLink
            appStoreLinkSource
            googlePlayLinkSource
            description
            __typename
        }
    }`
};

Query.partnerContacts = {
	query: gql`
        query partnerContacts {
            partnerContacts {
                id
                title
                persons {
                    id
                    name
                    phones
                    emails
                }
            }
        }`
};

Query.social = {
	query: gql`
        query social {
            socials {
                title
                link
                icon {
                    url
                }
            }
        }`
};

Query.contactsOffices = {
	query: gql`
        query contactsOffices {
            offices {
                id
                title
                description
                location { ...LocationBaseFragment }
            }
        }
    ${Fragment.LocationBaseFragment}`
};

Query.faq = {
	query: gql`
        query faq ($id: ID!) {
            faq(id: $id) { ...FAQCategoryBaseFragment }
        }
    ${Fragment.FAQCategoryBaseFragment}`,
	variables: {
		id: null
	}
};

Query.faqs = {
	query: gql`
        query faqs {
            faqs { ...FAQCategoryBaseFragment }
        }
    ${Fragment.FAQCategoryBaseFragment}`
};

Query.citiesBase = {
	query: gql`
        query citiesBase($serviceIds: [ID]) {
            cities(serviceIds: $serviceIds) {
                ...CityBaseFragment
            }
        }
    ${Fragment.CityBaseFragment}`,
	variables: {
		serviceIds: null
	}
};

Query.citiesWithStores = {
	query: gql`
        query citiesWithStores{
					cities{
						...CityBaseFragment
						stores {
							id
							title
						}
					}
				}
        ${Fragment.CityBaseFragment}`
};

Query.cities = {
	query: gql`
        query cities {
            cities {
                ...CityBaseFragment
            }
        }
        ${Fragment.CityBaseFragment}`
};


Query.storeServices = {
	query: gql`
        query storeServices {
            storeServices { ...StoreServiceBaseFragment }
        }
    ${Fragment.StoreServiceBaseFragment}`
};

Query.storesBase = {
	query: gql`
        query storesBase($filter: StoreFilterInputType, $pagingInfo: InputBatch!) {
            stores(filter: $filter, pagingInfo: $pagingInfo) {
                items {
                    ...StoreBaseFragment
                }
            }
        }
    ${Fragment.StoreBaseFragment}`,
	variables: {
		filter: {
			storeId: null,
			cityId: null,
			start: null,
			end: null,
			hasCertificate: null,
			servicesIds: null
		},
		pagingInfo: {
			limit: 0,
			offset: 0
		}
	}
};

Query.store = {
	query: gql`
        query store($slug: String) {
            store(slug: $slug) {
                ...StoreFragment
            }
        }
    ${Fragment.StoreFragment}`,
	variables: Query.storesBase.variables,
};

Query.storesCertificates = {
	query: gql`
        query storesCertificates($filter: StoreFilterInputType, $pagingInfo: InputBatch!) {
            stores(filter: $filter, pagingInfo: $pagingInfo) {
                items {
                    id
                    title
                    premium
                    slug
                    city {
                        id
                        title
                    }
                    workingHours {
                        start
                        end
                    }
                    location {
                        lat
                        lng
                    }
                }
            }
        }
    `,
	variables: Query.storesBase.variables,
};

Query.stores = {
	query: gql`
        query stores($filter: StoreFilterInputType, $pagingInfo: InputBatch!) {
            stores(filter: $filter, pagingInfo: $pagingInfo) {
                ${Fragment.ResultFields}
                items {
                    id
                    title
                    premium
                    slug
                    city { ...CityBaseFragment }
                    workingHours {
                        start
                        end
                    }
                    location { ...LocationBaseFragment }
                }
            }
        }
    ${Fragment.CityBaseFragment}
    ${Fragment.LocationBaseFragment}`,
	variables: Query.storesBase.variables,
};

Query.storesPage = {
	query: gql`
        query storesPage($filter: StoreFilterInputType, $pagingInfo: InputBatch!) {
            stores(filter: $filter, pagingInfo: $pagingInfo) {
                ${Fragment.ResultFields}
                items {
                    ...StoresPageFragment
                }
            }
        }
    ${Fragment.StoresPageFragment}`,
	variables: Query.storesBase.variables,
};

Query.promotions = {
	query: gql`
        query promotions {
            promotions { ...PromotionBaseFragment }
        }
    ${Fragment.PromotionBaseFragment}`
};

Query.promotion = {
	query: gql`
        query promotion($slug: String) {
            promotion (slug: $slug) { ...PromotionBaseFragment }
        }
    ${Fragment.PromotionBaseFragment}`
};


Query.campaigns = {
	query: gql`
        query campaigns($storeIds: [ID]) {
            campaigns(storeIds: $storeIds) {
                id
                slug
                title
                campaignPreview {
                    previewDescription
                    previewImage { url }
                    appearance { ...AppearanceBaseFragment }
                }
            }
        }
    ${Fragment.AppearanceBaseFragment}`,
	variables: {}
};

Query.campaign = {
	query: gql`
        query campaign ($slug: String) {
            campaign (slug: $slug) {
                id
                slug
                title
                stores {
                    id
                    title
                    premium
                    slug
                    city { ...CityBaseFragment }
                    workingHours {
                        start
                        end
                    }
                    location { ...LocationBaseFragment }
                }
                campaignContent {
                    contentDescription
                    contentImage { url }
                    contentNote
                    conditionsNote
                    conditions {
                        id
                        title
                        icon { url }
                    }
                }
                campaignOffers {
                    title
                    image {url}
                    items: campaignProducts {
                        title
                        versions: campaignProductVersions {
                            description
                            regularPrice
                            price: offerPrice
                            offerPriceDescription
                            colors
                        }
                    }
                }
                application {
                  ...ApplicationFragment
                }
                promotion {
                  slug
                  title
                }
            }
        }
        ${Fragment.ApplicationFragment}
        ${Fragment.CityBaseFragment}
        ${Fragment.LocationBaseFragment}
    `
};

Query.promotionProducts = {
	query: gql`
        query products ($categoryId : ID, $promotionSlug: String, $promotionId: ID, $storeIds:[ID], $pagingInfo: InputBatch, $random: Boolean) {
            products (categoryId: $categoryId, promotionId: $promotionId, promotionSlug: $promotionSlug, storeIds: $storeIds, pagingInfo: $pagingInfo, random: $random) {
                ${Fragment.ResultFields}
                items { ...OptimizedProductsFragment }
            }
        }
    ${Fragment.OptimizedProductsFragment}`,
	variables: {
		promotionId: null,
		promotionSlug: null,
		categoryId: null,
		storeIds: null,
		random: false,
		pagingInfo: {
			offset: 0,
			limit: 6,
		}
	}
};

Query.otherActionsProducts = {
	query: gql`
        query products ($categoryId : ID, $promotionSlug: String, $promotionId: ID, $storeIds:[ID], $pagingInfo: InputBatch, $random: Boolean) {
            products (categoryId: $categoryId, promotionId: $promotionId, promotionSlug: $promotionSlug, storeIds: $storeIds, pagingInfo: $pagingInfo, random: $random) {
                ${Fragment.ResultFields}
                items {
                    ...OptimizedProductsFragment
                }
            }
        }
    ${Fragment.OptimizedProductsFragment}`,
	variables: {
		promotionId: null,
		promotionSlug: null,
		categoryId: null,
		storeIds: null,
		random: true,
		pagingInfo: {
			offset: 0,
			limit: 6,
		}
	}
};

Query.product = {
	query: gql`
        query product ($slug: String) {
            product (slug: $slug) { ...ProductFragment }
        }
    ${Fragment.ProductFragment}`
};

Query.productCategories = {
	query: gql`
        query productCategories($pageSlug:String, $storeIds:[ID] ) {
          productCategories(pageSlug: $pageSlug, storeIds: $storeIds) {
            id
            title
            __typename
          }
        }`,
	variables: {
		pageSlug: null,
		storeIds: []
	}

};

Query.mainListItems = {
	query: gql`
        query mainListItems(
        $categoryId: ID,
        $storeIds: [ID],
        $storeSlug: String
        ) {
            products: mainListItems(
                categoryId : $categoryId,
                storeIds : $storeIds,
                storeSlug : $storeSlug
            ) {
                __typename
                ... on Promo { ...PromoFragment }
                ... on Product { ...HomepageProductFragment }
            }
        }
        ${Fragment.HomepageProductFragment}
        ${Fragment.PromoFragment}
    `,
	variables: {
		categoryId: null,
		storeIds: null,
		storeSlug: null,
	}
};

Query.offers = {
	query: gql`
        query offers ($categoryId: ID, $storeIds: [ID], $pagingInfo: InputBatch!) {
            offers (categoryId:$categoryId, storeIds: $storeIds, pagingInfo: $pagingInfo) {
                ${Fragment.ResultFields}
                items {
                    __typename
                    ... on Promo { ...PromoFragment }
                    ... on Product { ...OptimizedProductsFragment }
                    ... on Coupon {
                    ...OptimizedCouponsFragment
                        addresses {
                            store {
                                id
                            }
                        }
                    }
                }
            }
        }
    ${Fragment.PromoFragment}
    ${Fragment.OptimizedProductsFragment}
    ${Fragment.OptimizedCouponsFragment}`,
	variables: {
		categoryId: null,
		storeIds: null,
		pagingInfo: {
			offset: 0,
			limit: 99999
		}
	}
};
Query.offersSplited = {
	query: gql`query offers($categoryId: ID, $storeIds: [ID], $pagingInfo: InputBatch!, $pageSlug : String!, $random: Boolean!) {
      offersSplited(categoryId: $categoryId, storeIds: $storeIds, pagingInfo: $pagingInfo, pageSlug: $pageSlug, random: $random) {
        promos {
          count
          items {
            ... on Promo {
              ...PromoFragment
            }
          }
        }
        products {
          count
          items {
            ... on Product {
              ...OptimizedProductsFragment
            }
          }
        }
        coupons {
          count
          items {
            ... on Coupon {
              ...OptimizedCouponsFragment
              addresses {
                store {
                  id
                }
              }
            }
          }
        }
      }
    }
    ${Fragment.PromoFragment}
    ${Fragment.OptimizedProductsFragment}
    ${Fragment.OptimizedCouponsFragment}`,
	variables: {
		categoryId: null,
		storeIds: null,
		pagingInfo: {
			offset: 0,
			limit: 999999
		},
		pageSlug: "actions",
		random: false
	}
};

Query.pages = {
	query: gql`
        query pages {
            pages {
                type
                properties
            }
        }`
};

Query.page = {
	query: gql`
        query pages($type: String) {
            pages(type: $type) {
                type
                properties
                application{
                  name
                  googlePlayLink
                  appStoreLink
                  appStoreLinkSource
                  googlePlayLinkSource
                  description
                }
            }
        }`
};

Query.person = {
	query: gql`
        query person($id: ID!) {
            person(id: $id) {
                id,
                name,
                phones,
                emails,
            }
        }`,
};

Query.partners = {
	query: gql`
        query partners {
              partners {
                id
                slug
                title
                image {
                  url
                }
                content
                link
                linkTitle
                color
              }
        }`,
};

Query.selectedPressRelease = {
	query: gql`
        query pressRelease {
            selectedPressRelease: pressRelease (selected: true) {
                ...PressReleaseSelectedFragment
            }
        }
    ${Fragment.PressReleaseSelectedFragment}`
};

Query.pressRelease = {
	query: gql`
        query pressRelease ($slug : String) {
            pressRelease (slug: $slug) {
                ...PressReleaseFragment
            }
        }
    ${Fragment.PressReleaseFragment}`
};

Query.pressReleases = {
	query: gql`
        query pressReleases ($start : String, $end: String, $pagingInfo: InputBatch) {
            pressReleases (start: $start, end: $end, pagingInfo: $pagingInfo) {
                ${Fragment.ResultFields}
                items { ...PressReleaseBaseFragment }
            }
        }
    ${Fragment.PressReleaseBaseFragment}`
};

Query.suggestedProducts = {
	query: gql`
        query suggestedProducts ($excludingPromotionSlug : String!, $storeIds:[ID], $pagingInfo: InputBatch!) {
            suggestedProducts (excludingPromotionSlug: $excludingPromotionSlug, storeIds: $storeIds, pagingInfo: $pagingInfo) {
                ...OptimizedProductsFragment
            }
        }
    ${Fragment.OptimizedProductsFragment}`,
	variables: {
		storeIds: null,
		excludingPromotionSlug: null,
		pagingInfo: {
			limit: 9,
			offset: 0
		}
	}
};

Query.footer = {
	query: gql`
        query footer {
            footer {
                type
                title
                items {
                    __typename
                    ... on Page {
                        type
                        properties
                    }
                    ... on Promotion {
                        slug
                        title
                    }
                    ... on Campaign {
                        slug
                        title
                    }
                }
            }
        }`
};

Query.feedbackSubjects = {
	query: gql`
        query feedbackSubjects ($type : FeedbackSubjectType) {
            feedbackSubjects (type: $type) {
                id
                type
                subject
            }
        }`
};

Query.search = {
	query: gql`
        query search (
        $category: SearchCategory,
        $query: String!,
        $pagingInfo: InputBatch!
        ) {
            search (
                category: $category,
                query: $query,
                pagingInfo: $pagingInfo
            ) {
                paging {
                    limit
                    offset
                    count
                    items {
                        __typename

                        ... on Campaign {
                            slug
                            title
                            content: campaignContent {
                                description: contentDescription
                            }
                        }

                        ... on Promotion {
                            slug
                            title
                            description
                        }

                        ... on Product {
                            slug
                            title
                            description
                            promotion {
                                slug
                                title
                            }
                        }

                        ... on Page {
                              properties
                              type
                            }

                        ... on PressRelease {
                              slug
                              title
                              id
                              prContent : content
                              previewDescription
                            }

                        ... on Store {
                            slug
                            title
                            services {
                              enabled
                              service {
                                id
                                title
                              }
                            }
                        }

                        ... on FAQCategory {
                              id
                              title
                              questions {
                                id
                                title
                                description
                              }
                            }

                        ... on FAQQuestion {
                            id
                            title
                            description
                            category {
                                id
                                title
                            }
                        }


                        ... on PartnerContact {
                            id
                            title
                            persons {
                                id
                                name
                                emails
                            }
                        }

                        ... on Office {
                              id
                              title
                              description
                            }

                        ... on Person {
                            id
                            name
                            contacts {
                                id
                                title
                            }
                        }
                    }
                }
                categories:countInfo {
                    type:category
                    count
                }
            }
        }
    `
};

Query.me = {
	query: gql`
        query me {
            me { ...UserFragment }
        }
    ${Fragment.UserFragment}`,
	variables: {},
};

Query.meDetailed = {
	query: gql`
        query meDetailed {
            me: meDetailed { ...DetailedUserFragment }
        }
    ${Fragment.DetailedUserFragment}`,
	variables: {},
};

Query.meDetailedOtherData = {
	query: gql`
        query meDetailedOtherData {
            me: meDetailed { ...DetailedUserOtherDataFragment }
        }
    ${Fragment.DetailedUserOtherDataFragment}`,
	variables: {},
};

Query.meDetailedAddress = {
	query: gql`
        query meDetailedOtherData {
            me: meDetailed { ...DetailedUserAddress }
        }
    ${Fragment.DetailedUserAddress}`,
	variables: {},
};

Query.cards = {
	query: gql`
        query cards {
            cards { ...CardFragment }
        }
    ${Fragment.CardFragment}`,
	variables: {},
};

Query.moneyBox = {
	query: gql`
        query moneyBox {
            moneyBox { ...MoneyboxFragment }
        }
    ${Fragment.MoneyboxFragment}`,
	variables: {},
};

Query.coupons = {
	query: gql`
        query coupons{
            coupons{
                ...OptimizedCouponsFragment
            }
        }
    ${Fragment.OptimizedCouponsFragment}`,
};

Query.coupon = {
	query: gql`
        query coupon($id: ID!) {
            coupon(id: $id) {
                ...CouponsFragment
            }
        }
    ${Fragment.CouponsFragment}`,
	variables: {
		id: null,
	},
};

Query.otherInformation = {
	query: gql`
        query otherInformation {
            animals {
                id
                name: title
            }
            habits {
                id
                name: title
            }
        }
    `,
};

Query.phoneNumbers = {
	query: gql`
        query phoneNumbers {
            phoneNumbers { ...PhoneNumberFragment }
        }
    ${Fragment.PhoneNumberFragment}`,
};

Query.searchAddresses = {
	query: gql`
        query searchAddresses($address : AddressFilter!) {
            searchAddresses(address: $address) {
                ...SearchableAddressFragment
            }
        }
    ${Fragment.SearchableAddressFragment}`,
	variables: {
		address: {
			// postIndex   : "",
			region: "",
			district: "",
			city: "",
			cityType: "",
			// subCity     : "",
			// cityArea    : "",
			// subCityArea : "",
			street: "",
			// streetType  : "",
		}
	},
	normalizeVariables(variables) {
		try {
			// use only those parameters that in this.variables.address
			return {
				address: Object.keys(this.variables.address)
					.filter(key => variables.address[key])
					.map(key => ({[key]: variables.address[key]}))
					.reduce((res, d) => ({...res, ...d}), {})
			}

		} catch (err) {
			return variables;
		}
	}
};

Query.vouchers = {
	query: gql`
        query vouchers($current: Boolean) {
            vouchers(current: $current) {
                ...VoucherFragment
            }
        }
    ${Fragment.VoucherFragment}`
};

Query.subscriptions = {
	query: gql`
        query subscriptions {
            subscriptions {
                name
                email
                items {
                    type
                    enabled
                    storeIds
                }
            }
        }`
};

Query.family = {
	query: gql`
        query family {
            family: meDetailed {
                id
                persons: familyItems {
                    firstName
                    lastName
                    middleName
                    gender
                    birthday
                }
            }
        }`
};

Query.getMemberQuestion = {
	query: gql`
        query getMemberQuestion($barcode: String!) {
            getMemberQuestion(barcode: $barcode) {
                question
                widgetType
                id
                __typename
            }
        }
    `,
};

Query.findPostIndex = {
	query: gql`
        query findPostIndex($address: InputCheckAddress!) {
              findPostIndex(address: $address)
        }
    `,
};

Query.checks = {
	query: gql`
    query checks($offset: Int, $limit: Int, $dateFrom: DateTime, $dateTo: DateTime) {
              checks(offset: $offset, limit: $limit, dateFrom: $dateFrom, dateTo: $dateTo) {
                id
                created
                sum
                totalSum
                discountSum
                voucherSum
                bonusesCount
                filialName
                storeId
                payType
                number
              }
            }
    `,
};

Query.check = {
	query: gql`
        query check($storeId: ID!, $checkId: ID!, $creationDate: DateTime!) {
              check(storeId: $storeId, checkId: $checkId, creationDate: $creationDate) {
                items {
                  name
                  unit
                  count
                  price
                  unitText
                }
                rewards {
                  title
                  value
                  signText
                  unitText
                }
              }
            }
    `,
};


Query.events = {
	query: gql`
        query events {
            events {
                id
                title
                slug
                full_description
                startAt
                endAt
                place
                address
                logo{url}
                picture{url}
                pictureContent{url}
                color
                className
                preview{url}
                tickets{
                  id
                  isEntrance
                  price
                }
            }
        }
    `,
};

Query.user = {
	query: gql`
        query user {
            user {
                id
                phone
                createdAt
                name
                email
                sendToEmail
                card
            }
        }`,
	variables: {},
};

Query.event = {
	query: gql`
       query event ($slug : String) {
              event(slug: $slug) {
                    id
                    title
                    slug
                    full_description
                    startAt
                    endAt
                    place
                    address
                    logo{url}
                    picture{url}
                		pictureContent{url}
                    location{lat lng}
                    facebook_link
                    rules_reference
                    preview{url}
                    infoList{
                      title
                      description
                      image{url}
                    }
                      schedules{
     										 title
      									 description
    													}
                tickets{
                    id
                    title
                    description
                    short_description
                    price
                    stopAt
                    qti
                    isEntrance
                    lectionStartAt
                    lectionEndsAt
                    image{url}
                    total_count
                    preview{url}
                },
								promotion{
									id
									slug
									type
								}
								subscription
								promotionsTitle
                application{
                ...ApplicationFragment
                }
            }
        }
        ${Fragment.ApplicationFragment}
    `
};

Query.basket = {
	query: gql`
        query basket {
            basket{
            totalPrice
            createdAt
            ticketsExpiresAt
            basketItems{
                id
                quantity
                product{
                    ... on Cert{
                      id
                      name
                      rating
                      price
                      description
                    }
                    ... on Ticket{
                       id
                       name
                      price
                      isEntrance
                      mastercardDiscount(calculate:0)
                      mastercardDiscountType
         							mastercardDiscountDescription
                      description
                       event{
                         slug
                         title
                       }
                        type{
                         name
                       }
                    }
                    __typename
                }
            }
        }
    }
    `
};

Query.basketCount = {
	query: gql`
        query count{
          basketCount
        }
    `
};

Query.accessibleTicketsCount = {
	query: gql`
        query tc($id: ID!) {
          accessibleTicketsCount(id: $id)
        }
    `
};

Query.history = {
	query: gql`
        query history {
            history {
            ...OrderFragment
            ...SiteOrderFragment
          }
    }
    ${Fragment.OrderFragment}
    ${Fragment.SiteOrderFragment}`
};

Query.certificates = {
	query: gql`
        query certList {
            certList {
                name
                full_description
                description
                size
                image {
                    id
                    url
                }
                nominalList {
                    id
                    price
                }
            }
        }
    `
};


Query.additionPages = {
	query: gql`
      query additionPage {
          additionPages {
           published
           slug
           name
           headerColor
           showInBlock
           showInMenu
          }
        }
    `
};

Query.additionPage = {
	query: gql`
        query additionPage($slug: String!) {
          additionPage(slug:$slug) {
           id
           published
           slug
           name
           vacancyTitle
           bodyImage{
             url
           }
            headerIcon{
              id
              url
            }
            headerColor
            formTitle
            description
            video
            previewSmall{
              id
              url
            }
             previewMiddle{
              id
              url
            }
             previewFull{
              id
              url
            }
            forAmbasador
            anketType
            ambasadorEmails
            selstages{
              id
              description
            }
            advantages{
              id
              name
              description
              image{
                id
                url
              }
            }
            promo1{
              ...PromoFragment
            }
            promo2{
              ...PromoFragment
            }
          }
        }
        ${Fragment.PromoFragment}
    `
};

Query.vacancy = {
	query: gql`
       query vacancy ($id: ID!){
          vacancy (id: $id){
           category
           id
            name
            image{url}
            city {
              id
              title
                 }
            type
            employment
            position{
              name
              id
              slug
                 }
            office{
              name
              address

            }
            store{
              id
              title
            }
            disability
            experienced
            delivery
            reward
            contact_one
            contact_two
            rubric
            manager_name
            formTitle
            responcebilitiesTitle
            requirementsTitle
            selstagesList{
              description
              position
            }
            advantagesList{
              name
              image{url}
              description
              position
            }
            pages{
              name
              slug
            }
            startAt
            endAt
            responcebilitiesList{
              name
                }
            requirementsList{
              name
            }
          }
        }
    `
};

Query.vacancies = {
	query: gql`
       query vacancies($page_slug: String!) {
          vacancies (page_slug: $page_slug) {
            id
            name
            position{
              name
              id
              slug
            }
            image{url}
            city {
              id
              title
                 }
            type
            employment
            office{
              name
              address
            }
            store{
              id
              title
            }
          }
        }
    `
};
Query.positions = {
	query: gql`
        query positions{
          positions{
            id
            name
          }
        }
    `
};

Query.checkCardActivationCode = {
	query: gql`
        query checkCardActivationCode($barcode: String!){
          checkCardActivationCode(barcode: $barcode)
        }
    `
};


Query.vacanciesFilter = {
	query: gql`
        query vacanciesFilter($filter: VacancyFilterInputType) {
          vacanciesFilter(filter: $filter) {
            id
            name
            image{url}
            position{
                id
                name
                slug
              }
            city {
              id
              title
                 }
            type
            employment
            office{
              name
              address
            }
            store{
              id
              title
            }
          }
        }
    `
};

Query.mainPage = {
	query: gql`
        query mainPage {
          mainPage {
            id
            title
            color
            description
            formTitle
            video
            image{url}
            previewSmall{url}
            previewMiddle{url}
            previewFull{url}
            imgBlock{url}
            conditions{
              id
              image{url}
              title
              description
            }
            slides{
              id
              image{url}
              title
              description
            }
          }
        }
    `
};

Query.cheque = {
	query: gql`
        query cheque($storeId: Int!, $creationDate: DateTime!, $loyaltyFactId: Int!) {
              cheque(storeId: $storeId, creationDate: $creationDate, loyaltyFactId: $loyaltyFactId) {
              loyaltyFactId
							filId
							created
							sumDiscount
							chequeId
							chequeLines {
								lagerId
								lagerNameUA
								lagerUnit
								kolvo
								priceOut
							}
							chequeRewards {
							 	rewardTypeId
								applyText
								rewardValue
								signText
								unitText
							}
						}
				}
    `,
};

Query.getBalance = {
	query: gql`
			query getBalance {
					getBalance {
						balance
						balanceLines {
							balanceName
							codeName
							balance
						}
					}
			}
	`
};

Query.getBonus = {
	query: gql`
			query getBonus {
					getBonus {
						balance
						bonusLines {
							balance
							filialId
							created
							expired
							loyaltyFactId
							name
							typeLine
							signText
							bonusOperations {
								name
								balance
								codeName
							}
						}
					}
			}
	`
};

Query.getAvailableTimeSlotsByBasketGuid = {
	query: gql`
			query availableTimeSlots($orderId: String!) {
  			availableTimeSlots(orderId: $orderId)
				{
					time {
						id
						isAvailable
						timeFrom
						timeTo
					}
				}
			}
	`
};

Query.getBonusCancellation = {
	query: gql`
			query getBonusCancellation {
				getBonusCancellation
			}
	`
};

Query.getRecoveryContacts = {
	query: gql`
		query getRecoveryContacts($flowId: Int!) {
			getRecoveryContacts(flowId: $flowId) {
			id
			contactType
			contactHide
			__typename
		}
	}`
};

export default Query;
