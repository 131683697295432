import React from "react";
import {compose, withProps} from "recompose";
import {Field, withFormik} from "formik";
import {FORMS} from "../enums";
import {graphql} from "react-apollo";

import {DATA_TYPE} from "../../services/FormServices/constants";
import {normalizeFields, validate, checkCodeIdentity} from "../../services/FormServices/FormService";
import {IFormProps} from "../../services/FormServices/interfaces";
import {getUniqueId} from "../../utils";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import Mutation from "../../graphql/Mutation";
import Query from "../../graphql/Query";

import FormComponent from "../../components/Form/FormComponent";
import Form from "../../components/Form/Form";
import FormField from "../../components/Form/FormField";
import FormInput from "../../components/Form/FormInput";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import Button from "../../components/UI/Button/Button";
import FormRow from "../../components/Form/FormRow";
import FormError from "../../components/Form/FormError/FormError";
import RadioLabel from "../../components/Form/RadioLabel/RadioLabel";
import {
	ChangeCodeFormValues,
	ChangeCodeFormType,
	OuterBonusProtectionPropsType,
	InnerBonusProtectionFormProps,
	BonusProtectionStateType,
	VoucherSecureCodeType
} from "./types"

import "./BonusProtectionForm.styl";


const CODE_PROTECTION_TYPE = {
	nonAutomatic: "NOT_AUTOMATIC",
	automatic: "AUTOMATIC",
};

const form = FORMS.BONUS_PROTECTION_FORM,
	fields = {
		newCode: {
			name: "newCode",
			dataType: DATA_TYPE.code,
		},
		newCodeRe: {
			name: "newCodeRe",
			dataType: DATA_TYPE.code,
		},
	};

const BonusProtectionForm = compose<InnerBonusProtectionFormProps & OuterBonusProtectionPropsType &
	VoucherSecureCodeType, OuterBonusProtectionPropsType>(
	withProps(() => ({
		form,
		fields: normalizeFields(fields, form),
		formValidate: checkCodeIdentity
	})),
	graphql(Mutation.deactivateVoucherSecureCode.mutation, {name: "deactivateVoucherSecureCode"}),
	graphql(Query.checkVoucherSecureCode.query, {name: "isSecureCodeActivated"}),
	withFormik<IFormProps & OuterBonusProtectionPropsType, ChangeCodeFormValues>({
		mapPropsToValues: () => {
			return {
				isAutomatic: CODE_PROTECTION_TYPE.nonAutomatic,
				secureCode: "",
				secureCodeConfirm: "",
				submitError: "",
				formError: "",
				generateCode: false
			};
		},
		validate,
		handleSubmit: (values: ChangeCodeFormValues, formProps) => {
			formProps.props.onSubmit(values);
			formProps.setSubmitting(false);
		},
	})
)(class BonusProtectionForm extends FormComponent<InnerBonusProtectionFormProps & OuterBonusProtectionPropsType &
	VoucherSecureCodeType, BonusProtectionStateType> implements ChangeCodeFormType {

	constructor(props) {
		super(props);
		this.state = ({
			generateCode: false,
			mutationLoading: false
		})
	};

	id: string = getUniqueId();
	codeType = {
		generate: "generate",
		input: "input"
	};

	componentWillReceiveProps(nextProps: Readonly<InnerBonusProtectionFormProps & OuterBonusProtectionPropsType &
		VoucherSecureCodeType>, nextState: BonusProtectionStateType): void {
		if (!this.props.showSuccess && nextProps.showSuccess) {
			this.props.isSecureCodeActivated.refetch();
			this.props.resetForm({values: {newCode: "", newCodeRe: ""}});
		}
	}

	componentWillUnmount() {
		this.props.resetForm();
	}

	/**
	 * andress type change handler
	 * @param event
	 * @param value
	 */
	onCodeTypeChange = (value) => {
		switch (value) {
			case this.codeType.generate:
				this.setState({generateCode: true});
				this.props.resetForm({values :{newCode: "", newCodeRe: "", generateCode: false}});
				break;
			case this.codeType.input:
				this.setState({generateCode: false});
				break;
			default:
				break;
		}
	};
	onClick = () => {
		const {isAutomatic, newCode, newCodeRe} = this.props.values;
		let values = {
			isAutomatic: isAutomatic || CODE_PROTECTION_TYPE.nonAutomatic,
			secureCode: newCode,
			secureCodeConfirm: newCodeRe,
			submitError: "",
			formError: ""
		};

		if (this.state.generateCode) {
			values.isAutomatic = CODE_PROTECTION_TYPE.automatic;
		}
		this.props.onSubmit(values);
	};

	deactivateVoucherSecureCode = () => {
		const {isSecureCodeActivated: {refetch}, deactivateVoucherSecureCode} = this.props;

		return deactivateVoucherSecureCode()
			.then(r => {
				this.setState({mutationLoading: false});

				const {data: {deactivateVoucherSecureCode}} = r;
				if (deactivateVoucherSecureCode) {
					refetch();
				}
				return r;
			})
			.catch(err => {
				this.setState({mutationLoading: false});
				return err;
			});
	};


	render() {
		const {
			isSubmitting, handleSubmit, dirty, errors, showSuccess, isValid, form, errorMessage,
			successMessage, isSecureCodeActivated: {checkVoucherSecureCode, loading}
		} = this.props;
		const {generateCode, mutationLoading} = this.state;
		const btnProps = {
			className: "form__footer-btn btn-submit",
			gray: true,
			disabled: generateCode ? !generateCode : (Object.keys(errors).length > 0 || isSubmitting || !dirty || !isValid),
			loading: isSubmitting,
			children: renderTemplateTextNode(this.getMessageId(checkVoucherSecureCode ? "btnChange" : "btn")),
		};
		const deactivateBtnProps = {
			className: "form__footer-btn btn-submit",
			gray: true,
			disabled: !checkVoucherSecureCode,
			loading: mutationLoading,
			children: renderTemplateTextNode(this.getMessageId("btnDeactivate")),
		};

		const error = !!errors && (errors.submitError || errors.formError || "");

		return (
			<Form onSubmit={handleSubmit} className="bonus-protection__form" name={form}>
				<div className="form__body">
					<h1 className="heading3">
						{renderTemplateTextNode(this.getMessageId("heading"))}
					</h1>
					<hr/>
					<div className="form__text">
						{renderTemplateTextNode(this.getMessageId("heading_text"))}
					</div>
					<FormRow>
						<FormField>
							<Field
								className="address-form__type-radio"
								component={RadioLabel}
								type="radio"
								name="generateCode"
								withError={true}
								checked={generateCode}
								onChange={() => this.onCodeTypeChange(this.codeType.generate)}
							>
								{renderTemplateTextNode(this.getMessageId("generate_text"))}
							</Field>
						</FormField><FormField>
						<Field
							className="address-form__type-radio"
							component={RadioLabel}
							type="radio"
							name="generateCode"
							withError={true}
							checked={!generateCode}
							onChange={() => this.onCodeTypeChange(this.codeType.input)}
						>
							{renderTemplateTextNode(this.getMessageId("input_text"))}
						</Field>
					</FormField>

					</FormRow>
					{
						!generateCode &&
						<>
							<FormRow>
									<FormField>
										<Field
											name="newCode"
											component={FormInput}
											type="code"
											label={getTemplateText(this.getMessageId("label.code"))}
											data-tip data-for={this.id}
										/>
										<Tooltip
											id={this.id}
											event="focus"
											eventOff="blur"
											place="right">
											<div className="tooltip__change-password">
												{renderTemplateTextNode("hint.protectionCode")}
											</div>
										</Tooltip>
									</FormField>
										<FormField>
											<Field name="newCodeRe"
														 component={FormInput}
														 type="code"
														 label={getTemplateText(this.getMessageId("label.confirmCode"))}/>
									</FormField>
								</FormRow>

						</>
					}

				</div>

				<div className="form__footer">
					<div className="form__footer-status">
						{!!error && <FormError className="form__footer-error">{errorMessage || error}</FormError>}
						{showSuccess && (
							<div className="form__footer-success">
								{successMessage}
							</div>
						)}
					</div>
					<Button {...btnProps} onClick={this.onClick}/>
					{!loading && checkVoucherSecureCode &&
					<Button {...deactivateBtnProps} onClick={this.deactivateVoucherSecureCode}/>
					}
				</div>
			</Form>
		)
	}
});

export default BonusProtectionForm;