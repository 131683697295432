import "./BackLink.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";
import {Link} from "react-router-dom";

import Icon from "../Icon/Icon";
import {ICONS} from "../Icon/enums";
import {BackLinkProps} from "./types";


const BackLink: FunctionComponent<BackLinkProps> = ({className, children, to="", component, ...props}) => {
	return (
		<Link {...props} to={to} className={cn("back-link", className)}>
			<Icon className="back-link__icon" icon={ICONS.leftArrow}/>
			{children}
		</Link>
	)
};

export default BackLink;
