import {FORMS} from "js/forms/enums";
import {DATA_TYPE, RULE} from "@services/FormServices/constants";
import {normalizeFields, validate} from '@services/FormServices/FormService';
import {PasswordModalValuesType} from "./types";

export const form = FORMS.PASSWORD_FORM;

export const initialValues = {
	password: "",
	submitError: ""
}

export const fields = {
	password: {
		name: "password",
		dataType: DATA_TYPE.password,
		excludeRules: [RULE.has_lowercase, RULE.has_uppercase]
	}
};

export const handleValidate = (values: PasswordModalValuesType) =>
	validate(values, {fields: normalizeFields(fields, form), form})
