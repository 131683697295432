import React from 'react'
import Modal from 'react-responsive-modal';
import {SHOP_HOST} from '@services/EnvServices';
import Button from '../../UI/Button/Button';
import {ICONS} from '../../UI/Icon/enums';
import Icon from '../../UI/Icon/Icon';

import "./SuccessRepeatOrder.styl";

const SuccessRepeatOrder = ({isOpen, onClose, basketGuid}) => {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{"overlay": "modal-overlay", "modal": "modal-window success-repeat-modal", "closeButton": "modal-closeButton", "closeIcon": "modal-closeIcon"}}
		>
			<div className="success-repeat-modal__data">
				<Icon raw icon={ICONS.successRepeat} />
				<h3 className="success-repeat-modal__title">
					Товари успішно додано до корзини!
				</h3>
				<div>
					<Button
						primary
						reversed
						className="btn-proceed success-repeat-modal__button"
						onClick={onClose}
					>
						До моїх покупок
					</Button>
					<Button
						primary
						className="btn-proceed success-repeat-modal__button"
						onClick={() => window.open(`${SHOP_HOST}/change-cart-page/${basketGuid}`, "_self")}
					>
						До кошику
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default SuccessRepeatOrder