import "./ProposalsList.styl";

import React, {Component, FunctionComponent, Fragment, useMemo} from 'react';

import ProposalItem from "./ProposalItem/ProposalItem";
import {ProposalsListPropsType, ProposalsListStateType, CouponActivateType, CouponsActivateVariableType, ProposalsListType, ProposalsListOptimizationType} from "./types"

import getTemplateText from "../../services/TextService";
import {PATHS} from "../../services/RouteService/RouteService";
import {ROUTE} from "../../services/RouteService/enums";

import {ConfirmModal} from "../Modals/ConfirmModal/ConfirmModal";

//todo: добавить востоновление купона


const ProposalsListOptimization: FunctionComponent<ProposalsListOptimizationType> = ({id, list, shadows, markCoupon, proposalItemsState, handleSwitcherToggle}) => {

	const renderList = useMemo(() => list.map((item, index) => {
		return (
			<li key={item.id || index}>
				<ProposalItem
					{...item}
					position={!!proposalItemsState[item.id] || false}
					shadows={shadows}
					markCoupon={markCoupon}
					handleSwitcherToggle={handleSwitcherToggle}
				/>
			</li>
		)
	}), [list, proposalItemsState, handleSwitcherToggle, markCoupon, shadows]);

	return(
		<ul className="proposal-list items-per-row" id={id? id : ""}>
			{renderList}
		</ul>
	)

};

class ProposalsList extends Component<ProposalsListPropsType, ProposalsListStateType> implements ProposalsListType{
	constructor(props) {
		super(props);
		this.state = {
			countPerRow: 3,
			proposalItemsState: this.createProposalItemsState(),
			proposalCounter: 0,
			isProposalModalShown: false,
		};
	}

	initState = {};
	/***
	 * Handle switch on propose
	 * @param itemId
	 */
	handleSwitcherToggle = itemId => {
		const isLocationAccount = window.location.pathname === PATHS[ROUTE.ROOT];

		const switcherCheckedOff = !this.state.proposalItemsState[itemId];
		this.initState = JSON.parse(JSON.stringify(this.state.proposalItemsState));
		const newProposalItemsState = {
			...this.state.proposalItemsState,
			[itemId]: !this.state.proposalItemsState[itemId]
		};
		const variables = {"coupons": [{"couponId": +itemId, "isOff": switcherCheckedOff}]};
		this.activateAction(newProposalItemsState, variables, switcherCheckedOff, this.initState);

		if (isLocationAccount && this.state.proposalCounter > 1 &&
			!this.state.isProposalModalShown && !switcherCheckedOff) {
			//todo modal window action.

			this.setState({
				isProposalModalShown: true
			});
		}
	};

	/***
	 * Close modal window
	 */
	onCloseModal = () => {
		this.setState({
			isProposalModalShown: false
		});
	};
	/***
	 * Create switcher state
	 */
	createProposalItemsState = () => {
		const proposalItemsState = {};
		this.props.list.forEach(item => {
			proposalItemsState[item.id] = item.isOff;
		});
		return proposalItemsState;
	};
	/***
	 * Activate all actions
	 * @returns {{variables: {coupons: Array}, newProposalItemsState}}
	 */
	createVariablesAndNewStateForAllCouponsActivate = () => {
		const variablesArray: CouponActivateType[] = [];
		const newProposalItemsState = {};
		for (let key in this.state.proposalItemsState) {
			if (this.state.proposalItemsState[key]) variablesArray.push({"couponId": +key, "isOff": false});
			newProposalItemsState[key] = false;
		}
		this.activateAction(newProposalItemsState, {"coupons": variablesArray}, true, this.initState, this.onCloseModal);

	};

	/***
	 * function for mutation running and set State after mutation
	 * @param newProposalItemsState
	 * @param variables
	 * @param switcherCheckedOff
	 * @param initState
	 * @param callback
	 */
	activateAction = (newProposalItemsState, variables: CouponsActivateVariableType, switcherCheckedOff, initState, callback?: ()=>void) => {
		this.setState({proposalItemsState: newProposalItemsState}, () => {
			this.props.couponsActivate({variables}).then(() => {
				this.initState = JSON.parse(JSON.stringify(this.state.proposalItemsState));
				if (!switcherCheckedOff) {
					const proposalCounter = this.state.proposalCounter + 1;
					this.setState({
						proposalItemsState: newProposalItemsState,
						proposalCounter
					});
				} else {
					this.setState({
						proposalItemsState: newProposalItemsState,
						proposalCounter: 0
					});
				}
			}).catch(error => {
				console.error(error);
				this.setState({
					proposalCounter: 0,
					proposalItemsState: initState
				});
			});
			if(typeof callback === "function") {
				callback();
			}
		});
	};

	render() {
		const {id, list = [], shadows, markCoupon} = this.props;
		const {proposalItemsState, isProposalModalShown} = this.state;

		return (
			<Fragment>
				<ProposalsListOptimization
					id={id}
					list={list}
					shadows={shadows}
					markCoupon={markCoupon}
					proposalItemsState={proposalItemsState}
					handleSwitcherToggle={this.handleSwitcherToggle}
				/>
				<ConfirmModal
					title={getTemplateText("confirmModal.title")}
					snippet={getTemplateText("confirmModal.snippet")}
					onSubmit={this.createVariablesAndNewStateForAllCouponsActivate}
					isOpen={isProposalModalShown}
					onClose={this.onCloseModal}
				/>
			</Fragment>
		)
	}
}

export default ProposalsList;