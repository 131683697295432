import React, {Component} from "react";
import {compose, withProps} from "recompose";
import {Field, withFormik} from "formik";

import {FORMS} from "../enums";
import {RULE} from "../../services/FormServices/constants";
import {normalizeFields, validate} from "../../services/FormServices/FormService";
import {IFormProps} from "../../services/FormServices/interfaces";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import {CardActivateFormValues, InnerCardActivateFormProps, OuterCardActivateFormProps} from "./types";

import Form from "../../components/Form/Form";
import FormInput from "../../components/Form/FormInput";
import FormHint from "../../components/Form/FormHint";
import FormField from "../../components/Form/FormField";
import FormError from "../../components/Form/FormError/FormError";
import Button from "../../components/UI/Button/Button";

const form = FORMS.CARD_ACTIVATE_FORM,
	fields = {
		activationCode: {
			name: "activationCode",
			rules: [RULE.required, RULE.invalid],
			params: {
				[RULE.invalid]: (value = "") => value.length !== 4 && RULE.invalid
			}
		},
	};

const CardActivateForm = compose<InnerCardActivateFormProps & OuterCardActivateFormProps, OuterCardActivateFormProps>(
	withProps((props: any) => ({
		...props,
		form,
		fields: normalizeFields(fields,  form),
	})),
	withFormik<IFormProps & OuterCardActivateFormProps, CardActivateFormValues>({
		mapPropsToValues: () => {
			return {
				activationCode: "",
				submitError: ""
			};
		},
		validate,
		handleSubmit: (values: CardActivateFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false)
		},
	})
)(class CardAddForm extends Component<InnerCardActivateFormProps & OuterCardActivateFormProps> {

	componentWillUnmount() {
		localStorage.removeItem("barcodeToActivate");
		this.props.resetForm();
	}

	render() {
		const {isValid, isSubmitting, handleSubmit, form, errors} = this.props;
		const btnProps = {
			className: "btn-submit",
			primary: true,
			block: true,
			type: "submit",
			disabled: !isValid || isSubmitting,
			loading: isSubmitting,
			children: getTemplateText("cardActivateForm.button")
		};

		const	emailLabel = getTemplateText("cardAddForm.label.activationCode");

		return (
			<Form onSubmit={handleSubmit} className="card-activate-form" name={form}>
				<h3>{getTemplateText("cardAddForm.heading")}</h3>

				<FormField className="field-email">
					<Field name="activationCode" component={FormInput} maxLength={4} label={emailLabel}/>
					<FormHint>
						{renderTemplateTextNode("cardAddForm.hint.activationCode")}
					</FormHint>
				</FormField>

				{errors.submitError && <FormError>{errors.submitError}</FormError>}

				<Button {...btnProps}/>
			</Form>
		)
	}
});

export default CardActivateForm;