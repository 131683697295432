import {useContext} from 'react';
import {useMutation} from 'react-apollo';
import {FormikHelpers} from 'formik';

import Mutation from '@graphql/Mutation';
import {getResponseFields, getStoredAuthData, handleAccessTokenUserFetch} from '../../helpers';
import {handleFormErrors} from '@services/FormServices/FormService';

import {AccessCodeRequestName, AccessCodeResponseType, AccessCodeVariablesType} from '@graphql/types';
import {OtpFormValuesType} from '../types';
import {Store} from 'js/context/context';

const useConfirmAccessCode = (mutationName: AccessCodeRequestName) => {
	const {dispatch} = useContext<any>(Store);

	const [mutationFunction] = useMutation<AccessCodeResponseType<typeof mutationName>, AccessCodeVariablesType>(Mutation[mutationName].mutation);
	const {flowId = -1, phone} = getStoredAuthData();

	const confirmRecoveryAccess = (values: OtpFormValuesType, actions: FormikHelpers<OtpFormValuesType>, onFinish?: any) => {
		return mutationFunction({
			variables: {
				accessCode: values.otp,
				flowId: +flowId,
			}
		}).then(({data = {}, errors}) => {
			if (errors) {
				throw errors;
			}
			actions.setSubmitting(false);
			const confirmAccessCode = data[mutationName];
			if (!errors && confirmAccessCode) {
				const {nextStep, flowId, accessToken, expiresAt} = getResponseFields(confirmAccessCode);
				localStorage.setItem("lastPhoneEntered", phone);
				if (accessToken) {
					handleAccessTokenUserFetch(accessToken, expiresAt, dispatch);
					onFinish && onFinish();
				} else {
					localStorage.setItem("authData", JSON.stringify({...getStoredAuthData(), flowId, nextStep}));
					onFinish && onFinish(nextStep);
				}
			}
		}).catch(err => {
			handleFormErrors(err, actions);
		});
	}
	return confirmRecoveryAccess;
}

export default useConfirmAccessCode