import React, {Component, ReactElement} from "react";
import cn from "classnames";
import objectPath from "object-path";
import omit from "object.omit"

import FormLabel from "./FormLabel";
import FormError from "./FormError/FormError";
import TimeInput from "./TimeInput";
import DateInput from "./DateInput";
import BarcodeInput from "./BarcodeInput";
import PhoneInput from "./PhoneInput";
import {WithFocusState} from "../HOCs/WithFocusState/WithFocusState";
import {isFunction} from "../../utils";
import {normalizeValue} from "../../services/FormServices/FormService";
import {FormInputPropsType} from "./types"
import CodeInput from "./CodeInput";
import PasswordInput from "./PasswordInput/PasswordInput";




class FormInput extends Component<FormInputPropsType, {}> {

	/**
	 * handles change event
	 * @param event
	 */
	onChange = (event) => {
		this.normalizeField(event);
		if (isFunction(this.props.onChange)) {
			this.props.onChange(event);
		} else {
			this.props.field.onChange(event);
		}
	};

	/**
	 * normalizes field value
	 * set normalized value explicitly to event.target.value
	 * @param event
	 */
	normalizeField = (event) => {
		const newValue = event.target.value;
		const {normalize, form, field, type} = this.props;
		if (normalize && isFunction(this.props.normalize)) {
			const normalizedValue = normalize(newValue, this.props.field.value);
			if (newValue !== normalizedValue) {
				event.target.value = normalizedValue;
				form.setFieldValue(field.name, normalizedValue)
			}
		} else {
			const normalizedValue = normalizeValue(newValue, field.value, type);
			if (newValue !== normalizedValue) {
				event.target.value = normalizedValue;
				form.setFieldValue(field.name, normalizedValue);
			}
		}
	};

	render() {
		const {field, placeholder, label, className, disabled, hint, hintClassName, form, type, focused, inline, error, holderClassName, value, twoLineLabel} = this.props;
		const {touched, errors} = form;
		const {name} = field;
		const errorMessage = objectPath.get(errors, name, null) || error;
		const isTouched = objectPath.get(touched, name, null);
		const showError = !!errorMessage && isTouched && !focused;
		const inputComponentProps = omit(this.props, ["focused", "inline", "normalize"]);
		const labelProps = {
				className: cn("form__label--focusable", {
					focused: focused,
					"not-empty": field.value || value || placeholder,
					error: showError,
					disabled,
					"two-line-label": twoLineLabel
				}),
				children: label,
			},
			inputProps = {
				className: cn("form__input", className, {disabled})
			},
			borderProps = {
				className: cn("form__input-border", {focused: focused, "not-empty": field.value || value, error: showError, disabled})
			};


		let control: ReactElement | HTMLInputElement | null = null;
		switch (type) {
			case "phone":
				control = <PhoneInput
					{...field}
					{...inputComponentProps}
					type="tel"
					inputMode="numeric"
					id={"focused-" + field.name}
					placeholder={placeholder}
					disabled={disabled}
					active={focused}
					autoComplete="off"
				/>;
				break;
			case "time":
				control = <TimeInput
					{...field}
					{...inputComponentProps}
					type="text"
					disabled={disabled}
					autoComplete="off"
					active={focused}
					/>;
				break;
			case "oldPassword":
			case "password":
				const inputPasswordProps = omit(this.props, ["focused", "inline", "normalize", 	"field", "form", "active", "error", "hintClassName", "holderClassName", "twoLineLabel"]);
				control = <PasswordInput
					{...field}
					value={field.value || ""}
					{...inputPasswordProps}
					id={"focused-" + name}
					placeholder={placeholder}
					onChange={this.onChange}
					disabled={disabled}
					active={focused}
				/>;
				break;
			case "date":
				control = <DateInput
					{...field}
					{...inputComponentProps}
						type="text"
						placeholder={placeholder}
						disabled={disabled}
						autoComplete="off"
					/>;
				break;
			case "barcode":
				control =
					<BarcodeInput
						{...field}
						{...inputComponentProps}
						type="tel"
						pattern="[0-9]*"
						placeholder={placeholder}
						disabled={disabled}
						active={focused}
						autoComplete="off"
					/>;
				break;
			case "code":
				control =
					<CodeInput
						{...field}
						{...inputComponentProps}
						type="tel"
						placeholder={placeholder}
						disabled={disabled}
						active={focused}
						autoComplete="off"
					/>;
				break;
			default:
				const inputProps = omit(this.props, ["focused", "inline", "normalize", 	"field", "form", "active", "error", "hintClassName", "holderClassName", "twoLineLabel"]);
				control = <input
					type="text"
					{...field}
					value={field.value || ""}
					{...inputProps}
					onChange={this.onChange}
					id={"focused-" + name}
					placeholder={placeholder}
					disabled={disabled}
					autoComplete="off"
				/>;
		}

		return (
			<div className={cn("form__input-holder", holderClassName, {disabled, "form__input-holder--inline": inline})}>
				{hint && <div className={hintClassName}>{hint}</div>}
				{showError && <FormError>{errorMessage}</FormError>}
				{!!label && <FormLabel {...labelProps}/>}
				<div  {...inputProps}>
					{control}
					<i {...borderProps}/>
				</div>
			</div>
		)
	}
}


export default WithFocusState(FormInput);
