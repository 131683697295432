import axios from 'axios';
import React, {FC, useCallback, useContext} from 'react';
import moment from 'moment';

import Button from '../../../../../../components/UI/Button/Button';
import Icon from '../../../../../../components/UI/Icon/Icon';
import {HASH_MODALS, PAYMENT_TYPES_ICONS, QUERY_DATE_FORMAT} from '../../../../../../constants';
import {Store} from '../../../../../../context/context';
import {CABINET, PAYMENT_API, SHOP_HOST} from '../../../../../../services/EnvServices';
import {ROUTE} from '../../../../../../services/RouteService/enums';
import {myForaRoutesMap} from '../../../../../../services/RouteService/RouteService';
import getTemplateText, {FormattedHTMLMessage} from '../../../../../../services/TextService';
import {parseErrorOnfly} from '../../../../../../utils';

import {PurchaseDetailsItemType} from '../../../hooks/usePurchaseDetails/types';
import {VostokPreAuthPaymentResp} from './types';

import './PurchaseItemPaymentInfo.styl';

const PurchaseItemPaymentInfo: FC<{itemDetails: PurchaseDetailsItemType}> = ({itemDetails}) => {
	const {state} = useContext<any>(Store);
	const {fullPhoneNumber} = state.user;
	const {paymentStateId, paymentStateText, paymentType, paymentTypeText, actions, discount, points} = itemDetails;
	const isPaid = paymentStateId === 1;
	const paymentAvailable = !!actions.find(item => item === "paymentOnline");

	const paymentVostok = useCallback((item) => {
		const {id: basketGuid, orderNumber: orderId, sumOut: amount, filialId} = item;
		const from = moment().subtract(1, "M").format(QUERY_DATE_FORMAT);
		const to = moment().format(QUERY_DATE_FORMAT);
		const successRedirectUrl = `${CABINET}${myForaRoutesMap[ROUTE.PURCHASES]}?from=${from}&to=${to}#${HASH_MODALS.SUCCESS_PAYMENT}`;
		const failureRedirectUrl = `${SHOP_HOST}/unsuccess?basket=${basketGuid}`;

		axios.post<VostokPreAuthPaymentResp>(PAYMENT_API, {
			method: "VostokPreAuthPayment",
			data: {
				basketGuid,
				orderId,
				amount,
				phoneNumber: fullPhoneNumber,
				filialId
			}
		}).then(({data}) => {
			const {data: {errorCode, errorMessage, signature, keyHash, description,
				partnerOrderId, merchantId, authType,
				callbackUrl, cultureName, customParameters}, eComError} = data;
			if (eComError?.errorCode) {
				throw eComError.errorMessage;
			}
			if (errorCode) {
				throw errorMessage;
			}
			if (signature && keyHash) {
				const EcomSDK = window["EcomSDK"];
				if (EcomSDK) {
					const checkoutPay = new EcomSDK.CheckoutPay();
					checkoutPay.amount = amount;
					checkoutPay.description = description;
					checkoutPay.partnerOrderId = partnerOrderId;
					checkoutPay.merchantId = merchantId;
					checkoutPay.authType = authType;
					checkoutPay.successRedirectUrl = successRedirectUrl;
					checkoutPay.failureRedirectUrl = failureRedirectUrl;
					checkoutPay.callbackUrl = callbackUrl;
					checkoutPay.cultureName = cultureName;
					checkoutPay.signature = signature;
					checkoutPay.keyHash = keyHash;
					checkoutPay.customParameters = customParameters;
					checkoutPay.checkout();
					localStorage["redirectOnVostokCheckout"] = 1;
				} else {
					window.location.replace(failureRedirectUrl);
				}
			} else {
				window.location.replace(failureRedirectUrl);
			}
		})
			.catch(err => {
				parseErrorOnfly(err);
				window.location.replace(failureRedirectUrl);
			});
	}, [fullPhoneNumber]);

	return (
		<div className='purchase-payment-info-wrapper'>
			<div className='purchase-payment-type'>
				<span className='description'>{getTemplateText("myPurchases.order.payment")}</span>
				<div className='value'>
					<Icon raw icon={PAYMENT_TYPES_ICONS[paymentType].icon} />
					<span>{paymentTypeText}</span>
				</div>
			</div>
			<div className='devider' />
			{isPaid ?
				<div className='payment-details'>
					{!!discount &&
						<div className='payment-details-row discount'>
							<span className='description'>{getTemplateText("myPurchases.order.discount-sum")}</span>
							<span className='value'>-{discount}{getTemplateText("myPurchases.order.payment.uah")}</span>
						</div>}
					{!!points &&
						<div className='payment-details-row'>
							<span className='description'>{getTemplateText("myPurchases.order.bonuses-sum")}</span>
							<span className='value'>+<FormattedHTMLMessage id="points" values={{points}} /></span>
						</div>}
				</div>
				: <div className='payment-details'>
					<div className='payment-details-row'>
						<span className='description'>{paymentStateText}</span>
						{paymentAvailable && <Button onClick={() => paymentVostok(itemDetails)} className='pay-btn' primary>{getTemplateText("myPurchases.order.pay.btn")}</Button>}
					</div>
				</div>}
		</div>
	)
}

export default PurchaseItemPaymentInfo