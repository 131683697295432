import React, {FC, useEffect, useRef, useState} from 'react'
import {Field, FormikValues, useFormikContext} from 'formik';

import {AUTH_NEW_ENTRANCE_ACTIONS} from '@constants';
import {form} from './const';
import {needRecapture, phoneNumberFormat} from '@utils';
import {Recapture} from '@services/RecaptchaService';
import getTemplateText, {FormattedHTMLMessage} from '@services/TextService';
import useAutoFocus from '../../hooks/useAutoFocus';
import useSendOptToMobile from './hooks/useSendOptToMobile';
import useSendGoToOtpLogin from './hooks/useSendGoToOtpLogin';
import useSendRecoveryOtp from './hooks/useSendRecoveryOtp';
import {getStoredAuthData} from '../helpers';

import Form from '@components/Form/Form';
import FormField from '@components/Form/FormField';
import FormInput from '@components/Form/FormInput';
import Button from '@components/UI/Button/Button';
import FormError from '@components/Form/FormError/FormError';
import ChangeFlowButton from './components/ChangeFlowButton';

import {OTPFormPropsType} from './types';
import {ContactTypes} from '../types';

import "./OtpEntry.styl";

const OtpEntryForm: FC<OTPFormPropsType> = (props) => {
	const {entranceType = "LOYALTY", isRecovery, withPassword, determineNextStep} = props;

	const isMount = useRef(false);
	const [isTryAgainActive, setIsTryAgainActive] = useState(false);
	const {handleSubmit, isSubmitting, errors, isValid, resetForm} = useFormikContext<FormikValues>();

	const otpRef = useAutoFocus();
	const sendGoToOtpLogin = useSendGoToOtpLogin();
	const sendRecoveryOtp = useSendRecoveryOtp();
	const sendOptToMobile = useSendOptToMobile(entranceType);

	const {phone, email, contactType} = getStoredAuthData();
	const phoneLabel = {phone: phoneNumberFormat(phone)};
	const isRecaptchaShown = needRecapture();
	const isEmail = contactType === ContactTypes.EMAIL;

	useEffect(() => {
		isMount.current = true;
		hideTryAgain();
		return () => {
			isMount.current = false;
			resetForm();
		}
		// eslint-disable-next-line
	}, []);

	const hideTryAgain = () => {
		if (isMount.current) {
			setIsTryAgainActive(false);
			setTimeout(() => {
				if (isMount.current) {
					setIsTryAgainActive(true);
				}
			}, 60000)
		}
	};

	const goToPassword = (e) => {
		e.preventDefault();
		determineNextStep(AUTH_NEW_ENTRANCE_ACTIONS.passwordEntry);
	}

	const sendAgain = () => {
		hideTryAgain();
		if (isRecovery) {
			sendRecoveryOtp();
		} else if (withPassword) {
			sendGoToOtpLogin();
		} else {
			sendOptToMobile();
		}
	};

	return (
		<Form onSubmit={handleSubmit} className="otp-form" name={form}>
			<div className="otp-form_description">
				<FormattedHTMLMessage id={isEmail ? "otpEmailForm.description" : "otpForm.description"} values={isEmail ? {email} : phoneLabel} />
			</div>
			{isRecaptchaShown && <Recapture />}
			<FormField className="otp-form__password-field">
				<Field autoFocus name="otp" component={FormInput} label={getTemplateText("otpEmailForm.otp")} type="password" maxLength="4" innerRef={otpRef} />
				{withPassword && <ChangeFlowButton className={"otp-form__go_to_password-btn"} textId="otpForm.go_to_password" onClick={goToPassword} />}
				{isTryAgainActive && <ChangeFlowButton className={"otp-form__try-again-btn"} textId="otpForm.tryAgain" onClick={sendAgain} />}
			</FormField>
			{errors && <FormError>{errors.submitError}</FormError>}
			<Button className="btn-submit" primary block={false} type="submit" disabled={!isValid || isSubmitting} loading={isSubmitting}>
				{getTemplateText("otpForm.confirm")}
			</Button>
			{withPassword && <ChangeFlowButton className={"otp-form__go_to_password-btn mobile"} textId="otpForm.go_to_password" onClick={goToPassword} />}
		</Form>
	)
}

export default OtpEntryForm;