import React, {Component} from 'react';
import './Footer.styl'
import Logo from "../../UI/Logo/Logo";
import {PROJECT, VERSION} from "../../../services/EnvServices";
import {ICONS} from "../../UI/Icon/enums";
import Icon from "../../UI/Icon/Icon";

interface FooterProps {
}


class Footer extends Component<FooterProps> {
	render() {

			let data:any;
		switch (PROJECT) {
			case "fora":
				data = require(`./fora.json`);
				return (
					<footer className='fora footer'>
						<div className="row">
							<div className="col-3 logo-column">
								<div className="logo">
									<Logo/>
								</div>
								<div className="phone-block">
							<span className="footer-title">
								Безкоштовна гаряча лінія
							</span>
									<a href="tel:+380500301230" className="footer-phone-number">
										0 800 30 12 30
									</a>
								</div>
								<div className="socials">
									<a href="https://www.facebook.com/fora.ukraine/" target="_blanc" className="social-link">
										<Icon icon={ICONS.facebookCircleFora} raw/>
									</a>
									<a href="https://www.instagram.com/fora.market/?igshid=9t147wdns43g" target="_blanc" className="social-link">
										<Icon icon={ICONS.instagramFora} raw/>
									</a>
									<a href="https://t.me/foraINFO" target="_blanc" className="social-link">
										<Icon icon={ICONS.telegramCircleFora} raw/>
									</a>
									{/* <a href="https://chats.viber.com/forafoodmarket/en" target="_blanc" className="social-link">
										<Icon icon={ICONS.viberFora} raw/>
									</a> */}
								</div>
							</div>

							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.type ==="ACTIONS" || item.type ==="ABOUT" ) {
										const items:Array<object> = item.items;

										return (
											<div className="footer-block" key={key}>
												<div className='footer-widget-title'>
													{item.title}
												</div>
												<ul className="footer-list">

													{items.map((i,k) =>{
														const list:any = i,
															slug:string = list.slug,
															href:string = item.type === 'ACTIONS' ? 'https://fora.ua/promotions/' + slug : 'https://fora.ua/about/' + slug;
														return(
															<li className="footer-list-item" key={k}>
																<a href={href} className="footer-list-link">
																	{list.title}
																</a>
															</li>
														)
													})}
												</ul>
											</div>
										)
									}
									return null;
								})}
							</div>
							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.type ==="WORK") {
										const items:Array<object> = item.items;

										return (
											<div className="footer-block" key={key}>
												<div className='footer-widget-title'>
													{item.title}
												</div>
												<ul className="footer-list">

													{items.map((i,k) =>{
														const list:any = i,
															slug:string = list.slug,
															href:string = item.type === 'ABOUT' ? 'https://fora.ua/about/' + slug
																: 'https://fora.ua/work/' + slug ;
														return(
															<li className="footer-list-item" key={k}>
																<a href={href} className="footer-list-link">
																	{list.title}
																</a>
															</li>
														)
													})}
												</ul>
											</div>
										)
									}
									return null;
								})}
							</div>
							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.__typename ==="FooterLabel") {
										return (
											<div className="footer-block" key={key}>
												<div>
													<a href={item.href} className='footer-title'>{item.title}</a>
												</div>
											</div>
										)
									}
									return null;
								})}
							</div>
						</div>
						<div ><span className="version-link">{VERSION}</span></div>
					</footer>
				);
			case "silpo":
				data = require(`./silpo.json`);
				return (
					<footer className='silpo footer'>
						<div className="row">
							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.type ==="ACTIONS") {
										const items:Array<object> = item.items;

										return (
											<div className="footer-block" key={key}>
												<div className='footer-widget-title s'>
													{item.title}
												</div>
												<ul className="footer-list">

													{items.map((i,k) =>{
														const list:any = i,
															slug:string = list.slug,
															href:string = 'https://silpo.ua/offers/' + slug;
														return(
															<li className="footer-list-item s" key={k}>
																<a href={href} className="footer-list-link">
																	{list.title}
																</a>
															</li>
														)
													})}
												</ul>
											</div>
										)
									}
									return null;
								})}
							</div>
							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.type ==="ABOUT") {
										const items:Array<object> = item.items;

										return (
											<div className="footer-block" key={key}>
												<div className='footer-widget-title s' >
													{item.title}
												</div>
												<ul className="footer-list">

													{items.map((i,k) =>{
														const list:any = i,
															slug:string = list.type,
															href:string = 'https://silpo.ua/about/' + slug ;
														return(
															<li className="footer-list-item s" key={k}>
																<a href={href} className="footer-list-link">
																	{list.properties.title}
																</a>
															</li>
														)
													})}
												</ul>
											</div>
										)
									}
									return null;
								})}
							</div>
							<div className="col-3">
								{data.footer.map((item:any,key:number)=>{
									if(item.__typename ==="FooterLabel" && item.type !== 'ACTIONS' && item.type !== 'ABOUT') {
										return (
											<div className="footer-block" key={key}>
												<div>
													<a href={`https://silpo.ua/${item.type.toLowerCase()}`} className='footer-title s'>{item.title}</a>
												</div>
											</div>
										)
									}
									return null;
								})}
							</div>
						</div>

						<div ><span className="version-link">{VERSION}</span></div>
					</footer>
				);

		}

	}
}

export default Footer;
