import {ICONS} from "./components/UI/Icon/enums";
import {isDev} from "./services/EnvServices";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_PATTERN = /\d{1,2}\/\d{1,2}\/\d{4}/;
export const QUERY_DATE_FORMAT = "DD-MM-YYYY";
export const CASUAL_DATE_FORMAT = "DD.MM.YYYY";

export enum HASH_MODALS {
	LOGIN = 'login',
	REGISTER = 'registration',
	PASSWORD_RECOVERY = 'password-recovery',
	MONEYBOX = "moneybox",
	REGISTRATION_SUCCESS = "registration-success",
	ALREADY_REGISTERED = "already-registered",
	CONTACT_CENTER = "contact-center",
	ERROR_MESSAGE = "error-message",
	HOW_GET_CARD = "how-get-card",
	CARD_RECOVERY = "card-recovery",
	INFO = "info-message",
	OTP = "otp",
	PASSWORD = "password",
	CARD = "card",
	REGISTRATION_INFO = "registration-info",
	WELCOME = "welcome",
	SUCCESS_PAYMENT = "success-payment"
}


export enum VIEW_TYPES {
	map= "map",
	list= "list",
}

export enum CARD {
	ACTIVE= "ACTIVE",
	BLOCKED= "BLOCKED",
}

export const VOUCHER_TYPE = {
	BALANCE: "BALANCE",
	PURCHASES: "PURCHASES",
	COUPON: "COUPON",
	RINGOO: "RINGOO",
	BILA_ROMASHKA: "BILA_ROMASHKA",
	CARD_ACTIVATE: "CARD_ACTIVATE",
	MONEYBOX: "MONEYBOX",
	BANK_VOSTOK: "BANK_VOSTOK",
	HOLIDAY: "HOLIDAY",
	VOYAGE: "VOYAGE",
	YEZZZ: "YEZZZ",
	THRASH: "THRASH",
	RECYCLING: "RECYCLING",
	COUPON_FORA: "COUPON_FORA",
	PURCHASES_FORA: "PURCHASES_FORA",
	MOTIVATIONAL_PROJECTS_FORA: "MOTIVATIONAL_PROJECTS_FORA",
	POLL_FORA: "POLL_FORA",
	MONEYBOX_FORA: "MONEYBOX_FORA"
};

export enum PROMO_TYPE_ENUM {
	"PROMOTION"= "PROMOTION",
	"CAMPAIGN"= "CAMPAIGN",
	"LINK"= "LINK"
}

export const TYPE_NAME = {
	"Promo": "Promo",
	"Coupon": "Coupon",
	"Product": "Product",
	"Promotion": "Promotion",
	"Campaign": "Campaign",
	"Store": "Store",
	"StoreOnly": "StoreOnly",
	"FAQQuestion": "FAQQuestion",
	"FAQCategory": "FAQCategory",
	"PartnerContact": "PartnerContact",
	"Person": "Person",
	"Page": "Page",
	"PressRelease": "PressRelease",
	"Office": "Office",
	"Events": "Events",


};
export const PROMO_TYPE = {
	BACKGROUND: 1,
	PHOTO: 2,
	VIDEO: 3
};

export const COLORS = {
	white: "white",
	violet: "violet",
	cyan: "cyan",
	green: "green",
	olive: "olive",
	orange: "orange",
};

export const VARIABLES = {
	storesFilters: {
		start: "start",
		end: "end",
		all_day: "all_day",
		services: "services",
	},
	allDay: {
		start: "00:00",
		end: "23:59",
	},
	vacancyFilters: {
		full_day: "full_day",
		half_day: "half_day",
		without_experience: "without_experience",
		work_office: "work_office",
		work_market: "work_market",
		work_manufacture: "work_manufacture",
		staff_administrative: "staff_administrative",
		staff_accounting: "staff_accounting",
		staff_marketing: "staff_marketing",
		staff_commercial: "staff_commercial",
		staff_management: "staff_management",
		staff_building: "staff_building",
		staff_security: "staff_security",
		staff_operator: "staff_operator",
		staff_boss: "staff_boss",
		staff_other_market: "staff_other_market",
		staff_other_resto: "staff_other_resto",
		staff_manufacture: "staff_manufacture",
	}
};

export const donutCircleColors = {
	[VOUCHER_TYPE.PURCHASES]: "#f78429",
	[VOUCHER_TYPE.COUPON]: "#5c3481",
	[VOUCHER_TYPE.RINGOO]: "#88c728",
	[VOUCHER_TYPE.BILA_ROMASHKA]: "#ffce00",
	[VOUCHER_TYPE.CARD_ACTIVATE]: "#c1f2ee",
	[VOUCHER_TYPE.MONEYBOX]: "#50b7a1",
	[VOUCHER_TYPE.BANK_VOSTOK]: "#f22336",
	[VOUCHER_TYPE.HOLIDAY]: "#115583",
	[VOUCHER_TYPE.VOYAGE]: "#3949ab",
	[VOUCHER_TYPE.YEZZZ]: "#9f2929",
	[VOUCHER_TYPE.THRASH]: "#000000",
	[VOUCHER_TYPE.RECYCLING]: "#00b20e",
	[VOUCHER_TYPE.COUPON_FORA]: "#7ab929",
	[VOUCHER_TYPE.PURCHASES_FORA]: "#ef4237",
	[VOUCHER_TYPE.MOTIVATIONAL_PROJECTS_FORA]: "#5a4435",
	[VOUCHER_TYPE.POLL_FORA]: "#4c515a",
	[VOUCHER_TYPE.MONEYBOX_FORA]: "#115583",
};

export const UNIT_TYPES = {
	uah: "грн",
	bonus: "балів"
};

export const defaultColors = [
	"#f78429",
	"#5c3481",
	"#88c728",
	"#ffce00",
	"#c1f2ee",
	"#50b7a1",
	"#f22336",
	"#115583",
	"#3949ab",
	"#9f2929",
	"#000000",
	"#00b20e",
	"#7ab929",
	"#ef4237",
	"#5a4435",
	"#4c515a",
	"#115583",
];

export const ENUM = {
	ALL: "ALL",
	YES: "YES",
	NO: "NO",

	// size
	NORMAL: "NORMAL",
	LARGE: "LARGE",
	FULL_SIZE: "FULL_SIZE",

	// themes colors
	WHITE: "WHITE",
	VIOLET: "VIOLET",
	CYAN: "CYAN",
	GREEN: "GREEN",
	OLIVE: "OLIVE",
	ORANGE: "ORANGE",

	// campaign type
	WEEK_PRICE: "WEEK_PRICE",
	OWN_IMPORT: "OWN_IMPORT",
	HOT: "HOT",
	THEMATIC_WEEKS: "THEMATIC_WEEKS",
	KIDS_WEEKS: "KIDS_WEEKS",
	OTHER: "OTHER",

	// application subjects
	CONTACT: "CONTACT",
	FAQ: "FAQ",
	CV: "CV",

	// Gender
	MALE: "MALE",
	FEMALE: "FEMALE",

	// card
	ACTIVE: "ACTIVE",
	BLOCKED: "BLOCKED",

	// moneybox
	NOT_ACTIVATED: "NOT_ACTIVATED",
	ACTIVATED: "ACTIVATED",
	DEACTIVATED: "DEACTIVATED",
	AUTOMATICALLY: "AUTOMATICALLY",
	OPTIONAL: "OPTIONAL",

	// COUPON
	// PeriodType
	BY_DATE: "BY_DATE",
	CURRENT: "CURRENT",
	PREVIOUS: "PREVIOUS",
	// ACTIVE   : "ACTIVE",

	// UseType
	UNDEFINED: "UNDEFINED",
	ELECTRONIC: "ELECTRONIC",
	PAPER: "PAPER",

	// RelationshipStatus
	MARRIED: "MARRIED",
	NOT_MARRIED: "NOT_MARRIED",

	// PersonalPromo
	COUPON: "COUPON",
	VOUCHER: "VOUCHER",

	// AddressType
	MAILBOX: "MAILBOX",
	PRIVATE_HOME: "PRIVATE_HOME",
	FLAT: "FLAT",

	//EventAuth|Type
	EMAIL: "EMAIL",
	PHONE: "PHONE",

	//cookingOrderStatuses
	NEW: "0995336d-8483-46c7-a012-437df3cd40ab",
	APPROVED: "2f916855-834b-47c0-bd26-c615a411dfdf",
	CANCELLED: "acd8914b-11c6-43fb-9cbd-deddb9a220aa",
	EXPIRED: "2f257b3f-89cc-4e7e-8aec-cef9700034b6",
	READY: "f9e38007-b27c-409f-8ff7-b2c7c39eb294",
	DISMANTLED: "07baae3c-cff6-424a-a455-470909e70fe2",
	UTILIZED: "15225f94-ad0e-4a8a-a04d-ac83ac41d23d",
	ISSUED: "f1a67b87-fbd2-4f91-97eb-1764c42dc39e",

	//sendToEmailTypes
	COOKING: "cooking",
	DB: "db",

	//paymentStatus
	PAID: "Оплачене",
	UNPAID: "Неоплачене",

	MASTERCARD: "mastercard",
	VISA: "visa"
};

export enum SUBSCRIBE_TYPES {
	NEWPROD= "NEWPROD",
	WEEKPRICE= "WEEKPRICE",
	BEERMASTER= "BEERMASTER",
}

export const STORES_STATUS = {
	NOT_OPENED: "NOT_OPENED",
	OPENED: "OPENED",
	CLOSED: "CLOSED",
};

export const RANGE_FOCUS = {
	startDate: "startDate",
	endDate: "endDate",
};

export enum PROJECT {
	SILPO="silpo",
	FORA="fora",
}

export const MONEYBOX_STATUS = {
	NOT_ACTIVATED: "NOT_ACTIVATED",
	ACTIVATED: "ACTIVATED",
	DEACTIVATED: "DEACTIVATED",
	AUTOMATICALLY: "AUTOMATICALLY",
	OPTIONAL: "OPTIONAL",
};

export const ERRORS = {
	CODE_BAD_REQUEST: 400,
	CODE_UNAUTHORIZED: 401,
	CODE_FORBIDDEN: 403,
	CODE_NOT_FOUND: 404,

	CODE_INTERNAL_SERVER_ERROR: 500,
	CODE_SERVER_ERROR: 505,

	CODE_BAD_CREDENTIALS: 2001,
	CODE_CARD_BLOCKED: 2010,
	CODE_USER_BLOCKED: 2011,
	CODE_USER_NOT_REGISTERED: 2012,
	CODE_USER_REGISTERED: 2013,
	CODE_USER_NOT_ACTIVATED: 2014,
	CODE_USER_WITHOUT_EMAIL: 2015,
	CODE_INCORRECT_ANSWER: 2016,
	CODE_LOW_PROTECTION_PASSWORD: 2017,
	CODE_CHANGING_ELEMENT_STATUS_ERROR: 2018,
	CODE_PASSWORD_SENT: 2018,
};

export const MAX_PHONES_AMOUNT = 10;

export const PAGE_TYPE = {
	about: "about",
	ownBrands: "own-brands",
	kidsDepartments: "kids-departments",
	giftCertificates: "gift-certificates",
	ownProduction: "own-production",
	ourGuests: "our-guests",
	ownImports: "own-imports",
	pressCenter: "press-center",
	ourFriends: "our-friends",
	ourOffers: "our-offers",
	personalAccount: "personal-account",
	personalAssistant: "personal-assistant",
	cards: "cards",
	moneybox: "moneybox",
	personalPage: "personal-page",
	rules: "rules",
};

export const PASSWORD_STEPS = {
	recovery: "recovery",
	success: "success",
};

export const OPERATION_NAME = {
	loginwithcapture: "loginwithcapture",
	validateLogin: "validateLogin",
	goToRecovery: "goToRecovery",
	sendOtpToContact: "sendOtpToContact",
	confirmRecoveryAccessCode: "confirmRecoveryAccessCode",
	recoverAccess: "recoverAccess",
	confirmEmail: "confirmEmail",
	getRecoveryContacts: "getRecoveryContacts",
	confirmAccessCode: "confirmAccessCode",
	setCardPresence: "setCardPresence",
	registerMember: "registerMember",
	goToOtpLogin: "goToOtpLogin",
	confirmLoginAccessCode: "confirmLoginAccessCode",
	login: "login"
};

export const PAYMENT_TYPE = {
	"1": "Готівка",
	"2": "Картка",
	"3": "QR код"
};

export enum PAYMENT_TYPES {
	Unknown = 0,
	Cachdesk = 1,
	Online = 2,
	CourierCash = 3,
	CourierCard = 4,
	Masterpass = 5,
	CreditCashless = 6,
	ApplePay = 7,
	GooglePay = 8,
	VostokPay = 9
}

export const PAYMENT_TYPES_ICONS = {
	[PAYMENT_TYPES.Online]: {
		icon: ICONS.paymentCard,
	},
	[PAYMENT_TYPES.Masterpass]: {
		icon: ICONS.paymentCard,
	},
	[PAYMENT_TYPES.CreditCashless]: {
		icon: ICONS.paymentCard,
	},
	[PAYMENT_TYPES.VostokPay]: {
		icon: ICONS.paymentCard,
	},
	[PAYMENT_TYPES.Cachdesk]: {
		icon: ICONS.paymentPickup,
	},
	[PAYMENT_TYPES.CourierCash]: {
		icon: ICONS.paymentCash,
	},
	[PAYMENT_TYPES.CourierCard]: {
		icon: ICONS.paymentTerminal,
	},
	[PAYMENT_TYPES.ApplePay]: {
		icon: ICONS.applePay,
	},
	[PAYMENT_TYPES.GooglePay]: {
		icon: ICONS.googlePay,
	},
};

export enum AUTH_NEW_ENTRANCE_ACTIONS {
	authEntrance = "auth-entrance",
	otpEntry = "otp_entry",
	loginOtpEntry = "login_otp_entry",
	cardInformationEntry = "card_information_entry",
	registrationInfoEntry = "registration_info_entry",
	recoveryPasswordEntry = "recover_password_entry",
	recoveryPasswordAndEmailEntry = "recovery_password_and_email_entry",
	passwordEntry = "password_entry",
	passwordEntryWithoutLoginOtpLink = "password_entry_without_otp_login_link",
	createOrder = "create_order",
	showRegistrationWelcome = "show_registration_welcome",
	recoveryContactSelection = "recovery_contact_selection",
	recoveryOtpEntry = "recovery_otp_entry",
	authWelcomeWithCard= "auth-welcome-with-card",
}

export const BONUS_FILTER_TYPE = {
	ALL: 0,
	EARNINGS: 1,
	CHARGES: 2,
};

export const BONUS_TYPE_LINES = {
	creation : "BONUS_CREATION",
	debiting : "BONUS_DEBITING",
	blocking: "BONUS_BLOCKING",
	charity: "BONUS_CHARITY"
};

export const BONUS_STATUS = {
	used : "BONUS_STATUS_USED",
	active : "BONUS_STATUS_ACTIVE"
};

export enum DELIVERY_TYPES {
	UNKNOWN = 0,
	PICKUP = 1,
	DELIVERY_HOME = 2,
	DELIVERY_FLAT = 3,
	DELIVERY_OFFICE = 4,
	DELIVERY_FAST = 6,
	DELIVERY_FAST_FOOD = 7,
	DELIVERY_FAR_AWAY = 9,
	FAKE_DELIVERY = 99
}

export const DELIVERY_LABELS = {
	[DELIVERY_TYPES.UNKNOWN]: "Покупка у магазині",
	[DELIVERY_TYPES.PICKUP]: "Самовивіз",
	[DELIVERY_TYPES.DELIVERY_HOME]: "Доставка",
	[DELIVERY_TYPES.DELIVERY_FAR_AWAY]: "Нова пошта",
};

export enum PRODUCT_STATE {
	ORIGINAL = 0,
	DELETED = 1,
	ADDED = 2,
	CHANGED = 3
}

export enum BASKET_STATE {
	Draft = 0, // Формується
	NewOrder = 1, // Нове
	NotCompleted = 2, //Не завершене
	Confirmed =  10, // Підтверджене
	Cooking = 20, // Готуємо
	Collecting = 30, // Збираємо
	ReadyToShip = 40, // Готове до видачі
	WaitForCourier = 41, // Очікує кур'єра
	Delivering = 50, // В дорозі
	BackDelivering = 51, // В дорозі до магазину

	Shipped = 60, // Видане

	Refused = 100, // Скасоване
	Overdue = 101, // Скасоване
	Dismantled = 102, // Скасоване
	Recycled = 103, // Скасоване
};

export const BASKET_STATE_CLASS_LABELS = {
	[BASKET_STATE.Draft]: "new",
	[BASKET_STATE.NewOrder]: "new",
	[BASKET_STATE.NotCompleted]: "new",
	[BASKET_STATE.Confirmed]: "new",
	[BASKET_STATE.Cooking]: "new",
	[BASKET_STATE.Collecting]: "new",
	[BASKET_STATE.ReadyToShip]: "new",
	[BASKET_STATE.WaitForCourier]: "new",
	[BASKET_STATE.Delivering]: "new",
	[BASKET_STATE.BackDelivering]: "new",
	[BASKET_STATE.Shipped ]: "ready",
	[BASKET_STATE.Refused] : "refused",
	[BASKET_STATE.Overdue]: "refused",
	[BASKET_STATE.Dismantled]: "refused",
	[BASKET_STATE.Recycled]: "refused",
};

export enum PURCHASE_TYPES {
	ONLINE = 0,
	OFFLINE = 1,
};

export const PURCHASE_LABELS = {
	[PURCHASE_TYPES.ONLINE]: "Замовлення",
	[PURCHASE_TYPES.OFFLINE]: "Покупки в магазині",
};

export enum PURCHASE_STATE {
	ALL = 0, // Всі
	ACTIVE = 1, // Нове
	APPROVING = 2, //Сформоване
	COLLECTING = 5, // Збирається
	REFUSED = 4, // Скасоване
	READY_TO_SHIP = 6, // Готове
	SHIPPED = 7, //Видане
};

export const PURCHASE_STATE_LABELS = {
	[PURCHASE_STATE.ALL]: "Всі",
	[PURCHASE_STATE.ACTIVE]: "Нове",
	[PURCHASE_STATE.APPROVING]: "Сформоване",
	[PURCHASE_STATE.COLLECTING]: "Збирається",
	[PURCHASE_STATE.REFUSED]: "Скасоване",
	[PURCHASE_STATE.READY_TO_SHIP]: "Готове",
	[PURCHASE_STATE.SHIPPED]: "Видане",
};

export const PURCHASE_STATE_CLASS_LABELS = {
	[PURCHASE_STATE.ACTIVE]: "new",
	[PURCHASE_STATE.APPROVING]: "new",
	[PURCHASE_STATE.COLLECTING]: "new",
	[PURCHASE_STATE.READY_TO_SHIP]: "new",
	[PURCHASE_STATE.SHIPPED]: "ready",
	[PURCHASE_STATE.REFUSED]: "refused",
};

export const LOG_LEVEL = {
	error: "error"
}

export const FRONT_ERROR_LEVEL = {
	EMERGENCY: "emergency",
	ALERT: "alert",
	CRITICAL: "critical",
	ERROR: "error",
	WARNING: "warning",
	NOTICE: "notice",
	INFO: "info",
	DEBUG: "debug"
}

export const LAGER_STATE = {
	ORIGINAL: 0,
	ADDED: 2,
	REMOVED: 1,
	CHANGED:3
};

export const VOSTOK_SDK = isDev ? "https://sdk.ecom.test.vostok.bank/SDK/Source/ecom.sdk.js" :
	"https://sdk.ecom.vostok.bank/SDK/Source/ecom.sdk.js";