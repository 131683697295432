import React, {FunctionComponent} from "react";
import cn from "classnames";

import Icon from "../Icon/Icon";
import {ICONS} from "../Icon/enums";

import {LinkAllProps} from "./types";

import Link from "../Link";

import "./LinkAll.styl";


const LinkAll : FunctionComponent<LinkAllProps> =  ({icon = ICONS.chevronRight, children, iconClass = "", to="", className, ...props}) => {
	return (
		<Link to={to} className={cn("link-all", className)} {...props}>
			<span className="link-all__content">{children}</span>
			{icon && (
				<Icon className={cn("link-all__icon", iconClass)} icon={icon}/>
			)}
		</Link>
	)
};

export default LinkAll;
