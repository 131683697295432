import React, {Component} from "react";
import {findDOMNode} from "react-dom";
import {DateRangePicker} from "react-dates";
import cn from "classnames";
import moment from 'moment';

import {RANGE_FOCUS} from "../../../constants";
import Icon from "../Icon/Icon";
import {ICONS} from "../Icon/enums";
import {isAfterDay, isBeforeDay} from "./helpers";

import {UIDateRangePickerPropTypes, UIDateRangePickerStateTypes, UIDateRangePickerTypes} from './types';

import 'react-dates/lib/css/_datepicker.css';
import "./DateRangePicker.styl";

import "react-dates/initialize";
class UIDateRangePicker extends Component<UIDateRangePickerPropTypes, UIDateRangePickerStateTypes> implements UIDateRangePickerTypes {
	constructor(props) {
		super(props);
		this.state = {
			focusedInput: null,
			numberOfMonths: window.innerWidth <= 768 ? 1 : 2,
			position: null
		};
	}

	picker = null;


	componentDidMount() {
		this.handleWindowResize();
		window.addEventListener("resize", this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleWindowResize);
	}

	handleWindowResize = () => {
		const {position} = this.state;

		if (window.innerWidth <= 768) {
			const button: any = findDOMNode(this.picker);
			const numOfMonths = 2;
			if (button) {
				const buttonWidth = button.getBoundingClientRect().width;
				const pickerWidth = numOfMonths * 330;

				const widthAfterButton = pickerWidth / 2 - buttonWidth / 2;

				const rightFree = window.innerWidth - button.getBoundingClientRect().right;
				const leftFree = button.getBoundingClientRect().left;

				if (rightFree < widthAfterButton && leftFree < widthAfterButton) {
					this.setState({position: "left"});
				} else if (rightFree < widthAfterButton && position !== "right") {
					this.setState({position: "right"})
				} else if (leftFree < widthAfterButton && position !== "left") {
					this.setState({position: "left"})
				}
			}

		} else if (window.innerWidth > 768 && position !== null) {
			this.setState({position: null})
		}

		if (window.innerWidth <= 768 && this.state.numberOfMonths === 2) {
			this.setState({numberOfMonths: 1})
		} else if (window.innerWidth > 768 && this.state.numberOfMonths === 1) {
			this.setState({numberOfMonths: 2, position: null})
		}
	};

	onFocusChange = (focusedInput) => {
		this.setState({focusedInput});
	};

	render() {
		const {numberOfMonths, position} = this.state;

		const {placeholder, className, ...restProps} = this.props;
		const {startDate, endDate, onDatesChange, showClearDates} = restProps;
		const {focusedInput} = this.state;

		const props = {
			startDate,
			startDateId: "start-date-id",
			endDate,
			endDateId: "end-date-id",
			onDatesChange,
			showClearDates,
			numberOfMonths,
			focusedInput,
			onFocusChange: this.onFocusChange,
			readOnly: restProps.readOnly || true,
			hideKeyboardShortcutsPanel: restProps.hideKeyboardShortcutsPanel || true,
			navPrev: <Icon icon={ICONS.chevronLeft}/>,
			navNext: <Icon icon={ICONS.chevronRight}/>,
			displayFormat: "D.MM.YYYY",
			startDatePlaceholderText: "від",
			endDatePlaceholderText: "до",
			minimumNights: 0,
			isOutsideRange: (day) => {
				if (focusedInput === "endDate") {
					return isAfterDay(day, startDate?.clone().add(180, 'days')) || isBeforeDay(day, startDate);
				}
				if (focusedInput === "startDate") {
					return isBeforeDay(day, endDate?.clone().subtract(180, 'days')) || isAfterDay(day, endDate);
				}
				return false;
			},
			initialVisibleMonth: () => {
				if (startDate) {
					return moment(startDate);
				}
				return numberOfMonths > 1  ? moment().subtract(1, "M") : moment()
			}
		};

		const filled = startDate !== null || endDate !== null;
		return (
			<div className={cn("ui-date-range-picker", `-months-count-${numberOfMonths}`, {
				filled,
				focused: focusedInput,
				"-left": position === "left",
				"-right": position === "right"
			}, className)}>
				<DateRangePicker {...props} ref={ref => this.picker = ref}/>
				<div className="ui-date-range-placeholder"
						 onClick={() => this.onFocusChange(RANGE_FOCUS.startDate)}>{placeholder}</div>
			</div>
		)
	}
}


export default UIDateRangePicker;
