import React, {useCallback} from 'react';
import moment from 'moment';
import {useHistory, useLocation} from 'react-router-dom';

import {QUERY_DATE_FORMAT} from '../../../../constants';
import {getQueryParams, queryParamsToSearchString} from '../../../../services/RouteService/RouteService';
import getTemplateText from '../../../../services/TextService';

import DateRangePicker from '../../../../components/UI/DateRangePicker/DateRangePicker';

import "./DatePicker.styl";

const DatePicker = () => {
	const location = useLocation();
	const history = useHistory();
	const {to, from, ...other} = getQueryParams(location);

	const onDatesChange = useCallback(({startDate, endDate}) => {
		let params = {};

		if (startDate || endDate || other) {
			params = {
				...other,
				...(startDate ? {from: startDate.format(QUERY_DATE_FORMAT)} : {}),
				...(endDate ? {to: endDate.format(QUERY_DATE_FORMAT)} : {}),
			}
		}
		history.push({
			search: queryParamsToSearchString(params)
		});
	}, [history, other]);

	const dateRangeProps = {
		className: "purchases-period-filters__control",
		startDate: moment(from, QUERY_DATE_FORMAT).isValid() ? moment(from, QUERY_DATE_FORMAT) : null,
		endDate: moment(to, QUERY_DATE_FORMAT).isValid() ? moment(to, QUERY_DATE_FORMAT) : null,
		onDatesChange: onDatesChange,
		showClearDates: true,
		endDatePlaceholderText: getTemplateText("datePicker.end_date"),
	};

	return <DateRangePicker {...dateRangeProps}/>;
}

export default DatePicker