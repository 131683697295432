import React, {useState, useEffect, useRef} from 'react'

import {EmailShareButton, TelegramShareButton, ViberShareButton, WhatsappShareButton} from 'react-share'
import Button from '../../../UI/Button/Button';
import {ICONS} from '../../../UI/Icon/enums';
import Icon from '../../../UI/Icon/Icon';

import './Sharer.styl'

const Sharer = ({link, checkNumber, toogleSharer}) => {
	const ref = useRef<any>();
	const checkTitle = `Чек №${checkNumber}`;
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		const handleClick = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				toogleSharer(false);
			}
		};

		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [ref, toogleSharer]);

	const copy = () => {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(link);
			setCopied(true);
		} else {
			const el = document.createElement("input");
			el.value = link;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
			setCopied(true);
		}
	}

	return (
		<div ref={ref} className='sharer-wrapper'>
			<span>Поділитися</span>
			<Button onClick={() => toogleSharer(false)} className='sharer-close-button'>
				<Icon icon={ICONS.cancel_order} />
			</Button>
			<div className='buttons-row'>
				<TelegramShareButton className='social-button' title={checkTitle} url={link} >
					<Icon raw icon={ICONS.telegram} />
					<p>Telegram</p>
				</TelegramShareButton>
				<ViberShareButton className='social-button' title={checkTitle} url={link}>
					<Icon raw icon={ICONS.newViber} />
					<p>Viber</p>
				</ViberShareButton>
				<WhatsappShareButton className='social-button' title={checkTitle} url={link}>
					<Icon raw icon={ICONS.whatsapp} />
					<p>WhatsApp</p>
				</WhatsappShareButton>
				<EmailShareButton
					onClick={() => { }}
					openShareDialogOnClick
					className='social-button' title={checkTitle} url={link} subject={checkTitle}>
					<Icon raw icon={ICONS.email} />
					<p>Email</p>
				</EmailShareButton>
			</div>
			<div onClick={copy} className='copy-link'>
				<span>{link}</span>
				<Icon raw icon={ICONS.copy} />
			</div>
			{copied &&
				<div className='message'>
					Посилання скопійовано
				</div>}
		</div>
	)
}

export default Sharer