import React, {Component} from "react";
import Modal from "react-responsive-modal";
import {graphql} from "react-apollo";
import {compose} from "recompose";

import {getModalLocation, urlTo} from "../../../services/RouteService/RouteService";
import getTemplateText, {renderTemplateTextNode} from "../../../services/TextService";
import {HASH_MODALS, PAGE_TYPE} from "../../../constants";
import Query from "../../../graphql/Query";
import {getPage} from "../../../services/AboutService";

import {HashModalProps} from "../../Modal/ModalInterfaces";
import {HowGetCardProps} from "./types";

import Panel from "../../UI/Panel/Panel";
import Button from "../../UI/Button/Button";
import Link from "../../UI/Link";
import OL from "../../UI/OL/OL";
import BackLink from "../../UI/BackLink/BackLink";
import LinkAll from "../../UI/LinkAll/LinkAll";

import "./HowGetCardModal.styl";


const HowGetCardModal = compose <HowGetCardProps, HashModalProps> (
	graphql(Query.page.query, {
		options: {
			variables:{type: PAGE_TYPE.rules}
		}
	})
)( class HowGetCardModal extends Component <HowGetCardProps, {}> {
	render() {
		const {isOpen, onClose, data: {pages}} = this.props;
		const innerRulesLink = getPage(pages)(PAGE_TYPE.rules).innerRulesLink || "/assets/moneybox_rules_ua.pdf";
		return (
			<Modal open={isOpen} onClose={onClose} center
						 classNames={{
							 "overlay": "modal-overlay",
							 "modal": "modal-window how-get-card-modal",
							 "closeButton": "modal-closeButton",
							 "closeIcon": "modal-closeIcon"
						 }}
			>
				<Panel className="how-get-card__panel">
					<BackLink className="how-get-card__back-link"
										to={getModalLocation(HASH_MODALS.REGISTER)}>Зареєструватися</BackLink>
					<div className="how-get-card__head">
						<h3 className="how-get-card__heading">{getTemplateText("howGetCardModal.heading")}</h3>
						<LinkAll to={innerRulesLink} target="_blank" rel="noopener" className="how-get-card__rules">
							<span>офіційні правила</span>
						</LinkAll>
					</div>
					<hr/>
					<OL className="how-get-card__ol">
						<li>
							{renderTemplateTextNode("howGetCardModal.step_1")}
						</li>
						<li>
							{renderTemplateTextNode("howGetCardModal.step_2")}
						</li>
						<li>
							{renderTemplateTextNode("howGetCardModal.step_3")}
						</li>
					</OL>

					<div className="how-get-card__btn-holder">
						<Button
							def
							className="how-get-card__go-home"
							component={Link}
							to={urlTo()}
							onClick={onClose}
						>НА ГОЛОВНУ</Button>
					</div>
				</Panel>
			</Modal>
		);
	};
});


export default HowGetCardModal;
