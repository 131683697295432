import "./Panel.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";

type PanelPropsType = {
	className?: string;
	component?: string | React.ComponentType<any>;
}

const Panel: FunctionComponent<PanelPropsType> = ({className, component, ...props}) => {
	const Component = component || "div";
	return (<Component {...props} className={cn("panel", className)}/>)
};

export default Panel;