import React, {FunctionComponent} from "react";
import cn from "classnames";

type FormFieldPropsType = {
	className?: string;
	inline?: boolean;
}

const FormField: FunctionComponent<FormFieldPropsType> = ({className, inline, children}) => {
	return (
		<div className={cn("form__field", className, {"form__field--inline": inline})}>{children}</div>
	)
};

export default FormField;