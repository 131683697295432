import React, {Fragment, FC, useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";

import {PersonalProposalsBlockType} from "./types";
import {ProposalItemType} from "../../graphql/types";

import getTemplateText, {FormattedHTMLMessage, renderTemplateTextNode} from "../../services/TextService";
import {splitProposalsByType} from "../../services/ProposalService"
import client from "../../services/apollo";
import {isTargetProject} from "../../utils";
import {ENUM, ERRORS, PROJECT} from "../../constants";
import Mutation from "../../graphql/Mutation";
import {urlTo} from "../../services/RouteService/RouteService";
import {ROUTE} from "../../services/RouteService/enums";
import {ICONS} from "../UI/Icon/enums";

import Intro from "../Intro/Intro";
import ProposalsList from "../ProposalsList/ProposalsList";
import InfoIcon from "../UI/InfoIcon/InfoIcon";
import FormHint from "../Form/FormHint";
import NoData from "../UI/NoData/NoData";
import {ConfirmModal} from "../Modals/ConfirmModal/ConfirmModal";
import ChangeCouponTypeBar from "../ChangeCouponTypeBar/ChangeCouponTypeBar";
import ShowMore from "../UI/ShowMore/ShowMore";
import Icon from "../UI/Icon/Icon";

import "./PersonalProposalsBlock.styl";

const MESSAGE_VISIBILITY_TIME = 5000;

/**
 * Get intro for personal page
 * @param withIntro
 * @param spawnNextCouponDate
 */
function getIntro(withIntro?: boolean, spawnNextCouponDate?: string): JSX.Element | null {
	if (!withIntro || !spawnNextCouponDate) {
		return null;
	}
	const introProps = {
		title: getTemplateText("specialProposals.title"),
		className: "personal-proposals-block-intro",
		hr: true
	};
	return (
		<Intro {...introProps}>
			<div className="intro-text personal-proposals-block__intro-text">
				{renderTemplateTextNode("specialProposals.intro.description")}
			</div>
			{isTargetProject(PROJECT.SILPO) ?
				<div className="personal-proposals-block__intro-content">
					<div>
						{renderTemplateTextNode("specialProposals.newProposals")}&nbsp;
						{moment(spawnNextCouponDate).format("DD.MM.YYYY")}
					</div>
				</div> : null}

		</Intro>
	)
}

/**
 * timer for messages
 */
let timer: any = null;

const PersonalProposalsBlock: FC<PersonalProposalsBlockType> = ({ list, max, couponsActivate, withIntro, showMoreBlock,
																																	spawnNextCouponDate, couponsRefetch}) => {
	const [markedCoupons, setMarkedCoupons] = useState<ProposalItemType[]>([]);
	const [toRecover, setToRecover] = useState<ProposalItemType[]>([]);
	const [isOpenedProposalsModal, setIsOpenedProposalsModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState<any | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const {paper, electronic, gotMore} = splitProposalsByType(list, markedCoupons, max);
	const noPersonalProposals = electronic.length === 0 && paper.length === 0;
	const intro = getIntro(withIntro, spawnNextCouponDate);

	useEffect(() => () => {
		if (timer) {
			clearTimeout(timer);
		}
	}, []);
	/***
	 * Mark coupon
	 * @param coupon
	 */
	const markCoupon = (coupon: ProposalItemType) => {
		if (!!markedCoupons.find(d => d.id === coupon.id)) {
			setMarkedCoupons(markedCoupons.filter(d => d.id !== coupon.id));
		} else {
			setMarkedCoupons([...markedCoupons, coupon]);
		}
		successMessage && setSuccessMessage(null);
		errorMessage && setErrorMessage(null);
	};
	/***
	 * Unmark all coupons
	 */
	const cancelMarkedCoupons = () => {
		setMarkedCoupons([]);
		successMessage && setSuccessMessage(null);
		errorMessage && setErrorMessage(null);
	};
	/***
	 * Coupons to recover by mutation
	 * @param changeAll
	 */
	const getItemsToRecovery = (changeAll) => {
		setIsOpenedProposalsModal(true);
		setToRecover(changeAll ? paper.filter(d => {
			return d.isChangeable
		}) : markedCoupons);
	};
	/***
	 * Clear all messages
	 */
	const clearMessage = () => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			setSuccessMessage(null);
			setErrorMessage(null);
		}, MESSAGE_VISIBILITY_TIME);
	};
	/***
	 * Call on success mutation
	 * @param res
	 */
	const onSuccess = (res) => {
		setIsOpenedProposalsModal(false);
		const count = res.data.changeCouponsType.reduce((count, d) => d.successful ? count + 1 : count, 0);
		const sMessage = <FormattedHTMLMessage
			id="paperProposals.change_type_bar.success_message_electronic" values={{count}}/>;
		setMarkedCoupons([]);
		setSuccessMessage(sMessage);
		setLoading(false);

		couponsRefetch();
		clearMessage();
	};
	/***
	 * Call on error after mutation
	 * @param err
	 */
	const onError = (err) => {
		setIsOpenedProposalsModal(false);
		const {graphQLErrors} = err;
		cancelMarkedCoupons();

		if (graphQLErrors[0].code === ERRORS.CODE_USER_WITHOUT_EMAIL) {
			setErrorMessage(graphQLErrors[0].message);
			setLoading(false);
			clearMessage();
		}
	};
	/***
	 * Recovery proposals
	 */
	const recoveryProposals = () => {
		setLoading(true);

		const convertCoupon = toRecover.map(d => ({
			useType: ENUM.ELECTRONIC,
			couponId: d.id,
			type: ENUM.COUPON,
		}));

		return client.mutate({
			...Mutation.changeCouponType,
			variables: {
				coupons: convertCoupon
			}
		})
			.then(onSuccess)
			.catch(onError)
	};


	return (
		<Fragment>
			{intro}
			{!noPersonalProposals
				?
				<Fragment>
					<div className="personal-proposal-block">
						{electronic.length !== 0 && <div className="electronic-proposals-list">
                <h3 className="special-proposals__list-heading">
									{renderTemplateTextNode("electronicProposals.title")}
                    <InfoIcon className="personal-proposals__electronic -desktop"
                              place="right"
                              event="mouseenter"
                              eventOff="mouseleave">
											{renderTemplateTextNode("electronicProposals.title_hint")}
                    </InfoIcon>
                    <FormHint className="-mobile">
											{renderTemplateTextNode("electronicProposals.title_hint")}
                    </FormHint>
                </h3>
                <div className="personal-proposals_container">
                    <ProposalsList
                        list={electronic}
                        shadows={true}
                        couponsActivate={couponsActivate}
                    />
                </div>
            </div>}

						{paper.length !== 0 && <div className="paper-proposals-list">
                <h2>
									{renderTemplateTextNode("paperProposals.title")}
                    <InfoIcon className="personal-proposals__paper -desktop"
                              event="mouseenter"
                              place="right"
                              eventOff="mouseleave">
											{renderTemplateTextNode("paperProposals.title_hint")}
                    </InfoIcon>
                    <FormHint className="-mobile">
											{renderTemplateTextNode("paperProposals.title_hint")}
                    </FormHint>
                </h2>
                <div className="personal-proposals_container">
                    <ProposalsList
                        list={paper}
                        shadows={true}
                        couponsActivate={couponsActivate}
                        markCoupon={markCoupon}
                    />
                </div>
                <ConfirmModal
                    isOpen={isOpenedProposalsModal}
                    title={"paperProposals.modal.title"}
                    snippet={"paperProposals.modal.snippet"}
                    onSubmit={recoveryProposals}
                    onClose={() => setIsOpenedProposalsModal(false)}
                />

							{(!!markedCoupons.length || successMessage || errorMessage) && (
								<ChangeCouponTypeBar
									listId="paper-proposal-list"
									markedCount={markedCoupons.length}
									cancel={cancelMarkedCoupons}
									changeMarked={() => getItemsToRecovery(false)}
									changeAll={() => getItemsToRecovery(true)}
									successMessage={successMessage}
									error={errorMessage}
									loading={loading}
								/>
							)}
            </div>}
					</div>
					{!!showMoreBlock && gotMore &&
          <ShowMore component={Link} to={urlTo(ROUTE.SPECIAL_PROPOSALS)}>
						{getTemplateText("specialProposals.show_more")}<Icon icon={ICONS.chevronRight}/></ShowMore>}
				</Fragment>
				:
				<NoData className="withoutBefore">
					{renderTemplateTextNode("specialProposals.no_data.no_PP")}
				</NoData>
			}
		</Fragment>
	);
};


export default PersonalProposalsBlock;