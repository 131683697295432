import "./MyContactsNumberForm.styl";

import React, {Component} from "react";
import {compose, withProps} from "recompose";
import {DATA_TYPE, RULE} from "../../services/FormServices/constants";
import FormRow from "../../components/Form/FormRow";
import Icon from "../../components/UI/Icon/Icon";
import Button from "../../components/UI/Button/Button";
import FormField from "../../components/Form/FormField";
import FormInput from "../../components/Form/FormInput";
import {Field, FormikProps, withFormik} from "formik";
import FormError from "../../components/Form/FormError/FormError";
import Form from "../../components/Form/Form";
import {ICONS} from "../../components/UI/Icon/enums";
import {normalizeFields, validate} from "../../services/FormServices/FormService";
import {FORMS} from "../enums";
import {IFormProps} from "../../services/FormServices/interfaces";

type OuterMyContactsNumbersPropsType = {
	initialValues?: object;
	form?: string;
	onDismiss: () => any;
	onSubmit: (values: MyContactsNumberFormValues, formProps: object) => any;
}

export type MyContactsNumberFormValues = {
	comment: string;
	phone: string;
}

type InnerMyContactsNumbersPropsType = IFormProps & FormikProps<MyContactsNumberFormValues>

const fields = {
	comment: {
		name: "comment",
		dataType: DATA_TYPE.phoneTitle,
		rules: [RULE.required],
	},
	number: {
		name: "phone",
		dataType: DATA_TYPE.phone,
	},
};
const MyContactsNumberForm = compose<InnerMyContactsNumbersPropsType & OuterMyContactsNumbersPropsType, OuterMyContactsNumbersPropsType>(
	withProps((props: OuterMyContactsNumbersPropsType) => {
		return {
			fields: normalizeFields(fields, props.form),
		}
	}),
	withFormik<InnerMyContactsNumbersPropsType & OuterMyContactsNumbersPropsType, MyContactsNumberFormValues>({
		mapPropsToValues: ({initialValues}) => {
			return {
				comment: initialValues && initialValues.comment ? initialValues.comment : "",
				phone: initialValues && initialValues.phone ? initialValues.phone : ""
			};
		},
		validate,
		handleSubmit: (values: MyContactsNumberFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false);
		},
	})
)( class MyContactsNumberFormComponent extends Component<InnerMyContactsNumbersPropsType & OuterMyContactsNumbersPropsType, {}> {

	static defaultProps = {
		form: FORMS.MY_CONTACT_NUMBERS
	};

	componentWillUnmount() {
		this.props.resetForm();
	}

	render() {
		const {handleSubmit, onDismiss, isValid, isSubmitting, form, errors} = this.props;

		return (
			<Form className="my-contacts-number-form" onSubmit={handleSubmit} name={form}>
				<FormRow className="inline-row">
					<FormField inline className="inline-row__field field--title">
						<Field component={FormInput} name="comment" inline placeholder="Назва"
									 normalize={value => value.toUpperCase()}/>
					</FormField>
					<FormField inline className="inline-row__field field--number">
						<Field component={FormInput} name="phone" type="phone" inline placeholder="Телефон"/>
					</FormField>
					<div className="inline-row__buttons">
						<Button
							className="inline-row__button"
							type="submit"
							disabled={!isValid || isSubmitting}
						>
							<Icon icon={ICONS.circleCheck}/>
						</Button>
						<Button
							onClick={onDismiss}
							className="inline-row__button"
						>
							<Icon icon={ICONS.circleCross}/>
						</Button>
					</div>
				</FormRow>

				{!!errors && (errors.comment || errors.phone) && <FormError>{errors.comment || errors.phone}</FormError>}
			</Form>
		)
	}
});

export default MyContactsNumberForm;