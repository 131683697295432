import "./InfoModal.styl";

import React, {Component} from "react";
import Modal from "react-responsive-modal";
import {withRouter} from "react-router";

import {getQueryParams} from "../../../services/RouteService/RouteService";
import {FormattedHTMLMessage} from "../../../services/TextService";
import {InfoModalProps} from "../Registration/types";

import Button from "../../UI/Button/Button";


class InfoModal extends Component<InfoModalProps> {

	render() {
		const {location, isOpen, onClose} = this.props,
			query = getQueryParams(location);
		return (
			<Modal
				open={isOpen}
				onClose={onClose}
				center
				classNames={{
					"overlay": "modal-overlay",
					"modal": "modal-window info__dialog",
					"closeButton": "modal-closeButton",
					"closeIcon": "modal-closeIcon"
				}}
			>
				<div className={"info-modal__wrapper"}>
					<div className={"info-modal__content"}>
						<h3>{query && query.title ? query.title : ""}</h3>
						<p>{query && query.message ? query.message : ""}</p><br/>
						<Button primary className="btn-proceed button-ok" onClick={onClose}>
							<FormattedHTMLMessage id="common.modalWindow.button.good"/>
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}


export default withRouter(InfoModal);