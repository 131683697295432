import React from "react";
import reducer from "../context/reducers";
import {Store, initialState} from "../context/context";
import actions from "../context/actions";

let isInitialized = false;

export function StoreProvider(props) {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const value = {state, dispatch};

	if (!isInitialized) {
		dispatch(actions.init(dispatch));
		isInitialized = true;
	}

	return (
		<Store.Provider value={value}>
			{props.children}
		</Store.Provider>
	)
}