export enum ICONS {
	accountDots = "icon-account-dots",
	aimbulance= "icon-aimbulance",
	appleShape= "icon-apple-shape",
	applePay= "icon-apple-pay",
	arrowRight= "icon-arrow-right",
	addressPointer = "icon-address-pointer",
	assistantHeadphones="icon-assistant-headphones",
	breadCrumbsArrow= "icon-bread-crumbs-arrow",
	basket= "icon-basket",
	basketBold= "icon-basket-bold",
	bonuses= "icon-bonuses",
	calendar= "icon-calendar",
	caret= "icon-caret",
	card= "icon-card",
	credit= "icon-credit-card",
	cartBlockBasket= "icon-cart-block-basket",
	cartBlockOrder= "icon-cart-block-order",
	check= "icon-check",
	checkmark = "icon-checkmark",
	checkbox= "icon-checkbox",
	chevronDown= "icon-chevron-down",
	chevronLeft= "icon-chevron-left",
	chevronRight= "icon-chevron-right",
	circleCheck= "icon-circle-check",
	circleCross= "icon-circle-cross",
	circlePlus= "icon-circle-plus",
	circleSlash= "icon-circle-slash",
	circleInfo= "icon-circle-info",
	clock= "icon-clock",
	clockThin= "icon-clock-thin",
	cross= "icon-cross",
	crossThin= "icon-cross-thin",
	cup= "icon-cup",
	copy= "icon-copy",
	delete= "icon-delete",
	download= "icon-download",
	exit= "icon-exit",
	edit= "icon-edit",
	email= "icon-email",
	facebook= "icon-facebook",
	facebookCircle= "icon-facebook-circle",
	faqArrow= "icon-faq-arrow",
	faqThumbDown= "icon-faq-thumb-down",
	faqThumbUp= "icon-faq-thumb-up",
	fb= "icon-fb",
	file= "icon-file",
	filterList= "icon-filter-list",
	filterMap= "icon-filter-map",
	filters= "icon-filters",
	filter= "icon-filter",
	forKids= "icon-for-kids",
	googlePay= "icon-google-pay",
	instagram="icon-instagram",
	info= "icon-info",
	invoice= "icon-invoice",
	kidsTrademark= "icon-kids-trademark",
	leftArrow= "icon-left-arrow",
	mail= "icon-mail",
	marker= "icon-marker",
	marker_2= "icon-marker-2",
	markerOutline= "icon-marker-outline",
	menu= "icon-menu",
	more= "icon-more",
	moneybox = "icon-moneybox",
	paymentCard= "icon-payment-card",
	paymentCash= "icon-payment-cash",
	paymentPickup= "icon-payment-pickup",
	paymentTerminal= "icon-payment-terminal",
	pencil= "icon-pencil",
	phone= "icon-phone",
	press= "icon-press",
	priceCut= "icon-price-cut",
	print= "icon-print",
	productPlacement= "icon-product-placement",
	productPlacementNo= "icon-product-placement-no",
	productPlacementWarning= "icon-product-placement-warning",
	rightArrow= "icon-right-arrow",
	receipt= "icon-receipt",
	search= "icon-search",
	selectArrow= "icon-select-arrow",
	smallCalendar= "icon-small-calendar",
	successRepeat= "icon-success-repeat",
	star= "icon-star",
	storeClock= "icon-store-clock",
	storeMan= "icon-store-man",
	storeMarker= "icon-store-marker",
	share= "icon-share",
	telegramCircle= "icon-telegram-circle",
	ticket= "icon-ticket",
	trashBucket= "icon-trash-bucket",
	telegram= "icon-telegram",
	tw= "icon-tw",
	twitter= "icon-twitter",
	upload= "icon-upload",
	userAccount= "icon-user-account",
	viber= "icon-viber",
	newViber= "icon-new-viber",
	restore= "icon-restore",
	lockOpened= "icon-lock-opened",
	lockClosed= "icon-lock-closed",
	emailHotLine= "icon-email-hot-line",
	emailHotLineFora= "icon-email-hot-line-fora",
	moneyCoins= "icon-money-coins",
	bus= "icon-bus",
	disability= "icon-disability",
	order_repeat= "icon-mo-repeat",
	order_comment= "icon-mo-comment",
	download_order= "icon-mo-download",
	cancel_order= "icon-mo-cancel",
	phoneEmpty= "icon-phone-empty",
	youtubeLogo= "icon-youtube-logo",
	note= "icon-note",
	timerClock= "icon-timer-clock",
	portfolio= "icon-portfolio",
	iconProposalMap= "icon-proposal-map",
	faq= "icon-faq",
	facebookCircleFora= "icon-facebook-circle-fora",
	instagramFora="icon-instagram-fora",
	telegramCircleFora= "icon-telegram-circle-fora",
	viberFora="icon-viber-fora",
	closedEye="icon-closed-eye",
	openedEye="icon-open-eye",
	downloadQr="icon-welcome-download",
	emailQr="icon-welcome-e-mail",
	person="icon-person",
	mobileApp="icon-mobile-app",
	moreBtn= "icon-more-btn",
	percentSign = "icon-percent-sign",
	coins = "icon-coins",
	label= "icon-label",
	minus="icon-minus",
	plus="icon-plus",
	transfer="icon-transfer",
	tap="icon-tap",
	dislike="icon-dislike",
	like="icon-like",
	whatsapp= "icon-whatsapp",
	warning= "icon-warning"
}

export enum SHAPE  {
	circle= "circle",
}

export enum THEME {
	brown= "brown",
	orange= "orange",
	violet= "violet",
	cyan= "cyan",
	green= "green",
	ltgreen= "ltgreen",
	olive= "olive",
}