import "./ChangePasswordForm.styl";


import React from "react";
import {compose, withProps} from "recompose";
import {Field, FormikProps, withFormik} from "formik";

import {FORMS} from "../enums";
import {DATA_TYPE, RULE} from "../../services/FormServices/constants";
import {checkPasswordIdentity, normalizeFields, validate} from "../../services/FormServices/FormService";
import {IFormProps} from "../../services/FormServices/interfaces";
import FormComponent from "../../components/Form/FormComponent";
import {getUniqueId} from "../../utils";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import Form from "../../components/Form/Form";
import FormField from "../../components/Form/FormField";
import FormInput from "../../components/Form/FormInput";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import Icon from "../../components/UI/Icon/Icon";
import {ICONS} from "../../components/UI/Icon/enums";
import Button from "../../components/UI/Button/Button";
import FormRow from "../../components/Form/FormRow";
import FormError from "../../components/Form/FormError/FormError";

export type ChangePasswordFormValues = {
	oldPassword: string;
	password: string;
	passwordRe: string;
	submitError: string;
	formError: string;
}

type OuterChangePasswordPropsType = {
	onSubmit: (values:ChangePasswordFormValues, formProps: any) => any;
	showSuccess: boolean;
}

type ChangePasswordFormType = {
	id: string;
}

type InnerChangePasswordFromProps = FormikProps<ChangePasswordFormValues> & IFormProps;

const form = FORMS.CHANGE_PASSWORD_FORM,
	fields = {
		oldPassword: {
			name: "oldPassword",
			rules: [RULE.required]
		},
		newPassword: {
			name: "password",
			dataType: DATA_TYPE.password,
		},
		newPasswordRe: {
			name: "passwordRe",
			dataType: DATA_TYPE.password,
		},
	};


const ChangePasswordForm = compose<InnerChangePasswordFromProps & OuterChangePasswordPropsType, OuterChangePasswordPropsType>(
	withProps(() => ({
		form,
		fields: normalizeFields(fields, form),
		formValidate: checkPasswordIdentity
	})),
	withFormik<IFormProps & OuterChangePasswordPropsType, ChangePasswordFormValues>({
		mapPropsToValues: () => {
			return {
				oldPassword: "",
				password: "",
				passwordRe: "",
				submitError: "",
				formError: ""
			};
		},
		validate,
		handleSubmit: (values: ChangePasswordFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
			formProps.setSubmitting(false);
		},
	})
)(class ChangePasswordForm extends FormComponent<InnerChangePasswordFromProps & OuterChangePasswordPropsType, {}> implements ChangePasswordFormType {

	id: string = getUniqueId();

	componentWillUnmount() {
		this.props.resetForm();
	}

	render() {
		const {isSubmitting, handleSubmit, dirty, errors, showSuccess, isValid, form} = this.props;
		const btnProps = {
			className: "form__footer-btn btn-submit",
			gray: true,
			type: "submit",
			disabled: Object.keys(errors).length > 0 || isSubmitting || !dirty || !isValid,
			loading: isSubmitting,
			children: renderTemplateTextNode(this.getMessageId("btn")),
		};

		const error = !!errors && (errors.submitError || errors.formError || "");

		return (
			<Form onSubmit={handleSubmit} className="change-password__form" name={form}>
				<div className="form__body">
					<h3>
						{renderTemplateTextNode(this.getMessageId("heading"))}
						</h3>
					<hr/>
					<div className="form__text">
						{renderTemplateTextNode(this.getMessageId("heading_text"))}
					</div>


					<FormRow>
						<FormField>
							<Field name="oldPassword"
										 component={FormInput}
										 type="oldPassword"
										 label={getTemplateText(this.getMessageId("label.old_password"))}
							/>
						</FormField>
						<FormField/>
					</FormRow>

					<FormRow>
						<FormField>
							<Field
								name="password"
								component={FormInput}
								type="password"
								label={getTemplateText(this.getMessageId("label.new_password"))}
								data-tip data-for={this.id}
							/>
							<Tooltip
								id={this.id}
								event="focus"
								eventOff="blur"
								place="right">
								<div className="tooltip__change-password">
									{renderTemplateTextNode("hint.password")}
								</div>
							</Tooltip>
						</FormField>
						<FormField>
							<Field name="passwordRe"
										 component={FormInput}
										 type="password"
										 label={getTemplateText(this.getMessageId("label.new_password_re"))}/>
						</FormField>
					</FormRow>
				</div>

				<div className="form__footer">
					<div className="form__footer-status">
						{!!error && <FormError className="form__footer-error">{error}</FormError>}

						{showSuccess && (
							<div className="form__footer-success">
								<Icon icon={ICONS.circleCheck}/>
								{getTemplateText("form.success")}
							</div>
						)}
					</div>
					<Button {...btnProps}/>
				</div>
			</Form>
		)
	}
});

export default ChangePasswordForm;