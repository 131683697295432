import React, {Component} from "react";
import cn from "classnames";

import {getElementTop, getWindowScroll, parseErrorOnfly} from "../../utils";
import {FormattedHTMLMessage} from "../../services/TextService";
import {ICONS} from "../UI/Icon/enums";
import {ChangeCouponTypeBarPropsType, ChangeCouponTypeBarStateType} from "./types";

import FormError from "../Form/FormError/FormError";
import Icon from "../UI/Icon/Icon";
import Button from "../UI/Button/Button";

import "./ChangeCouponTypeBar.styl";

class ChangeCouponTypeBar extends Component<ChangeCouponTypeBarPropsType, ChangeCouponTypeBarStateType> {
	barNode: HTMLElement | null = null;

	constructor(props) {
		super(props);
		this.state = {
			fixed: false
		};
	}

	componentDidMount() {
		this.updateBarPosition();
		window.addEventListener("scroll", this.updateBarPosition)
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.updateBarPosition)
	}

	updateBarPosition = () => {
		const {fixed} = this.state;
		const {windowHeight, scrollTop, scrollLeft} = this.getWindowDimensions();
		const {height, top, barHeight} = this.getListDimensions();

		const triggerTop = top - windowHeight / 2 - barHeight,
			triggerBottom = top + height - windowHeight + barHeight;

		if (triggerTop < scrollTop && scrollTop < triggerBottom) {
			if (!fixed) {
				this.setState({fixed: true})
			}
		} else {
			if (fixed) {
				this.setState({fixed: false})
			}
		}
		try {
			if (this.barNode) {
				this.barNode.scrollLeft = scrollLeft;
			}
		} catch (err: any) {
			parseErrorOnfly(err);
		}
	};

	getListDimensions = () => {
		const {listId} = this.props,
			listEl = document.getElementById(listId);
		let height = 0, top = 0, barHeight = 0;

		if (listEl) {
			const rect = listEl.getBoundingClientRect();
			height = rect.height;
			top = getElementTop(listEl);
		}

		if (this.barNode && this.barNode.getBoundingClientRect) {
			barHeight = this.barNode.getBoundingClientRect().height;
		}
		return {
			top,
			height,
			barHeight
		}
	};

	getWindowDimensions = () => {
		const scroll = getWindowScroll() || {top: 0, left: 0};
		return {
			windowHeight: window.innerHeight,
			scrollTop: scroll.top,
			scrollLeft: scroll.left,
		}
	};

	setBarRef = (node) => {
		this.barNode = node;
	};

	render() {
		const {fixed} = this.state;
		const {markedCount, successMessage, error, loading} = this.props;
		const {cancel, changeMarked, changeAll} = this.props;

		let rightBlock: JSX.Element | null = null;

		if (error) {
			rightBlock = (
				<FormError className="change-coupon-type-bar__right-part">
					<div className="change-coupon-type-bar__message">
						{error}
					</div>
				</FormError>
			)
		} else {
			rightBlock = (
				successMessage ? (
					<div className="change-coupon-type-bar__message">
						{successMessage}
						<Icon icon={ICONS.check}/></div>
				) : (
					<div className="change-coupon-type-bar__right-part">
						<Button
							className="change-coupon-type-bar__btn-change-selected"
							onClick={changeMarked}
							primary
							disabled={loading}
						>
							<FormattedHTMLMessage id="paperProposals.change_type_bar.btn_marked"
																		values={{count: markedCount}}/>
						</Button>

						<Button
							className="change-coupon-type-bar__btn-change-all"
							onClick={changeAll}
							primary
							disabled={loading}
						>
							<FormattedHTMLMessage id="paperProposals.change_type_bar.btn_all"/>
						</Button>
					</div>
				)
			)
		}

		return (
			<div className="change-coupon-type-bar-dummy">
				<div className={cn("change-coupon-type-bar", {fixed})} ref={this.setBarRef}>
					<div className="change-coupon-type-bar__body">
						<div className="change-coupon-type-bar__content">
							{!successMessage && !error &&
							<Button
								className="change-coupon-type-bar__btn-cancel"
								onClick={cancel}
								def outline
							>
								<FormattedHTMLMessage id="paperProposals.change_type_bar.btn_cancel"/>
							</Button>
							}

							{rightBlock}

						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default ChangeCouponTypeBar;