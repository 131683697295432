import React, {FC} from 'react'
import {Formik, FormikHelpers} from 'formik';
import {useMutation} from '@apollo/react-hooks';

import {ENUM} from '@constants';
import Mutation from '@graphql/Mutation';
import {handleValidate, initialValid, initialValues} from './const';
import {getResponseFields} from '../helpers';

import {FormattedHTMLMessage} from '@services/TextService';
import {handleFormErrors} from '@services/FormServices/FormService';
import {checkRecapture} from '@services/RecaptchaService';

import PhoneEntranceForm from './PhoneEntranceForm';
import {PhoneEntranceFormValuesType} from './types';
import {ValidateLoginResponseDataType, ValidateLoginVariablesType} from '@graphql/types';
import {AuthFormPropsType, EntrancePropsType} from '../types';

import "./PhoneEntrance.styl"

const PhoneEntrance: FC<AuthFormPropsType & EntrancePropsType> = (props) => {
	const {determineNextStep, entranceType = "LOYALTY"} = props;
	const [validateLogin] = useMutation<ValidateLoginResponseDataType, ValidateLoginVariablesType>(Mutation.validateLogin.mutation);

	const handleSubmit = (values: PhoneEntranceFormValuesType, actions: FormikHelpers<PhoneEntranceFormValuesType>) => {
		return checkRecapture()
			.then(ckey => {
				return validateLogin({
					variables: {
						type: ENUM.PHONE,
						value: values.phone.replace(/\s/g, ""),
						entranceType,
						ckey
					}
				})
					.then(({data = {}, errors}) => {
						if (errors) {
							throw errors;
						}
						const validateLogin = data.validateLogin;
						if (validateLogin && !errors) {
							const {flowId, nextStep, contactValue, contactType} = getResponseFields(validateLogin);
							localStorage.setItem("authData", JSON.stringify({flowId, nextStep, "phone": contactValue || values.phone, contactType}));
							actions.setSubmitting(false);
							determineNextStep(nextStep);
						}
					})
					.catch((err) => {handleFormErrors(err, actions)})
			})
	}

	return (
		<>
			<div className="modal_description">
				<FormattedHTMLMessage id="initial-entrance.modalWindow.description-phone" />
			</div>
			<Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={initialValid} validate={handleValidate}>
				<PhoneEntranceForm />
			</Formik>
		</>
	)
}

export default PhoneEntrance;