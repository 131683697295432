import React, {Component} from "react";
import {graphql} from "react-apollo";

import moment from "moment";
import {compose, Omit} from "recompose";

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {DATE_FORMAT} from "../../services/FormServices/constants";
import {formatBirthday, isObject} from "../../utils";
import Panel from "../UI/Panel/Panel";
import {GraphQLMutationType, meDetailedResponseType} from "../../graphql/types";
import PersonalInformationForm, {PersonalInformationFormValues} from "../../forms/PersonalInformationForm/PersonalInformationForm";
import {normalizeName, parseFormError} from "../../services/FormServices/FormService";

import "./PersonalInformation.styl";

type PersonalInformationType = {
	timer: ReturnType<typeof setTimeout> | null;
}

type PersonalInformationStateType = {
	showSuccess: boolean;
}

type InnerPersonalInformationPropsType = GraphQLMutationType &  {
	meData: meDetailedResponseType
}

const PersonalInformation = compose<InnerPersonalInformationPropsType, {}>(
	graphql(Query.meDetailed.query, {name: "meData"}),
	graphql(Mutation.changePersonalData.mutation),
)(class PersonalInformation extends Component<InnerPersonalInformationPropsType, PersonalInformationStateType> implements PersonalInformationType {

	timer: ReturnType<typeof setTimeout> | null;

	constructor(props) {
		super(props);
		this.state = {
			showSuccess: false
		};

		this.timer = null;
	}

	componentWillUnmount() {
		if (this.timer) clearTimeout(this.timer);
	}

	/**
	 * handles on submit
	 * @param values
	 * @param formProps
	 */
	onSubmit = (values: Omit<PersonalInformationFormValues, "submitError">, formProps) => {
		const {firstName, gender, lastName, middleName, birthday} = values;

		const variables = {
			birthday: birthday,
			firstName: normalizeName(firstName),
			gender: gender,
			lastName: normalizeName(lastName),
			middleName: normalizeName(middleName)
		};

		return this.props.mutate({
			variables: {
				personalData: {
					...variables,
					birthday: variables.birthday ? formatBirthday(variables.birthday) : null,
				}
			}
		})
			.then(r => {
				if (typeof r === "object" && 'data' in r) {
					if (r.data && r.data.changePersonalData) {
						this.showSuccessMessage();
						this.props.meData.refetch();
					}
				}
			})
			.catch(err => {
				formProps.setErrors(parseFormError(err));
			})
	};


	/**
	 * shows success message
	 */
	showSuccessMessage = () => {
		this.setState({showSuccess: true});
		this.activateSuccessTimer();
	};

	/**
	 * activates success timer
	 */
	activateSuccessTimer = () => {
		this.timer = setTimeout(() => {
			if (this.timer) clearTimeout(this.timer);
			this.setState({showSuccess: false})
		}, 4E3);
	};

	/**
	 * get initial values for personal info form
	 */
	getInitialValues = () => {
		const {meData: {me}} = this.props;
		let res = {};

		if (isObject(me)) {
			const {birthday="", firstName="", lastName="", middleName="", gender=""} = me;
			const bDay = moment(birthday);
			res = {
				firstName, lastName, middleName, gender,
				birthday: bDay.isValid() ? bDay.format(DATE_FORMAT) : "",
			}
		}

		return res;
	};

	render() {
		const initialValues: Partial<PersonalInformationFormValues> = this.getInitialValues();
		return (
			<Panel className="my-info__personal-information">
				<PersonalInformationForm
					initialValues={initialValues}
					onSubmit={this.onSubmit}
					showSuccess={this.state.showSuccess}
				/>
			</Panel>
		)
	}
});

export default PersonalInformation;
