import React, {FC, useEffect, useState} from 'react'
import classNames from 'classnames';

import {ICONS} from '../../../../../../components/UI/Icon/enums';
import Icon from '../../../../../../components/UI/Icon/Icon';
import {PRODUCT_STATE} from '../../../../../../constants';
import {SHOP_HOST} from '@services/EnvServices';
import {useCheckImageUrl} from '../../../../../../utils';
import getTemplateText from '../../../../../../services/TextService';
import {PurchaseProductItemPropsType} from './types';

import './PurchaseProducts.styl';

const deliveryImg = '/images/fora/img-fora-delivery.svg';
export const DELIVERY_ID = 839624;

const PurchaseProductItem: FC<PurchaseProductItemPropsType> = ({product, filialId, deliveryType, removeFromSelected, isSelectingMode, addToSelected}) => {
	const {number, imageUrls, name, actualCount, orderCount, actualSum, orderSum, unit, lineStateId, hint, slug} = product;
	const previewImage = useCheckImageUrl(Object.values(imageUrls)[0]);
	const qtyUnit = unit === "кг" || unit === "шт";
	const isDeleted = lineStateId === PRODUCT_STATE.DELETED;
	const isChanged = lineStateId === PRODUCT_STATE.CHANGED;
	const isAdded = lineStateId === PRODUCT_STATE.ADDED;
	const isDeliveryItem = number === DELIVERY_ID;
	const [isChecked, setIsChecked] = useState(true);

	const productUrl = `${SHOP_HOST}${deliveryType === 1 ? `/rfi/${slug}/${filialId}/1` : `/product/${slug}`}`;

	useEffect(() => {
		if (isSelectingMode) {
			setIsChecked(true);
		}
	}, [isSelectingMode])

	const openProduct = (e) => {
		e.preventDefault();
		if (!isDeliveryItem && !isSelectingMode) {
			window.open(productUrl, '_blank');
		} else if (isSelectingMode) {
			handleChange();
		}
	}

	const handleChange = () => {
		if (isChecked) {
			removeFromSelected(product);
		} else {
			addToSelected(product);
		}
		setIsChecked(prev => !prev)
	}

	return (
		<>
			<li onClick={openProduct} className={classNames('purchase-product-wrapper', {isDeliveryItem})}>
				{!isDeliveryItem && isSelectingMode && <>
					<input id={`checkbox-${product.number}`} className='item-checkbox' type="checkbox" onChange={handleChange} checked={isChecked} />
					<label htmlFor={`checkbox-${product.number}`} className='item-checkbox-label' />
				</>}
				<img alt="urchase-product-img" className={classNames('purchase-product-img', {isDeleted})} src={isDeliveryItem ? deliveryImg : previewImage} />
				<div className='purchase-product-info'>
					<div className='purchase-product-info-row'>
						<span className={classNames('title', {isDeleted})}>
							{isAdded && <span>{getTemplateText("myPurchases.order.item.added")}</span>}
							{name}
						</span>
						{isDeleted && <span className='status'>{getTemplateText("myPurchases.order.item.deleted")}</span>}
					</div>
					<div className={classNames('purchase-product-info-row', {isDeleted})}>
						<span className='unit'>
							{actualCount || orderCount} {!qtyUnit ? "шт" : unit}
							{hint && isChanged && <>
								<Icon className="hint" raw icon={ICONS.info} />
								<span className='hint-text'>{hint}</span>
							</>}
						</span>
						<span className='price'>{actualSum || orderSum}{getTemplateText("myPurchases.order.payment.uah")}</span>
					</div>
				</div>
			</li>
		</>
	)
}

export default PurchaseProductItem
