import React, {useCallback, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom';

import {getQueryParams, queryParamsToSearchString} from '../../../../services/RouteService/RouteService';
import usePurchaseStatistic from '../hooks/usePurchaseStatistic';

import {PURCHASE_STATUS} from '../Filters/const';
import Icon from '../../../../components/UI/Icon/Icon';
import {ICONS} from '../../../../components/UI/Icon/enums';
import Loader from '../../../../components/UI/Loader/Loader';

import {StatisticItemType} from '../hooks/usePurchaseStatistic/types';
import "./FiltersHeader.styl";

const FiltersHeader = () => {
	const location = useLocation();
	const history = useHistory();
	const {type, ...other} = getQueryParams(location);
	const {status} = other;
	const currentTabIndex = type ? +type + 1 : 0;
	const {items, loading} = usePurchaseStatistic();
	const [activeTab, setActiveTab] = useState(currentTabIndex);

	const handleTabClick = useCallback((tab: StatisticItemType, index: number) => () => {
		const {id} = tab;
		setActiveTab(index);
		const {status: _, ...otherFiltered} = other;
		history.push({
			search: queryParamsToSearchString({
				...(id !== null ? {type: id.toString()} : {}),
				...otherFiltered,
			})
		});
	}, [other, history]);

	const handleStatusClear = useCallback(() => {
		const currentTab = items.find(item => item.id === +type) || items[0];
		handleTabClick(currentTab, currentTabIndex)();
		// eslint-disable-next-line
	}, [type, currentTabIndex, handleTabClick]);

	if (loading) {
		return <Loader />
	}

	return (
		<div>
			<div className="filters-header">
				{items.map((item: StatisticItemType, index) =>
					<button
						key={index}
						className={activeTab === index ? 'tab-item active' : 'tab-item'}
						onClick={handleTabClick(item, index)}>
						{item.stateText}
						<span className='tab-item_counter'>{item.count}</span>
					</button>
				)}
			</div>
			{!!+status &&
				<div onClick={handleStatusClear} className='filters-chips'>
					{PURCHASE_STATUS.find(item => item.id === +status)?.title}
					<Icon raw icon={ICONS.cancel_order} />
				</div>}
		</div>
	)
}

export default FiltersHeader