import {FORMS} from "js/forms/enums";
import {DATA_TYPE, RULE} from "@services/FormServices/constants";
import {OtpFormValuesType} from "./types";
import {normalizeFields, validate} from '@services/FormServices/FormService';

export const initialValues: OtpFormValuesType = {
	otp: "",
	submitError: ""
}

export const fields = {
	otp: {
		name: "otp",
		dataType: DATA_TYPE.otp,
		params: {
			[RULE.invalid]: (value = "") => value.length !== 4 && RULE.invalid
		},
	}
};


export const form = FORMS.OTP_FORM;

export const initialValid = (props) => {
	const errors = validate(props.initialValues, {fields: normalizeFields(fields, form), form});
	return Object.keys(errors).length === 0;
}

export const handleValidate = (values: OtpFormValuesType) =>
	validate(values, {fields: normalizeFields(fields, form), form})