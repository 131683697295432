import React, {Component} from "react";
import {graphql} from "react-apollo";
import {compose, Omit} from "recompose";

import Mutation from "../../graphql/Mutation";
import ChangePasswordForm, {ChangePasswordFormValues} from "../../forms/ChangePasswordForm/ChangePasswordForm";
import Panel from "../UI/Panel/Panel";
import {parseFormError} from "../../services/FormServices/FormService";

import {ChangePasswordType, ChangePasswordStateType, InnerChangePasswordPropsType} from "./types";

const NOT_DISPLAY = true;

const ChangePassword = compose<InnerChangePasswordPropsType, {}>(
	graphql(Mutation.changePassword.mutation),
)(class ChangePassword extends Component<InnerChangePasswordPropsType, ChangePasswordStateType> implements ChangePasswordType {

	timer: ReturnType<typeof setTimeout> | null;

	constructor(props) {
		super(props);
		this.state = {
			showSuccess: false
		};

		this.timer = null;
		NOT_DISPLAY && window.location.replace("/");
	}

	componentWillUnmount() {
		if (this.timer) clearTimeout(this.timer);
	}

	/**
	 * on submit handler
	 * @param values
	 * @param formProps
	 */
	onSubmit = (values: Omit<ChangePasswordFormValues, "submitError">, formProps) => {
		return this.props.mutate({
			variables: {
				oldPassword: values.oldPassword,
				newPassword: values.password
			}
		})
			.then(r => {
				if (typeof r === "object" && 'data' in r) {
					if (r.data.changePassword) {
						this.showSuccessMessage();
						if (r.data.changePassword) {
							this.showSuccessMessage();
							formProps.resetForm();
						}
					}
				}
			})
			.catch(err => {
				formProps.setErrors(parseFormError(err));
			});
	};

	showSuccessMessage = () => {
		this.setState({showSuccess: true});
		this.activateSuccessTimer();
	};

	activateSuccessTimer = () => {
		this.timer = setTimeout(() => {
			if (this.timer) clearTimeout(this.timer);
			this.setState({showSuccess: false})
		}, 4E3);
	};


	render() {
		if (NOT_DISPLAY) {
			return null;
		}
		return (
			<Panel className="my-info__change-password">
				<ChangePasswordForm
					onSubmit={this.onSubmit}
					showSuccess={this.state.showSuccess}
				/>
			</Panel>
		)
	}
});

export default ChangePassword;