import {useMutation} from 'react-apollo';
import {FormikValues, useFormikContext} from 'formik';

import {getResponseFields, getStoredAuthData} from '../../helpers';
import Mutation from '@graphql/Mutation';
import {handleFormErrors} from '@services/FormServices/FormService';

import {GoToOtpLoginResponseType, GoToOtpLoginVariablesType} from '@graphql/types';

const useSendGoToOtpLogin = () => {
	const {flowId, phone} = getStoredAuthData();
	const {resetForm, ...otherProps} = useFormikContext<FormikValues>();
	const [goToOtpLogin] = useMutation<GoToOtpLoginResponseType, GoToOtpLoginVariablesType>(Mutation.goToOtpLogin.mutation);

	const sendGoToOtpLogin = () => {
		return goToOtpLogin({variables: {flowId}})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const goToOtpLogin = data.goToOtpLogin;
				if (goToOtpLogin && !errors) {
					const {nextStep, flowId, contactType, contactValue} = getResponseFields(goToOtpLogin);
					localStorage.setItem("authData", JSON.stringify({nextStep, flowId, [contactType.toLowerCase()]: contactValue || phone, contactType}));
				}
				resetForm();
			})
			.catch((err) => {
				resetForm();
				handleFormErrors(err, otherProps);
			})
	}

	return sendGoToOtpLogin;
}

export default useSendGoToOtpLogin