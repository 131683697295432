import {MutateProps, QueryControls} from "react-apollo";
import {CardType} from "../pages/SettingsPage/MyCards/types";
import {AUTH_NEW_ENTRANCE_ACTIONS} from "../constants";

export type meDetailed = {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	gender: string;
	email: string;
	birthday: string;
	mobilePhoneCode: string;
	mobilePhoneNumber: string;
	homePhoneCode: string;
	homePhoneNumber: string;
	certificateDeliveryType: string;
}

export type me = {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email:string;
	emailConfirmed:boolean;
	currentBonus: number;
	currentBalance: number;
	notification: string;
	barcode: string;
	spawnNextCouponDate: string;
	fullPhoneNumber: string;
}

export type phoneNumber = {
	phoneCode: string;
	phoneNumber: string;
	position: number;
	comment: string;
}

export type AddressType = {
	region: string;
	district: string;
	city: string;
	cityType: string;
	shortCityType: string;
	cityDistrict: string;
	subCity: string;
	subCityDistrict: string;
	street: string;
	streetType: string;
	buildingNumber: string;
	apartment: string;
	isPrivateHome: boolean;
	postIndex: string;
	cityArea: string;
	subCityArea: string;
}

export type MailBoxType = {
	postIndex: string;
	postBoxNumber: string;
}

export type meDetailedAddress = {
	id: number;
	addressType: "MAILBOX" | "PRIVATE_HOME" |"FLAT";
	address: AddressType;
	mailBox: MailBoxType;
}

export type GraphQLResponseType = QueryControls;

export type GraphQLMutationType = MutateProps;

export type meDetailedResponseType = GraphQLResponseType & {
	me: meDetailed;
}
export type meResponseType = GraphQLResponseType & {
	me: me;
}
export type phonesDataResponseType = GraphQLResponseType & {
	phoneNumbers: phoneNumber[];
}

export type FamilyDataResponseType = GraphQLResponseType & {
	family: {
		id: string;
		persons: FamilyMember[];
	};
}

export type meDetailedAddressResponseType = GraphQLResponseType & {
	me: meDetailedAddress;
}

export type FamilyMember = {
	id: string;
	birthday: string;
	firstName: string;
	gender: string;
	lastName: string;
	middleName: string;
}

export type meDetailedOtherDataResponseType = GraphQLResponseType & {
	me: DetailedUserOtherData
}

export type OtherDataResponseType = GraphQLResponseType & {
	animals: OtherDataKeyValue[];
	habits: OtherDataKeyValue[];
}

export type DetailedUserOtherData = {
	id: string,
	otherData: {
		hasCar: boolean;
		relationshipStatus: string;
		allergy: string;
		animals: OtherDataKeyValue[];
		dietHabits: OtherDataKeyValue[];
	}
}

export type OtherDataKeyValue = {
	name: string;
	id: string;
}


export type VoucherItem = {
    name: string;
    type: string;
    value: number;
    __typename: string;
}

export type Voucher = {
    id: string;
    value: number;
    useType: string;
    voucherType: number;
    voucherText: string;
    typeDescription: string;
    status: number;
    modifiedAt: Date;
    startedAt: Date;
    endAt: Date;
    items: VoucherItem[];
    isChangeable: boolean;
}

export type VouchersType = {vouchers: Voucher[]}
export type MoneyBoxValueType = {
	balance: number;
	status: "ACTIVATED" | "DEACTIVATED" | "NOT_ACTIVATED";
	title?: string;
}
export type MoneyBoxType = {
	moneyBox: MoneyBoxValueType
}

export type ProposalItemType = {
	id: string;
	isOff: boolean;
	limitDescription: string;
	promoDescription: string;
	rewardValue: number;
	signText: string;
	startedAt: string;
	status: string;
	unitText: string;
	useType: "ELECTRONIC" | "PAPER";
	isChangeable: boolean;
	isCouponControl: boolean;
	listImages: string[];
	listBrands: string[];
	addresses?: CouponAddressType[];
	addressDescription?: any;
	warningDescription?: any;
	marked?: boolean;
	showMark?: boolean;
	archived: boolean;
	couponDescription: string;
	endAt: string;
	multipleUsingEnable:boolean;
	isLike:boolean | null;
	__typename: string;
}

export type CouponAddressType = {
	city: string;
	street: string;
	store: StoreType;
}

export type ProposalsType = {
	coupons: ProposalItemType[];
}
export type ProposalType = {
	coupon: ProposalItemType;
}

//TODO: implement typing for find post index
export type FindIndexResponseType = GraphQLResponseType & {
	findPostIndex: any;
}

export type cardsResponseType = GraphQLResponseType & {
	cards: CardType[];
}

export type StoreType = {
	id: string;
	title: string;
	slug?: string;
	premium?: boolean;
	hasCertificate?: boolean;
	workingHours?: TimePeriodType;
	city: CityType;
	location: LocationType;
	filial_id?: number;
	macroRegion?: MacroRegionType;
	services?: StoreServiceItemType[];
	dayLoads?: DayLoadItemType[];
}



export type DayLoadItemType = {
	from: number;
	to: number;
	traffic: number;
}
export type StoreServiceItemType = {
	enabled: boolean;
	service: StoreServiceType;
}
export type StoreServiceType = {
	id: string;
	title: string;
	icon: any;
}

export type MacroRegionType = {
	pp_id: number;
	name: string;
}

export type LocationType = {
	lng: string | number;
	lat: string | number;
}
export type TimePeriodType = {
	start: string;
	end: string;
}

export type CityType = {
	id: string;
	title: string;
	stores: StoreType;
}

export type MyOrdersListType = {
	orders: MyOrdersOptimizeType[]
}

export type MyOrdersOptimizeType = {
	allowCancel: boolean;
	allowChangeDeliveryTime: boolean;
	allowPaymentOnline: boolean;
	created: string;
	deliveryAddress: string;
	deliveryDateFrom: string;
	deliveryDateTo: string;
	deliveryLatitude: number;
	deliveryLongitude: number;
	deliveryType: number;
	deliveryTypeText: string;
	filialId: number;
	id: string;
	note: string;
	orderNumber: number;
	orderTypeText: string;
	owner: string;
	paid: boolean;
	paymentOnlineSumLimit: number;
	paymentState: string;
	paymentStateText: string;
	paymentType: number;
	paymentTypeText: string;
	state: number;
	stateId: number;
	stateText: string;
	sumOut: number;
	type: number;
	typeId: number;
	actions: string[],
	pdf_download_link: string
}

export type ChecksListType = {
	cheques: CheckOptimizeType[]
}

export type CheckOptimizeType = {
	loyaltyFactId: number;
	sumReg: number;
	sumBalance: number;
	cityName: String
	frId: number;
	zId: number;
	frChequeId: number;
	payType: number;
	starId: number;
	filId: number;
	chequeId: number;
	created: string;
	filialName: string;
	fiscalNumber: string | null
}

export type CheckItem ={
	lagerId: number;
	lagerNameUA: string;
	lagerUnit: string;
	kolvo: number;
	priceOut: number;
	unitText: string;
}

export type CheckReward = {
	rewardTypeId: number;
	applyText: string;
	rewardValue: number;
	goldCouponPromoType: number;
	promoId: number;
	usedText: string;
	usedCountText: string;
	usedUnitText: string;
	signText: string;
	unitText: string;
}

export type CheckDetail = {
	loyaltyFactId: number;
	filId: number;
	created: string;
	sumDiscount: number;
	chequeId: number;
	chequeLines: CheckItem[];
	chequeRewards: CheckReward[];
}

export type getMemberQuestionResponseType = GraphQLResponseType & {
	getMemberQuestion: GetMemberQuestionType;
};

export type GetMemberQuestionType = {
	question: string;
	id: number;
	widgetType: string;
}

export type GetContactsResponseType = GraphQLResponseType & {
	getContacts: Contact[]
}

export type Contact = {
	id: number;
	contactType: string;
	contactHide: string;
}

export type PageType = {
	data: {
		pages:{
			application: any | null;
			properties: {
				balanceContent: string;
				balanceDescription: string;
				balanceSubTitle: string;
				balanceTitle: string;
				benefitItems: {
					description: string;
					title: string;
				}[];
				benefitSubItems: {
					description: string;
					image: string;
				}[];
				benefitSubTitle: string;
				benefitTitle: string;
				color: string;
				innerRulesLink: string;
				innerRulesLinkTitle: string;
				innerSteps: {
					title: string;
				}[];
				innerTitle: string;
				moreLinkTitle: string;
				partnerDescription: string;
				partnerTitle: string;
				rules: {
					title: string;
				}[];
				title: string;
			};
			type: string;
			__typename: string;
		}[];
	}
}

export type getBalanceResponseType = GraphQLResponseType & {
	getBalance: getBalanceDataType;
}

export type getBalanceDataType = {
	balance: number;
	balanceLines: BalanceItemType[];
}

export type BalanceItemType = {
	name?: string;
	balanceName?: string;
	codeName: string;
	balance: number;
}

export type getBonusResponseType = GraphQLResponseType & {
	getBonus: getBonusDataType;
}

export type getBonusDataType = {
	name: string;
	unitText: string;
	balance: string;
	bonusLines: BonusLineType[];
}

export type BonusLineType = {
	balance: number;
	unusedBalance: number;
	filialId: number;
	created: string;
	expired: string;
	bonusStatusId: string;
	loyaltyFactId: string;
	name: string;
	bonusTypeId: string;
	typeLine: string;
	signText: string;
	unitText: string;
	bonusOperations: BonusItemType[];
}

export type BonusItemType = {
	name: string;
	balance: number;
	unitText: string;
	codeName: string;
}

export type FlowResponseDataType = {
	flow: {
		flowId: number;
		currentPlace: string;
		nextStep: AUTH_NEW_ENTRANCE_ACTIONS | null;
		__typename: string;
	}
}

export type ContactUsedForOtpDataType = {
	contactUsedForOtp: {
		contactType: string;
		contactValue: string;
		__typename: string;
	}
}

export type AccessTokenDataType = {
	accessToken: {
		accessToken: string;
		expiresAt: string;
		__typename: string;
	} | null
}

export type ValidateLoginResponseDataType = {
	validateLogin: FlowResponseDataType & ContactUsedForOtpDataType;
}

export type ValidateLoginVariablesType = {
	type: string;
	value: string;
	entranceType: "LOYALTY" | "ECOMMERCE";
	ckey: string;
}

export type AccessCodeVariablesType = {
	flowId: number;
	accessCode: string;
}

export type GetRecoveryContactsVariablesType = {
	flowId: number;
}

export type GetRecoveryContactsResponseType = {
	getRecoveryContacts: {
		id: number;
		contactType: string;
		contactHide: string;
		__typename: string;
	}[]
}

export enum AccessCodeRequestName {
	ConfirmRecoveryAccessCode = "confirmRecoveryAccessCode",
	ConfirmLoginAccessCode = "confirmLoginAccessCode",
	ConfirmAccessCode = "confirmAccessCode"
}
export type AccessCodeResponseType<T extends AccessCodeRequestName> = {
	[key in T]: T extends AccessCodeRequestName.ConfirmRecoveryAccessCode
	? FlowResponseDataType
	: FlowResponseDataType & AccessTokenDataType;
}

export type SetCardPresenceVariablesType = {
	flowId: number;
	card: string;
}

export type SetCardPresenceResponseType = {
	setCardPresence: FlowResponseDataType & ContactUsedForOtpDataType;
}

export type RegisterMemberVariablesType = {
	flowId: number;
	password: string;
	passwordRepeat: string;
	member: {
		firstName: string;
		lastName: string;
		birthday: string;
		email: string;
	}
}

export type RegisterMemberResponseType = {
	registerMember: FlowResponseDataType & AccessTokenDataType;
}

export type LoginVariablesType = {
	flowId: number;
	password: string;
}

export type LoginResponseType = {
	login: FlowResponseDataType & AccessTokenDataType;
}

export type GoToRecoveryVariablesType = {
	flowId: number;
}

export type GoToRecoveryResponseType = {
	goToRecovery: FlowResponseDataType & ContactUsedForOtpDataType;
}

export type RecoverAccessVariablesType = {
	flowId: number;
	password: string;
	email?: string;
}

export type RecoverAccessResponseType = {
	recoverAccess: FlowResponseDataType & AccessTokenDataType;
}

export type SendOtpToContactVariablesType = {
	flowId: number;
	contactId: number;
}

export type SendOtpToContactResponseType = {
	sendOtpToContact: FlowResponseDataType & ContactUsedForOtpDataType;
}

export type GoToOtpLoginVariablesType = {
	flowId: number;
}

export type GoToOtpLoginResponseType = {
	goToOtpLogin: FlowResponseDataType & ContactUsedForOtpDataType;
}