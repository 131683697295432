function getfullName(_user) {
	const user = _user || {};

	return [user.lastName, user.firstName, user.middleName].join(" ");
}

function getNameParts(user) {
	const name = (user || {}).name;
	const parts = name.split(" ");
	return {
		lastName: parts[0] || "",
		firstName: parts[1] || "",
		middleName: parts[2] || "",
	}
}

function isUserAuthenticated(user) {
	return !!(user || {}).id;
}

function normalizePhone(phoneCode = "", phoneNumber = "") {
	if (!phoneCode || !phoneNumber) return "";
	return [
		"+38",
		phoneCode,
		phoneNumber.substring(0, 3),
		phoneNumber.substring(3, 5),
		phoneNumber.substring(5, 7)].join(" ");
}

function parsePhone(phoneStr = "") {
	const phone = phoneStr.replace(/\+38| |\(|\)/g, "");
	return {
		code: phone.substr(0, 3),
		number: phone.substr(3),
	};
}

export {
	getfullName,
	getNameParts,
	isUserAuthenticated,
	normalizePhone,
	parsePhone,
}