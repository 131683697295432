import {FORMS} from "js/forms/enums";
import {DATA_TYPE} from "@services/FormServices/constants";

export const form = FORMS.CREATE_PASSWORD_EMAIL_VERIFICATION_FORM;
export const fields = {
	passwordRe: {
		name: "passwordRe",
		dataType: DATA_TYPE.password,
	},
	password: {
		name: "password",
		dataType: DATA_TYPE.password,
	},
};

export const initialValues = {
	otp: "",
	password: "",
	passwordRe: "",
	email: "",
	submitError: ""
}
