import React, {Component} from 'react';
import cn from "classnames";
import {compose} from "recompose";
import {graphql, MutationFunction} from "react-apollo";

import Mutation from "../../../graphql/Mutation";
import { FormattedHTMLMessage} from "../../../services/TextService"
import {getUniqueId, isFunction, isObject, parseErrorOnfly} from "../../../utils";

import {ICONS} from "../../UI/Icon/enums";
import Icon from "../../UI/Icon/Icon";
import Tooltip from "../../UI/Tooltip/Tooltip";

import "./ProposalLike.styl";

type ProposalLikeStateType = {
	isLiked: null | boolean
}

type ProposalLikePropsType = {
	changeCouponsLike: MutationFunction;
	isLike: null | boolean;
	itemId: string;
	withTooltip?: boolean;
}

class ProposalLike extends Component<ProposalLikePropsType & {}, ProposalLikeStateType> {
	id: string

	constructor(props) {
		super(props);
		this.id = getUniqueId();
		this.state = {
			isLiked: this.props.isLike
		};
	}

	handleLikeChange = (status) => {
		const {itemId, changeCouponsLike} = this.props;
		if (isFunction(changeCouponsLike) && !!itemId) {
			const variables = {
				coupons:
					[{
						couponId: +itemId,
						isLike: status
					}]
			};
			this.setState({isLiked: status}, () => {
				changeCouponsLike({variables}).then((r) => {
					if (isObject(r)) {
						const {errors}:any = r;
						if (errors) {
							throw errors;
						}
					}
				}).catch(error => {
					parseErrorOnfly(error, true);
				});
			});
		}
	};

	setLike = () => {
		const {isLiked} = this.state;
		if (isLiked === null || !isLiked) {
			this.handleLikeChange(true)
		} else {
			this.handleLikeChange(null)
		}
	};

	setDisLike = () => {
		const {isLiked} = this.state;
		if (isLiked === null || isLiked) {
			this.handleLikeChange(false)
		} else {
			this.handleLikeChange(null)
		}
	};
	render() {
		const {withTooltip} = this.props;
		const {isLiked} = this.state;
		return (
			<div className="proposal-like-control">
				{withTooltip && <Tooltip
					id={this.id}
					place="top">
					<FormattedHTMLMessage id="proposal.like.tooltip"/>
				</Tooltip>}
				<Icon raw icon={ICONS.dislike} onClick={this.setDisLike} className={cn("dislike", {"active": isLiked !== null && !isLiked})}/>
				<Icon raw icon={ICONS.like} onClick={this.setLike}  className={cn("like", {"active": isLiked})}
							data-tip
							data-for={this.id}/>
			</div>
		)
	}
};


export default compose<ProposalLikePropsType, {}>(
	graphql(Mutation.changeCouponsLike.mutation, {name: "changeCouponsLike"})
)(ProposalLike);