import React, {Component} from "react";
import cn from "classnames";
import {Field, FieldProps} from "formik";

type RadioButtonPropsType =  FieldProps & {
	checked: boolean;
}

class RadioButton extends Component<RadioButtonPropsType> {

	shouldComponentUpdate(nextProps) {
		return this.props.checked !== nextProps.checked;
	}

	onBlur = () => {
		const {field: {value, onBlur}} = this.props;
		onBlur(value);
	};

	render() {
		const {field, children, checked} = this.props;
		return (
			<label className="radio-button__holder">
				<Field className="radio-button__input" {...field} onBlur={this.onBlur} component="input" type="radio"/>
				<span className={cn("radio-button", {"checked": checked})}>{children}</span>
			</label>
		)
	}
}

export default RadioButton;