import "./Unavailable.styl";
import React from "react";
import Logo from "../../components/UI/Logo/Logo";
import img from "images/503.png"

const Unavailable = () => {
	return (
		<div className="unavailable-wrapper">
			<div className="unavailable-holder">
				<Logo/>
				<img src={img} alt="unavailable" className="unavailable-image"/>
				<div className="unavailable-text">
					Вибачте, сайт зараз не працює. <br/>
					Ми знаємо про проблему і вирішуємо її. <br/>
					Будь ласка, повертайтеся пізніше.
					<p>
						Якщо у вас термінове питання, <br/> зателефонуйте на гарячу лінію, ми допоможемо <br/>
						<a href="tel:0800301230">0-800-301-230</a>
					</p>
				</div>
			</div>
		</div>
	)
};

export default Unavailable