import React, {Component} from "react";
import {compose} from "recompose";
import ReCAPTCHA from "react-google-recaptcha";
import actions from "../context/actions";
import WithContext from "../components/HOCs/WithContext/WithContext";
import {needRecapture} from "../utils";

let recaptchaInstance;

const resetRecaptcha = () => {
	if (needRecapture() && recaptchaInstance) {
		recaptchaInstance.reset();
	}
};

const executeRecaptcha = () => {
	if (needRecapture() && recaptchaInstance) {
		recaptchaInstance.execute()
	}
};

const getValueRecaptcha = () => {
	if (needRecapture() && recaptchaInstance) {
		return recaptchaInstance.getValue()
	} else if (!needRecapture()) {
		return " "
	}
	return false
};

const checkRecapture = ():Promise<string> => {
	let tries = 0;
	let timer;
	function check(resolve, reject) {
		const capture = getValueRecaptcha();
		if (capture) {
			resolve(capture);
		} else if (tries === 200) {
			reject();
		} else {
			tries = tries + 1;
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				check(resolve, reject);
			}, 500)
		}
	}

	return new Promise((resolve, reject) => {
		executeRecaptcha();
		check(resolve, reject);
	})
};

type InnerRecaptchaServiceProps = {
	setKey: (key: string) => any;
	resetRcpt: () => any;
};

const Recapture = compose<InnerRecaptchaServiceProps, {}>(
	WithContext(
		(state) => ({state}),
		(dispatch) => ({
			setKey: (key) => {
				dispatch(actions.setGoogleCkey(key));
			},
			resetRcpt: () => {
				dispatch(actions.resetgcapture());
			}
		})
	)
)(class Recapture extends Component<InnerRecaptchaServiceProps, {}> {
	componentWillUnmount() {
		this.props.resetRcpt();
	}

	render() {
		const {GOOGLE_CAPTURE_KEY} = process.env;
		const {setKey} = this.props;
		return (
			<ReCAPTCHA
				sitekey={GOOGLE_CAPTURE_KEY}
				onChange={setKey}
				ref={e => recaptchaInstance = e}
				className="grecapture"
				size="invisible"
			/>)
		}
});

export {Recapture,  resetRecaptcha, executeRecaptcha, getValueRecaptcha, checkRecapture};