import React from 'react'
import {Field} from 'formik'
import {getUniqueId} from '@utils'

import getTemplateText, {FormattedHTMLMessage} from '@services/TextService'
import {getMessageId} from '../../helpers'
import {form} from '../const'
import useAutoFocus from '../../../hooks/useAutoFocus';

import FormField from '@components/Form/FormField'
import FormInput from '@components/Form/FormInput'
import Tooltip from '@components/UI/Tooltip/Tooltip'

const PasswordVerification = () => {
	const passwordId = getUniqueId();
	const passwordLabel = getMessageId("label.password", form);
	const passwordReLabel = getMessageId("label.passwordRe", form);
	const passwordRef = useAutoFocus();

	return (
		<FormField className="password-form-field">
			<Field
				name="password"
				component={FormInput}
				label={passwordLabel && getTemplateText(passwordLabel)}
				type="password"
				data-tip data-for={passwordId}
				innerRef={passwordRef}
			/>
			<Tooltip id={passwordId} event="focus" eventOff="blur" place="bottom">
				<div className="tooltip__create-password">
					<FormattedHTMLMessage id="hint.password" />
				</div>
			</Tooltip>
			<Field
				name="passwordRe"
				component={FormInput}
				label={passwordReLabel && getTemplateText(passwordReLabel)}
				type="password"
			/>
		</FormField>
	)
}

export default PasswordVerification