export enum RULE {
	required = "required",
	min_length = "min_length",
	max_length = "max_length",
	no_numbers = "no_numbers",
	numbers_only = "numbers_only",
	invalid = "invalid",
	ean13 = "ean13",
	invalid_format = "invalid_format",
	file_type = "file_type",
	file_size = "file_size",
	has_number = "has_number",
	has_lowercase = "has_lowercase",
	has_uppercase = "has_uppercase",
	has_character = "has_character",
	has_same_character = "has_same_character",
	has_simple_combinations = "has_simple_combinations"
}

export enum DATA_TYPE  {
	barcode = "barcode",
	birthday= "birthday",
	bool= "bool",
	card= "card",
	city= "city",
	date= "date",
	email= "email",
	file= "file",
	name= "name",
	number= "number",
	otp= "otp",
	password= "password",
	oldPassword= "oldPassword",
	phone= "phone",
	code= "code",
	phoneTitle= "phoneTitle",
	postIndex= "postIndex",
	text= "text",
	nominal= "nominal",
	fullname= "fullname",
	interName= "interName",
	mbStatus = "mbStatus",
	multipleDataType = "multipleDataType",
}

const DATA_TYPE_RULES = {
	[DATA_TYPE.barcode]: [RULE.required, RULE.invalid, RULE.ean13],
	[DATA_TYPE.birthday]: [RULE.required, RULE.invalid, RULE.invalid_format],
	[DATA_TYPE.bool]: [RULE.required],
	[DATA_TYPE.card]: [RULE.required, RULE.invalid],
	[DATA_TYPE.date]: [RULE.required, RULE.invalid],
	[DATA_TYPE.email]: [RULE.required, RULE.invalid, RULE.max_length],
	[DATA_TYPE.code]: [RULE.required, RULE.has_same_character, RULE.has_simple_combinations, RULE.min_length],
	[DATA_TYPE.file]: [RULE.required, RULE.file_type, RULE.file_size],
	[DATA_TYPE.name]: [RULE.required, RULE.min_length, RULE.max_length, RULE.invalid],
	[DATA_TYPE.interName]: [RULE.required, RULE.min_length, RULE.max_length, RULE.invalid],
	[DATA_TYPE.number]: [RULE.required, RULE.invalid],
	[DATA_TYPE.otp]: [RULE.required, RULE.invalid, RULE.numbers_only],
	[DATA_TYPE.password]: [RULE.required, RULE.has_number, RULE.has_character, RULE.has_lowercase, RULE.has_uppercase, RULE.invalid, RULE.min_length],
	[DATA_TYPE.phone]: [RULE.required, RULE.invalid, RULE.invalid_format],
	[DATA_TYPE.phoneTitle]: [RULE.max_length, RULE.invalid],
	[DATA_TYPE.postIndex]: [RULE.required, RULE.numbers_only, RULE.min_length, RULE.max_length],
	[DATA_TYPE.text]: [RULE.required, RULE.min_length, RULE.max_length],
	[DATA_TYPE.city]: [RULE.required, RULE.invalid],
	[DATA_TYPE.fullname]: [RULE.required, RULE.invalid],
	[DATA_TYPE.mbStatus]: [RULE.required, RULE.has_uppercase],
	[DATA_TYPE.multipleDataType]: [RULE.required],
};

const FILE_TYPES = {
	pdf: "application/pdf",
	doc: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"],
	image: ["image/jpeg", "image/png"],
};

const DATE_FORMAT = "DD/MM/YYYY",
	DATE_FORMAT_PATTERN = /\d{1,2}\/\d{1,2}\/\d{4}/,
	QUERY_DATE_FORMAT = "DD-MM-YYYY";

const PHONE_MASK = "+38 999 999 99 99",
	PHONE_MASK_CHAR = null;

const TIME_MASK = [/[0-2]/, /\d/, ":", /[0-5]/, /\d/],
	TIME_MASK_CHAR = "–",
	TIME_PLACEHOLDER = "––:––";

const DATE_FORMAT_CHARS = {
		"1": "[0-1]",
		"3": "[0-3]",
		"9": "[0-9]",
	},
	DATE_MASK = "39/19/9999",
	DATE_MASK_CHAR = "–",
	DATE_PLACEHOLDER = "дд/мм/рррр";

const BARCODE_MASK = "02xxxxxxxxxxxxxx",
	BARCODE_MASK_CHAR = null,
	BARCODE_FORMAT_CHARS = {
		"x": "[0-9]"
	},
	BARCODE_ACTIVE = false,
	BARCODE_DEFAULT_VALUE = "";

const CODE_MASK = "xxxx",
	CODE_MASK_CHAR = null,
	CODE_FORMAT_CHARS = {
		"x": "[0-9]"
	},
	CODE_ACTIVE = false,
	CODE_DEFAULT_VALUE = "";


export  {
	CODE_MASK,
	CODE_MASK_CHAR,
	CODE_FORMAT_CHARS,
	CODE_ACTIVE,
	CODE_DEFAULT_VALUE,
	FILE_TYPES,
	DATA_TYPE_RULES,
	DATE_FORMAT,
	DATE_FORMAT_CHARS,
	DATE_FORMAT_PATTERN,
	DATE_MASK,
	DATE_MASK_CHAR,
	DATE_PLACEHOLDER,
	BARCODE_DEFAULT_VALUE,
	BARCODE_ACTIVE,
	BARCODE_FORMAT_CHARS,
	BARCODE_MASK,
	BARCODE_MASK_CHAR,
	PHONE_MASK,
	PHONE_MASK_CHAR,
	TIME_PLACEHOLDER,
	TIME_MASK,
	TIME_MASK_CHAR,
	QUERY_DATE_FORMAT
}