import React, {Fragment}from 'react';
import './SocialBlock.base.styl';

import Icon from "../../../../UI/Icon/Icon";
import {ICONS} from "../../../../UI/Icon/enums";
import getTemplateText from "../../../../../services/TextService";
import {isTargetProject} from "../../../../../utils";
import {PROJECT} from "../../../../../constants";

const socialNetworkList = [
	{
		title: "nav.facebook_link",
		href: "https://www.facebook.com/fora.ukraine/",
		icon: ICONS.facebookCircleFora
	},
	{
		title: "nav.instagram_link",
		href: "https://www.instagram.com/fora.market/?igshid=9t147wdns43g",
		icon: ICONS.instagramFora
	},
	{
		title: "nav.telegram_link",
		href: "https://t.me/foraINFO",
		icon: ICONS.telegramCircleFora
	},
	// {
	// 	title: "nav.viber_link",
	// 	href: "https://chats.viber.com/forafoodmarket/en",
	// 	icon: ICONS.viberFora
	// },
];

const SocialBlock: React.FunctionComponent = () => {
	return (
		<div className="social-block">
			{isTargetProject(PROJECT.SILPO)
				?
					<div className="menu__footer">
						<div className={"hot-line-and-facebook-container"}>
							<div className="hot-line">
								<div className="menu__footer-icon-wrapper">
									<Icon className="hot-line__icon" icon={ICONS.phone}/>
								</div>
								<div className="right-side">
									<h4 className="hot-line__title">
										{getTemplateText("nav.hot_line.title")}
									</h4>
									<div className="hot-line__number">
										<a href={`tel:${getTemplateText("nav.hot_line.number")}`}>
											{getTemplateText("nav.hot_line.number")}
										</a>
									</div>
									<div className="hot-line__hint">
										{getTemplateText("nav.hot_line.hint")}
									</div>
								</div>
							</div>

							<a
								className="facebook-link"
								title={getTemplateText("nav.facebook_link")}
								target="_blank"
								rel="noopener noreferrer"
								href="https://facebook.com/silpo"
							>
								<div className="menu__footer-icon-wrapper">
									<Icon icon={ICONS.facebookCircle} raw/>
								</div>
								<span>{getTemplateText("nav.facebook_link")}</span>
							</a>
						</div>

						<a href="mailto:club@fora.ua?" target="_top">
							<div className={"email-hot-line"}>
								<div className="menu__footer-icon-wrapper">
									<Icon className="email-hot-line__icon" icon={ICONS.emailHotLine}/>
								</div>
								<div className="right-side">
									<h4 className="email-hot-line__title">
										{getTemplateText("email.title")}
									</h4>
									<div className="email-hot-line__email">
										{getTemplateText("email")}
									</div>
								</div>
							</div>
						</a>
					</div>
				:
					<Fragment>
						<div className={"social-networks-container"}>
							{socialNetworkList.map(({title, href, icon},key)=>(
								<a
									className="social-network-link"
									title={getTemplateText(title)}
									target="_blank"
									rel="noopener noreferrer"
									href={href}
									key={key}
								>
									<div className="menu__footer-icon-wrapper">
										<Icon icon={icon} raw/>
									</div>
									<span>{getTemplateText(title)}</span>
								</a>
							))}
						</div>

						<a href="mailto:club@fora.ua?" target="_top">
							<div className={"email-hot-line"}>
								<div className="menu__footer-icon-wrapper">
									<Icon icon={ICONS.emailHotLineFora} raw/>
								</div>
								<div className="right-side">
									<h4 className="email-hot-line__title">
										{getTemplateText("email.title")}
									</h4>
									<div className="email-hot-line__email">
										{getTemplateText("email")}
									</div>
								</div>
							</div>
						</a>

						<div className="hot-line">
							<div className="right-side">
								<h4 className="hot-line__title">
									{getTemplateText("nav.hot_line.title")}
								</h4>
								<div className="hot-line__number">
									<a href={`tel:${getTemplateText("nav.hot_line.number")}`}>
										{getTemplateText("nav.hot_line.number")}
									</a>
								</div>
							</div>
						</div>
					</Fragment>
			}

		</div>
	)
};

export default SocialBlock;
