import React, {useCallback, useEffect, useRef, useState} from 'react'
import Toggle from "react-toggle"

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {parseErrorOnfly} from '../../utils';
import {FormattedHTMLMessage} from '../../services/TextService';
import client from '../../services/apollo';

import {ICONS} from '../UI/Icon/enums';
import Icon from '../UI/Icon/Icon';

enum BonusStatus {
	Activated = "ACTIVATED",
	Deactivated = "DEACTIVATED"
}

const BonusesManage = () => {
	const mounted = useRef(false)
	const [result, setResult] = useState(null);
	const [toggleState, setToggleState] = useState<boolean | null>(null);

  if (!mounted.current) {
		client.query({
			...Query.getBonusCancellation
		}).then(({data}) => {
			setResult(data.getBonusCancellation)
		})
		.catch(err => parseErrorOnfly(err, true))
	}

	useEffect(() => {
    mounted.current = true
  }, []);

	useEffect(() => {
		if (result) {
			setToggleState(result === BonusStatus.Activated)
		}
	}, [result])

	const handleSwitcherToggle = useCallback(() => {
		client.mutate({
			...Mutation.changeBonusCancellation,
			variables: {
				bonusCancellationStatus: toggleState ? BonusStatus.Deactivated : BonusStatus.Activated
			},
			refetchQueries: [
				{query: Query.getBonusCancellation.query},
				'GetBonusCancellation'
			]
		}).then(({data}) => {
			if (data.changeBonusCancellation) {
				setToggleState(!toggleState);
			}
		})
		.catch(error => parseErrorOnfly(error, true))
	}, [toggleState]);

	if (toggleState === null) return null;

	return (
		<div className="moneybox_container">
			<div className="preview-panel">
				<Icon icon={ICONS.bonuses} raw />
				<div className="title-line">
					<h2 className="moneybox-title heading3">
						<FormattedHTMLMessage id="personalPage.bonuses.heading"/>
					</h2>
					<div className="switch-wrap">
						<Toggle
							className="moneybox-toggle"
							checked={toggleState}
							icons={false}
							onChange={handleSwitcherToggle}
						/>
						<div className="moneybox-tooltip">
							<Icon className="info-icon moneybox-tooltip-icon">
								<div className="tooltip-massage"><FormattedHTMLMessage id="personalPage.bonuses.hint" /></div>
							</Icon>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BonusesManage