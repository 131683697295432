import React, {FC, PropsWithChildren} from 'react'
import cn from "classnames";

import {RadioButtonType} from './types';
import "./RadioButton.styl";

const RadioButton:FC<PropsWithChildren<RadioButtonType>> = ({onChange, isDisabled = false, selected, value, title, children}) => {
	const isSelected = value === (isNaN(+selected) ? selected : +selected);

	return (
		<div className={cn("radio-item")} key={value}>
			<div className={cn("radio-item-wrapper", {"disabled": isDisabled}, {"active": isSelected})}>
				<input className="radio"
					type="radio"
					value={value}
					checked={isSelected}
					onChange={onChange}
					id={`${title}-${value}`}
					disabled={isDisabled}
				/>
				<label className={cn("label", {"selected-label": isSelected})} htmlFor={`${title}-${value}`}>
					{children}
				</label>
			</div>
		</div>
	)
}

export default RadioButton