import React, {FC} from "react";
import {RouteComponentProps} from "react-router";
import MyContactsNumbers from "../../../components/MyContactsNumbers/MyContactsNumbers";

import "./RechargeNumbers.styl";


type RechargeNumbersPropsType = {} & RouteComponentProps;

const RechargeNumbers: FC<RechargeNumbersPropsType> = () => {
	return (
		<div className="recharge-numbers-page">
			<MyContactsNumbers/>
		</div>
	)
};


export default RechargeNumbers;