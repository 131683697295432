import React, {FunctionComponent} from "react";
import Modal from 'react-responsive-modal';

import {FormattedHTMLMessage} from "../../../services/TextService";
import {urlTo} from "../../../services/RouteService/RouteService";
import {ROUTE} from "../../../services/RouteService/enums";
import Link from "../../UI/Link";
import {HashModalProps} from "../../Modal/ModalInterfaces";

import "./СardRecoveryModal.styl"
import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";

export const CardRecoveryModal: FunctionComponent<HashModalProps> = ({isOpen, onClose}) =>{
	return(
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{"overlay": "modal-overlay", "modal": "modal-window recovery-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
		>
			<Link className="get-card__back-link"
								to={urlTo(ROUTE.PERSONAL_PAGE)}
			>
				<Icon className="back-link__icon" icon={ICONS.leftArrow}/>
				<FormattedHTMLMessage id={"CardRecovery.texts.toMain"}/>
			</Link>
			<h3 className="card-recovery-dialog__heading">
				<FormattedHTMLMessage id={"CardRecovery.texts.recoveryTitle"}/>
			</h3>
			<hr/>
			<div className="card-recovery-dialog__text">
				<FormattedHTMLMessage id={"CardRecovery.texts.description"}/>
			</div>
			<ol className="ordered-list card-recovery-dialog__ol">
				<li>
					<FormattedHTMLMessage id={"CardRecovery.texts.step1"}/>
				</li>
				<li>
					<FormattedHTMLMessage id={"CardRecovery.texts.step2"}/>
				</li>
			</ol>
			<div className="panel-footer">
				<div className="card-recovery-dialog__hint">
					<FormattedHTMLMessage id={"CardRecovery.texts.footer"}/>
				</div>
			</div>
		</Modal>
	)
};

