import React, {FC, useContext} from 'react'
import {Formik, FormikHelpers} from 'formik'
import {useMutation} from '@apollo/react-hooks';

import {isFunction} from '@utils';
import Mutation from '@graphql/Mutation';
import {handleValidate, initialValues} from './const';
import {handleFormErrors} from '@services/FormServices/FormService';
import {getResponseFields, getStoredAuthData, handleAccessTokenUserFetch} from '../helpers';
import PasswordEntryForm from './PasswordEntryForm';

import {PasswordModalValuesType, PasswordModalPropsType} from './types';
import {LoginResponseType, LoginVariablesType} from '@graphql/types';
import {Store} from 'js/context/context';

import "./PasswordEntry.styl";

const PasswordEntry: FC<PasswordModalPropsType> = (props) => {
	const {determineNextStep, onClose, withOpt = false} = props;
	const {dispatch} = useContext<any>(Store);

	const [login] = useMutation<LoginResponseType, LoginVariablesType>(Mutation.login.mutation);
	const {flowId, phone} = getStoredAuthData();

	const handleSubmit = (values: PasswordModalValuesType, formActions: FormikHelpers<PasswordModalValuesType>) => {
		const password = values.password
		return login({variables: {flowId, password}})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const login = data.login;
				if (!errors && login) {
					const {accessToken, expiresAt} = getResponseFields(login);
					if (accessToken) {
						localStorage.setItem("lastPhoneEntered", phone);
						handleAccessTokenUserFetch(accessToken, expiresAt, dispatch);
						onClose && isFunction(onClose) && onClose();
					}
				}
			})
			.catch((err) => {
				handleFormErrors(err, formActions);
			})
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidate}>
			<PasswordEntryForm determineNextStep={determineNextStep} withOpt={withOpt} />
		</Formik>
	)
}

export default PasswordEntry