import "./RadioLabel.styl";

import React, {Component} from "react";
import cn from "classnames";
import {FieldProps} from "formik";

import Radio from "../Radio/Radio";
import FormError from "../FormError/FormError";
import {WithFocusState} from "../../HOCs/WithFocusState/WithFocusState";
import {WithFocusProps} from "../../HOCs/WithFocusState/types";


type RadioLabelPropsType = FieldProps & WithFocusProps & {
	title: string;
	withError: boolean;
	[key: string]: any
}

type RadioLabelStateType = {
	focused: boolean;
}

class RadioLabel extends Component<RadioLabelPropsType, RadioLabelStateType> {

	render() {
		const {className, form, field, title, children, withError, checked, focused, ...props} = this.props;
		const {errors, touched} = form;
		const {name} = field;
		const error = errors[name];
		const showError = !focused && !!error && touched[name];

		return (
			<div className={cn("radio-label", className, {"with-error": withError})}>
				{showError && withError && (
					<div className="radio-label__error-holder">
						<FormError className="radio-label__error">{error}</FormError>
					</div>
				)}
				<label className="radio-label__tag">
					<Radio {...props} checked={checked} field={field} form={form} className="radio-label__control"/>
					<span className="radio-label__body">
                    {title && <span className={cn("radio-label__title", {"checked": checked})}>{title}</span>}
						{children && <span className="radio-label__content">{children}</span>}
                </span>
				</label>
			</div>
		)
	}
}



export default WithFocusState(RadioLabel);