import React from "react";

import getTemplateText from "../../services/TextService";
import {setDocumentTitle} from "../../services/TitleService";

import Logo from "../../components/UI/Logo/Logo";
import Auth from "../../components/AuthSteps/Auth";
import UnitedEntranceWrapper from "@components/AuthSteps/components/UnitedEntranceWrapper/UnitedEntranceWrapper";

import "./UnauthorizedPage.styl";

const UnauthorizedPage = () => {
	const title = getTemplateText("loginModal.title");
	setDocumentTitle(title);
	return (
		<div className="unauthorized-wrapper">
			<div className="unauthorized-header">
				<div className="unauthorized-message">
					<Logo />
					<div className="unauthorized-message__right-side">
						<div className="hot-line__title">
							{getTemplateText("nav.hot_line.title")}
						</div>
						<div className="hot-line__number">
							<a href={`tel:${getTemplateText("nav.hot_line.number")}`}>
								{getTemplateText("nav.hot_line.number")}
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="unauthorized-content">
				<div className="unauthorized-holder">
					<UnitedEntranceWrapper>
						<Auth />
					</UnitedEntranceWrapper>
				</div>
			</div>
		</div>
	)
};

export default UnauthorizedPage