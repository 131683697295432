import React, {Component} from "react";
import {FormattedHTMLMessage} from "../../services/TextService";
import {compose} from "recompose";
import cn from "classnames";

import Button from "../UI/Button/Button";
import Icon from "../UI/Icon/Icon";
import NearestStoreModal from "../Modals/NearestStoreModal/NearestStoreModal";
// import {GA} from "../../services/Analitics";
import {ICONS} from "../UI/Icon/enums";
import {graphql} from "react-apollo";
import Query from "../../graphql/Query";
import {getNearestStore} from "../../services/AppServices";
import DropDown from "../DropDown/DropDown";
import actions from "../../context/actions";
import WithContext from "../HOCs/WithContext/WithContext";
import "./NearestStore.styl";

type nearestModalTypeProps = {
	onClose: any;
	citiesWithStores: any;
	setNearestStore: (id) => void;
	className: string;
}
type nearestModalTypeState = {
	isOpened: boolean;
}

//TODO:	analytics
const NearestStore = compose<nearestModalTypeProps, nearestModalTypeState>(
	graphql(Query.citiesWithStores.query, {name: "citiesWithStores"})
)(class extends Component<nearestModalTypeProps, nearestModalTypeState> {
	node: any | null = null;
	constructor(props) {
		super(props);
		this.state = {
			isOpened: false
		}
	}

	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	// getter methods for render
	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

	getCities = (props = this.props) => {
		try {
			return props.citiesWithStores.cities || []
		} catch (err) {
			return []
		}
	};

	handleClick = (el) => {
		const dropdown = document.querySelectorAll('.nearest-stores-drop-down');
		if (dropdown.length && !dropdown[0].contains(el.target)) {
			this.node.close()
		}
	};

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false)
	}

	getActiveStores = () => {
		try {
			const activeNearestStore = (getNearestStore() || []) || false;
			const cities = this.getCities();

			const
				storesWithCities = (cities || []).reduce((res, city) => ([
					...res,
					...(city.stores || []).map(s => ({
						...s,
						city: {id: city.id, title: city.title}
					}))
				]), []);
			return storesWithCities.filter(s => activeNearestStore.find(ns => ns === s.id))
		} catch (err) {
			return err;
		}
	};

	onNearestStoreModalOpen = () => {
		// const parent = this.props.analiticsParent;
		// if (!!parent) {
		// 	GA("send", "event", "choose_supermarket", parent);
		// }
		this.setState({isOpened: true});
	};

	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
	// Optional render methods
	// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

	renderSelectedStores = () => {
		const activeNearestStore = (getNearestStore() || [])[0] || false;

		const activeStores = this.getActiveStores(),
			activeStore = activeStores.find(as => as.id === activeNearestStore) || {};
		const activeStoreTitle = (
			<span>
				<FormattedHTMLMessage
					id="store.list_item.title"
					values={{street: activeStore.title}}
				/>, {activeStore.city.title}
			</span>
		);

		return (
			<div className="nearest-store__selected">
				Ви обрали магазин "Фора" за адресою
				<DropDown className="drop-down--inline nearest-stores-drop-down" button={activeStoreTitle}
									ref={node => this.node = node}
									onOpen={() => {
									}}
				>
					<ul>
						{(activeStores || []).map((s, i) => {
							const active = activeNearestStore === s.id;
							const props = {
								key: i,
								className: cn("nearest-stores-drop-down__store", {active}),
								...(!active ? {onClick: () => this.props.setNearestStore(s.id)} : {}),
								children: (
									<span>
					<FormattedHTMLMessage
						id="store.list_item.title"
						values={{street: s.title}}/>, {s.city.title}
					</span>
								),
							};

							return <li {...props} />
						})}
					</ul>
					<div
						className="nearest-stores-drop-down__edit"
						onClick={() => this.setState({isOpened: true})}
					>
						<Icon icon={ICONS.pencil}/>
						Редагувати список
					</div>
				</DropDown>
			</div>
		)
	};

	renderNotSelectedStores = () => {
		return (
			<div className="nearest-store__body">
				<div className="nearest-store__message">
					<div className="nearest-store__message-cell">
						<FormattedHTMLMessage id="nearestStore.logged.not_selected_stores"/>
					</div>
				</div>
				<div className="nearest-store__btn-holder">
					<Button className="nearest-store__btn" primary onClick={this.onNearestStoreModalOpen}>
						<FormattedHTMLMessage id="nearestStore.btn_add_store"/>
					</Button>
				</div>
			</div>
		)
	};

	render() {
		const cities = this.getCities();
		const {className, children} = this.props;
		const {isOpened} = this.state;
		const activeStores = this.getActiveStores();
		const showSelectedStores = !!activeStores.length && !!cities.length,
			showNotSelectedStores = !activeStores.length && !!cities.length;
		return (
			<div className={cn("nearest-store", className)}>
				{showSelectedStores && this.renderSelectedStores()}
				{showNotSelectedStores && this.renderNotSelectedStores()}
				{children && <div className="nearest-store__content">{children}</div>}

				<NearestStoreModal
					open={isOpened}
					onClose={() => this.setState({isOpened: false})}/>

			</div>
		)
	}
});

export default WithContext(
	(state) => ({
		nearestStore: state.nearestStore
	}),
	dispatch => ({
		setNearestStore: (storeId) => dispatch(actions.setNearestStore(storeId)),
	})
)(NearestStore);