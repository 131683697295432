import React from 'react';
import {Store} from "../../../context/context";
import {isFunction, isUndefined} from "../../../utils";

/**
 * maps actions to component props
 * @param getPropsFromActionFunc
 * @param value
 */
function mapDispatchToProps<T>(getPropsFromActionFunc, value) : T |{dispatch: () => void} | null {
	 if (isFunction(getPropsFromActionFunc)) {
		return {...getPropsFromActionFunc(value.dispatch), dispatch: value.dispatch};
	} if (isUndefined(getPropsFromActionFunc)) {
		return {dispatch: value.dispatch};
	} else {
	 	return null;
	}
}

/**
 * maps state props to component props
 * @param getPropsFromStateFunc
 * @param value
 */
function mapStateToProps<T>(getPropsFromStateFunc, value): T | {state: object} | null {
	if (isUndefined(getPropsFromStateFunc)) {
		return {state: value.state};
	} else if (isFunction(getPropsFromStateFunc)) {
		return getPropsFromStateFunc(value.state);
	} else {
		return null;
	}
}


/**
 * wraps component ans passes store and dispatch as props
 * @param getPropsFromStateFunc
 * @param getPropsFromActionFunc
 * @return wrapped component with store and  dispatch
 */
function WithContext <statePropsType, dispatchPropsType>(getPropsFromStateFunc?, getPropsFromActionFunc?){
	return function (WrappedComponent) {
		return function Wrapper(props) {
			return (
				<Store.Consumer>
					{(value) => {
						const dispatchProps = mapDispatchToProps<dispatchPropsType>(getPropsFromActionFunc, value);
						const stateProps = mapStateToProps<statePropsType>(getPropsFromStateFunc, value);
						return <WrappedComponent {...props} {...stateProps} {...dispatchProps || {}}/>}
					}
				</Store.Consumer>
			)
		}
	}
}

export default WithContext;