import {detect} from "detect-browser";

import client from "./apollo";
import Mutation from "../graphql/Mutation";
import {parseErrorOnfly, tryParseJSON} from "../utils";
import {FRONT_ERROR_LEVEL, LOG_LEVEL} from "../constants";
import {isDev, VERSION} from "./EnvServices";
import {clearUser} from "../actions/userActions";
import {getUserGuid} from "./UserGuid";
import CookiesService from "./CookiesService";

const BASKET_GUID_LENGTH = 36;
const detectData = detect();
const INFO = detectData ? `${detectData.name} (${detectData.version}), ${detectData.os}` : "";
const logger: string[] = [];
function cycleJson(obj) {
	if (typeof obj === "string") {
		return obj;
	}
	const seen: any[] = [];

	return JSON.stringify(obj, function(key, val) {
		if (typeof val === "object") {
			if (seen.indexOf(val) >= 0)
				return;
			seen.push(val)
		}
		return val
	}, 2);

}
/***
 * filter known errors or return
 * @param message
 * @returns {*|string}
 */
function detectFrontErrorLevel(message) {
	const KNOWN_ERRORS = {
		"GraphQL error: Could not decode JSON, syntax error - malformed JSON." : FRONT_ERROR_LEVEL.ALERT,
		"Uncaught TypeError: Cannot read property '0' of undefined" : FRONT_ERROR_LEVEL.ERROR,
		"Network error: Failed to fetch" : FRONT_ERROR_LEVEL.WARNING,
		"GraphQL error: No access to this action" : FRONT_ERROR_LEVEL.INFO,
		"Minified React error #109; visit http://facebook.github.io/react/docs/error-decoder.html?invariant=109&args[]=t for the full message or use the non-minified dev environment for full errors and additional helpful warnings." : FRONT_ERROR_LEVEL.NOTICE,
		"GraphQL error: Call to a member function getToken() on null" : FRONT_ERROR_LEVEL.ERROR,
		"GraphQL error: Notice: Undefined index: only_visa" : FRONT_ERROR_LEVEL.INFO,
		"ResizeObserver loop limit exceeded" : FRONT_ERROR_LEVEL.INFO,
		"Timeout" : FRONT_ERROR_LEVEL.WARNING,
		"скасовано" : FRONT_ERROR_LEVEL.ERROR,
		"Network error: отменено" : FRONT_ERROR_LEVEL.ERROR,
	};
	return KNOWN_ERRORS[message] || FRONT_ERROR_LEVEL.ALERT;
}
/***
 * Exclude some messages
 * @param message
 * @returns {boolean}
 */
function shouldSend(message: string): boolean {
	if (logger.indexOf(message) !== -1) {
		return false;
	}
	const EXCLUDED_MESSAGE = isDev? [] : [
		"Network error: need ticket token",
		"Поле електронна адреса не відповідає формату",
		"Невірний пароль",
		"Помилка google капчі",
		"Некоректний email",
		"Код доступу застарів",
		"Некоректний код доступу",
		"Сетевое соединение потеряно.",
		"Перевищено кількість спроб відправки",
		"Наступна відправка тимчасово заборонена",
		"Ваша персональна сторінка заблокована до",
		"Не удалось найти сервер с указанным именем хоста.",
		"ResizeObserver loop limit exceeded",
		"ResizeObserver loop completed with undelivered notifications.",
		"Request aborted",
		"Minified React error #109; visit http://facebook.github.io/react/docs/error-decoder.html?invariant=109&args[]=t for the full message or use the non-minified dev environment for full errors and additional helpful warnings.",
		"Сталася помилка. Ми працюємо над цим. Спробуйте пізніше",
		"Failed to fetch",
		"отменено",
		"Uncaught SyntaxError: Identifier 'originalPrompt' has already been declared",
		"У гостя не знайдено контактів в анкеті",
		"Not founded delivery type",
		"Not founded suggestion",
		"Network error: Failed to fetch",
		"NetworkError when attempting to fetch resource.",
		"GraphQL error: У гостя не знайдено контактів в анкеті",
		"GraphQL error: Перевищено кількість спроб відправки",
		"GraphQL error: Некоректний email",
		"GraphQL error: Невірний пароль",
		"GraphQL error: Користувача не знайдено",
		"GraphQL error: Наступна відправка тимчасово заборонена",
		"GraphQL error: Поле електронна адреса не відповідає формату",
		"GraphQL error: Помилка google капчі",
		"Uncaught TypeError: Failed to execute 'scroll' on 'Window': 2 arguments required, but only 1 present.",
		"Block-scoped declarations (let, const, function, class) not yet supported outside strict mode"
	];
	for (let i = 0; i < EXCLUDED_MESSAGE.length; i++) {
		const text = EXCLUDED_MESSAGE[i];
		if (message.indexOf(text) !== -1) {
			return false
		}
	}
	return true;
}
/**
 * sends error info to log it
 * @param err
 * @param other
 */
function sendError (err: Error | {} | string, other?: {}) {
	let message = "";
	let stack: Error | {} | string = "";
	try {
		if (typeof err === "object") {
			message = (err || {})["message"];
			stack = (err || {})["stack"] || (err || {})["code"];
			if (!!other && !!other["operation"]) {
				const {operationName = "", variables = ""} = ((other || {})["operation"] || {});
				stack = {
					operationName,
					variables
				}
			}
		} else if (typeof err === "string") {
			message = err;
		}
		if (err["isAxiosError"]) {
			stack = err["response"] || {};
			message = ((stack["data"] || {}).eComError || {}).errorMessage ||
				((stack["data"] || {}).eComError || {}).errorMessage ||
				((stack["data"] || {}).eComError || {}).errorDescription || message;
			if (!!message && typeof message === "string" && message.length === BASKET_GUID_LENGTH && (stack["config"] || {}).data) {
				const tmp = tryParseJSON((stack["config"] || {}).data);
				message = tmp["method"];
			}
		}
		if (!shouldSend(message)) {
			return false;
		}
		logger.push(message);
		if (isDev) {
			debugger
		}
		if (message.indexOf("API") !== -1 && !err["isAxiosError"]) {
			clearUser();
		}
		const userId = `"lastPhoneEntered": "${localStorage["lastPhoneEntered"] ? localStorage["lastPhoneEntered"] : ""}", "authData": "${localStorage["authData"] ? localStorage["authData"] : ""}", "barcode": "${CookiesService.getItem("barcode") ? CookiesService.getItem("barcode") : ""}"`

		const {hostname, href} = window.location;
		const frontLogInput = {
			domain: hostname,
			url: href,
			browserName: INFO,
			frontVersion: VERSION,
			descriptionError: !!message && typeof message === "string" ? message : "unknown personal page message",
			trace: cycleJson(stack || ""),
			userId: `{"userGuid": "${getUserGuid()}", ${userId}}`,
			frontErrorLevel: detectFrontErrorLevel(message),
			level: LOG_LEVEL.error
		};
		client.mutate({
			...Mutation.frontLog,
			variables: {
				frontLogInput
			}
		})
			.then(({data: {frontLog, errors}}) => {
				if (!frontLog && errors) {
					throw errors || {};
				}
			})
			.catch((err) => {
				console.error(parseErrorOnfly(err, false, null,  false));
			});
	} catch (err: any) {
		console.error(parseErrorOnfly(err, false, null,  false));
	}
}

export {
	sendError
}