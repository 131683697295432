import React, {FunctionComponent} from "react";
import cn from "classnames";

type FormRowPropsType = {
	className?: string;
}

const FormRow: FunctionComponent<FormRowPropsType> = ({className, children}) => {
	return (
		<div className={cn("form-row", className)}>{children}</div>
	)
};

export default FormRow;