import React, {FC} from "react";
import Modal from 'react-responsive-modal';

import {CookieAgreementModalProps} from "./types";
import {FormattedHTMLMessage} from "../../../services/TextService";

import "./CookieAgreementModal.styl";


const COOKIES_RULES_LINK = "/assets/cookies_rules.pdf";
const CookieAgreementModal: FC<CookieAgreementModalProps> = (props) => {
	const { isOpen, onClose } = props;

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{
				"overlay": "modal-overlay cookie-banner-overlay",
				"modal": "modal-window cookie-banner-modal",
				"closeButton": "modal-closeButton cookie-banner-closeButton",
				"closeIcon": "modal-closeIcon"
			}}
		>
			<div className="cookie-banner-popup">
				<FormattedHTMLMessage id="cookieBanner.popup" values={{link: COOKIES_RULES_LINK}}/>
			</div>
		</Modal>
	)
};


export default CookieAgreementModal;