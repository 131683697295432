import React, {FC} from 'react'
import {Field, FormikValues, useFormikContext} from 'formik';

import {ENUM} from '@constants';
import {getMessageId} from '../helpers';
import {fieldsItems, form} from './const';
import getTemplateText from '@services/TextService';

import Form from '@components/Form/Form';
import FormField from '@components/Form/FormField';
import FormInput from '@components/Form/FormInput';
import FormError from '@components/Form/FormError/FormError';
import RadioLabel from '@components/Form/RadioLabel/RadioLabel';
import Button from '@UI/Button/Button';

const CardInformationEntryForm: FC = () => {
	const {handleSubmit, isSubmitting, setFieldValue, errors, isValid, values} = useFormikContext<FormikValues>();
	const barcodeLabelId = getMessageId("label.barcode", form);

	const handleChange = (value: string) => () => {
		setFieldValue("hasCard", value);
	}

	return (
		<Form onSubmit={handleSubmit} name={form} className="personal-account-card-form">
			<FormField className="has-card_form-field">
				{fieldsItems.map(({value, title}) => {
					return (
						<Field
							id={value}
							key={value}
							name="hasCard"
							type="radio"
							component={RadioLabel}
							value={value}
							title={title}
							withError
							defaultChecked={values.hasCard === value}
							onChange={handleChange(value)}
						/>
					)
				})}
			</FormField>

			{values.hasCard === ENUM.YES &&
				<FormField className="barcode_form-field">
					<Field
						name="barcode"
						type="barcode"
						component={FormInput}
						label={getTemplateText(barcodeLabelId)}
					/>
				</FormField>}

			{errors && errors.submitError && <FormError>{errors.submitError}</FormError>}

			<Button className="btn-submit" primary type="submit" disabled={!isValid || isSubmitting} loading={isSubmitting} >
				ПРОДОВЖИТИ
			</Button>
		</Form>
	)
}

export default CardInformationEntryForm