import React, {FunctionComponent, useState} from "react";
import Modal from 'react-responsive-modal';
import client from "../../../services/apollo";
import Mutation from "../../../graphql/Mutation";
import {parseErrorOnfly} from "../../../utils";

import {CancelOrderModalProps} from "./types";
import {FormattedHTMLMessage} from "../../../services/TextService";

import Button from "../../UI/Button/Button";

import "./CancelOrderModal.styl";

const renderReasonsOptions = (reasons: string[] = [], selectedReason, otherReason,  onReasonChange, onOtherReasonChange) => (
	<div className="cancel-data_reasons">
		{reasons.map(reason => {
			return (
				<div className="radio-label"
						 key={reason}>
					<label className="radio-label__tag">
					<span className="radio-label__control">
						<input
							className="radio__input"
							value={reason}
							name="contact"
							onChange={onReasonChange(reason)}
							type="radio"
						/>
						<i className="radio__icon"/>
					</span>
						<div className="radio-label__body">
							<span className="radio-label__content">{reason}</span>
						</div>
					</label>
					{reason === extendedReason && selectedReason === extendedReason && (
						<input
							type="text"
							value={otherReason}
							className="rounded__input reason__input"
							maxLength={500}
							onChange={onOtherReasonChange}
							placeholder="Розкажіть, що пішло не так"/>
					)}
				</div>
			)})}
	</div>
);

const extendedReason = "Інша причина";

const reasons = [
	"Змінились плани",
	"Оформив нове замовлення",
	"Не знайшов, що шукав",
	"Не було в наявності потрібних товарів",
	"Не підійшов час",
	extendedReason
];

export const CancelOrderModal: FunctionComponent<CancelOrderModalProps> = ({
			isOpen, onClose, title, id, paid, orderHistoryRefetch
	} ) => {
	const [reason, setReason] = useState('');
	const [otherReason, setOtherReason] = useState('');

	const onReasonChange = (reason) => () => {
		setReason(reason)
	};

	const onOtherReasonChange = (event) => {
		const value = event.target.value || "";
		setOtherReason(value)
	};

	/**
	 * cancels the order
	 */
	const cancelOrder = () => {
		const variables = {
			orderId: id,
			reasonComment: reason !== extendedReason ? reason : otherReason || reason
		};

		onClose();

		client.mutate({
			...Mutation.cancelOrder,
			variables
		})
			.then(({data: {cancelOrder}, errors}) => {
				if (!errors && cancelOrder) {
					orderHistoryRefetch();
				} else {
					throw errors;
				}
			})
			.catch((err) => {
				parseErrorOnfly(err, true);
			})
	};

	return (
			<Modal
				open={isOpen}
				onClose={onClose}
				center
				classNames={{"overlay": "modal-overlay", "modal": "modal-window login-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
			>
				<div className="cancel-modal__data">
					<h3 className="cancel-modal__title">
						<FormattedHTMLMessage id={title}/>
					</h3>

					<p className="paid-text">
						<FormattedHTMLMessage id={`cancellingFeedbackForm.${paid ? "paid" : "unpaid"}.text`}/>
					</p>

					{renderReasonsOptions(reasons, reason, otherReason, onReasonChange, onOtherReasonChange)}

					<Button
						primary
						className="btn-proceed cancel-modal__button"
						disabled={!reason}
						onClick={cancelOrder}
					>
						Підтвердити
					</Button>
				</div>
			</Modal>
		);
};



