import React, {FC, useRef} from 'react';
import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';

import useOrdersList from '../hooks/useOrdersList';
import Loader from '../../../../components/UI/Loader/Loader';
import PurchaseItem from './components/PurchaseItem/PurchaseItem';
import NoPurchase from '../../../../components/NoPurchase/NoPurchase';
import {getQueryParams} from '../../../../services/RouteService/RouteService';
import Button from '../../../../components/UI/Button/Button';

import './PurchasesList.styl';

const PurchasesList: FC<{isPreview?: boolean}> = ({isPreview}) => {
	const listRef = useRef<HTMLUListElement | null>(null);
	const {orders, loading} = useOrdersList(listRef, !!isPreview);
	const location = useLocation();
	const {to, from} = getQueryParams(location);

	if (!loading && !orders.length) {
		return <NoPurchase text={
			isPreview || (!from || !to)
				? `Вибачте, не знайдено жодних покупок.`
				: `Вибачте, за обраний період <br/> з ${from} по ${to} <br/> не знайдено жодних покупок.`} />
	}

	return (
		<>
			<div className='purchases-list-wrapper'>
				<ul ref={listRef} className={classNames('purchases-list', {loading})}>
					{orders.map(item => <PurchaseItem key={item.orderNumber} item={item} />)}
					{loading && <Loader />}
				</ul>
			</div>
			{isPreview &&
				<Button primary component={Link} to='purchases'
					className="personal-page__history-btn-all">Історія покупок</Button>}
		</>
	)
}

export default PurchasesList;