import React, {FC} from "react";

import {isTargetProject} from "../../utils";
import getTemplateText from "../../services/TextService";
import {PROJECT} from "../../constants";
import foraAppStoreQr from "images/fora-qr-code-AppStore.gif";
import foraGooglePlayQr from "images/fora-qr-code-PlayMarket.gif";

import Link from "../UI/Link";
import Icon from "../UI/Icon/Icon";
import {ICONS} from "../UI/Icon/enums";
import AppsBlock from "../Layout/Menu/SidebarMenu/AppsBlock/AppsBlock";

import "./PersonalProposals.base.styl"

const PersonalProposals: FC = () => {
	const appStoreLink = isTargetProject(PROJECT.FORA) ?
		"https://apps.apple.com/us/app/фора/id1460809674" :
		"https://itunes.apple.com/app/id1392610549";
	const googlePlayLink = isTargetProject(PROJECT.FORA) ? " https://play.google.com/store/apps/details?id=ua.fora.android" :
		"https://play.google.com/store/apps/details?id=ua.silpo.android&referrer=utm_source%3Dsite%26utm_medium%3Dmenu%26utm_term%3Dapp-download";
	return (
		<div className="personal-proposals-panel">
			{isTargetProject(PROJECT.SILPO ) ? <div>
				<h3 className="personal-proposals__heading">{getTemplateText("bonuses.electronic.heading")}</h3>
				<hr/>
				<h4 className="personal-proposals__sub-heading">Електронні</h4>
				<div className="personal-proposals-description">
					{getTemplateText("bonuses.electronic.description")}
				</div>
				<div className="divider"/>
				</div> : null}
			<p className="advice">
				{getTemplateText("bonuses.electronic.advise")}
			</p>
			{isTargetProject(PROJECT.SILPO) ?
			<Link to="" className="link__item">
				<div className="link__item-icon">
					<Icon icon={ICONS.mobileApp} raw/>
				</div>
				<div className="link underline-hover">
					Мобільний додаток
				</div>
			</Link> :
				<AppsBlock
					appStoreLinkSource={foraAppStoreQr}
					appGooglePlayLinkSource={foraGooglePlayQr}
					appStoreLink={appStoreLink}
					googlePlayLink={googlePlayLink}
					title={getTemplateText("mobileApp.text")}
				/>
			}
		</div>
	)
};

export default PersonalProposals;
