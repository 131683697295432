import React, {FC, useCallback, useState} from 'react'
import {Formik} from 'formik'
import {useMutation} from 'react-apollo';

import {isObject} from '@utils';
import {handleValidate, initialValues} from './const';

import Mutation from '@graphql/Mutation';
import {handleFormErrors} from '@services/FormServices/FormService';
import {getResponseFields, getStoredAuthData} from '../helpers';

import RecoveryContactSelectionForm from './RecoveryContactSelectionForm'

import {Contact, SendOtpToContactResponseType, SendOtpToContactVariablesType} from '@graphql/types';
import {AuthFormPropsType} from '../types';

import "./RecoveryContactSelection.styl"

const RecoveryContactSelection: FC<AuthFormPropsType> = ({determineNextStep}) => {
	const [contact, setContact] = useState<Contact | null>(null);
	const [sendOtpToContact] = useMutation<SendOtpToContactResponseType, SendOtpToContactVariablesType>(Mutation.sendOtpToContact.mutation);

	const {flowId} = getStoredAuthData();

	const onContactChange = useCallback((contact: Contact) => {
		setContact(contact)
	}, []);

	const handleSubmit = (values, actions) => {
		const contactValue = values.contact;
		const contactId = isObject(contactValue) ? contactValue.value : +contactValue;

		return sendOtpToContact({variables: {contactId, flowId, }})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const sendOtpToContact = data.sendOtpToContact;
				if (!errors && sendOtpToContact && contact) {
					const {nextStep, flowId, contactType, contactValue} = getResponseFields(sendOtpToContact);
					localStorage.setItem("authData", JSON.stringify({...getStoredAuthData(), flowId, nextStep, contactType, [contactType.toLowerCase()]: contactValue || contact.contactHide, contactId}));
					determineNextStep(nextStep);
				}
			})
			.catch((e) => {
				handleFormErrors(e, actions);
			});
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidate}>
			<RecoveryContactSelectionForm onContactChange={onContactChange} />
		</Formik>
	)
}

export default RecoveryContactSelection