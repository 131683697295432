import React, {FC, useContext} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {useMutation} from 'react-apollo';

import Mutation from '@graphql/Mutation';
import {formatBirthday, isFunction} from '@utils';
import {handleFormErrors} from '@services/FormServices/FormService';
import {handleValidate, initialValid, initialValues} from './const';
import RegistrationInfoEntryForm from './RegistrationInfoEntryForm';
import {getResponseFields, getStoredAuthData, handleAccessTokenUserFetch} from '../helpers';
import {Store} from 'js/context/context';

import {PersonalInfoValuesType} from './types';
import {RegisterMemberResponseType, RegisterMemberVariablesType} from '@graphql/types';
import {AuthFormPropsType} from '../types';

import "./RegistrationInfoEntry.styl";

const RegistrationInfoEntry: FC<AuthFormPropsType> = ({determineNextStep, onClose}) => {
	const {dispatch} = useContext<any>(Store);
	const [registerMember] = useMutation<RegisterMemberResponseType, RegisterMemberVariablesType>(Mutation.registerMember.mutation);
	const {flowId} = getStoredAuthData();

	const setVariables = (values: PersonalInfoValuesType) => {
		const {password, passwordRe, firstName, lastName, email} = values;
		return {
			flowId: +flowId,
			password,
			passwordRepeat: passwordRe,
			member: {
				firstName,
				lastName,
				birthday: formatBirthday(values.birthday),
				email,
			},
		}
	}

	const handleSubmit = (values: PersonalInfoValuesType, formActions: FormikHelpers<PersonalInfoValuesType>) => {
		return registerMember({variables: setVariables(values)})
			.then(({data = {}, errors}) => {
				if (errors) {
					throw errors;
				}
				const registerMember = data.registerMember;
				if (!errors && registerMember) {
					const {accessToken, expiresAt, nextStep} = getResponseFields(registerMember);
					if (accessToken) {
						handleAccessTokenUserFetch(accessToken, expiresAt, dispatch);
						onClose && isFunction(onClose) && onClose();
					}
					formActions.setSubmitting(false);
					localStorage.setItem("authData", JSON.stringify({...getStoredAuthData(), email: values.email, nextStep}));
					determineNextStep(nextStep);
				}
			})
			.catch((e) => {
				handleFormErrors(e, formActions);
			});
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={initialValid} validate={handleValidate}>
			<RegistrationInfoEntryForm />
		</Formik>
	)
}

export default RegistrationInfoEntry;