import React, {Component} from 'react';
import {graphql} from "react-apollo";
import {compose} from "recompose";

import {InnerPersonalPagePropsType, PersonalPagePropsType} from "./types";

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {COLORS} from "../../constants";
import {dateMatcher} from "../../utils";
import {getfullName} from "../../services/UserServices";
import {setDocumentTitle} from "../../services/TitleService";
import {ROUTE} from "../../services/RouteService/enums";
import WithContext from "../../components/HOCs/WithContext/WithContext";

import LoadHolder from "../../components/UI/Loader/LoadHolder";
import Banner from "../../components/Banner/Banner";
import BonusPreview from "../../components/BonusPreview/BonusPreview";
import BalancePreview from "../../components/BalancePreview/BalancePreview";
import UnusedBalanceLines from "../../components/UnusedBalanceLines/UnusedBalanceLines";
import Moneybox from "../../components/Moneybox/Moneybox";
import PersonalProposalsBlock from '../../components/PersonalProposalsBlock/PersonalProposalsBlock';
import BonusesManage from '../../components/BonusesManage/BonusesManage';
import PurchasesList from '../PurchasesPage/components/PurchasesList/PurchasesList';

import "./PersonalPage.styl";

const PersonalPage = compose<PersonalPagePropsType & InnerPersonalPagePropsType, {}>(
	WithContext(({user, sighUpModal}) => ({
		barcode: user.barcode,
		birthday: user.birthday,
		fullName: getfullName(user),
		hasAssistant: user.hasAssistant,
		spawnNextCouponDate: user.spawnNextCouponDate,
		currentBonus: user.currentBonus,
		sighUpModal
	})),
	graphql(Mutation.couponsActivate.mutation, {name: "couponsActivate"}),
	graphql(Query.coupons.query, {
		name: "couponsData",
		options: {
			fetchPolicy: "network-only",
			variables: Query.coupons.variables,
		}
	}),
	graphql(Query.getBalance.query, {name: "getBalanceData"}),
)(class PersonalPage extends Component<PersonalPagePropsType & InnerPersonalPagePropsType, {}> {

	render() {
		const {
			spawnNextCouponDate,
			couponsData,
			couponsActivate,
			currentBonus,
			getBalanceData
		} = this.props;

		let birthdayBanner: JSX.Element | null = null;
		if (this.props.birthday && dateMatcher(this.props.birthday)) {
			birthdayBanner = <Banner
				className="birthday-banner"
				title="Вітаємо  з днем  народження!"
				image={require("../../../img/account/birthdayBanner/promo-birthday-modal.png")}
				color={COLORS.olive}
				link={ROUTE.SPECIAL_PROPOSALS}
			/>
		}
		setDocumentTitle("Персональна сторiнка");

		return (
			<>
				{birthdayBanner}

				<BonusPreview currentBonus={currentBonus} />

				<LoadHolder isLoading={getBalanceData.loading}>
					<>
						<BalancePreview currentBalance={getBalanceData.getBalance} />
						<UnusedBalanceLines currentBalance={getBalanceData.getBalance} />
					</>
				</LoadHolder>

				<BonusesManage />
				<Moneybox />

				<LoadHolder isLoading={couponsData.loading}>
					<PersonalProposalsBlock
						list={couponsData.coupons}
						spawnNextCouponDate={spawnNextCouponDate}
						withIntro={true}
						max={6}
						couponsActivate={couponsActivate}
						couponsRefetch={couponsData.refetch}
						showMoreBlock
					/>
				</LoadHolder>

				<div className="personal-page__history">
					<h3 className="personal-page__history-heading">Мої покупки</h3>
					<hr />
					<PurchasesList isPreview />
				</div>

			</>
		)
	}
});

export default PersonalPage;
