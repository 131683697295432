import "./CardAddModal.styl";

import React, {FunctionComponent} from "react";
import Modal from 'react-responsive-modal';
import {CardAddModalPropsType} from "./types";

import CardAddForm from "../../../forms/CardAddForm/CardAddForm";
import CardActivateForm from "../../../forms/CardActivateForm/CardActivateForm";


const CardAddModal: FunctionComponent<CardAddModalPropsType> = ({open, onClose, shouldShowActivateCardForm,onActivationCodeSubmit, onCardSubmit}) => {
		return (
			<Modal open={open} onClose={onClose}
					 classNames={{"overlay": "modal-overlay", "modal": "modal-window card-add-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}>
				{shouldShowActivateCardForm ?
					<CardActivateForm onSubmit={onActivationCodeSubmit}/>
					: <CardAddForm onSubmit={onCardSubmit}/>}
			</Modal>
		)
};

export default CardAddModal;