import axios from "axios";
import moment from "moment";
import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {getTokenData} from "../../../../../services/AppServices";
import {BASKET_API} from "../../../../../services/EnvServices";
import {getQueryParams} from "../../../../../services/RouteService/RouteService";
import {parseErrorOnfly} from "../../../../../utils";

import {GetPurchasesStatisticResp, PurchaseStatisticRequestData, StatisticItemType} from "./types";

const usePurchaseStatistic = () => {
	const location = useLocation();
	const {to, from} = getQueryParams(location);
	const [loading, setLoading] = useState(false);
	const {accessToken} = getTokenData();
	const [items, setItems] = useState<StatisticItemType[]>([]);

	const getPurchasesStatistic = useCallback((): Promise<StatisticItemType[]> => {
		setLoading(true);
		const data: PurchaseStatisticRequestData = {
			...(from ? {dateFrom: moment(from, 'DD-MM-YYYY').startOf('day').format()} : {}), // (не обов'язково, якщо не вказано береться за останні 30 днів)
			...(to ? {dateTo: moment(to, 'DD-MM-YYYY').endOf('day').format()} : {}), // (не обов'язково, якщо не вказано береться поточна дата)
		}
		return axios.post<GetPurchasesStatisticResp>(BASKET_API, {
			method: "GetPurchasesStatistic",
			data
		}, {headers: {"x-fora-authtoken": accessToken}})
			.then(({data: {data, eComError}}) => {
				if (!!eComError.errorCode) {
					throw new Error(eComError.errorMessage);
				}
				return data || [];
			})
			.catch(err => {
				parseErrorOnfly(err, true, null, true);
				return [];
			})
			.finally(() => setLoading(false));
	}, [from, to, accessToken]);

	useEffect(() => {
		getPurchasesStatistic()
			.then(response => setItems(response));
		// eslint-disable-next-line
	}, [from, to])

	return {items, loading}
}

export default usePurchaseStatistic;