import axios from "axios";
import {useCallback, useState} from "react";

import {PURCHASE_TYPES} from './../../../../../constants';
import {getTokenData} from "../../../../../services/AppServices";
import {BASKET_API} from "../../../../../services/EnvServices";
import {parseErrorOnfly} from "../../../../../utils";
import {ChequeDataType, PurchaseDetailsItemType, PurchaseDetailsRequestData, PurchaseDetailsResponse} from "./types";

const usePurchaseDetails = () => {
	const [loading, setLoading] = useState(false);
	const {accessToken} = getTokenData();

	const getPurchaseDetails = useCallback((purchaseType: PURCHASE_TYPES, basketGuid?: string, chequeData?: ChequeDataType): Promise<PurchaseDetailsItemType | null> => {
		setLoading(true);
		const data: PurchaseDetailsRequestData = {
			purchaseType,
			...(purchaseType === PURCHASE_TYPES.ONLINE ? {basketData: {basketGuid}} : {}),
			...(purchaseType === PURCHASE_TYPES.OFFLINE ? {chequeData} : {}),
		}
		return axios.post<PurchaseDetailsResponse>(BASKET_API, {
			method: "PurchaseDetail",
			data
		}, {headers: {"x-fora-authtoken": accessToken}})
			.then(({data: {data, eComError}}) => {
				if (!!eComError.errorCode) {
					throw new Error(eComError.errorMessage);
				}
				return data || null;
			})
			.catch(err => {
				parseErrorOnfly(err, true, null, true);
				return null;
			})
			.finally(() => setLoading(false));
	}, [accessToken]);

	return {getPurchaseDetails, loading}
}

export default usePurchaseDetails