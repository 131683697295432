import React from 'react';

import './Logo.styl'

interface LogoProps {
}

const Logo: React.FunctionComponent = (props: LogoProps) => {
	const {project} = process.env;
	switch (project) {
		case "silpo" :
			return (
				<a href="https://silpo.ua">
					<span  id="logo" className="logo">
						<svg version="1.1" x="0px" y="0px" viewBox="0 0 735 291.3">
				<title>silpo.ua</title>
				<g>
					<path fill="#F47B20" d="M303.2,6c-33.1,0.8-56.2,23.5-67.1,36.9c0,0-0.5,0.6-0.9,1.1c-0.7-0.2-1.3-0.5-1.3-0.5
						c-22.7-8.2-48.3-9.7-74.1-4.5C135,44.1,99.3,56.9,73.5,77.8C28,114.5,4,158.7,6.1,202.3c1.2,25.2,12.8,47.1,33.6,63.1
						c34.1,26.3,90.4,20.3,113.1,16.5c25.3-4.3,422-75.4,474-85.3c47.4-9.1,92.5-25.1,101.5-81.1c0.5-3.3,0.8-6.7,0.8-10V105
						c0-13.7-4.3-26.5-11.7-35.2c-13.9-16.1-37.2-18-53.6-18c-22.6-0.1-265.9,4.6-293.6,5.1c0,0-0.9,0-1.8,0c-0.1-0.9-0.2-1.8-0.2-1.8
						c-1.5-11.1-6-21.2-13-29.2C346.8,16.5,331,5.3,303.2,6z"/>
					<path fill="#232E83" d="M303,0c-34.2,0.8-58.4,23.5-69.8,37c-23.1-7.8-48.9-9.1-74.6-3.9c-25.5,5.2-62.2,18.4-88.9,40
						C22.7,111.1-2,157.1,0.1,202.7c1.3,27,13.7,50.4,35.9,67.5c36,27.8,94.3,21.6,117.7,17.7c24.8-4.2,421.1-75.2,474.1-85.3
						c49.5-9.4,96.6-26.4,106.3-86c0.6-3.7,0.9-7.3,0.9-10.9c0.1-15.3-4.7-29.8-13.1-39.6c-15.5-18-40.6-20.1-58.1-20.1
						c-14,0-114.4,1.7-290.5,5.1c-2.1-11-6.8-20.9-13.7-28.8C350.4,11.6,333.1-0.7,303,0z M359.7,22.1L359.7,22.1L359.7,22.1z
						 M237.7,44.2C248.3,31,271,8.8,303.2,8c27-0.7,42.3,10.2,50.4,19.4c6.8,7.7,11.1,17.4,12.5,28.1l0.4,3.6l3.6-0.1
						c27.7-0.5,270.8-5.2,293.6-5.1c15.9,0,38.7,1.9,52,17.4c7.1,8.3,11.2,20.6,11.2,33.8v0.5c0,3.3-0.3,6.5-0.8,9.7
						c-8.8,54.7-53.2,70.5-99.9,79.4c-52,9.9-448.7,81-474,85.3c-22.4,3.8-78,9.7-111.5-16.1c-20.3-15.6-31.6-36.9-32.8-61.6
						c-2-43,21.6-86.6,66.6-122.9c25.6-20.6,60.9-33.3,85.4-38.3c25.5-5.2,50.7-3.7,73,4.4l2.7,1L237.7,44.2z"/>
					<path fill="#FFFFFF" d="M703.4,112.6l-2.1-4.8l-0.7-1.6c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.6-0.1-1.1,0.3l-7.7,5.8l0.2-3.2
						c0.5-6.5-0.7-18.2-6.2-23.6c-5.1-5-12.3-7.3-21-6.7c-9.1,0.7-18.8,4.1-25.1,9.3c-9.4,7.7-15.3,21.3-15.3,21.3s0.5-4.3,0.5-8.2
						c0-6.4-1.7-11.5-5.2-15c-4.1-4.4-10.5-6.2-18.8-5.6c-2.9,0.2-10.2,2.7-14.7,7.2c0.4-1.4,1.8-5.9,1.8-5.9l-31.2,1.6l-8.9,6.8
						l-5.7,25.6c0,0-0.4-1-0.6-1.4c-3.1-6.8-9.2-12.8-18.9-11.9c-5.5,0.5-10.6,2.1-15.2,4.7l-2.9,1.7l6-24.4L472,85.7l-11.7,10.2l2.7-10
						l-42.2,0.4l-1.9,7l-0.1-0.1l-1.6-2.2c-1.6-2.2-4.5-4.1-6.5-4.9c-9-3.6-25.8-0.5-33.8,6.3c-18.5,15.6-19.3,41.8-20.4,57.3
						c-0.8,11.7-1.9,16.4-2.9,17.4c0.3-8.4-4.2-15.5-8.8-18.6c-6.1-4.1-14.8-4.2-22.3-1.7c-16,5.4-19.1,14.5-19.1,14.5l23.9-70.4
						c0,0-9.5,0.3-13.5,0.5c13.1-3.5,20.9-12.3,21.6-24.7c0,0,0-0.6,0-0.6c0-0.2,0-0.4,0-0.5c0-6.2-2.8-12.5-7.7-16.9
						c-3.7-3.3-12.1-6.8-20.4-6.9c-4.5,0-10.4,0.9-15.9,2.5c-7.3,2.1-21.6,10.1-23.3,25.6c-0.8,7.6,0.3,12.7,4.1,17.5
						c1.6,2,5.1,4.3,9.4,5.2c-5.4,0.2-9.7,0.3-10,0.3c-0.7,0.6-6.9,6.4-7.4,6.8c-0.9,2.8-6.7,21.8-10.8,32.4
						c-3.6,9.4-7.8,18.4-12.4,26.4c-13.5,23.7-30.8,38.9-49.9,44c-13.7,3.7-23.6,3.4-31.9-0.8c-8.4-4.2-14.4-17-14.3-30.4
						c0.1-14.1,6.3-32.7,10.5-40.9c14.5-28.5,26.1-35.8,36.5-39.6c8.6-3.1,14.6-2.3,15.9-1.5c-2.1,1.2-3.8,2.2-3.8,2.2
						c-14.8,8.6-24.7,22.5-25.8,36.5c-0.7,8.8,1.9,16.7,7.3,22.3c5.3,5.5,13.2,8.1,22.2,7.2c20.4-1.9,39.5-21.7,40.8-42.4
						c0.8-11.7-2.2-21.2-8.8-28.4c-11-12-24-16.2-47.8-15.6c-21.8,0.6-46.3,9-67.3,23.1c-17.9,12-41.7,34-53.2,70.3
						c-6.8,21.5-1.3,48.9,12.6,62.4c18.6,18,42.1,22.7,78.6,15.5c24.7-4.9,48.8-18.8,71.6-41.6l2-2l0.4,2.8c0.8,4.9,2.5,9,5.2,12.2
						c4.3,5.3,13.1,11,30.8,8.6c11.8-1.6,25.3-7.7,41.2-18.7l0.8-0.5l0.8,0.5c6.9,4.5,16.3,8.6,33.4,5l0.3-0.1
						c6.4-1.3,23.4-4.9,36.9-24.1c1.8-2.6,3.6-5.7,5.5-10l2.3-5.1l0.4,5.6c0.6,7.8,1.7,13.4,5.6,17.2c4.8,4.7,12,6.6,20.3,5.3
						c16.1-2.5,27.1-15.9,34.1-25.7l1.4-1.9l1.1,2.1c5.7,10.9,20.9,16.2,38.8,13.5c17-2.6,25.5-10.5,32.8-19.3l1.4-1.8l-3.3,13.5l44-6.9
						l8-33c0,0-0.2,8.9,0.8,13.7c1,4.8,3,8.8,4.9,10.7c4.3,4.2,11.5,5,16.1,4.3c7.1-1.1,17.6-4.3,27.3-18.9l1.5-2.3l1,2.5
						c3.1,7.4,14.5,12.3,25,10.6c14.2-2.3,24.9-10.4,31-23.4l0.3-0.6l0.6-0.2c7.3-2.3,14.4-9.9,16.4-17.8
						C703.5,115.9,703.9,113.9,703.4,112.6z M460,96.8c0.1-0.3,0.2-0.6,0.2-0.9C460.3,96,460.2,96.3,460,96.8L460,96.8z"/>
					<g>
						<path fill="#232E83" d="M303,87.2c13.8-0.6,27.3-6.8,28.4-21.1c0.7-9.6-6.6-19.5-21.8-20.4c-8.4-0.5-16.1,1.5-21.6,5.7
							c-4.2,3.2-7.1,7.5-8.4,13c-1.5,6.2-0.8,11.4,2.2,15.4C285.7,84.9,293.3,87.6,303,87.2z"/>
						<path fill="#232E83" d="M698.7,110.6c-2,4.4-6.1,8.9-9.7,9.5l-1.6,0.4c0,0,1.5-9.7,1.5-12.6c0.3-13.3-2.8-27.2-23.6-26.1
							c-12.7,0.7-31.3,11.8-34.2,35.7l0,0.1l0,0.1c0,0.1-0.1,0.3-0.1,0.5c-3.2,8.3-9,20-13.1,20.5c-0.9,0.1-1.7-0.2-2.3-0.8
							c-0.8-0.7-1.2-1.9-1.1-3.4c0.1-2.6,1.1-6.5,2.3-11.6c1.7-6.7,3.1-13,3.8-20c1.5-16.3-8.5-19.5-18.8-18.7
							c-7.6,0.6-14.7,5.1-16.7,7.4l-3.7,4.2c0,0,1.7-8,2.2-10.2c-3,0.1-21.3,0.7-23.2,0.8l-8.2,33.1c-2.8,3.8-6.8,6.9-10.1,7.8l-1.6,0.4
							l-0.2-1.7c-0.2-1.7-0.4-3.3-0.8-5c-0.5-3.7-3.7-16.4-16.1-15.4c-31.9,2.7-31,42.2-11.9,36.6l2.2-0.7l-0.4,2.4
							c-2.5,15.7-9.3,24-18.4,25.2c-1.4,0.2-2.7-0.2-3.7-1.2c-1.6-1.5-2.5-4.4-2.4-7.8c0.2-7.7,15.5-71.9,15.5-71.9s-27.6,1-29.6,1.1
							c-1.4,5.9-7.5,31.4-7.6,31.5l-0.9,3.4c-1.2,4.3-2,7.2-2.5,10.2l0,0.1l0,0.1c-0.1,0.2-0.1,0.4-0.2,0.5c-4.6,12.1-12.8,25.2-19,25.9
							c-1.2,0.1-2.3-0.2-3.1-1c-0.9-0.8-1.4-2.1-1.3-3.6c0,0,0-0.1,0-0.1l0.5-5.3c0,0,15.8-57.5,16.8-61.3c-3.5,0.1-33.8,0.7-33.8,0.7
							l-3,10.5c-0.8-8.5-18.8-20-32.3-7.8c-14.3,12.9-18.2,32.2-20.4,51.2c0,0-0.2,0.7-0.2,0.8c-3,22.9-8.3,38.6-24.3,40.7
							c-3.6,0.5-6.9,0.3-9.2-1.1c5.5-4.2,8.9-7.6,11.5-12c5.9-9.8,3.2-18.4-2.9-22.5c-12.4-8.1-25.9,1.8-28.3,14.4
							c-1.5,7.7,1.2,14.2,3.6,18c-4.3,2.4-7.7,5.1-13.6,5.9c-3.5,0.5-7-1.3-8.1-2.9c-3.5-4.8,1.2-20.1,1.9-22.1c0,0,21.9-65.1,23.2-69.1
							c-3.8,0.2-44.1,1.5-46.1,1.6c-1.4,4.1-6.5,20-11.7,34.7c-10.3,28.7-40.3,68-71.1,75.9c-16.4,4.3-29.2,3.5-37.4-1.5
							c-11.1-6.8-17.4-23.2-13.1-46.1c3-16.2,5.7-22.2,10-31.4c25.8-54.9,67.3-42.5,64.7-38.3c-4.2,1.4-7.9,3.6-7.9,3.6
							c-6.6,3.5-17.3,13.9-17.7,26.8c-0.3,8.3,2,15,6.5,19.4c4.4,4.3,10.9,6.3,18.2,5.6c16.4-1.5,29.7-13.8,30.3-32.1
							c0.3-9.9-2.4-18-8.2-24c-9.2-9.6-20-14.4-44-13.7c-37,1-93.5,27.6-109.7,86.4c-5.5,19.9,1.7,43.8,13.6,54.7
							c16.5,15.1,37,20.7,72.9,13.9c32-6,58.2-27.8,77.5-50.7c0,0.4,0,0.7-0.1,1.1c-4.5,16.8,1.7,34.7,29.7,31.1
							c11.7-1.5,32.3-8.2,46.7-19.4c6.6,5.8,18,9.1,31,7.2c5.3-0.8,19-4.4,30.9-21.6c9.5-13.6,14.2-40,16.8-54.3c1.2-6.6,2-11,3-12.9
							c0.9-1.7,1.7-3.8,4.2-4.9c5-2.1,7.6,2.8,6.6,6.4c-1,3.6-6.2,21.9-6.2,21.9l-1,2.9c-3.1,8.9-6.5,19-6.8,29.6
							c-0.2,7.8,1.8,14,5.9,17.8c3.8,3.5,9.2,4.9,15.6,4c17.6-2.6,30.2-20.4,36.1-30.9l0.8-1.5l1.2-2.1l0.7,3.9
							c2.8,15.6,15.1,21.8,33.5,19.1c23.9-3.5,38.3-15.9,42.7-37.5l0.2-0.9l0.8-0.3c2.2-0.8,4.4-2,6.6-3.4l1.8-1.2l-8.5,34.5
							c3.4-0.5,25.8-4.3,27.6-4.5c0.5-2.1,10.3-44,10.3-44c1.7-7.3,6.4-21.1,11.8-21.4c1-0.1,2,0.3,2.8,1.2c1.2,1.2,1.8,3.2,1.8,5.5
							c-0.1,4.6-1.5,9.7-3.2,15.6c-2,7.1-4.1,15.9-4.9,24.5c-1.3,14.6,10.9,14.5,14.6,13.9c8.7-1.3,20.2-5.8,30-27.4l0.5,2.3
							c2.5,12,13.2,19.1,24.4,17.3c13.8-2.1,23.2-9.6,28.4-22l0.4-0.8l0.8-0.1c5.4-0.6,12.9-6.9,14.8-14.2
							C699.8,113.4,699.7,111.9,698.7,110.6z M332.8,156.6c4.9,0.2,6.9,3.3,4.2,9.3c-1.4,3-5.2,7.5-10.3,11.3
							C320.4,167.3,326.5,156.3,332.8,156.6z M516.2,130.4c-0.9,1.3-2.9,2.2-6.1,2.7c-3.3,0.6-4.1-1.2-4.2-2c-0.7-2.9,2.4-7.5,5.2-8.8
							c1.6-0.7,3.4-0.4,3.9,0.2C517.3,125.1,516.2,130.4,516.2,130.4z M668.3,129c-2,6-4.7,12.4-10,13.1c-1.8,0.2-3.3-0.2-4.5-1.2
							c-1.8-1.6-2.6-3.1-2.4-8.6c0.3-12.8,7-44.7,16-45.1c1.2-0.1,2.4,0.4,3.3,1.4c1.9,2.1,2.7,6.1,2.1,10.9l-0.1,0.6
							c0,0-9.5,0.4-10.5,14.5c-0.6,8.5,6.5,12.5,6.5,12.5L668.3,129z M670.8,119.6c-3.1-1.9-2.9-7.5-1.3-9.6c0.8-1.1,2.1-1.2,2.8-0.8
							C672.2,112.6,672,115.7,670.8,119.6z"/>
					</g>
				</g>
			</svg>
       		</span>
				</a>
			);
		default :
			return (
				<span id="logo">
					<a href="https://fora.ua">
						<svg version="1.1" id="logo-svg" x="0px" y="0px" viewBox="0 0 200 59.3">
							<g>
								<g>
									<path className="logo-st0" d="M137,0H18.5C8.2,0,0,8.3,0,18.6v40.7h118.5c10.2,0,18.5-8.2,18.5-18.5L137,0L137,0z"/>
									<g>
										<path className="logo-st1" d="M42.1,18.4H42c-1.6,0-3.1,0.4-4.5,1.1c-0.5-0.7-1.3-1.1-2.3-1.1c-1,0-1.8,0.4-2.3,1.1
											c-1.4-0.7-2.9-1.1-4.5-1.1h-0.2c-2.7,0-5,0.9-6.9,2.7c-1.9,1.8-2.8,4-2.8,6.5v3.9c0,2.6,0.9,4.7,2.8,6.5c1.9,1.8,4.2,2.7,6.9,2.7
											h0.2c1.4,0,2.7-0.3,4-0.8v1.9c0,0.7,0.3,1.4,0.8,2c0.5,0.5,1.2,0.8,2,0.8c0.7,0,1.4-0.2,1.9-0.7c0.5-0.5,0.9-1,0.9-1.6
											c0-0.2,0-0.3,0-0.5V40c1.2,0.5,2.6,0.8,4,0.8h0.2c2.7,0,5-0.9,6.9-2.7c1.9-1.8,2.8-4,2.8-6.5v-3.9c0-2.6-0.9-4.7-2.8-6.5
											C47.1,19.3,44.8,18.4,42.1,18.4z M42.1,35.3H42c-1.1,0-2-0.4-2.8-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-3.9c0-1.1,0.4-1.9,1.2-2.7
											s1.7-1.1,2.8-1.1h0.2c1.1,0,2,0.4,2.8,1.1c0.8,0.7,1.2,1.6,1.2,2.7v3.9c0,1.1-0.4,1.9-1.2,2.7C44.2,34.9,43.3,35.3,42.1,35.3z
											 M28.3,35.3h-0.2c-1.1,0-2-0.4-2.8-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-3.9c0-1.1,0.4-1.9,1.2-2.7c0.8-0.7,1.7-1.1,2.8-1.1h0.2
											c1.1,0,2,0.4,2.8,1.1c0.8,0.7,1.2,1.6,1.2,2.7v3.9c0,1.1-0.4,1.9-1.2,2.7C30.3,34.9,29.4,35.3,28.3,35.3z"/>
										<path className="logo-st1" d="M64,18.4h-0.2c-2.8,0-5.2,0.9-7.2,2.7c-2,1.8-3,4-3,6.5v3.9c0,2.6,1,4.7,3,6.5c2,1.8,4.4,2.7,7.2,2.7H64
											c2.8,0,5.2-0.9,7.2-2.7c2-1.8,3-4,3-6.5v-3.9c0-2.6-1-4.7-3-6.5C69.2,19.3,66.7,18.4,64,18.4z M64,35.3h-0.2
											c-1.2,0-2.1-0.4-2.9-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-3.9c0-1.1,0.4-1.9,1.2-2.7c0.8-0.7,1.8-1.1,2.9-1.1H64c1.2,0,2.1,0.4,2.9,1.1
											c0.8,0.7,1.2,1.6,1.2,2.7v3.9c0,1-0.4,1.9-1.2,2.7C66.1,34.9,65.1,35.3,64,35.3z"/>
										<path className="logo-st1" d="M86.2,18.4H86c-1.7,0-3.3,0.4-4.7,1.1c-0.5-0.7-1.4-1.1-2.4-1.1c-0.8,0-1.6,0.3-2.1,0.8
											c-0.6,0.5-0.9,1.2-0.9,1.9v20.7c0,0.7,0.3,1.4,0.9,2c0.6,0.5,1.3,0.8,2.1,0.8c0.8,0,1.5-0.2,2.1-0.8c0.6-0.5,0.9-1.2,0.9-1.9V40
											c1.3,0.5,2.7,0.8,4.1,0.8h0.2c2.8,0,5.2-0.9,7.2-2.7c2-1.8,3-4,3-6.5v-3.9c0-2.6-1-4.7-3-6.5C91.4,19.3,88.9,18.4,86.2,18.4z
											 M86.2,35.3H86c-1.2,0-2.1-0.4-2.9-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-3.9c0-1.1,0.4-1.9,1.2-2.7c0.8-0.7,1.8-1.1,2.9-1.1h0.2
											c1.2,0,2.1,0.4,2.9,1.1c0.8,0.7,1.2,1.6,1.2,2.7v3.9c0,1-0.4,1.9-1.2,2.7C88.3,34.9,87.3,35.3,86.2,35.3z"/>
										<path className="logo-st1" d="M117.7,19.2c-0.6-0.5-1.3-0.8-2.1-0.8c-1.1,0-1.9,0.4-2.4,1.1c-1.4-0.7-3-1.1-4.7-1.1h-0.2
											c-2.8,0-5.2,0.9-7.2,2.7c-2,1.8-3,4-3,6.5v3.9c0,2.6,1,4.7,3,6.5s4.4,2.7,7.2,2.7h0.2c1.7,0,3.3-0.4,4.7-1.1
											c0.5,0.7,1.4,1.1,2.4,1.1c0.8,0,1.6-0.3,2.1-0.8c0.6-0.5,0.9-1.2,0.9-2V21.2C118.6,20.4,118.2,19.7,117.7,19.2z M108.4,35.3h-0.2
											c-1.2,0-2.1-0.4-2.9-1.1c-0.8-0.7-1.2-1.6-1.2-2.7v-3.9c0-1.1,0.4-1.9,1.2-2.7c0.8-0.7,1.8-1.1,2.9-1.1h0.2
											c1.2,0,2.1,0.4,2.9,1.1c0.8,0.7,1.2,1.6,1.2,2.7v3.9c0,1-0.4,1.9-1.2,2.7C110.5,34.9,109.6,35.3,108.4,35.3z"/>
									</g>
								</g>
								<g>
									<path className="logo-st2" d="M181.4,0h-40.7l0,40.7c0,10.2,8.3,18.6,18.5,18.6h22.1c10.2,0,18.6-8.4,18.6-18.6V18.6
										C200,8.4,191.6,0,181.4,0z"/>
									<circle className="logo-st3" cx="163.6" cy="26" r="0.6"/>
									<circle className="logo-st3" cx="177" cy="26" r="0.6"/>
									<path className="logo-st3" d="M173.4,28.8h-0.5c-0.6,0-1.1,0.4-1.1,0.9l-0.1,7.6c0,0.4,0.4,0.6,0.9,0.6h0.5c0.5,0,0.9-0.3,0.9-0.6l0.4-7.6
										C174.4,29.2,174,28.8,173.4,28.8z"/>
									<path className="logo-st3" d="M163.5,29.7c-0.1-0.5-0.6-0.9-1.2-0.9h-0.5c-0.6,0-0.9,0.6-0.9,1.1l1.3,7.4c0.1,0.4,0.4,0.6,0.9,0.6h0.5
										c0.5,0,0.8-0.3,0.8-0.6L163.5,29.7z"/>
									<path className="logo-st3" d="M167.9,28.8h-0.5c-0.6,0-1,0.4-0.9,0.9l0.6,7.6c0,0.4,0.5,0.6,0.9,0.6h0.5c0.5,0,0.9-0.3,0.9-0.6l-0.3-7.6
										C168.9,29.2,168.4,28.8,167.9,28.8z"/>
									<path className="logo-st3" d="M178.9,28.8h-0.5c-0.6,0-1.1,0.4-1.2,0.9l-0.8,7.6c0,0.4,0.3,0.6,0.8,0.6h0.6c0.5,0,0.8-0.3,0.9-0.6l1.1-7.4
										C179.9,29.4,179.5,28.8,178.9,28.8z"/>
									<path className="logo-st1" d="M184,24.1h-4.1h-2.3l-4.2-7c-0.3-0.5-1-0.7-1.6-0.4l-0.5,0.3c-0.5,0.3-0.7,1-0.4,1.6l1.9,3.1l1.4,2.4l2,3.3
										c-0.2-0.2-0.5-0.4-0.7-0.6l-2.2-2.7h-6.1l-2.2,2.7c-0.2,0.2-0.4,0.5-0.7,0.6l2-3.3l1.4-2.4l1.9-3.1c0.3-0.5,0.2-1.3-0.4-1.6
										l-0.5-0.3c-0.5-0.3-1.3-0.2-1.6,0.4l-4.2,7h-2.3h-4.1c-0.5,0-1,0.5-1,1l0.1,1.9c0,0.5,0.5,0.8,1,0.8h0.5l1.7,10.9
										c0.5,2.1,1.9,2.1,3.9,2.1l15.8,0c1.9,0,2.8-0.2,3.4-2.2l1.7-10.7h0.5c0.5,0,1-0.4,1-0.8V25C185.1,24.5,184.5,24.1,184,24.1z
										 M163,25.7c0.2-0.3,0.6-0.4,0.9-0.2s0.4,0.6,0.2,0.9c-0.2,0.3-0.6,0.4-0.9,0.2C163,26.3,162.8,26,163,25.7z M163.7,37.9h-0.5
										c-0.5,0-0.9-0.3-0.9-0.6l-1.3-7.4c-0.1-0.5,0.3-1.1,0.9-1.1h0.5c0.6,0,1.1,0.4,1.2,0.9l1,7.6C164.5,37.7,164.2,37.9,163.7,37.9z
										 M168.4,37.9h-0.5c-0.5,0-0.9-0.3-0.9-0.6l-0.6-7.6c0-0.5,0.4-0.9,0.9-0.9h0.5c0.6,0,1.1,0.4,1.1,0.9l0.3,7.6
										C169.3,37.7,168.9,37.9,168.4,37.9z M174,37.3c0,0.4-0.5,0.6-0.9,0.6h-0.5c-0.5,0-0.9-0.3-0.9-0.6l0.1-7.6c0-0.5,0.5-0.9,1.1-0.9
										h0.5c0.6,0,1,0.4,1,0.9L174,37.3z M177.6,25.7c0.2,0.3,0.1,0.7-0.2,0.9c-0.3,0.2-0.7,0-0.9-0.2c-0.2-0.3-0.1-0.7,0.2-0.9
										C177,25.2,177.4,25.3,177.6,25.7z M179.8,29.9l-1.1,7.4c-0.1,0.4-0.4,0.6-0.9,0.6h-0.6c-0.5,0-0.9-0.3-0.8-0.6l0.8-7.6
										c0.1-0.5,0.6-0.9,1.2-0.9h0.5C179.5,28.8,179.9,29.4,179.8,29.9z"/>
								</g>
							</g>
						</svg>
					</a>
				</span>
			)
	}
};

export default Logo;
