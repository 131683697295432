import React, {FC} from "react";
import "./NoPurchase.styl";
import Link from "../UI/Link";
import {SHOP_HOST} from "@services/EnvServices";

const NoPurchase: FC<{text: string}> = ({text}) => {
	return (
		<div className="no-data">
			<div className="no-purchase">
				<img src="/images/no-purchase.svg" alt=""/>
				<div dangerouslySetInnerHTML={{__html: text}} className="no-purchase-text"></div>
				<div>
					<Link to={SHOP_HOST} className="btn btn-primary">До покупок</Link>
					<Link to={`${SHOP_HOST}/all-offers`} className="btn btn-primary outline">Акції</Link>
				</div>
			</div>
		</div>
	);
};

export default NoPurchase;