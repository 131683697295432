export enum FORMS {
	LOGIN_FORM = "loginForm",
	REGISTRATION = "registration",
	PASSWORD_RECOVERY_FORM = "passwordRecoveryForm",
	CARD_ADD_FORM = "cardAddForm",
	CHANGE_PASSWORD_FORM = "changePasswordForm",
	PERSONAL_INFORMATION_FORM = "personalInformationForm",
	MONEY_BOX_FORM = "moneyBoxForm",
	MY_CONTACT_DATA = "myContactData",
	MY_CONTACT_NUMBERS = "myContactNumbers",
	ADDRESS_FORM = "addressForm",
	MY_BONUSES_FAMILY_FORM = "myBonusesFamily",
	BONUSES_EXTRA_FORM = "bonusesExtraForm",
	BONUS_PROTECTION_FORM = "bonusProtectionForm",
	TEST_FROM = "testForm",
	CONFIRM_QUESTION = "confirmQuestion",
	SUBSCRIBE_FORM = "subscribeForm",
	OTP_FORM = "otpForm",
	OTP_EMAIL_FORM = "otpEmailForm",
	PASSWORD_FORM = "passwordForm",
	CARD_FORM = "cardForm",
	REGISTRATION_INFO_FORM = "registrationInfoForm",
	EMAIL_FORM = "emailForm",
	MAIN_FORM = "mainForm",
	CREATE_PASSWORD_WITH_EMAIL_FORM = "createPasswordWithEmailForm",
	CONTACT_RECOVERY_FORM = "contactRecoveryForm",
	CREATE_PASSWORD_EMAIL_VERIFICATION_FORM = "createPasswordWithEmailVerificationForm",
	CARD_ACTIVATE_FORM = "cardActivateForm",
	VERIFY_EMAIL_FORM = "verifyEmailForm"
}