import gql from "graphql-tag";

const ResultFields = `limit offset count`;

const FlowFragment = gql`
    fragment FlowFragment on Flow {
			flowId
			currentPlace
			nextStep
    }
`;

const SiteOrderFragment = gql`
fragment SiteOrderFragment on SiteOrder{
  id
  createdAt
  expiresAt
  totalPrice
  check_url
  orderItems {
    id
    price
    quantity
    metadata
    product {
      ... on Cert{
            id
            name
            rating
            price
            description
          }
      ... on Ticket{
             id
             name
             price
             description
             isEntrance
             lectionStartAt
             event{
               address
               place
               startAt
               title
               slug
             }
          }
      __typename
    }
    __typename
  }
  __typename
    }
`;

const OrderFragment = gql`
    fragment OrderFragment on Order{
          id
          number
          statusName
          statusId
          createDate
          customerPhone
          customerName
          email
          filialId
          check_url
          readyDate
          check_url
		  paid
          form_url
          interval
          totalCount
          note
          url
          barCode
          sendingSMS
          orderSum
          transactionDate
          bankMFO
          barCodeImg
          bankMFO
          merchantId
          terminalId
          rrn
          proxyPan
          cardBPId
          authorizeCode
          lagers{
                number
                name
                count
                price
                unit
                __typename
          }
          __typename
    }
`;

const BasketFragment = gql`
    fragment BasketFragment on Basket{
      expiredDate
      filialId
      totalCount
      totalPrice
      lagers{
        id
        name
        lagerUnit
        remnant
        items{
          id
          unit
          price
          count
          added
          __typename
        }
        __typename
      }
      __typename
    }

`;

const ApplicationFragment = gql`
    fragment ApplicationFragment on Application {
      name
      googlePlayLink
      appStoreLink
      appStoreLinkSource
      googlePlayLinkSource
      description
    }
`;

const AccessTokenFragment = gql`
    fragment AccessTokenFragment on AccessToken {
        accessToken
        expiresAt
    }
`;

const FileBaseFragment = gql`
    fragment FileBaseFragment on File {
        id
        url
    }
`;

const CityBaseFragment = gql`
    fragment CityBaseFragment on City {
        id
        title
    }
`;

const LocationBaseFragment = gql`
    fragment LocationBaseFragment on Location {
        lat
        lng
    }
`;

const PeriodBaseFragment = gql`
    fragment PeriodBaseFragment on Period {
        start
        end
    }
`;

const LabelBaseFragment = gql`
    fragment LabelBaseFragment on Label {
        title
        description
        icon { url }
    }
`;

const StoreServiceBaseFragment = gql`
    fragment StoreServiceBaseFragment on StoreService {
        id
        title
        icon { url }
    }
`;

const AppearanceBaseFragment = gql`
    fragment AppearanceBaseFragment on Appearance {
        size
        color
    }
`;

const StoreBaseFragment = gql`
    fragment StoreBaseFragment on Store {
        id
        title
        premium
    }
`;

const PromotionBaseFragment = gql`
    fragment PromotionBaseFragment on Promotion {
        id
        slug
        type
        title
        description
        color
        image{ url }
    }
`;

const FAQQuestionBaseFragment = gql`
    fragment FAQQuestionBaseFragment on FAQQuestion {
        id
        title
        description
    }
`;

const FAQCategoryBaseFragment = gql`
    fragment FAQCategoryBaseFragment on FAQCategory {
        id
        title
        icon { url }
        questions { ...FAQQuestionBaseFragment }
    }
    ${FAQQuestionBaseFragment}
`;

const SkuFragment = gql`
	fragment SkuFragment on SkuItem{
		id
		skuNameUA
		alcoholContent
		ethno
		country
		trademark
		manufacturer
		bouquetTone
		servingTemperature
		gastronomicCombination
		glutenFree
		advancedRecipe
		vegan
		wortDensity
		IBUBitterness
		style
}
`;

const ProductBaseFragment = gql`
    fragment ProductBaseFragment on Product {
        id
        slug
        title
        type
				sku_info{
    			...SkuFragment
   				 __typename
  			}
        image { url }
        weight
        description
        price
        oldPrice
        discount
        points
        pointsText
        label { ...LabelBaseFragment }
        activePeriod { ...PeriodBaseFragment }
        category { id }
        appearance { ...AppearanceBaseFragment }
    }
    ${PeriodBaseFragment}
    ${AppearanceBaseFragment}
    ${LabelBaseFragment}
    ${SkuFragment}
`;



const PressReleaseBaseFragment = gql`
    fragment PressReleaseBaseFragment on PressRelease {
        id
        slug
        title
        pdfFile { url }
        publishedAt
    }
`;

const DayLoadBaseFragment = gql`
    fragment DayLoadBaseFragment on DayLoad {
        dayCode
        start
        end
        data {
            from
            to
            traffic
        }
    }
`;

const SearchableAddressFragment = gql`
    fragment SearchableAddressFragment on SearchableAddress {
        region
        district
        city
        cityType
        cityDistrict
        shortCityType
        subCity
        subCityDistrict
        street
        streetType
    }
`;

const AddressFragment = gql`
    fragment AddressFragment on Address {
        postIndex
        region
        district
        city
        cityType
        subCity
        street
        streetType
        buildingNumber
        apartment
    }
`;
const MailBoxTypeFragment = gql`
    fragment MailBoxTypeFragment on MailBoxType {
        postIndex
        postBoxNumber
    }
`;

const PressReleaseSelectedFragment = gql`
    fragment PressReleaseSelectedFragment on PressRelease {
        ...PressReleaseBaseFragment
        description: previewDescription
        image: previewImage { url }
    }
    ${PressReleaseBaseFragment}
`;

const PressReleaseFragment = gql`
    fragment PressReleaseFragment on PressRelease {
        ...PressReleaseBaseFragment
        backgroundImage { url }
        content
        fbLink
        twitterLink
    }
    ${PressReleaseBaseFragment}
`;

const PromotionFragment = gql`
    fragment PromotionFragment on Promotion {
        ...PromotionBaseFragment
        labelIcon { url }
        labelIconReversed { url }
    }
    ${PromotionBaseFragment}
`;

const PersonFragment = gql`
    fragment PersonFragment on Person {
        id
        name
        phones
        emails
    }`;

const PromoFragment = gql`
    fragment PromoFragment on Promo {
			id
			title
			description
			detailsButton
			position
			image { url }
			appearance { ...AppearanceBaseFragment }
			type
			promotion {
				slug
				title
				__typename
			}
			campaign {
				slug
				title
				__typename
			}
			link
			video
			displayType
			imageSmall{
				url
			}
			imageMiddle{
				url
			}
			imageFull{
				url
			}

		}
    ${AppearanceBaseFragment}
		`;

const StoreFragment = gql`
    fragment StoreFragment on Store {
        ...StoreBaseFragment
        slug
        city { ...CityBaseFragment }
        workingHours {
            start
            end
        }
        location { ...LocationBaseFragment }
        dayLoads { ...DayLoadBaseFragment }
    }
    ${StoreBaseFragment}
    ${CityBaseFragment}
    ${LocationBaseFragment}
    ${DayLoadBaseFragment}
`;

const StoresPageFragment = gql`
    fragment StoresPageFragment on Store {
        id
        title
        premium
        slug
        city { ...CityBaseFragment }
        workingHours {
            start
            end
        }
        location { ...LocationBaseFragment }
        services {
            enabled
            service {
                ...StoreServiceBaseFragment
            }
        }
    }
    ${CityBaseFragment}
    ${LocationBaseFragment}
    ${StoreServiceBaseFragment}
`;

const HomepageProductFragment = gql`
    fragment HomepageProductFragment on Product {
        ...ProductBaseFragment
        promotion {
            ...PromotionFragment
        }
        stores {
            id
        }
    }
    ${PromotionFragment}
    ${ProductBaseFragment}
`;

const ProductFragment = gql`
    fragment ProductFragment on Product {
        ...ProductBaseFragment
        promotion {
            id
            slug
            title
            labelIcon { url }
            labelIconReversed { url }
        }
        stores {
            id
            title
            premium
            slug
            city {
                id
                title
            }
            workingHours {
                start
                end
            }
            location {
                lat
                lng
            }
        }

				propertiesBlockTitle
				propertiesBlockDescription
				properties{
					description
					image{
						url
					}
				}
    }
    ${ProductBaseFragment}
`;

const OptimizedProductsFragment = gql`
    fragment OptimizedProductsFragment on Product {
        id
        slug
        type
        title
        weight
        image {url}
        price
        oldPrice
        discount
        points
        pointsText
        appearance {
            size
            color
        }
        promotion {
            id
            slug
            labelIcon { url }
            labelIconReversed { url }
        }
        activePeriod {
            start
            end
        }
    }
`;

const UserFragment = gql`
    fragment UserFragment on User {
				memberId
        firstName
        lastName
        middleName
        email
        emailConfirmed
        currentBonus
        currentBalance
        barcode
        spawnNextCouponDate
				fullPhoneNumber
    }
`;

const DetailedUserFragment = gql`
    fragment DetailedUserFragment on DetailedUser {
				memberId
        firstName
        lastName
				middleName
        gender
        email
        birthday
        mobilePhoneCode
        mobilePhoneNumber
    }
`;

const DetailedUserAddress = gql`
    fragment DetailedUserAddress on DetailedUser {
        id
        address { ...AddressFragment }
        mailBox { ...MailBoxTypeFragment }
        addressType
    }
    ${AddressFragment}
    ${MailBoxTypeFragment}
`;

const DetailedUserOtherDataFragment = gql`
    fragment DetailedUserOtherDataFragment on DetailedUser {
        id
        otherData {
            hasCar
            relationshipStatus
            allergy
            animals { id name }
            dietHabits { id name }
        }
    }
`;

const CardFragment = gql`
    fragment CardFragment on Card {
        barcode
        typeName
        issueDate
        status
        statusName
        isStatusChangeable
    }
`;

const MoneyboxFragment = gql`
    fragment MoneyboxFragment on MoneyBox {
        title
        status
    }
`;

const OptimizedCouponsFragment = gql`
    fragment OptimizedCouponsFragment on Coupon {
        id
        useType
				type
        startedAt
        endAt
        isChangeable
        isCouponControl
        listImages
        listBrands
        signText
        rewardValue
        unitText
        couponDescription
        promoDescription
        limitDescription
        isOff
        isLike
        multipleUsingEnable
				archived
    }
`;

// fixme : use less data store { }. Here is not need all data from StoreFragment
const CouponsFragment = gql`
    fragment CouponsFragment on Coupon {
        id
        isLike
        position
        useType
        status
        type
        modifiedAt
        startedAt
        endAt
        signText
        rewardValue
        listImages
        listLogos
        rewardText
        unitText
        couponDescription
        promoDescription
        warningDescription
        longWarningDescription
        limitDescription
        addressDescription
        addresses {
            city
            street
            store {
                ...StoreFragment
            }
        }
        isChangeable
        archived
        multipleUsingEnable
    }
    ${StoreFragment}
`;

const PhoneNumberFragment = gql`
    fragment PhoneNumberFragment on PhoneNumber {
        phoneCode
        phoneNumber
        position
        comment
    }
`;

const VoucherFragment = gql`
    fragment VoucherFragment on Voucher {
        id
        value
        useType
        voucherType
		voucherText
        typeDescription
        status
        modifiedAt
        startedAt
        endAt
        items {
            name
            type
            value
        }
        isChangeable
    }
`;

const ContactUsedForOtpFragment = gql`
	fragment ContactUsedForOtpFragment on ContactUsedForOtp {
		contactType
		contactValue
		__typename
	}
`;

export {
	ResultFields,
	AccessTokenFragment,

	FileBaseFragment,
	CityBaseFragment,
	LocationBaseFragment,
	PeriodBaseFragment,
	LabelBaseFragment,
	StoreServiceBaseFragment,
	AppearanceBaseFragment,
	StoreBaseFragment,
	PromotionBaseFragment,
	FAQQuestionBaseFragment,
	FAQCategoryBaseFragment,
	ProductBaseFragment,
	PressReleaseBaseFragment,
	DayLoadBaseFragment,

	SearchableAddressFragment,
	AddressFragment,
	PressReleaseSelectedFragment,
	PressReleaseFragment,
	PromotionFragment,
	OptimizedProductsFragment,
	ProductFragment,
	PersonFragment,
	PromoFragment,
	StoreFragment,
	StoresPageFragment,
	UserFragment,
	DetailedUserFragment,
	DetailedUserAddress,
	DetailedUserOtherDataFragment,
	CardFragment,
	MoneyboxFragment,
	CouponsFragment,
	OptimizedCouponsFragment,
	PhoneNumberFragment,
	VoucherFragment,
	HomepageProductFragment,
	ApplicationFragment,
	BasketFragment,
	OrderFragment,
	SiteOrderFragment,
	FlowFragment,
	ContactUsedForOtpFragment
};
