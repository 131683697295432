import "./DropDownMenu.styl";

import React, {Component} from "react";
import cn from "classnames";
import {compose, withProps} from "recompose";



interface DropDownMenuProps {
	hide: () => void;
	preventClose: boolean;
	component:  string | React.ComponentType<any>
	className?:string;
}

interface DropDownMenuState {
}

class DropDownMenu extends Component<DropDownMenuProps> {
	handleClickOutside() {
		this.props.hide();
	}

	onClick = () => {
		const {preventClose} = this.props;
		if (!preventClose) this.handleClickOutside();
	};

	stopScrollPropagation = (e) => {
		e.stopPropagation();
	};

	render() {
		const {className, component, children} = this.props;
		const Component = component || "div",
			props = {
				onScroll: this.stopScrollPropagation,
				className: cn("drop-down-menu", className),
				onClick: this.onClick,
				children,
			};

		return (
			<Component {...props}/>
		);
	}
}



export default compose<DropDownMenuProps,DropDownMenuState>(
	withProps(() => ({
		eventTypes: ["click"],
	}))
)(DropDownMenu);