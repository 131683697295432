
import React, {Component} from "react";
import Modal from 'react-responsive-modal';

import Button from "../../UI/Button/Button";
import getTemplateText from "../../../services/TextService";
import {CardLockModalPropsType} from "./types";

import "./CardLockModal.styl";

class CardLockModal extends Component<CardLockModalPropsType, {}> {

	static defaultProps = {
		onSuccess: () => null
	};

	/**
	 * handles user response
	 * @param response
	 */
	onResponseGiven = (response) => () => {
		const {onSuccess, onClose} = this.props;
		if (response === "yes") {
			onSuccess && onSuccess();
		}
		onClose();
	};

	render() {
		const {card, actionText, onClose, open} = this.props;
		if (!card) return;
		return (
			<Modal focusTrapped={false} open={open} classNames={{"overlay": "modal-overlay", "modal": "modal-window card-lock-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}
							onClose={onClose}>
					<div className="card-lock-modal__message">
						<h3 className="card-lock-modal__heading">
							{actionText} вашу картку<br/>&laquo;Фора club&raquo;?
						</h3>
						<div className="card-lock-modal__buttons">
							<Button def className="card-lock-modal__button card-lock-modal__button--no"
											onClick={this.onResponseGiven("no")}>{getTemplateText("no")}</Button>
							<Button def className="card-lock-modal__button card-lock-modal__button--yes"
											onClick={this.onResponseGiven("yes")}>{getTemplateText("yes")}</Button>
						</div>
					</div>
			</Modal>
		)
	}
}


export default CardLockModal;