import React, {Component} from 'react';
import { FormikProps, withFormik} from 'formik';
import {normalizeFields, validate} from "../../services/FormServices/FormService";
import {compose, withProps} from "recompose";

import Form from "../../components/Form/Form";
import "./NearestStoreForm.styl";
import Button from "../../components/UI/Button/Button";

import WithContext from "../../components/HOCs/WithContext/WithContext";
import FormError from "../../components/Form/FormError/FormError";
import Icon from "../../components/UI/Icon/Icon";
import {ICONS} from "../../components/UI/Icon/enums";
import getTemplateText from "../../services/TextService";

import {SubscriptionsFormOtherProps, SubscriptionsFormValues} from "./types";
import NearestStore from "./NearestStore";
import Panel from "../../components/UI/Panel/Panel";
import {FORMS} from "../enums";

const form = FORMS.SUBSCRIBE_FORM,
	fields = {
		city: {
			name: "city"
		},
		store: {
			name: "store",
		},
	};

//todo: fix types
const NearestStoreForm = compose<FormikProps<SubscriptionsFormValues> & SubscriptionsFormOtherProps, { form: string }>(
	withProps<{}, SubscriptionsFormOtherProps>((props) => {
		return {
			form,
			fields: normalizeFields(fields, form),
			stores: props.initialValues.stores
		}
	}),
	withFormik<SubscriptionsFormOtherProps, SubscriptionsFormValues>({
		// Transform outer props into form values
		mapPropsToValues: props => {
			return {
				submitError: '',
				stores: (props.initialValues.stores  || []).reduce((obj, store) => {
					obj[store._id] = store;
					return obj;
				}, {})
			};
		},
		validate,
		isInitialValid: true,
		handleSubmit: (values: SubscriptionsFormValues, formProps) => {
			formProps.props.onSubmit(values, formProps);
		}
	})
)(class extends Component<FormikProps<SubscriptionsFormValues> & SubscriptionsFormOtherProps, {}> {

	render() {
		const {
			showSuccess,
			values,
			errors,
			handleSubmit,
			isSubmitting,
			isValid,
			citiesWithStores,
			setFieldValue,
			setFieldTouched
		} = this.props;
		return (
			<Panel className="nearest-store-panel">
				<Form onSubmit={handleSubmit} className="nearest-store-form" name={form}>
				<NearestStore
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
						citiesWithStores={citiesWithStores}
						values={values}
						stores={this.props.stores}
						unregisterField={this.props.unregisterField}
					/>

					<div className="form__footer-status">
						{errors && errors.submitError && <FormError>{errors.submitError}</FormError>}

						{showSuccess && (
							<div className="form__footer-success">
								<Icon icon={ICONS.circleCheck}/>{getTemplateText("form.success")}
							</div>
						)}
					</div>
					<Button primary type="submit" className="select-store"
									disabled={!isValid || isSubmitting}
					>
						зберегти
					</Button>
				</Form>
			</Panel>
		)
	}
});

export default WithContext(
	(state) => ({user: state.user}))(NearestStoreForm);