import React, {FC} from "react";
import cn from "classnames";

import {FormattedHTMLMessage} from "@services/TextService";
import {UnitedExntranceWrapperPropsType} from "./types";
import foraClub from "images/fora-club.png";

import "./UnitedEntranceWrapper.styl";

const UnitedEntranceWrapper: FC<UnitedExntranceWrapperPropsType> = (props) =>   {

	const {className, title, withoutTitleImage, children} = props;

	return (
		<div className={cn("auth", className)}>
			<h3 className="modal-title">
				{!withoutTitleImage && <img src={foraClub} alt="Fora Club"/>}
				<FormattedHTMLMessage id={title ? title: "unitedEntrance.title"}/>
			</h3>
			{children}
		</div>
	);
};

export default UnitedEntranceWrapper;