import "./ShowMore.styl";

import React, {FunctionComponent} from "react";
import cn from "classnames";

import {FormattedHTMLMessage} from "../../../services/TextService";
import {ShowMoreProps} from "./types";

import Button from "../Button/Button";

const ShowMore: FunctionComponent<ShowMoreProps> = ({children = <FormattedHTMLMessage id="showMore"/>, className, block = true, ...props}) => {
	return (
		<Button {...props} block={block} className={cn("btn-show-more", className)}>{children}</Button>
	)
};

export default ShowMore;