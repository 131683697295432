import React, {FC, useEffect, useState} from "react";
import Modal from "react-responsive-modal";

import getTemplateText, {FormattedHTMLMessage} from "../../../services/TextService";
import {WelcomeBlockPropsType} from "./types";
import AppsBlock from "../../Layout/Menu/SidebarMenu/AppsBlock/AppsBlock";
import foraAppStoreQr from "images/fora-qr-code-AppStore.gif";
import foraGooglePlayQr from "images/fora-qr-code-PlayMarket.gif";
import {isFunction, isTargetProject} from "../../../utils";
import {AUTH_NEW_ENTRANCE_ACTIONS, PROJECT} from "../../../constants";
import actions from "../../../context/actions";
import WithContext from "../../HOCs/WithContext/WithContext";
import UnitedEntranceWrapper from "@components/AuthSteps/components/UnitedEntranceWrapper/UnitedEntranceWrapper";

import "./WelcomeModal.styl";

const WelcomeModal: FC<WelcomeBlockPropsType> = ({isOpen, onClose, sighUpModal, setSighUpModal}) =>  {
	const [withCard, setWithCard] = useState(AUTH_NEW_ENTRANCE_ACTIONS.authWelcomeWithCard === sighUpModal);
	useEffect(() => {
		if (isFunction(onClose) && !sighUpModal) {
			onClose()
		}
	}, [onClose, sighUpModal]);

	useEffect(() =>{
		return () => {
			setSighUpModal(null);
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setWithCard(AUTH_NEW_ENTRANCE_ACTIONS.authWelcomeWithCard === sighUpModal)
	}, [sighUpModal]);

	const appStoreLink = isTargetProject(PROJECT.FORA) ?
		"https://apps.apple.com/us/app/фора/id1460809674" :
		"https://itunes.apple.com/app/id1392610549";
	const googlePlayLink = isTargetProject(PROJECT.FORA) ? " https://play.google.com/store/apps/details?id=ua.fora.android" :
		"https://play.google.com/store/apps/details?id=ua.silpo.android&referrer=utm_source%3Dsite%26utm_medium%3Dmenu%26utm_term%3Dapp-download";

	return (
		<Modal
			focusTrapped={false}
			open={isOpen}
			onClose={onClose}
			center
			classNames={
				{"overlay": "modal-overlay welcome-overlay",
					"modal": "modal-window auth-modal welcome-modal",
					"closeButton" : "modal-closeButton",
					"closeIcon": "modal-closeIcon"
				}}
		>
			<UnitedEntranceWrapper
				title="unitedEntrance.welcomeTitle"
			>
			<div className="welcome__wrapper">
				<div className="modal-description">
					<FormattedHTMLMessage id="welcomeModal.description"/>
				</div>
				<AppsBlock
					appStoreLinkSource={foraAppStoreQr}
					appGooglePlayLinkSource={foraGooglePlayQr}
					appStoreLink={appStoreLink}
					googlePlayLink={googlePlayLink}
				/>
				<span className="text-divider">
					<FormattedHTMLMessage id="initial-entrance.modalWindow.or"/>
				</span>
				<div className="modal-description">
					{getTemplateText(withCard ? "welcomeModal.description.alternativeWithCard" : "welcomeModal.description.alternative")}
				</div>
			</div>
			</UnitedEntranceWrapper>
		</Modal>
	)
};

export default WithContext(
	({sighUpModal}) => ({sighUpModal}),
	dispatch => ({
		setSighUpModal: (sighUpModal) => dispatch(actions.setSighUpModal(sighUpModal)),
		dispatch
	})
)(WelcomeModal);
