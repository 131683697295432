import React, {FC, useState} from 'react';

import SuccessRepeatOrder from '../../../../../../components/Modals/SuccessRepeatOrder/SuccessRepeatOrder';
import Button from '../../../../../../components/UI/Button/Button';
import ButtonsLoader from '../../../../../../components/UI/ButtonsLoader/ButtonsLoader';
import {ICONS} from '../../../../../../components/UI/Icon/enums';
import Icon from '../../../../../../components/UI/Icon/Icon';
import {PURCHASE_TYPES} from '../../../../../../constants';
import getTemplateText from '../../../../../../services/TextService';
import {PurchaseProductItemType} from '../../../hooks/usePurchaseDetails/types';
import useRepeatOrder from '../../../hooks/useRepeatOrder';
import PurchaseItemGeneralInfo, {PurchaseItemGeneralPropsType} from '../PurchaseItemGeneralInfo/PurchaseItemGeneralInfo';
import PurchaseItemPaymentInfo from '../PurchaseItemPaymentInfo/PurchaseItemPaymentInfo';
import PurchaseProductItem, {DELIVERY_ID} from '../PurchaseProducts/PurchaseProductItem';

import "./PurchaseItemDetails.styl";

const PurchaseItemDetails: FC<PurchaseItemGeneralPropsType> = ({itemDetails, refetchOrder, chequeData}) => {
	const [isRepeatCompleted, setIsRepeatCompleted] = useState(false);
	const [repeatedBasketGuid, setRepeatedBasketGuid] = useState<string | null>(null);

	const [selectedItems, setSelectedItems] = useState<PurchaseProductItemType[]>([]);
	const {repeatOrder, loading} = useRepeatOrder();
	const [isSelectingMode, setIsSelectingMode] = useState(false);
	const isRepeatAvailable = itemDetails.actions.includes("repeat")

	const removeFromSelected = (selectedItem: PurchaseProductItemType) => {
		setSelectedItems([...selectedItems.filter(item => item.number !== selectedItem.number)]);
	}

	const addToSelected = (selectedItem: PurchaseProductItemType) => {
		setSelectedItems([...selectedItems, selectedItem]);
	}

	const handleSelectingMode = () => {
		setIsSelectingMode(true);
		setSelectedItems(itemDetails.orderLines);
	}

	const cancelSelectingMode = () => {
		setIsSelectingMode(false);
		setSelectedItems([]);
	}


	const handleRepeatOrder = () => {
		let basketGuid;
		const productsList = selectedItems
			.map(item => ({key: item.number, value: item.actualCount || item.orderCount}))
			.filter(item => item.key !== DELIVERY_ID);
		if (!productsList.length) {
			return;
		} else {
			if (itemDetails.purchaseType === PURCHASE_TYPES.ONLINE) {
				basketGuid = itemDetails.id;
			}
			repeatOrder(itemDetails.purchaseType, productsList, basketGuid, chequeData)
				.then(res => {
					setIsRepeatCompleted(true);
					setRepeatedBasketGuid(res);
				})
		}
	}

	return (
		<div className='purchase-details'>
			<div className='purchase-details-general'>
				<PurchaseItemGeneralInfo itemDetails={itemDetails} refetchOrder={refetchOrder} />
				<PurchaseItemPaymentInfo itemDetails={itemDetails} />
			</div>
			{isRepeatAvailable &&
				(isSelectingMode ?
					<>
						<Button disabled={loading} onClick={handleRepeatOrder} className='repeat-btn-mobile' primary>
							<Icon raw icon={ICONS.basketBold} />
							{getTemplateText("myPurchases.order.repeat.add")}
						</Button>
						<Button onClick={cancelSelectingMode} className='cancel-btn-mobile'>
							{getTemplateText("myPurchases.order.repeat.cancel")}
						</Button>
					</>
					: <Button onClick={handleSelectingMode} className='repeat-btn-mobile' primary>
						<Icon raw icon={ICONS.basketBold} />
						{getTemplateText("myPurchases.order.repeat.btn")}
					</Button>
				)}

			<div className='purchase-details-order-title'>
				<span>{getTemplateText("myPurchases.your-order")}</span>
				<div>
					{isRepeatAvailable &&
						(isSelectingMode ?
							<>
								<Button onClick={cancelSelectingMode} className='cancel-btn'>
									{getTemplateText("myPurchases.order.repeat.cancel")}
								</Button>
								<Button disabled={loading} onClick={handleRepeatOrder} className='repeat-btn' primary>
									{loading ? <ButtonsLoader /> : <Icon raw icon={ICONS.basketBold} />}
									{getTemplateText("myPurchases.order.repeat.add")}
								</Button></>
							: <Button onClick={handleSelectingMode} className='repeat-btn' primary>
								<Icon raw icon={ICONS.basketBold} />
								{getTemplateText("myPurchases.order.repeat.btn")}
							</Button>)
					}
				</div>
			</div>
			<ul>
				{itemDetails.orderLines.map(item => <PurchaseProductItem
					key={item.number}
					product={item}
					filialId={itemDetails.filialId}
					deliveryType={itemDetails.deliveryType}
					removeFromSelected={removeFromSelected}
					addToSelected={addToSelected}
					isSelectingMode={isSelectingMode}
				/>)}
			</ul>
			{
				isRepeatCompleted &&
					<SuccessRepeatOrder isOpen={true} onClose={() => setIsRepeatCompleted(false)} basketGuid={repeatedBasketGuid} />
			}
		</div >
	);
};

export default PurchaseItemDetails;