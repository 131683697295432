import React, {Component} from "react";
import {graphql, MutationFunction} from "react-apollo";
import {ErrorResponse} from 'apollo-link-error';
import {compose, shallowEqual} from "recompose";

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {phonesDataResponseType} from "../../graphql/types";
import getTemplateText from "../../services/TextService";
import {ICONS} from "../UI/Icon/enums";
import {FORMS} from "../../forms/enums";
import {normalizePhone, parsePhone} from "../../services/FormServices/FormService";
import MyContactsNumberForm, {MyContactsNumberFormValues} from "../../forms/MyContactsNumberForm/MyContactsNumberForm";
import {ERRORS, MAX_PHONES_AMOUNT} from "../../constants";

import FormError from "../Form/FormError/FormError";
import Panel from "../UI/Panel/Panel";
import Button from "../UI/Button/Button";
import Icon from "../UI/Icon/Icon";

import "./MyContactsNumbers.styl";

type MyContactsNumber = {
	phoneCode: string;
	phoneNumber: string;
	position: number;
	comment: string;
}

type MyContactsNumbersStateType = {
	showAddNumber: boolean;
	editPhone: string | null;
	errors: object | "";
	removingPhones: any[];
	fields: any[];
}

type InnerMyContactsNumbersPropsType = {
	phonesData: phonesDataResponseType;
	createPhoneNumber: MutationFunction;
	removePhoneNumber: MutationFunction;
	editPhoneNumber: MutationFunction;
};

/**
 * forms normalized phone numbers
 * @param phoneNumbers {array}
 */
const getNormalizedFields = (phoneNumbers: MyContactsNumber[]): MyContactsNumber[] => {
	return (phoneNumbers || [])
		.map(d => ({
			...d,
			phone: normalizePhone(d.phoneCode, d.phoneNumber)
		}));
};

/**
 * compares previous phone list to new list
 * @param prevFields
 * @param nextFields
 * @returns boolean;
 */
const compareLists = (prevFields, nextFields): boolean => {
	const prev = prevFields.map(d => d.phone + d.comment),
		next = nextFields.map(d => d.phone + d.comment);

	return shallowEqual(prev, next);
};

//TODO implement one parse error method
/**
 * parses add phone numbers errors
 * @param err
 */
const parsePhoneErrors = (err: ErrorResponse) => {
	if (err["graphQLErrors"]) {
		for (const errElement of err["graphQLErrors"]) {
			if (errElement["fields"] && errElement["fields"].length > 0) {
				const phoneError = errElement["fields"].find(f => ~["phoneCode", "phoneNumber"].indexOf(f.field)),
					commentError = errElement["fields"].find(f => ~["comment"].indexOf(f.field));
				return {
					...(commentError ? {comment: commentError.message} : {}),
					...(phoneError ? {phone: phoneError.message} : {}),
				};
			} else if (errElement["message"] && errElement["code"] === ERRORS.CODE_INTERNAL_SERVER_ERROR) {
					return {
						...(errElement ? {message: errElement.message} : {}),
					}
			}
		}
	}
};

export default compose<InnerMyContactsNumbersPropsType, {}>(
	graphql(Query.phoneNumbers.query, {name: "phonesData"}),
	graphql(Mutation.createPhoneNumber.mutation, {name: "createPhoneNumber"}),
	graphql(Mutation.removePhoneNumber.mutation, {name: "removePhoneNumber"}),
	graphql(Mutation.editPhoneNumber.mutation, {name: "editPhoneNumber"}),
)(class MyContactsNumbers extends Component<InnerMyContactsNumbersPropsType, MyContactsNumbersStateType> {
	constructor(props) {
		super(props);
		this.state = {
			showAddNumber: false,
			editPhone: null,
			errors: "",
			removingPhones: [],
			fields: [],
		};
	}

	static getDerivedStateFromProps(nextProps: InnerMyContactsNumbersPropsType, prevState) {
		const fields = getNormalizedFields(nextProps.phonesData.phoneNumbers);
		if (!compareLists(prevState.fields, fields)) {
			return {fields};
		}
		return null;
	}

	/**
	 * handles edit phone event
	 * @param field
	 * @returns function
	 */
	onEditPhone = (field) => (newPhone, formProps) => {
		const {phoneCode, phoneNumber, position, comment} = field;
		const {phone} = newPhone;
		const parsedPhone = parsePhone(phone);

		return this.props.editPhoneNumber({
			variables: {
				oldPhoneNumber: {phoneCode, phoneNumber, position, comment},
				phoneNumber: {
					position,
					phoneCode: parsedPhone.code,
					phoneNumber: parsedPhone.number,
					comment: newPhone.comment || "",
				},
			}
		})
			.then(r => {
				if (typeof r === "object" && r.data.editPhoneNumber) {
					this.props.phonesData.refetch()
						.then(this.cancelEditContactRow);
				}
			})
			.catch(err => {
				const errors = parsePhoneErrors(err);
				if (typeof errors === "object" && Object.keys(errors).length) {
					formProps.setErrors(errors);
					this.setState({errors});
				}
			});
	};

	/**
	 * sets editing phone field
	 * @param phone
	 */
	editContactRow = (phone) => () => {
		this.setState({editPhone: phone});
	};

	/**
	 * add removed phone to list of removing phones
	 * @param phone
	 */
	startRemovingPhone = (phone) => {
		this.setState({removingPhones: [...this.state.removingPhones, phone]});
	};

	/**
	 * filters removing phones list
	 * @param phone
	 */
	endRemovingPhone = (phone) => {
		this.setState({removingPhones: this.state.removingPhones.filter(d => d !== phone)});
	};

	/**
	 * handles removing phone
	 * @param field
	 * @returns function
	 */
	removeContactRow = (field) => () => {
		const {phone, phoneCode, phoneNumber} = field;

		this.startRemovingPhone(phone);
		return this.props.removePhoneNumber({
			variables: {
				phoneNumber: {phoneCode, phoneNumber}
			}
		})
			.then(r => {
				if (typeof r === "object" && r.data.removePhoneNumber) {
					this.props.phonesData.refetch();
					this.endRemovingPhone(phone);
				}
			})
			.catch(err => {
				this.endRemovingPhone(phone);
				const errors = parsePhoneErrors(err);

				if (typeof errors === "object" && Object.keys(errors).length) {
					this.setState({errors});
				}
			});
	};

	/**
	 * cancels editing phone field
	 */
	cancelEditContactRow = () => {
		this.setState({editPhone: null, errors: ""});
	};

	/**
	 * shows adding new phone block
	 */
	showAddNumber = () => {
		this.setState({showAddNumber: true})
	};

	/**
	 * hides adding new phone block
	 */
	hideAddNumber = () => {
		this.setState({showAddNumber: false, errors: ""})
	};

	/**
	 * handles adding new number event
	 * @param values
	 * @param formProps
	 */
	addNewNumber = (values: MyContactsNumberFormValues, formProps) => {
		const {phone, comment} = values;
		const parsedPhone = parsePhone(phone);

		return this.props.createPhoneNumber({
			variables: {
				phoneNumber: {
					comment,
					phoneCode: parsedPhone.code,
					phoneNumber: parsedPhone.number,
					position: this.getPhoneNumbersLength() + 1
				}
			}
		})
			.then(r => {
				if (typeof r === "object" && r.data.createPhoneNumber) {
					this.props.phonesData.refetch()
						.then(this.hideAddNumber);
				}
			})
			.catch(err => {
				const errors = parsePhoneErrors(err);
				if (typeof errors === "object" && Object.keys(errors).length) {
					formProps.setErrors(errors);
					this.setState({errors});
				}
			});
	};

	getPhoneNumbersLength = (props = this.props) => {
		const {phonesData: {phoneNumbers}} = props;
		return (phoneNumbers || []).length;
	};


	/**
	 * renders phone form field
	 * @param field
	 */
	renderNumberField = (field) => {
		const {editPhone, removingPhones} = this.state;
		const {comment, phone} = field;
		const initialValues = {comment, phone};
		const isEditing = editPhone === phone,
			isRemoving = !removingPhones.indexOf(phone);

		return (
			<li key={phone}>
				{!isRemoving && isEditing ? (
					<MyContactsNumberForm
						initialValues={initialValues}
						form={FORMS.MY_CONTACT_NUMBERS + "--" + phone}
						onDismiss={this.cancelEditContactRow}
						onSubmit={this.onEditPhone(field)}
					/>
				) : (
					<div className="my-contacts__numbers__list-item">
						<div className="my-contacts__numbers__list-item-title">
							<div className="innerText">{comment}</div>
						</div>
						<div className="my-contacts__numbers__list-item-value">
							<div className="innerText" title={phone}>{phone}</div>
						</div>
						<div className="my-contacts__numbers__list-item-controls">
							<Button
								disabled={isRemoving}
								onClick={this.editContactRow(phone)}
								className="my-contacts__numbers__list-item-button"
							>
								<Icon icon={ICONS.pencil}/>
							</Button>
							<Button
								disabled={isRemoving}
								onClick={this.removeContactRow(field)}
								className="my-contacts__numbers__list-item-button"
							>
								<Icon icon={ICONS.trashBucket}/>
							</Button>
						</div>
					</div>
				)}
			</li>
		)
	};

	render() {
		const {showAddNumber, errors, fields} = this.state;
		let maxContactsAmount = false;
		if (fields && fields.length >= MAX_PHONES_AMOUNT) {
			maxContactsAmount = true;
		}

		return (
			<Panel className="my-contacts__numbers">
				<h1 className="my-contacts__numbers-heading heading3">Мої номери</h1>
				<hr/>
				<div className="my-contacts__numbers-text">
					{getTemplateText("myContactNumbers.text")}
				</div>

				<ul className="my-contacts__numbers__list">
					{fields.map(this.renderNumberField)}
					{!maxContactsAmount ? (
						<li className="my-contacts__numbers__list-add-holder">
							{showAddNumber ? (
								<MyContactsNumberForm
									form={FORMS.MY_CONTACT_NUMBERS}
									onDismiss={this.hideAddNumber}
									onSubmit={this.addNewNumber}
								/>
							) : (

								<Button
									className="my-contacts__numbers__add-button"
									onClick={this.showAddNumber}
								>
									<Icon icon={ICONS.circlePlus}/>
									{getTemplateText("myContactNumbers.button")}
								</Button>
							)}
						</li>
					) : null}

				</ul>
				{typeof errors === "object" && Object.keys(errors).map(key => <FormError key={key}>{errors[key]}</FormError>)}
			</Panel>
		)
	}
});