import React, {Component} from "react";
import Button from "../../components/UI/Button/Button";
import Icon from "../../components/UI/Icon/Icon";
import FormField from "../../components/Form/FormField";
import {Field, FieldArray} from "formik";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import FormSelect from "../../components/Form/FormSelect";
import {ICONS} from "../../components/UI/Icon/enums";
import {SubscribeStoresProps, SubscribeStoresState} from "./types";
import {getUniqueId} from "../../utils";


//todo: fix types
class NearestStore extends Component<SubscribeStoresProps, SubscribeStoresState> {
	constructor(props) {
		super(props);
		this.state = {
			fields: this.props.stores || [],

		};
	}

	/**
	 * handle select change
	 */
	onSelectChange = (id) => (option) => {
		const {setFieldTouched, setFieldValue} = this.props;

		if (option.option.__typename === "City") {
			setFieldTouched(`stores.${id}.cid`);
			setFieldValue(`stores.${id}.cid`, (option || {}).value);
			const fields = this.state.fields.map((store) => {
				if (store._id === id) {
					store.cid = (option || {}).value;
				}
				return store;
			});
			this.setState({
				fields
			})
		} else if (option.option.__typename === "StoreOnly") {
			setFieldTouched(`stores.${id}.sid`);
			setFieldValue(`stores.${id}.sid`, (option || {}).value);
			const fields = this.state.fields.map((store) => {
				if (store._id === id) {
					store.sid = (option || {}).value;
				}
				return store;
			});
			this.setState({
				fields
			})
		}

	};


	/***
	 * getting selected city value
	 * @param id
	 */
	getCitySelectValue = (id = "") => {
		const {citiesWithStores} = this.props;
		if (citiesWithStores !== undefined && id !== undefined) {
			const city = citiesWithStores ? citiesWithStores.find(option =>
				option.id === id) : '';

			return (
				{value: city && city.id, label: city && city.title, option: city && city}
			)
		}
	};

	/***
	 * getting selected store value
	 * @param sid
	 * @param cid
	 */
	getStoreSelectValue = (sid = "", cid = "") => {
		const {citiesWithStores} = this.props;
		if (citiesWithStores !== undefined) {

			const city = citiesWithStores.find(c => c.id === cid);
			const stores = city ? city.stores.find(option =>
				option.id === sid) : '';
			return (
				{value: stores && stores.id, label: stores && stores.title, option: stores && stores}
			);
		}
	};

	/***
	 * getting current city stores options list
	 * @returns {{value: *, label: *, option: *}[]}
	 */
	getCityStoresOptions = (id = "__1", props = this.props) => {
		const {citiesWithStores, values} = props;
		if (citiesWithStores !== undefined) {
			const cid = values.stores[id] && values.stores[id].cid;
			const city = citiesWithStores.find(c => cid === c.id);

			return (!!city && city.stores.map(s => ({value: s.id, label: s.title, option: s}))) || []

		} else {
			return []
		}
	};

	/****
	 *  getting cities options list
	 * @param props
	 * @returns {{value: *, label: *, option: *}[]}
	 */
	getCityOptions(props = this.props) {

		if (props.citiesWithStores === undefined) {
			return ([
				{label: "", value: ""},
			])
		}
		return props.citiesWithStores.map(c => ({value: c.id, label: c.title, option: c}));
	}

	/***
	 * removing stores field
	 * @param id
	 * @param formProps
	 */
	removeStore(id = "__1", formProps) {
		const {fields} = this.state,
			{values} = this.props;
		const array = [...fields];
		let deletingIndex;
		array.forEach((store, index) => {
			if (store._id === id) {
				deletingIndex = index;
			}
		});
		if (deletingIndex) {
			array.splice(deletingIndex, 1);
			this.setState({fields: array});
		}
		else {
			array.splice(0, 1);
			this.setState({fields: array},()=> {
				if(this.state.fields.length === 0) {
					this.addStore()
				}
			});

		}
		const filteredStore = {...values.stores};
		delete filteredStore[id];

		formProps.setValues({...values, stores: filteredStore});
	}

	/***
	 * add new stores field
	 */
	addStore() {
		const id = getUniqueId(),
			{setFieldValue} = this.props;
		setFieldValue(`stores.${id}`, {});
		this.setState((prevState) => ({
			fields: [...prevState.fields, {
				_id: id
			}]
		}))
	}

	/****
	 * rendering stores fields
	 * @param value
	 */
	renderFields = (value) => ({field, form}) => {
		const {values: {stores}} = this.props;
		const cid = stores[value._id] && stores[value._id].cid;
		const sid = stores[value._id] && stores[value._id].sid;
		const isVisibleRemoveButton =  Object.keys(stores).length > 1 || cid !== undefined || sid !== undefined;

		return (
			<div className="subscribe-stores">
				<div className={"store-fields-wrapper"}>
					<FormField>
						<Field
							name={`stores.${value._id}.cid`}
							labelKey="city"
							valueKey="city"
							component={FormSelect}
							options={this.getCityOptions()}
							value={this.getCitySelectValue(cid || "__1")}
							onChange={this.onSelectChange(value._id)}
							label="Місто"
							autosize={false}
							searchable
							autoFocus
							noOptionsMessage={() =>  null }
						/>
					</FormField>
					<FormField>
						<Field
							name={`stores.${value._id}.sid`}
							labelKey="store"
							valueKey="store"
							component={FormSelect}
							options={this.getCityStoresOptions(value._id)}
							value={this.getStoreSelectValue(sid, cid)}
							onChange={this.onSelectChange(value._id)}
							label="Магазин"
							noOptionsMessage={() =>  null	 }
							autosize={false}
							searchable
						/>
					</FormField>
				</div>
				{stores &&
				<div className={"store-buttons-wrapper"}>
					<Button className={`select-form__stores-control-delete ${!isVisibleRemoveButton ? 'notvisible' : ''}`}
									onClick={() => this.removeStore(value._id, form)}
					>
						<Icon icon={ICONS.trashBucket}/>
					</Button>
				</div>
				}
			</div>
		)
	};

	render() {
		const {fields} = this.state;
		return (
			<React.Fragment>
				<div className="nearest-store-form__message">
					{renderTemplateTextNode("nearestStore.formMessage")}
				</div>
				<FieldArray
					name="stores"
					render={() => (
						fields.map((value) => (
							<Field
								key={value._id}
								name={value._id}
								render={this.renderFields(value)
								}
							/>
						))
					)}
				/>
				<div className="select-form__stores-buttons">
					<Button className="select-form__stores-btn"
									onClick={() => this.addStore()}
					>
						<Icon icon={ICONS.circlePlus}/> {getTemplateText("subscribeForm.button.add_store")}
					</Button>
				</div>
			</React.Fragment>
		)
	}
}

export default NearestStore;