import React, {FC} from 'react';
import {Link} from "react-router-dom";

import {ICONS} from "../../../../UI/Icon/enums";
import WithContext from "../../../../HOCs/WithContext/WithContext";
import {ROUTE} from "../../../../../services/RouteService/enums";
import {urlTo} from "../../../../../services/RouteService/RouteService";
import getTemplateText, {FormattedHTMLMessage} from "../../../../../services/TextService";
import {AccountBarProps} from "./types";

import Icon from "../../../../UI/Icon/Icon";

import './AccountBar.base.styl';


const AccountBar:FC<AccountBarProps> = (props) => {
	const {user: {firstName, lastName, currentBonus, currentBalance}, isLogin, closeMenu} = props;

	return ( isLogin
		?
			<div className="menu-account -mobile">
				<div className="menu-account__user-data">
					<div className="account__user-icon">
						<Icon icon={ICONS.person} raw/>
					</div>
					<div className="account__user-name">
						<Link to={urlTo(ROUTE.PERSONAL_PAGE)}	onClick={closeMenu}>
							{`${firstName || ""} ${lastName ? lastName[0] + "." : ""}`}
						</Link>
					</div>
				</div>

				{!!currentBonus && <div className="menu-account__user-bonuses">
					<div className="bonus-count">
						<span>Доступно</span>
					</div>
					<div className="bonus-value">
						<span className="bonus-amount">{currentBonus.toFixed(2)}</span>
						<span className="bonus-currency">{getTemplateText("hrn")}</span>
					</div>
				</div>}

				{!!currentBalance &&	<div className="menu-account__user-points">
					<span>на рахунку</span> <FormattedHTMLMessage id="points" values={{points: currentBalance}}/>
				</div>}
			</div>
		: null
	)
};


export default WithContext(
	(state) => ({
		user: state.user,
		isLogin: state.isLogin
	})
)(AccountBar);