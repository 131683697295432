import React, {Component} from "react";
import InputElement from "react-input-mask";
import {PHONE_MASK, PHONE_MASK_CHAR} from "../../services/FormServices/constants";
import {FieldProps} from "formik";

type PhoneInputType = FieldProps & {
	[key: string]: any
}

class PhoneInput extends Component<PhoneInputType, {}> {

	static defaultProps = {
		mask: PHONE_MASK,
		maskChar: PHONE_MASK_CHAR,
	};

	render() {
		let newProps = {...this.props};
		newProps.autoFocus = false;
		const {active, mask, field, form, ...props} = newProps;
		const initialNumber =  field.value === "" && active ? "38" : field.value;
		return <InputElement {...props} value={initialNumber} mask={active ? mask : ''}/>
	}
}

export default PhoneInput;
