import {useCallback, useState} from 'react'
import axios from 'axios';

import {PURCHASE_TYPES} from '../../../../../constants';
import {getTokenData} from '../../../../../services/AppServices';
import {BASKET_API} from '../../../../../services/EnvServices';
import {parseErrorOnfly} from '../../../../../utils';
import {RepeatPurchaseProductsListItem, RepeatPurchaseRequestData, RepeatPurchaseResp} from './types';
import {ChequeDataType} from '../usePurchaseDetails/types';

const useRepeatOrder = () => {
	const [loading, setLoading] = useState(false);
	const {accessToken} = getTokenData();

	const repeatOrder = useCallback((purchaseType: PURCHASE_TYPES, productsList: RepeatPurchaseProductsListItem [], basketGuid?: string, chequeData?: Omit<ChequeDataType, "filialId">): Promise<string | null> => {
		setLoading(true);
		const data: RepeatPurchaseRequestData = {
			purchaseType,
			...(purchaseType === PURCHASE_TYPES.ONLINE ? {basketGuid} : {}),
			...(purchaseType === PURCHASE_TYPES.OFFLINE ? {...chequeData} : {}),
			productsList
		}
		return axios.post<RepeatPurchaseResp>(BASKET_API, {
			method: "RepeatPurchase",
			data
		}, {headers: {"x-fora-authtoken": accessToken}})
			.then(({data: {data, eComError}}) => {
				if (!!eComError.errorCode) {
					throw new Error(eComError.errorMessage);
				}
				return data.basketId || null;
			})
			.catch(err => {
				parseErrorOnfly(err, true, null, true);
				return null;
			})
			.finally(() => setLoading(false));
	}, [accessToken]);

	return {repeatOrder, loading};
}

export default useRepeatOrder