import {ACTION_TYPES} from "./enums";
import CookiesService, {setCustomCookies} from "../services/CookiesService";
import client from "../services/apollo";
import {getTokenData} from "../services/AppServices";
import Query from "../graphql/Query";
import {AUTH_NEW_ENTRANCE_ACTIONS} from "../constants";
import moment from "moment";

const actions = {
	init: (dispatch) => {
		(async () => {
			const tokenData = getTokenData();

			function initialize() {
				dispatch({
					type: ACTION_TYPES.init,
					payload: {
						isInitialized: true
					}
				})
			}

			client.query({
				query: Query.time.query,
			})
				.then(({data, errors}) => {
					if (!errors) {
						// setLocalAndServerTimeDiff(data.time);
					}
					else {
						throw errors;
					}
				})
				.catch(err => {
					// parseErrorOnfly(err, false);
				});

			if (tokenData.isValid) {
				try {
					client.query({
						query: Query.me.query,
					})
						.then((r1) => {
							return client.query({
								query: Query.meDetailed.query,
							})
								.then((r2) => {
									const {data: {me = {}} = {}} = r1;

									if (me) {
										dispatch(actions.setUser({...me, ...r2.data.me}));
										CookiesService.setItem("barcode", me.barcode);
										dispatch(actions.initStores());
									}

									initialize();
								})
						})
						.catch((err) => {
							// parseErrorOnfly(err, true);
							if (err.message.indexOf("API Key") !== -1) {
								// dispatch(AuthActions.logout());
							}
							initialize();
						});
				} catch (err) {
					console.log("AppActions init error get user", err);
					initialize();
				}
			} else {
				initialize();
			}
		})();
		return ({
			type: "",
			payload: {}
		});
	},
	login: (payload, dispatch) => {
		const accessToken = (payload.accessToken || {}).accessToken;
		const expiresAt = moment((payload.accessToken || {}).expiresAt).utc();
		setCustomCookies("accessToken", accessToken, {expires: expiresAt});
		setCustomCookies("expiresAt", expiresAt, {expires: expiresAt});

		try {
			client.query({
				query: Query.me.query,
			})
				.then((r1) => {
					return client.query({
						query: Query.meDetailed.query
					})
						.then((r2) => {
							const {data: {me = {}} = {}} = r1;

							if (me) {
								dispatch(actions.setUser({...me, ...r2.data.me}));
								CookiesService.setItem("barcode", me.barcode);
							}
						})
				})
				.catch(err => {
					if (err.message.indexOf("API Key") !== -1) {
						dispatch(actions.logout());
					}
					console.error(err);
				});
		} catch (err) {
			console.error(err);
		}
		return ({
			type: ACTION_TYPES.login,
			payload: {isLogin: true}
		});
	},
	setUser: (user) => {
		return ({
			type: ACTION_TYPES.setUser,
			payload: {
				isLogin: true,
				user
			}
		});
	},
	logout: () => {
		CookiesService.removeItem("accessToken");
		CookiesService.removeItem("expiresAt");
		CookiesService.removeItem("barcode");
		setTimeout(() => client.cache.reset());
		return ({
			type: ACTION_TYPES.logout
		});
	},
	setGoogleCkey: (payload) => {
		return {
			type: ACTION_TYPES.setGoogleCaptureKey,
			payload
		}
	},
	resetgcapture: () => {
		// resetRecaptcha();
		return {
			type: ACTION_TYPES.resetGCapture,
			payload: ""
		}
	},
	initStores: () => {
		const initialStores = CookiesService.getItem("nearestStores"),
			newStores = initialStores.split(',');
			const uniqueStores = Array.from(new Set(newStores));
		return {
			type: ACTION_TYPES.SET_ACTIVE_NEAREST_STORE,
			payload: uniqueStores
		}
	},
	setNearestStore: (storeId) => {
		const initialStores = CookiesService.getItem("nearestStores"),
		newStores = initialStores.split(',');
		newStores.unshift(storeId);
		const uniqueStores = Array.from(new Set(newStores));
		CookiesService.setItem("nearestStores", uniqueStores.toString());

		return {
			type: ACTION_TYPES.SET_ACTIVE_NEAREST_STORE,
			payload: uniqueStores
		}
	},
	setSighUpModal: (sighUpModal: AUTH_NEW_ENTRANCE_ACTIONS | null) => {
		return {
			type: ACTION_TYPES.SET_SIGH_UP_MODAL,
			payload: sighUpModal
		}
	},
};

export default actions;