import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames';

import {getQueryParams, queryParamsToSearchString} from '../../../../services/RouteService/RouteService';
import {PURCHASE_TYPES} from '../../../../constants';
import {PURCHASE_STATUS} from './const';
import getTemplateText from '../../../../services/TextService';

import {ICONS} from '../../../../components/UI/Icon/enums';
import Icon from '../../../../components/UI/Icon/Icon';
import RadioButton from '../RadioButton/RadioButton';
import useOnClickOutside from './useOnClickOutside';
import {PurchaseFilterItemType} from './types';

import "./Filters.styl";

const Filters = () => {
	const location = useLocation();
	const history = useHistory();
	const filtersRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const {to, from, status, type} = getQueryParams(location);
	const defaultStatus = PURCHASE_STATUS.find(item => item.id === +status)?.value || PURCHASE_STATUS[0].value;

	const [selectedFilterStatus, setSelectedFilterStatus] = useState(defaultStatus);

	const handleFilterClick = useCallback(() => {
		setIsOpen(prev => !prev);
	}, []);

	const handleOutsideClick = useCallback((event) => {
		let targetElement = event.target;
		while (targetElement !== null) {
			if (targetElement.classList && targetElement.classList.contains('filter-btn')) {
				return;
			}
			targetElement = targetElement.parentElement;
		}
		setIsOpen(false);
	}, []);

	useOnClickOutside(filtersRef, handleOutsideClick);

	const onStatusFilterChange = useCallback((item: PurchaseFilterItemType) => () => {
		setSelectedFilterStatus(item.value);
		history.push({
			search: queryParamsToSearchString({
				...(type ? {type} : {}),
				...(from ? {from} : {}),
				...(to ? {to} : {}),
				...(item.value && item.value !== PURCHASE_STATUS[0].value ? {status: item.id.toString()} : {})
			})
		});
	}, [from, to, type, history]);

	useEffect(() => {
		if (!status) {
			setSelectedFilterStatus(PURCHASE_STATUS[0].value);
		}
	}, [status])

	if (+type === PURCHASE_TYPES.OFFLINE) {
		return null;
	}

	return (
		<div className='filters-wrapper'>
			<button onClick={handleFilterClick} className={classNames('filter-btn', {"active": isOpen})}>
				<Icon raw icon={ICONS.filter} />
			</button>
			{isOpen &&
				<div ref={filtersRef} className='filters-list'>
					<span>{getTemplateText("myPurchases.order.status.title")}</span>
					<ul>
						{PURCHASE_STATUS.map(item => <li key={item.id}>
							<RadioButton title={item.title} value={item.value} onChange={onStatusFilterChange(item)} selected={selectedFilterStatus}>
								{item.title}
							</RadioButton>
						</li>)}
					</ul>
				</div>}
		</div>
	)
}

export default Filters