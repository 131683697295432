import React, {FunctionComponent} from "react";
import cn from "classnames";
import {RouteComponentProps, withRouter} from "react-router";
import {Link} from "react-router-dom";

import {reduceTags, renderNode} from "../../utils";
import {urlTo} from "../../services/RouteService/RouteService";
import {ROUTE} from "../../services/RouteService/enums";
import {ICONS} from "../UI/Icon/enums";
import {listItemShapeType, BreadCrumbsComponentPropsType} from "./types";

import Icon from "../UI/Icon/Icon";

import "./BreadCrumbs.styl";

const BreadCrumbsComponent: FunctionComponent<BreadCrumbsComponentPropsType> = ({className, list}) => (
	<ul className={cn("bread-crumbs", className)}>
		{list.map(({to, text}, i, arr) => {
			const ChildComponent: any = to ? Link : "span";
			const props = {
				className: "bread-crumbs-link",
				children: renderNode(text),
				to,
			};

			return (
				<li key={i}>
					<ChildComponent {...props}/>
					{i !== arr.length - 1 && <Icon icon={ICONS.breadCrumbsArrow}/>}
				</li>
			)
		})}
	</ul>
);

type LabelsType = Partial<Record<ROUTE, string>>

type BreadCrumbsPropsType = RouteComponentProps & {
	className?: string;
	labels: LabelsType;
	links?: listItemShapeType[];
	params?: object;
	customParams?: object;
};

const BreadCrumbs: FunctionComponent<BreadCrumbsPropsType> = ({className, labels={}, links, location: {pathname}, params, customParams}: BreadCrumbsPropsType) => {
	const _params = {...params, ...customParams};
	let list: any[] = [];
	if (Object.keys(labels).length === 0 && links && links.length > 0) {
		list = links;
	} else {
		list = Object.keys(labels).map((l) => {
				const to = urlTo(l, _params),
					label = labels[l];

				return {
					...(pathname === to.pathname ? {} : {to}),
					text: reduceTags(label)
				}
			})
	}
	const props = {
		className,
		list
	};

	return <BreadCrumbsComponent {...props}/>
};

export default withRouter(BreadCrumbs);