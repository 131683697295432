import "./CardAddedSuccessModal.styl";

import React, {FC} from "react";
import Modal from 'react-responsive-modal';

import {ModalPropsType} from "../../Modal/types";
import getTemplateText from "../../../services/TextService";
import {ICONS} from "../../UI/Icon/enums";
import Icon from "../../UI/Icon/Icon";
import Button from "../../UI/Button/Button";

const CardAddedSuccessModal: FC<ModalPropsType> = ({open, onClose}) => {
	return (
		<Modal open={open} onClose={onClose}
					 focusTrapped={false}
					 classNames={{"overlay": "modal-overlay", "modal": "modal-window card-added-modal", "closeButton" : "modal-closeButton", "closeIcon": "modal-closeIcon"}}>
				<div>
					<h3 className="card-added_heading">
						<Icon icon={ICONS.check}/>
						<div className="card_added_heading-text">{getTemplateText("cardAdded.heading")}</div>
					</h3>
					<p className='card-added_description'>
						{getTemplateText("cardAdded.description")}
					</p>
					<Button primary  className="continue_button" onClick={onClose}>Продовжити</Button>
				</div>
		</Modal>
	)
};

export default CardAddedSuccessModal;