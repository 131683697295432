import React, {FunctionComponent, ReactElement} from "react";

import Loader from "./Loader";

type LoadHolderPropsTypr = {
	isLoading: boolean;
	children?: ReactElement;
}

const LoadHolder: FunctionComponent<LoadHolderPropsTypr> = ({isLoading, children}): ReactElement<any> | null => {

	if (isLoading) {
		return <Loader/>
	}
	if (children) {
		return children
	}
	return null
};

export default LoadHolder;