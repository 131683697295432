import {ENUM} from '@constants';
import {useMutation} from '@apollo/react-hooks';
import {FormikValues, useFormikContext} from 'formik';

import {getResponseFields, getStoredAuthData} from '../../helpers';
import Mutation from '@graphql/Mutation';
import {handleFormErrors} from '@services/FormServices/FormService';
import {checkRecapture} from '@services/RecaptchaService';

import {ValidateLoginResponseDataType, ValidateLoginVariablesType} from '@graphql/types';
import {EntranceType} from '../../types';

const useSendOptToMobile = (entranceType: EntranceType = "LOYALTY") => {
	const {phone} = getStoredAuthData();
	const [validateLogin] = useMutation<ValidateLoginResponseDataType, ValidateLoginVariablesType>(Mutation.validateLogin.mutation);
	const {resetForm, ...otherProps} = useFormikContext<FormikValues>();

	const sendOptToMobile = () => {
		return checkRecapture()
			.then(ckey => {
				return validateLogin({
					variables: {
						type: ENUM.PHONE,
						value: phone.replace(/\s/g, ""),
						entranceType,
						ckey
					}
				})
					.then(({data = {}, errors}) => {
						const validateLogin = data.validateLogin;
						if (errors) {
							throw errors;
						}
						if (validateLogin && !errors) {
							const {flowId, nextStep} = getResponseFields(validateLogin);
							localStorage.setItem("authData", JSON.stringify({flowId, nextStep, phone}));
						}
						resetForm();
					})
					.catch((err) => {
						resetForm();
						handleFormErrors(err, otherProps);
					})
			})
	};
	return sendOptToMobile;
}

export default useSendOptToMobile