import {isDev} from "./EnvServices";

export function GA(...params) {

	isDev && console.log("%cGA --- ", "background: #edd732;");
	isDev && console.log(...params);
	// !DEV_MODE &&
	if (typeof window["gtag"] !== "undefined") {
		window["gtag"](...params);
	}

}
