import "./Icon.base.styl";

import React, {Component} from "react";
import cn from "classnames";
import {ICONS, SHAPE, THEME} from "./enums";

type IconStateType = {
	icon: string
}

type IconPropsType =  {
	icon?: ICONS;
	shape?: SHAPE;
	theme?: THEME;
	raw?: boolean;
	[key: string]:any;
}

class Icon extends Component<IconPropsType, IconStateType> {
	constructor(props) {
		super(props);
		const {icon} = props;
		this.state = {
			icon: icon && require(`./${icon}.svg`)
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {icon} = nextProps;
		if (prevState.icon !== icon) {
			return {
				icon: require(`./${icon}.svg`)
			};
		}
		return null;
	}

	render() {
		const {className, icon, raw, children, shape, theme, ...restProps} = this.props;

		const props = {
			...restProps,
			className: cn("icon", className, icon, {
				raw,
				[`icon-shape--${shape}`]: shape,
				[`icon-theme--${theme}`]: theme,
			}),
			...(this.state.icon ? {dangerouslySetInnerHTML: {__html: this.state.icon}} : {children})
		};

		return <i {...props} />
	}
}

export default Icon;
