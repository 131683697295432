import React, {FC} from "react";
import InputElement from "react-input-mask";
import {FieldProps} from "formik";

import {
	CODE_FORMAT_CHARS,
	CODE_MASK,
	CODE_MASK_CHAR,
	CODE_ACTIVE,
	CODE_DEFAULT_VALUE
} from "../../services/FormServices/constants";

type CodeInputPropsType =  FieldProps & {
	mask?: string;
	maskChar?: string | null;
	formatChars?: {};
	active: boolean;
	value: string;
	[key: string]: any
}

const CodeInput: FC<CodeInputPropsType>  = ({form,  mask = "", field, active, ...props}) => {
	const {value} = field;
	return 	<InputElement {...props} value={value}   mask={mask}/>
};

CodeInput.defaultProps = {
	mask: CODE_MASK,
	maskChar: CODE_MASK_CHAR,
	formatChars: CODE_FORMAT_CHARS,
	active: CODE_ACTIVE,
	value: CODE_DEFAULT_VALUE,
};

export default CodeInput;
