import React, {Component} from "react";
import InputElement from "react-text-mask";


import {TIME_MASK, TIME_MASK_CHAR, TIME_PLACEHOLDER} from "../../services/FormServices/constants";
import {isFunction} from "../../utils";

const mask = (rawValue) => {
	if (rawValue[0] === "2") {
		return [/[0-2]/, /[0-3]/, ":", /[0-5]/, /\d/]
	}
	return [/[0-2]/, /\d/, ":", /[0-5]/, /\d/]
};

type TimeInputProps =  {
	showMask?: boolean,
	mask?: RegExp,
	placeholderChar?: string;
	placeholder?: string;
	[key: string]: any;
}

class TimeInput extends Component<TimeInputProps, {}> {

	static defaultProps = {
		showMask: true,
		mask: TIME_MASK,
		placeholderChar: TIME_MASK_CHAR,
		placeholder: TIME_PLACEHOLDER,
	};

	/**
	 * handles onfocus event
	 * @param e
	 */
	onFocus = (e) => {
		e.persist();
		const {target: el} = e;
		if (el.value === "––:––") {
			setTimeout(() => el.setSelectionRange(0, 0), 0);
		}

		if (isFunction(this.props.onFocus)) {
			this.props.onFocus();
		}
	};


	render() {
		const {active, form, field: {onBlur,  ...fieldProps}, ...props} = this.props;
		return <InputElement {...props} {...fieldProps}  mask={mask} onFocus={this.onFocus}/>
	}
}

export default TimeInput;