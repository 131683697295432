/**
 * Created by e.viniychuk on 8/8/17.
 */
import {renderTemplateTextNode} from "./TextService";
import {ProposalItemType} from "../graphql/types";
import {isTargetProject} from "../utils";
import {PROJECT} from "../constants";

export const getProposalStatusText = (proposal) : JSX.Element | null => {
	const {status} = proposal;
	let result: JSX.Element | null = null;
	if (status === "USED") {
		result = renderTemplateTextNode("proposal.period.expired.used");
	} else {
		result = renderTemplateTextNode("proposal.period.expired");
	}
	return result;
};

/**
 * gets image placeholder if no image is provided
 * @returns {string}
 */
export function getImagePlaceholder() {
	if (isTargetProject(PROJECT.FORA)) {
		return "/images/special-proposal-placeholders/sp-fora.png";
	}

	let count = Math.floor(Math.random() * 7);
	if (count === 0) {
		count = 1;
	}
	return `/images/special-proposal-placeholders/${count}.svg`;
}

/***
 * Split proposals
 * @param items
 * @param markedCoupons
 * @param max
 */
export function splitProposalsByType(items: ProposalItemType[], markedCoupons: ProposalItemType[], max?: number): {paper: ProposalItemType[]; electronic: ProposalItemType[]; gotMore: boolean} {
	const paper: ProposalItemType[] = [];
	const electronic: ProposalItemType[] = [];
	let gotMore = false;

	if (Array.isArray(items)) {
		for (const item of items) {
			switch (item.useType) {
				case "PAPER":
					if (typeof max !== "number" || paper.length < max) {
						item.marked = !!markedCoupons.find(c => c.id === item.id);
						item.showMark = !!markedCoupons.length;
						paper.push(item);
					} else if (paper.length >= max) {
						gotMore = true;
					}
					break;
				case "ELECTRONIC":
					if (typeof max !== "number" || electronic.length < max) {
						electronic.push(item);
					} else if (electronic.length >= max) {
						gotMore = true;
					}
					break;
				default:
			}
		}
	}

	return {
		paper,
		electronic,
		gotMore
	};
}
