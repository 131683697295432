import {ENUM} from "@constants";
import {DATA_TYPE} from "@services/FormServices/constants";
import {IField} from "@services/FormServices/interfaces";
import {CardModalFormValuesType} from "./types";
import {normalizeFields, validate} from '@services/FormServices/FormService';
import {FORMS} from "js/forms/enums";

export const form = FORMS.CARD_FORM;

export const fieldsPropsUpdate = (hasCard: string): Record<string, IField> => {
	if (hasCard === ENUM.YES) {
		return {
			hasCard: {
				name: "hasCard",
				dataType: DATA_TYPE.bool,
			},
			barcode: {
				name: "barcode",
				dataType: DATA_TYPE.barcode,
			}
		}
	} else {
		return {
			hasCard: {
				name: "hasCard",
				dataType: DATA_TYPE.bool,
			},
		}
	}
};

export const fieldsItems = [{
	value: ENUM.YES,
	title: "Є картка",
	}, {
	value: ENUM.NO,
	title: "Не маю картки",
	},
]

export const initialValues: CardModalFormValuesType = {
	hasCard: ENUM.YES,
	barcode: "",
	submitError: ""
}

export const initialValid = (props) => {
	const errors = validate(props.initialValues, {fields: normalizeFields(fieldsPropsUpdate(props.initialValues.hasCard), form), form});
	return Object.keys(errors).length === 0;
}

export const handleValidate = (values: CardModalFormValuesType) =>
	validate(values, {fields: normalizeFields(fieldsPropsUpdate(values.hasCard), form), form})