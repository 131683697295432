import {compose} from "recompose";
import React, {Component} from "react";
import {graphql, MutationFunction} from "react-apollo";
import {ErrorResponse} from 'apollo-link-error';

import {meDetailedResponseType, meResponseType} from "../../graphql/types";

import Query from "../../graphql/Query";
import Mutation from "../../graphql/Mutation";
import {DATA_TYPE} from "../../services/FormServices/constants";
import {normalizePhone, parsePhone} from "../../services/FormServices/FormService";
import {FORMS} from "../../forms/enums";

// import Button from "../UI/Button/Button";
// import Icon from "../UI/Icon/Icon";
// import {ICONS} from "../UI/Icon/enums";
import Panel from "../UI/Panel/Panel";
import FormError from "../Form/FormError/FormError";
import FormLabel from "../Form/FormLabel";
import MyContactsRowForm, {MyContactsRowFormValues} from "../../forms/MyContactsRowForm/MyContactsRowForm";

import "./MyContactsData.styl";
import {phoneNumberFormat} from "../../utils";


type MyContactsDataStateType = {
	editingField: string;
	error: string;
}

type InnerMyContactsDataPropsType = {
	meData: meDetailedResponseType;
	me: meResponseType;
	confirmEmail: MutationFunction;
	changeHomePhone: MutationFunction;
	changeEmail: MutationFunction;
	changeMobilePhone: MutationFunction;
}

const fields = {
	mobilePhone: {
		label: "Мобільний номер",
		name: "mobilePhone",
		dataType: DATA_TYPE.phone,
		type: "phone",
	},
	email: {
		label: "Електронна адреса",
		name: "email",
		dataType: DATA_TYPE.email,
		type: "text",
	},
};

/**
 * parses contact graphQL errors
 * @param err
 */
const parseContactsErrors = (err: ErrorResponse) => {
	if (err["graphQLErrors"]) {
		for (const errElement of err["graphQLErrors"]) {
			if (errElement["fields"] && errElement["fields"].length > 0) {
				const emailError = errElement["fields"].find(f => f.field === "email"),
					mobilePhoneError = errElement["fields"].find(f => ~["mobilePhoneCode", "mobilePhoneNumber"].indexOf(f.field)),
					homePhoneError = errElement["fields"].find(f => ~["homePhoneCode", "homePhoneNumber"].indexOf(f.field));
				return {
					...(emailError ? {email: emailError.message} : {}),
					...(mobilePhoneError ? {mobilePhone: mobilePhoneError.message} : {}),
					...(homePhoneError ? {homePhone: homePhoneError.message} : {}),
				};
			}
		}
	}
};


const MyContactsData = compose<InnerMyContactsDataPropsType, {}>(
	graphql(Query.meDetailed.query, {name: "meData"}),
	graphql(Query.me.query, {name: "me"}),
	graphql(Mutation.confirmEmail.mutation, {name: "confirmEmail"}),
	graphql(Mutation.changeHomePhone.mutation, {name: "changeHomePhone"}),
	graphql(Mutation.changeEmail.mutation, {name: "changeEmail"}),
	graphql(Mutation.changeMobilePhone.mutation, {name: "changeMobilePhone"}),
)(class MyContactsData extends Component<InnerMyContactsDataPropsType, MyContactsDataStateType> {

	constructor(props) {
		super(props);
		this.state = {
			editingField: "",
			error: ""
		};
	}

	/**
	 * change home phone
	 * @param phone
	//  */
	changeHomePhone = (phone: string) => {
		const parsedPhone = parsePhone(phone);
		return this.props.changeHomePhone({
			variables: {
				data: {
					homePhoneCode: parsedPhone.code,
					homePhoneNumber: parsedPhone.number,
				}
			}
		})
	};

	/**
	 * chnage mobile phone
	 * @param phone
	 */
	changeMobilePhone = (phone) => {
		const parsedPhone = parsePhone(phone);
		return this.props.changeMobilePhone({
			variables: {
				data: {
					mobilePhoneCode: parsedPhone.code,
					mobilePhoneNumber: parsedPhone.number,
				}
			}
		})
	};

	/**
	 * change email
	 * @param email
	 */
	changeEmail = (email) => {
		return this.props.changeEmail({
			variables: {
				data: {
					email: typeof email === "string" ? email.trim() : ""
				}
			}
		}).then(res => {
			const {emailConfirmed} = this.props.me.me;
			if (typeof res === "object" && (res.data || {}).changeContactData)
				if (!emailConfirmed) {
					this.props.confirmEmail()
				}
			return res;
		})
	};

	/**
	 * handles contacts submit
	 * @param values
	 * @param formProps
	 */
	onContactsSubmit = (values: MyContactsRowFormValues, formProps) => {
		const submitType = Object.keys(values)[0];
		const value = values[submitType];
		//TODO: change mutation type
		let mutation: any = null;
		switch (submitType) {
			case "mobilePhone" :
				mutation = this.changeMobilePhone(value);
				break;
			case "email" :
				mutation = this.changeEmail(value);
				break;
			default:
		}

		return mutation.then(res => {
			this.props.me.refetch();
			this.props.meData.refetch().then(this.cancelEditContactRow);
		}).catch(err => {
			formProps.setErrors(parseContactsErrors(err));
		})
	};

	/**
	 * moves cursor to end
	 * @param el
	 */
	moveCursorToEnd(el) {
		if (typeof el.selectionStart === "number") {
			el.selectionStart = el.selectionEnd = el.value.length;
		} else if (typeof el.createTextRange !== "undefined") {
			el.focus();
			const range = el.createTextRange();
			range.collapse(false);
			range.select();
		}
	}

	/**
	 * sets editing contact row
	 * @param name
	 */
	editContactRow = (name) => () => {
		this.setState({editingField: name});
		setTimeout(() => {
			const focusedElement = document.getElementById("focused-" + name);
			if(focusedElement !== null) {
				focusedElement.focus();
			}
			this.moveCursorToEnd(focusedElement);
		}, 0);

	};

	/**
	 * cancels editing row
	 */
	cancelEditContactRow = () => {
		this.setState({editingField: ""});
	};

	/**
	 * forms fields form me data
	 */
	getFields = () => {
		const {meData: {me}, me: {me: user}} = this.props;
		if (!me) return [];

		return Object.keys(fields).map(f => {
			const {name} = fields[f];
			let value: null | string;

			switch (name) {
				case "mobilePhone":
					value = me.mobilePhoneCode ? normalizePhone(me.mobilePhoneCode, me.mobilePhoneNumber) : phoneNumberFormat(user.fullPhoneNumber);
					break;
				case "email":
					value = me.email;
					break;
				default:
					value = null;
					break;
			}

			return {
				...fields[f],
				value,
			}
		})
	};

	/**
	 * render contacts field
	 * @param field
	 */
	renderContactsField = (field) => {
		const {editingField} = this.state;
		const {label, name, value} = field;
		const isEditing = editingField === name;

		return (
			<li key={name}>
				{isEditing ? (
					<MyContactsRowForm
						form={FORMS.MY_CONTACT_DATA + "--" + name}
						field={field}
						initialValues={{[name]: value}}
						onSubmit={this.onContactsSubmit}
						onDismiss={this.cancelEditContactRow}
					/>
				) : (
					<div className="my-contacts__list-item">
						<FormLabel className="my-contacts__list-item-label" component="label">{label}</FormLabel>
						<div className="my-contacts__list-item-value">
							<div className="innerText" title={value}>{value}</div>
						</div>
						{/*<div className="my-contacts__list-item-controls">*/}
							{/*<Button*/}
								{/*onClick={this.editContactRow(name)}*/}
								{/*className="my-contacts__list-item-button"*/}
							{/*>*/}
								{/*<Icon icon={ICONS.pencil}/>*/}
							{/*</Button>*/}
						{/*</div>*/}
					</div>
				)}
			</li>
		)
	};

	render() {
		const {error} = this.state;
		return (
			<Panel className="my-contacts__data">
				<h3 className="my-contacts__data-heading">Контактні дані</h3>

				<hr/>

				<ul className="my-contacts__list">
					{this.getFields().map(this.renderContactsField)}
				</ul>

				{error && <FormError>{error}</FormError>}
			</Panel>
		)
	}
});

export default MyContactsData;