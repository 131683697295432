import gql from "graphql-tag";
import * as Fragment from "./Fragment";

const Mutation: {[key: string]: any} = {
	deleteItemsFromBasket: {
		mutation: gql`
			mutation deleteItemsFromBasket($items: [DeleteItemsBasket!] ) {
  			deleteItemsFromBasket(items: $items) {
					totalPrice
					createdAt
					ticketsExpiresAt
					basketItems{
						id
						quantity
						product{
							... on Cert{
								id
								name
								rating
								price
								description
							}
							... on Ticket{
								 id
								 name
								 mastercardDiscount
								description
								price
								event{
									 slug
									 title
								 }
								 type{
									 name
								 }
							}
							__typename
						}
					}
  		}
  	}`
	},
	sendOrderToEmail: {
		mutation: gql`
			mutation sendOrderToEmail($mails: [String!], $type:String!, $order:[MailInput]) {
  			sendOrderToEmail(mails: $mails, type: $type, list: $order )
		}`
	},
	cancelOrder: {
		mutation: gql`
			mutation EcomCancelOrder($orderId: String!, $reasonComment: String) {
				cancelOrder(orderId: $orderId, reasonComment: $reasonComment)
			}`
	},
	deleteBasket: {
		mutation: gql`
        mutation deleteBasket{
          deleteBasket
        }`
	},
	addLagerBasketItem: {
		mutation: gql`
            mutation addLagerBasketItem($lager: Int, $cnt: Int, $filialId: Int){
              addLagerBasketItem(lager: $lager, cnt:$cnt, filialId: $filialId ){
                ...BasketFragment
                __typename
              }
            }
        ${Fragment.BasketFragment}`
	},
	activateVoucherSecureCode: {
		mutation: gql`
            mutation activateVoucherSecureCode($voucherSecureCode: VoucherSecureCodeInput!) {
								activateVoucherSecureCode(voucherSecureCode:$voucherSecureCode)
						}`
	},
	changeVoucherSecureCode: {
		mutation: gql`
            mutation changeVoucherSecureCode($voucherSecureCode: VoucherSecureCodeInput!) {
								changeVoucherSecureCode(voucherSecureCode:$voucherSecureCode)
						}`
	},
	deactivateVoucherSecureCode: {
		mutation: gql`
            mutation deactivateVoucherSecureCode {
							deactivateVoucherSecureCode
						}`
	},

	addAppeal: {
		mutation: gql`
         mutation addAppeal(
             $storeId: ID!,
             $zoneId: ID!,
             $problemId: ID!,
             $phone: String!,
             $name: String!,
             $comment: String!,
             $feedback: Boolean,
             $ckey: String!
            ) {
                addAppeal(
                    storeId: $storeId,
                    zoneId: $zoneId,
                    problemId: $problemId,
                    phone: $phone,
                    name: $name,
                    comment: $comment,
                    feedback: $feedback,
                    ckey: $ckey
                )
            }
        `
	},
	createAmbasador: {
		mutation: gql`
            mutation pageCv(
              $fullName: String!,
              $placeOfResidence: String!,
              $birthday:String!,
              $phone: String!,
              $email: String!,
              $university: String!,
              $specialization: String!,
              $grade: Int!,
              $gradePointAverage: Float!,
              $additionalEducation: String,
              $graduateDate: Int,
              $previousWork: String,
              $position: String,
              $mainResponsibilities: String,
              $workPeriod: String,
              $inFacebook: Boolean,
              $inTelegram: Boolean,
              $inInstagram: Boolean,
              $otherSocials: String,
              $ambasadorParticipation: String!,
              $leadershipQualities: String,
              $teamWorking: String,
              $dispositionQualities: String,
              $pageSlug:String!
            ) {
                pageCv(
                    fullName: $fullName,
                    placeOfResidence: $placeOfResidence,
                    birthday: $birthday,
                    phone: $phone,
                    email: $email,
                    university: $university,
                    specialization: $specialization,
                    grade: $grade,
                    gradePointAverage: $gradePointAverage,
                    additionalEducation: $additionalEducation,
                    graduateDate: $graduateDate,
                    previousWork: $previousWork,
                    position: $position,
                    mainResponsibilities: $mainResponsibilities,
                    workPeriod: $workPeriod,
                    inFacebook: $inFacebook,
                    inTelegram: $inTelegram,
                    inInstagram: $inInstagram,
                    otherSocials: $otherSocials,
                    ambasadorParticipation: $ambasadorParticipation,
                    leadershipQualities: $leadershipQualities,
                    teamWorking: $teamWorking,
                    dispositionQualities: $dispositionQualities,
                    pageSlug: $pageSlug
                )
            }
        `,
	},
	couponsActivate: {
		mutation: gql`
        mutation changeCouponsControl($coupons:[ChangeCouponsControlInput!]!) {
          changeCouponsControl(coupons:$coupons)
        }
      `
	},
	changeCouponsLike: {
		mutation: gql`
        mutation changeCouponsLike($coupons:[ChangeCouponsLikeInput!]!) {
          changeCouponsLike(coupons:$coupons)
        }
      `
	},
	faqVote: {
		mutation: gql`
            mutation faqVote($faqId: ID!, $vote: Int!) {
                object:faqVote(faqId: $faqId, vote: $vote) {
                    ...FAQQuestionBaseFragment
                }
            }
        ${Fragment.FAQQuestionBaseFragment}`
	},
	uploadFile: {
		mutation: gql`
            mutation uploadFile {
                uploadFile(field: "image") {
                    ...FileBaseFragment
                }
            }
        ${Fragment.FileBaseFragment}`,
	},
	createFeedback: {
		mutation: gql`
            mutation createFeedback($name: String!, $email: String!, $phone: String!, $subjectId: ID!, $content: String!, $fileId: ID) {
                createFeedback(name: $name, email: $email, phone: $phone, subjectId: $subjectId, content: $content, fileId: $fileId)
            }
        `,
	},
	createCv: {
		mutation: gql`
            mutation createCv(
                $firstName: String!,
                $lastName: String!,
                $email: String!,
                $phone: String!,
                $settlement: String!
                $birthday: String!,
                $fileId: ID,
                $link: String!,
                $stores: String,
                $cityId: ID,
                $position: String,
                $workTime: Boolean
            ) {
                createCv(
                    firstName: $firstName,
                    lastName:$lastName,
                    email: $email,
                    phone: $phone,
                    settlement: $settlement
                    birthday: $birthday,
                    fileId: $fileId,
                    link: $link,
                    stores: $stores,
                    cityId: $cityId,
                    position: $position,
                    workTime: $workTime
                )
            }
        `,
	},
	subscribe: {
		mutation: gql`
            mutation subscribe($name: String, $email: String, $items: [SubscriptionItemInput]!) {
                subscribe(name: $name, email: $email, items: $items)
            }
        `,
	},

	loginwithcapture: {
		mutation: gql`
         mutation loginwithcapture($barcode: String!, $password: String!, $ckey: String!) {
                loginwithcapture(barcode: $barcode, password: $password, ckey: $ckey) {
                    accessToken { ...AccessTokenFragment }
                    user { ...UserFragment }
                }
            }
            ${Fragment.AccessTokenFragment}
            ${Fragment.UserFragment}

        `
	},
	logout: {
		mutation: gql`
				mutation logout {
						logout
				}`
	},
	confirmViberKey: {
		mutation: gql`
         mutation confirmViberKey($barcode: String!, $password: String!, $ckey: String!, $token: String!) {
                confirmViberKey(barcode: $barcode, password: $password, ckey: $ckey, token: $token) {
                  	ResultCode
                    ResultCode
                    ResultType
                    ResultStr
                }
            }
        `
	},

	createCard: {
		mutation: gql`
            mutation createCard($barcode: String!, $activationCode: String!) {
                createCard(barcode: $barcode, activationCode: $activationCode)
            }
        `
	},
	changeCardStatus: {
		mutation: gql`
            mutation changeCardStatus($barcode: String!, $status: CardStatus!) {
                changeCardStatus(barcode: $barcode, status: $status)
            }
        `
	},
	changeMoneyBoxStatus: {
		mutation: gql`
            mutation changeMoneyBoxStatus($status: MoneyBoxStatus!) {
                changeMoneyBoxStatus(status: $status)
            }
        `
	},
	changePersonalData: {
		mutation: gql`
            mutation changePersonalData($personalData: PersonalDataInput!) {
                changePersonalData(personalData: $personalData)
            }
        `
	},
	changeFamilyData: {
		mutation: gql`
            mutation changeFamilyData($familyItems: InputFamilyData!) {
                changeFamilyData(familyItems: $familyItems)
            }
        `
	},
	changePassword: {
		mutation: gql`
            mutation changePassword($oldPassword: String!, $newPassword: String!) {
                changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
            }
        `
	},
	changeCertificateDeliveryType: {
		mutation: gql`
            mutation changeCertificateDeliveryType($certificateDeliveryType: CertificateDelivery!) {
                changeCertificateDeliveryType(certificateDeliveryType: $certificateDeliveryType)
            }
        `
	},
	changeOtherUserData: {
		mutation: gql`
            mutation changeOtherUserData($otherData: InputOtherUserData!) {
                changeOtherUserData(otherData: $otherData)
            }
        `
	},
	changeContactData: {
		mutation: gql`
            mutation changeContactData($contactData: InputContactData!) {
                changeContactData(contactData: $contactData)
            }
        `
	},
	changeHomePhone: {
		mutation: gql`
            mutation changeHomePhone($data: InputHomePhoneData!) {
                changeHomePhone(data: $data)
            }
        `
	},
	changeEmail: {
		mutation: gql`
            mutation changeEmail($data: InputEmailData!) {
                changeEmail(data: $data)
            }
        `
	},
	changeMobilePhone: {
		mutation: gql`
            mutation changeMobilePhone($data: InputMobilePhoneData!) {
                changeMobilePhone(data: $data)
            }
        `
	},
	changeCouponType: {
		mutation: gql`
            mutation changeCouponsType($coupons: [ChangeCouponsTypeInput!]!) {
							changeCouponsType(coupons: $coupons) {
										couponId
                    type
                    successful
                }
            }
        `
	},
	changeBonusCancellation: {
		mutation: gql`
			mutation changeBonusCancellation($bonusCancellationStatus: BonusCancellationStatus!){
				changeBonusCancellation(bonusCancellationStatus: $bonusCancellationStatus)
			}`
	},
	createPhoneNumber: {
		mutation: gql`
            mutation createPhoneNumber($phoneNumber: PhoneNumberInput!) {
                createPhoneNumber(phoneNumber: $phoneNumber)
            }
        `
	},
	removePhoneNumber: {
		mutation: gql`
            mutation removePhoneNumber($phoneNumber: RemovePhoneNumberInput!) {
                removePhoneNumber(phoneNumber: $phoneNumber)
            }
        `
	},
	editPhoneNumber: {
		mutation: gql`
            mutation editPhoneNumber($oldPhoneNumber: PhoneNumberInput!, $phoneNumber: PhoneNumberInput!) {
                editPhoneNumber(oldPhoneNumber: $oldPhoneNumber, phoneNumber: $phoneNumber)
            }
        `,
		variables: {
			comment: "",
		}
	},
	changeAddressData: {
		mutation: gql`
            mutation changeAddressData($addressType: AddressType!, $address: InputAddress) {
                changeAddressData(addressType: $addressType, address: $address)
            }
        `,
		variables: {
			addressType: null,
			address: null,
		}
	},
	changeMailboxAddressData: {
		mutation: gql`
            mutation changeAddressData($addressType: AddressType!, $mailBox: InputMailBox) {
                changeAddressData(addressType: $addressType, mailBox: $mailBox)
            }
        `,
		variables: {
			addressType: null,
			mailBox: null,
		}
	},
	checkBarcode: {
		mutation: gql`
            mutation checkBarcode($barcode: String!, $ckey: String!) {
              checkBarcode(barcode: $barcode, ckey: $ckey) {
                message
                status
              }
            }
        `,
	},
	checkOtp: {
		mutation: gql`
            mutation checkOtp($phoneNumber: String!) {
              checkOtp(phoneNumber: $phoneNumber) {
                message
                status
                phone
              }
            }
        `,
	},

	checkEmail: {
		mutation: gql`
            mutation checkEmail($email: String!) {
              checkEmail(email: $email) {
                status
              }
            }
        `,
	},
	confirmUpcEmail: {
		mutation: gql`
            mutation confirmUpcEmail($code: String!) {
              confirmUpcEmail(code: $code) {
                ticketToken
                expiresAt
                user {
                    id
                    phone
                    createdAt
                    name
                    email
                    sendToEmail
                    card
                }
              }
            }
        `,
	},

	registration: {
		mutation: gql`
            mutation registration($data: InputRegistrationType!) {
              registration(data: $data) {
                  accessToken { ...AccessTokenFragment }
                  user { ...UserFragment }
              }
            }
            ${Fragment.AccessTokenFragment}
            ${Fragment.UserFragment}
        `,
	},
	verifyMemberQuestionAnswer: {
		mutation: gql`
            mutation verifyMemberQuestionAnswer($barcode: String!, $answer: String!,$answer_id: Int! ) {
              verifyMemberQuestionAnswer(barcode: $barcode, answer: $answer, answer_id: $answer_id ){
                hash
                status
                resultType
                resultCode
              }
            }
        `,
	},
	identifyMember: {
		mutation: gql`
            mutation identifyMember(
              $barcode: String!,
              $email: String!,
              $hash: String) {
                identifyMember(
                  barcode: $barcode,
                  email:  $email,
                  hash:   $hash
                )
            }
        `,
	},
	checkMailboxPostIndex: {
		mutation: gql`
            mutation checkMailboxPostIndex($postIndex: String!) {
                  checkMailboxPostIndex(postIndex: $postIndex)
                }
        `,
	},
	checkMailbox: {
		mutation: gql`
            mutation checkMailbox($mailBox: InputMailBox!) {
                  checkMailbox(mailBox: $mailBox)
                }
        `,
	},
	checkAddress: {
		mutation: gql`
            mutation checkAddress($address: InputAddress!) {
                  checkAddress(address: $address)
                }
        `,
	},
	checkUserInfo: {
		mutation: gql`
            mutation checkUserInfo($info: ShortUserInfo!) {
                checkUserInfo(info: $info)
            }
        `,
	},
	changeTicketName: {
		mutation: gql`
            mutation changeTicketName($data: InputNameData!) {
                changeTicketName(data: $data)
            }
        `,
	},
	changeTicketEmail: {
		mutation: gql`
            mutation changeTicketEmail($data: InputEmailData!) {
                changeTicketEmail(data: $data)
            }
        `,
	},
	changeTicketCard: {
		mutation: gql`
            mutation changeTicketCard($data: InputCardData!) {
                changeTicketCard(data: $data)
            }
        `,
	},
	changeTicketPhone: {
		mutation: gql`
            mutation changeTicketPhone ($data: InputPhoneData!) {
                changeTicketPhone (data: $data)
            }
        `,
	},
	addBasketItem: {
		mutation: gql`
            mutation addBasketItem($basketItem: InputBasketItem!) {
              addBasketItem(product: $basketItem) {
                totalPrice
                createdAt
                ticketsExpiresAt
                basketItems{
                  id
                  quantity
                  product{
                    ... on Cert{
                      id
                      name
                      rating
                      price
                      description
                    }
                    ... on Ticket{
                       id
                       name
                      price
                      mastercardDiscount
                      description
                      event{
                         slug
                         title
                       }
                       type{
                         name
                       }
                    }
                    __typename
                  }
                }
              }
            }
        `,
	},

	setBasketItem: {
		mutation: gql`
            mutation setBasketItem($basketItem: InputBasketItem!){
                setBasketItem(product: $basketItem) {
                totalPrice
                createdAt
                ticketsExpiresAt
                basketItems{
                  id
                  quantity
                  product{
                    ... on Cert{
                      id
                      name
                      rating
                      price
                      description
                    }
                    ... on Ticket{
                       id
                       name
                      description
                      price
                      isEntrance
                      mastercardDiscount(calculate:0)
                      mastercardDiscountType
                      mastercardDiscountDescription
                      event{
                         slug
                         title
                       }
                       type{
                         name
                       }
                    }
                    __typename
                  }
                }
              }
            }
        `,
	},
	getCulinaryOrder: {
		mutation: gql`
            mutation getCulinaryOrder($readyDate:String, $filialId:Int, $note:String,  $sendingSMS:Boolean){
              getCulinaryOrder(readyDate: $readyDate, note: $note, filialId: $filialId,  sendingSMS:$sendingSMS){
                success
                errorId
                errorMessage
                order{
                  id
                  number
                  statusName
                  createDate
                  customerPhone
                  customerName
                  email
                  filialId
                  readyDate
                  note
                  url
                  barCode
                  sendingSMS
                  orderSum
                  transactionDate
                  bankMFO
                  checkSum
                  merchantId
                  terminalId
                  rrn
                  proxyPan
                  cardBPId
                  authorizeCode
                  lagers{
                    number
                    name
                    count
                    price
                    __typename
                  }
                  __typename
                }
                free{
                  begin
                  interval
                  freeName
                  freeMinutes
                  __typename
                }
                __typename
              }
            }
        `,
	},
	createOrder: {
		mutation: gql`
				mutation createOrder($type:String!, $orderId:Int, $cardType:cardInputType ){
					createOrder(type:$type, orderId: $orderId, cardType: $cardType) {
					 path
					 params{
						Version
						MerchantID
						TerminalID
						TotalAmount
						Currency
						locale
						PurchaseTime
						Signature
						currency
						OrderID
						SD
						PurchaseDesc
					}
					}
				}
        `,
	},
	sendTicketsToEmail: {
		mutation: gql`
            mutation sendTicketsToEmail($MailInput:[MailInput], $mails:[String!]){
              sendTicketsToEmail(list: $MailInput, mails:$mails)
            }
        `,
	},
	addCertIntoBasket: {
		mutation: gql`
            mutation addCertIntoBasket($basketItem: InputBasketItem!) {
                addCertIntoBasket(product: $basketItem) {
                    totalPrice
                    basketItems {
                        product{
                            name
                            vendorCode
                            __typename
                        }
                    }
                }
            }
        `,
	},
	promoActivate: {
		mutation: gql`
            mutation promoActivate($hash: String) {
              promoActivate(token: $hash) {
               resultCode
               resultType
               resultStr
              }
            }
        `,
	},
	addEventReminder: {
		mutation: gql`
            mutation addEventReminder($eventId: Int, $phone: String, $email: String) {
              addEventReminder( eventId: $eventId, phone: $phone, email: $email)
            }
        `,
	},
	sendQR: {
		mutation: gql`
      mutation sendQR {
        sendQR
      }`
	},
	registerNew: {
		mutation: gql`
      mutation registerNew($flowId: Int!) {
				registerNew(flowId: $flowId) {
					...FlowFragment
				}
      }
		${Fragment.FlowFragment}`
	},
	setCartFlow: {
		mutation: gql`
      mutation setCartFlow($cartFlow: CartFlow!, $flowId: Int!) {
				setCartFlow(cartFlow: $cartFlow, flowId: $flowId) {
					flow {
					 ...FlowFragment
				 }
					user {
					 accessToken
					 expiresAt
				 }
				}
      }
		${Fragment.FlowFragment}`
	},
	// NEW METHODS
	validateLogin: {
		mutation: gql`
		mutation validateLogin($type: LoginType!, $value: String!, $entranceType: EntranceType!, $ckey: String) {
			validateLogin(type: $type, value: $value, entranceType: $entranceType, ckey: $ckey) {
				flow {
					...FlowFragment
				}
				contactUsedForOtp {
					...ContactUsedForOtpFragment
				}
				__typename
			}
		}
	${Fragment.ContactUsedForOtpFragment}
	${Fragment.FlowFragment}`
	},
	goToRecovery: {
		mutation: gql`
		mutation goToRecovery($flowId: Int!) {
			goToRecovery(flowId: $flowId) {
				flow {
					...FlowFragment
				}
				contactUsedForOtp {
					...ContactUsedForOtpFragment
				}
				__typename
			}
		}
	${Fragment.ContactUsedForOtpFragment}
	${Fragment.FlowFragment}`
	},
	sendOtpToContact: {
		mutation: gql`
		mutation sendOtpToContact($flowId: Int!, $contactId: Int!) {
			sendOtpToContact(flowId: $flowId, contactId: $contactId) {
				flow {
					...FlowFragment
				}
				contactUsedForOtp {
					...ContactUsedForOtpFragment
				 }
				__typename
			}
		}
	${Fragment.ContactUsedForOtpFragment}
	${Fragment.FlowFragment}`
	},
	confirmRecoveryAccessCode: {
		mutation: gql`
		mutation confirmRecoveryAccessCode($flowId: Int!, $accessCode: String!) {
			confirmRecoveryAccessCode(flowId: $flowId, accessCode: $accessCode) {
				flow {
					...FlowFragment
				}
				__typename
			}
		}
	${Fragment.FlowFragment}`
	},
	recoverAccess: {
		mutation: gql`
		mutation recoverAccess($flowId: Int!, $password: String!, $email: String) {
			recoverAccess(flowId: $flowId, password: $password, email: $email) {
				flow {
					...FlowFragment
				}
				accessToken {
					...AccessTokenFragment
				}
				__typename
			}
		}
	${Fragment.AccessTokenFragment}
	${Fragment.FlowFragment}`
	},
	confirmEmail: {
		mutation: gql`
		mutation confirmEmail($emailAccessHash: String!) {
			confirmEmail(emailAccessHash: $emailAccessHash)
		}`
	},
	confirmAccessCode: {
		mutation: gql`
		mutation confirmAccessCode($flowId: Int!, $accessCode: String!) {
			confirmAccessCode(flowId: $flowId, accessCode: $accessCode) {
				flow{
					...FlowFragment
				}
				accessToken{
					...AccessTokenFragment
				}
			}
		}
	${Fragment.AccessTokenFragment}
	${Fragment.FlowFragment}`
	},
	setCardPresence: {
		mutation: gql`
		mutation setCardPresence($flowId: Int!, $card: String) {
			setCardPresence(flowId: $flowId, card: $card) {
				flow{
					...FlowFragment
				}
				contactUsedForOtp {
					...ContactUsedForOtpFragment
				}
				__typename
			}
		}
	${Fragment.ContactUsedForOtpFragment}
	${Fragment.FlowFragment}`
	},
	registerMember: {
		mutation: gql`
		mutation registerMember($member: RegistrationMemberInput!, $password: String!, $passwordRepeat: String!, $flowId: Int!) {
			registerMember(member: $member, password: $password, passwordRepeat: $passwordRepeat, flowId: $flowId) {
				flow {
					...FlowFragment
				}
				accessToken {
					...AccessTokenFragment
				}
				__typename
			}
		}
	${Fragment.AccessTokenFragment}
	${Fragment.FlowFragment}`
	},
	goToOtpLogin: {
		mutation: gql`
		mutation goToOtpLogin($flowId: Int!) {
			goToOtpLogin(flowId: $flowId) {
				flow {
					...FlowFragment
				}
				contactUsedForOtp {
					...ContactUsedForOtpFragment
				}
				__typename
			}
		}
	${Fragment.ContactUsedForOtpFragment}
	${Fragment.FlowFragment}`
	},
	confirmLoginAccessCode: {
		mutation: gql`
		mutation confirmLoginAccessCode($flowId: Int!, $accessCode: String!) {
			confirmLoginAccessCode(flowId: $flowId, accessCode: $accessCode) {
				flow{
					...FlowFragment
				}
				accessToken{
					...AccessTokenFragment
				}
			}
		}
	${Fragment.AccessTokenFragment}
	${Fragment.FlowFragment}`
	},
	login: {
		mutation: gql`
		mutation login($flowId: Int!, $password: String!) {
			login(flowId: $flowId, password: $password) {
				flow{
					...FlowFragment
				}
				accessToken{
					...AccessTokenFragment
				}
			}
		}
	${Fragment.AccessTokenFragment}
	${Fragment.FlowFragment}`
	},
	frontLog: {
		mutation: gql`
			mutation frontLog($frontLogInput: FrontLogInput!) {
  			frontLog(frontLogInput: $frontLogInput)
  			}
		`
	},
	changeTimeSlotByBasketGuid: {
		mutation: gql`
			mutation changeTimeSlot ($orderId: String!, $timeSlotId: Int!){
				changeTimeSlot(orderId: $orderId, timeSlotId: $timeSlotId)
			}
		`
	}
};


export default Mutation;
