import React, {FC, useState} from "react";
import cn from "classnames";

import {openShop} from "../../../../services/RouteService/RouteService";

import {ICONS} from "../../../UI/Icon/enums";
import {menuData} from "./menuData";

import Logo from "../../../UI/Logo/Logo";
import AccountBar from "./AccountBar/AccountBar";
import NavigationBlock from "./NavigationBlock/NavigationBlock";
import SocialBlock from "./SocailBlock/SocialBlock";
import Icon from "../../../UI/Icon/Icon";
import Button from "../../../UI/Button/Button";

import './SidebarMenu.base.styl';


type SidebarMenuProps = {
	isHidden?:boolean;
	toogle:any;
	location: any;
};

const SidebarMenu: FC<SidebarMenuProps> = (props) => {
	const {isHidden, toogle, location} = props;

	const [isMainMenuVisible, toggleDisplay] = useState(false);

	const toggleDisplayMainMenu = () => {
		toggleDisplay(prevState => !prevState);
	};

	const currentSubdomain = "my.fora.ua",
		mainMenu = (menuData || {}).mainMenu || [],
		additionalMenu = ((menuData || {}).additionalMenu || []).filter(
			(additionalMenuItem) => additionalMenuItem.subdomain === currentSubdomain
		)[0] || [];

	const mainMenuProps = {
		location,
		className: "main-menu",
		links: mainMenu.map((menuItem) => {
				return {
					...menuItem,
					link: `https://fora.ua/${menuItem.path}`,
					subLinks: (menuItem.subLinks && menuItem.subLinks.length !== 0 )
						?
						menuItem.subLinks.map((nestedMenuItem) => ({
							...nestedMenuItem,
							link: `https://fora.ua/${menuItem.path}/${nestedMenuItem.path}`,
						}))
						: null
				}
			})
	};

	const additionalMenuProps = {
		location,
		className: "additional-menu",
		parentSlug: additionalMenu.path ? `/${additionalMenu.path}` : "",
		links: additionalMenu.links,
	};


return(
	<aside id="sidebar" hidden={isHidden}>
		<div className="menu-sidebar">
			<Icon icon={ICONS.cross} className="close-button" onClick={toogle}	/>
			<Logo/>
			<AccountBar closeMenu={toogle}/>
			<Button
					className="menu-button"
					primary
					onClick={() => openShop()}
				>
					<Icon className="icon-tap" icon={ICONS.tap}/>
					Інтернет-Магазин
			</Button>

			<div className="navigation-block">
				<div className={cn("menu__body another-section", {open: isMainMenuVisible})}>
					<div className="main-menu-title" onClick={toggleDisplayMainMenu}>
						<div className="to-main-menu-title">
							<span>До головного меню</span>
						</div>
						<div className="dropdown-icon">
							<Icon icon={isMainMenuVisible ? ICONS.minus : ICONS.plus}/>
						</div>
					</div>
					<NavigationBlock {...mainMenuProps} />
				</div>

				<div className="menu__body">
					<NavigationBlock {...additionalMenuProps} />
				</div>
			</div>

			<SocialBlock/>
		</div>
	</aside>
	)
};

export default SidebarMenu;
