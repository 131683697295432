import React, {FunctionComponent, useState, useEffect} from "react";
import {Omit} from "recompose";

import {InnerMySubscriptionsPropsType} from "../../../forms/SubscriptionsForm/types";
import Panel from "../../../components/UI/Panel/Panel";
import BonusProtectionForm from "../../../forms/BonusProtectionForm/BonusProtectionForm";
import {ChangeCodeFormValues} from "../../../forms/BonusProtectionForm/types";

import "./BonusProtection.styl";
import client from "../../../services/apollo";
import Query from "../../../graphql/Query";
import Mutation from "../../../graphql/Mutation";
import getTemplateText from "../../../services/TextService";

const BonusProtection: FunctionComponent<InnerMySubscriptionsPropsType> = () => {
	const [showSuccess, setShowSuccess] = useState(false);
	const [errMessage, setErrMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	let timer: ReturnType<typeof setTimeout> | null = null;

	useEffect(() => () => {
		if (timer) clearTimeout(timer);
	}, [timer]);

	const showSuccessMessage = () => {
		setShowSuccess(true);
		activateSuccessTimer();
	};

	const activateSuccessTimer = () => {
		timer = setTimeout(() => {
			if (timer) clearTimeout(timer);
			setShowSuccess(false);
		}, 4E3);
	};


	const onSubmit = (values: Omit<ChangeCodeFormValues, "submitError">) => {
		let {isAutomatic, secureCode, secureCodeConfirm} = values;


		client.query({
			query: Query.checkVoucherSecureCode.query,
			"fetchPolicy": "network-only"
		}).then(res => {

			const voucherSecureCode = {
				isAutomatic,
					secureCode,
					secureCodeConfirm
			};

			if(res.data.checkVoucherSecureCode){
				client.mutate({
					...Mutation.changeVoucherSecureCode,
					variables: {voucherSecureCode}
				})
					.then(data => {
						if ((data || {}).errors) {
							throw data.errors;
						}
						setSuccessMessage(getTemplateText("form.protection.reSuccess"));
						showSuccessMessage();

					})
					.catch(err => {
						setErrMessage(err.message);
					});
			}else{
				client.mutate({
					...Mutation.activateVoucherSecureCode,
					variables: {voucherSecureCode}
				})
					.then(data => {

						setSuccessMessage(getTemplateText("form.protection.success"));
						showSuccessMessage();

					})
					.catch(err => {
						setErrMessage(err.message);
						showSuccessMessage();
					});
			}
		})
			.catch(err => {
				setErrMessage(err.message);
				showSuccessMessage();
		});
	};
	return (
		<Panel className="my-info__bonus-protection">
			<BonusProtectionForm
				onSubmit={onSubmit}
				showSuccess={showSuccess}
				errorMessage={errMessage}
				successMessage={successMessage}
			/>
		</Panel>
	)
};

export default BonusProtection;
