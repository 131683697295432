import React, {FC, useEffect, useRef} from 'react'
import {FormikValues, useFormikContext} from 'formik'

import getTemplateText from '@services/TextService'
import {form} from './const'

import Form from '@components/Form/Form'
import FormError from '@components/Form/FormError/FormError'
import Button from '@components/UI/Button/Button'
import EmailVerification from './components/EmailVerification'
import PasswordVerification from './components/PasswordVerification'

const RecoveryPasswordAndEmailEntryForm: FC<{withEmail: boolean}> = ({withEmail}) => {
	const isMount = useRef(false);
	const {handleSubmit, isValid, setFieldValue, isSubmitting, errors, resetForm} = useFormikContext<FormikValues>();

	useEffect(() => {
		isMount.current = true;
		return () => {
			isMount.current = false;
			resetForm();
		}
		// eslint-disable-next-line
	}, [])

	const onEmailChange = ({target}) => {
		const value = target.value || "";
		setFieldValue("email", value.trim());
	};

	return (
		<Form onSubmit={handleSubmit} className="create-password-with-email-verification__form" name={form}>
			<PasswordVerification />
			{withEmail && <EmailVerification onEmailChange={onEmailChange} />}
			{errors.submitError && <FormError>{errors.submitError}</FormError>}
			<Button
				className="btn-submit"
				primary
				block={false}
				type="submit"
				disabled={!isValid || isSubmitting}
				loading={isSubmitting}
			>{getTemplateText("otpForm.confirm")}</Button>
		</Form>
	)
}

export default RecoveryPasswordAndEmailEntryForm