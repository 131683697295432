import React, {Component} from "react";
import cn from "classnames";
import {compose} from "recompose";
import moment from "moment";
import {graphql} from "react-apollo";

import {MyCardsPropsType, MyCardsStateType} from "./types";
import {CardAddFormValues} from "../../../forms/CardAddForm/types";

import client from "../../../services/apollo";
import Query from "../../../graphql/Query";
import Mutation from "../../../graphql/Mutation";
import {isArray, isValidDate} from "../../../utils";
import {parseFormError} from "../../../services/FormServices/FormService";
import getTemplateText, {renderTemplateTextNode} from "../../../services/TextService";
import {CARD, ENUM, ERRORS} from "../../../constants";
import {ICONS} from "../../../components/UI/Icon/enums";
import {CardActivateFormValues} from "../../../forms/CardActivateForm/types";

import Button from "../../../components/UI/Button/Button";
import Icon from "../../../components/UI/Icon/Icon";
import CardLockModal from "../../../components/Modals/CardLockModal/CardLockModal";
import FormError from "../../../components/Form/FormError/FormError";
import CardAddModal from "../../../components/Modals/CardAddModal/CardAddModal";
import CardAddedSuccessModal from "../../../components/Modals/CardAddedSuccessModal/CardAddedSuccessModal";

import "./MyCards.styl";


const MyCards = compose(
	graphql(Query.cards.query, {name: "cardsData"}),
)(class MyCards extends Component<MyCardsPropsType, MyCardsStateType> {
	constructor(props) {
		super(props);
		this.state = {
			cards: [],
			error: null,
			cardLockModalOpenBarcode: null,
			addCardModalOpen: false,
			shouldShowActivateCardForm: false,
			cardAdded: false
		};
	}

	changeCardStatus = (card) => () => {
		const {status, barcode} = card;

		return client.mutate({
			...Mutation.changeCardStatus,
			variables: {
				barcode,
				status: status === ENUM.ACTIVE ? ENUM.BLOCKED : ENUM.ACTIVE
			}
		})
			.then(({data: {changeCardStatus}, data}) => {
				if (changeCardStatus) {
					this.setState({error: null});
					this.props.cardsData.refetch();
				}
			})
			.catch(err => {
				const {graphQLErrors} = err;
				if (graphQLErrors[0].code === ERRORS.CODE_USER_WITHOUT_EMAIL) {
					this.setState({error: graphQLErrors[0].message});
				}
			});
	};

	/**
	 * checks card whether it needs activation code to be activated
	 * if does, it redirects to the activation code form
	 * if doesn't, just adds card
	 * @param values
	 * @param formProps
	 */
	checkCardForActivationCode = (values: CardAddFormValues, formProps) => {
		return client.query({
			query: Query.checkCardActivationCode.query,
			variables: {
				barcode: values.barcode
			}
		})
		.then(({data={}, errors}) => {
			if (errors) {
				throw errors;
			}
			if (data.checkCardActivationCode) {
				this.setState({shouldShowActivateCardForm: true});
				localStorage.setItem("barcodeToActivate", values.barcode);
			} else {
				const variables = {
					barcode: values.barcode,
					activationCode: ""
				};
				this.addCardSubmit(variables, formProps);
			}
		})
			.catch((err) => {
				const errors = parseFormError(err);
				if (Object.keys(errors).length) {
					formProps.setErrors(errors);
				}
			})
	};

	/**
	 * handles activation code form and creates card
	 * @param values
	 * @param formProps
	 */
	addCardActivationCodeSubmit = (values: CardActivateFormValues, formProps) => {
		const barcode = localStorage.getItem("barcodeToActivate");
		if (barcode) {
			const variables = {
				barcode: barcode,
				activationCode: values.activationCode
			};
			this.addCardSubmit(variables, formProps);
		}
	};

	toggleCardAddedSuccessModal = () => {
		this.setState((prevState => ({cardAdded: !prevState.cardAdded})))
	};

	addCardSubmit = (values, formProps) => {
		return client.mutate({
			...Mutation.createCard,
			variables: values
		})
			.then(({data: {createCard, errors}}) => {
				if (createCard) {
					this.props.cardsData.refetch();
					this.toogleAddCardModal();
					this.setState({cardAdded: true})
				} else if (errors){
					const formErrors = parseFormError(errors);
					if (Object.keys(formErrors).length) {
						formProps.setErrors(formErrors);
					}
				}
			})
			.catch(err => {
				const errors = parseFormError(err);
				if (Object.keys(errors).length) {
					formProps.setErrors(errors);
				}
			});
	};

	setOpenedCardLockModal =  (barcode) => () => {
		this.setState( {cardLockModalOpenBarcode: barcode})
	};

	toogleAddCardModal =  () => {
		this.setState((prevState) => ({
			addCardModalOpen: !prevState.addCardModalOpen,
			shouldShowActivateCardForm: false
		}))
	};


	render() {
		const {cardsData: {cards}} = this.props;
		const {cardLockModalOpenBarcode, addCardModalOpen, shouldShowActivateCardForm, cardAdded} = this.state;

		const cardAddButton = (
			<Button onClick={this.toogleAddCardModal} className="cards__button cards__button-add">
                <span>
                    <Icon icon={ICONS.circlePlus}/>додати нову картку
                </span>
			</Button>
		);

		return (
			<div className="my-cards-page">
				<div className="my-cards">
					<h1 className="my-cards__heading heading3">Ваші картки</h1>

					<table className="cards__table">
						<thead className="cards__head -desktop">
						<tr>
							<th className="cards__cell cards__head-th">Дата видачі</th>
							<th className="cards__cell cards__head-th">Номер картки</th>
							<th className="cards__cell cards__head-th">Тип картки</th>
							<th className="cards__cell cards__head-th">Статус</th>
							<th className="cards__cell cards__head-th"/>
						</tr>
						</thead>

						<tbody className="cards__body">
						{isArray(cards) && cards.map((card, index) => {
							const {status, isStatusChangeable, barcode} = card;
							const active = status === CARD.ACTIVE,
								actionText = active ? "Заблокувати" : "Активувати",
								date = new Date(card.issueDate),
								blockButton = (
									<Button
										onClick={this.setOpenedCardLockModal(barcode)}
										className="cards__button cards__button-toggle-lock">
										<span>
											{actionText}
											<Icon icon={active ? ICONS.lockClosed : ICONS.lockOpened}/>
										</span>
									</Button>
								);

							return (
								<tr key={index} className={cn("cards__row", {"active": active})}>
									<td className="cards__cell cards__row-td">
										<span className="cards__card-label -mobile">Дата видачі</span>
										<div className="cards__card-date">
											{isValidDate(date) && moment(date).format("DD.MM.YYYY")}
										</div>
									</td>
									<td className="cards__cell cards__row-td">
										<span className="cards__card-label -mobile">Номер картки</span>
										<div className="cards__card-number">{card.barcode}</div>
									</td>
									<td className="cards__cell cards__row-td">
										<span className="cards__card-label -mobile">Тип картки</span>
										<div className="cards__card-type">
											{card.typeName}
										</div>
									</td>
									<td className="cards__cell cards__row-td">
										<span className="cards__card-label -mobile">Статус</span>
										<div className="cards__card-status">
											{card.statusName}
										</div>
									</td>
									<td className="cards__cell cards__row-td cards__row-controls">
										<div className="cards__card-controls">
											{isStatusChangeable &&
												<React.Fragment>
													{blockButton}
													<CardLockModal
														open={cardLockModalOpenBarcode === barcode}
														card={card}
														actionText={actionText}
														onSuccess={this.changeCardStatus(card)}
														onClose={this.setOpenedCardLockModal(null)}
													/>
												</React.Fragment>
											}
										</div>
									</td>
								</tr>
							)
						})}
						</tbody>

						<tfoot className="cards__foot">
						<tr>
							<td colSpan={2} className="cards__cell cards__foot-td">
								{!!this.state.error && (
									<FormError>
										{this.state.error}
									</FormError>
								)}
							</td>
							<td colSpan={3} className="cards__cell cards__foot-td -right">
								 {cardAddButton}
									<CardAddModal
										open={addCardModalOpen}
										onClose={this.toogleAddCardModal}
										shouldShowActivateCardForm={shouldShowActivateCardForm}
										onCardSubmit={this.checkCardForActivationCode}
										onActivationCodeSubmit={this.addCardActivationCodeSubmit}
									/>
							</td>
						</tr>
						</tfoot>
					</table>
				</div>

				<div className="my-cards__info">
					<h4 className="my-cards__info-heading">{getTemplateText("myCards.infoHeading")}</h4>
					<hr/>
					<div className="my-cards__info-text">{renderTemplateTextNode("myCards.infoText")}
					</div>
				</div>

				<CardAddedSuccessModal
					open={cardAdded}
					onClose={this.toggleCardAddedSuccessModal}/>

			</div>
		)
	}
})

export default (MyCards);