export const API_ENDPOINT = (() => process.env["API_ENDPOINT"] || "")();
export const PAYMENT_API = (() => process.env["PAYMENT_API"] || "")();
export const CABINET = (() => process.env["CABINET"] || "")();
export const SHOP_HOST = (() => process.env["SHOP_HOST"] || "")();
export const BASKET_API = (() => process.env["BASKET_API"] || "")();
export const isDev = (() => (process.env["NODE_ENV"] === "development") ||
	API_ENDPOINT === "https://new-my.fora.iir.fozzy.lan/graphql")();
export const VERSION = (() => process.env["VERSION"] || "0.0.0")();
export const ANALYTICS = (() => process.env["ANALITICS"] || false)();
export const RECAPTURE = (() => process.env["RECAPTURE"] || false)();
export const GOOGLE_CAPTURE_KEY = (() => process.env["GOOGLE_CAPTURE_KEY"] || "")();
export const HOST = (() => process.env["HOST"] || "")();
export const PROJECT = (() => process.env["PROJECT"] || "")();
export const LOGGER = (() => process.env.LOGGER !== undefined ? process.env.LOGGER : true)();