import React, {FC} from 'react'
import Modal from "react-responsive-modal";

import getTemplateText from '../../../services/TextService';

import {HashModalProps} from '../../Modal/ModalInterfaces';
import Button from '../../UI/Button/Button';
import {ICONS} from '../../UI/Icon/enums';
import Icon from '../../UI/Icon/Icon';

import "./SuccessPaymentModal.styl";

const paymentSuccessImg = "/images/fora/img-payment-success.svg"

const SuccessPaymentModal: FC<HashModalProps> = ({isOpen, onClose}) => {

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center
			classNames={{
				"overlay": "modal-overlay",
				"modal": "modal-window info__dialog success-payment-modal",
				"closeButton": "modal-closeButton",
				"closeIcon": "modal-closeIcon"
			}}
		>
			<div>
				<img className="charity-logo" src={paymentSuccessImg} alt="payment success" />
			</div>
			<h2 className="page-heading">{getTemplateText("succesPage.payment")}</h2>
			<h3 className="page-text-charity" >
				{getTemplateText("succesPage.order.description")}
			</h3>
			<div className="payment-warning">
				<Icon icon={ICONS.circleInfo} />
				{getTemplateText("succesPage.payment.warning")}
			</div>
			<div className="default-btn-holder">
				<Button className="choose_market_btn" primary onClick={onClose}>
					{getTemplateText("headerInfo.myOrders")}
				</Button>
			</div>
		</Modal>
	)
}

export default SuccessPaymentModal