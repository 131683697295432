import React from 'react'
import {Field} from 'formik'
import {getUniqueId} from '@utils'

import FormField from '@components/Form/FormField'
import FormInput from '@components/Form/FormInput'
import FormRow from '@components/Form/FormRow'
import {FormattedHTMLMessage} from '@services/TextService'
import {Tooltip} from 'react-tooltip'

const UserPasswordFields = () => {
	const passwordHintId = getUniqueId();

	return (
		<FormRow>
			<FormField>
				<Field
					name="password"
					type="password"
					component={FormInput}
					label="Створіть свій зручний пароль"
					data-tooltip-id={passwordHintId}
				/>
				<Tooltip className="tooltip__create-password" id={passwordHintId} openOnClick place="bottom">
					<div >
						<FormattedHTMLMessage id="hint.password" />
					</div>
				</Tooltip>
			</FormField>
			<FormField>
				<Field
					name="passwordRe"
					type="password"
					component={FormInput}
					label="Повторіть пароль" />
			</FormField>
		</FormRow>
	)
}

export default UserPasswordFields