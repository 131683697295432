import React, {FC, useCallback, useState} from 'react';
import classNames from 'classnames';

import {DELIVERY_LABELS, DELIVERY_TYPES, PURCHASE_STATE_CLASS_LABELS, PURCHASE_TYPES} from '../../../../../../constants';
import usePurchaseDetails from '../../../hooks/usePurchaseDetails';
import {OrderItemType} from '../../../hooks/useOrdersList/types';
import {PurchaseDetailsItemType} from '../../../hooks/usePurchaseDetails/types';

import {ICONS} from '../../../../../../components/UI/Icon/enums';
import Icon from '../../../../../../components/UI/Icon/Icon';
import PurchaseItemDetails from '../PurchaseItemDetails/PurchaseItemDetails';
import Loader from '../../../../../../components/UI/Loader/Loader';

import './PurchaseItem.styl';

const PurchaseItem: FC<{item: OrderItemType}> = ({item}) => {
	const [openedPurchase, setOpenedPurchase] = useState(false);
	const {getPurchaseDetails, loading} = usePurchaseDetails();
	const [purchaseDetailsData, setPurchaseDetailsData] = useState<PurchaseDetailsItemType | null>(null);
	const {purchaseState, deliveryType, createDateText, orderNumber, stateText, summ} = item;
	const activeState = PURCHASE_STATE_CLASS_LABELS[purchaseDetailsData?.state || purchaseState];
	const activeStateText = purchaseDetailsData?.stateText || stateText;

	const handlePurchaseClick = useCallback((item: OrderItemType) => () => {
		setOpenedPurchase(prev => !prev);
		if (!openedPurchase) {
			let basketGuid;
			const chequeData: any = {};
			if (item.purchaseType === PURCHASE_TYPES.ONLINE) {
				basketGuid = item.id;
			} else {
				chequeData.chequeId = item.chequeId;
				chequeData.filialId = item.filialId;
				chequeData.created = item.created;
			}
			getPurchaseDetails(item.purchaseType, basketGuid, chequeData).then(res => setPurchaseDetailsData(res));
		}
	}, [openedPurchase, getPurchaseDetails]);

	const refetchOrder = useCallback((item: PurchaseDetailsItemType) => {
		if (item.purchaseType === PURCHASE_TYPES.ONLINE) {
			getPurchaseDetails(item.purchaseType, item.id).then(res => setPurchaseDetailsData(res));
		}
	}, [getPurchaseDetails]);

	return (
		<li className={classNames('purchases-list-item', {"isActive": openedPurchase})}>
			<div onClick={handlePurchaseClick(item)} className='purchases-list-item-info'>
				<div className='purchases-item-main'>
					<span className='purchases-item-delivery'>{DELIVERY_LABELS[deliveryType]}</span>
					<div className='purchases-item-datetime'>
						<time className='purchases-item-date'>{createDateText.split(" ").join(", ")}</time>
						{deliveryType !== DELIVERY_TYPES.UNKNOWN &&
							<span className='purchases-item-order'>,&nbsp;<span>&#8470;</span>{orderNumber}</span>}
					</div>
				</div>
				<span className='purchases-item-sum'>{summ + " грн"}</span>
				<span className={classNames('purchases-item-status', activeState)}>{activeStateText}</span>
			</div>
			<div onClick={handlePurchaseClick(item)} className='purchases-list-item-info-mobile'>
				<span className='purchases-item-sum-mobile'>{summ + " грн"}</span>
				<div className='purchases-item-datetime-mobile'>
					<time className='purchases-item-date'>{createDateText.split(" ").join(", ")}</time>
					{deliveryType !== DELIVERY_TYPES.UNKNOWN &&
						<span className='purchases-item-order'>,&nbsp;<span>&#8470;</span>{orderNumber}</span>}
				</div>
			</div>
			<Icon className={{"isActive": openedPurchase}} icon={ICONS.faqArrow} />
			{loading && <Loader />}
			{openedPurchase && !loading && purchaseDetailsData && <PurchaseItemDetails itemDetails={purchaseDetailsData} refetchOrder={refetchOrder} chequeData={item.chequeId ? {chequeId: item.chequeId, created: item.created} : undefined} />}
		</li>
	)
}

export default PurchaseItem;