import React, {FC, useState} from 'react';
import {AUTH_NEW_ENTRANCE_ACTIONS} from '../../constants';

import CardInformationEntry from './components/CardInformationEntry/CardInformationEntry';
import OtpEntry from './components/OtpEntry/OtpEntry';
import PasswordEntry from './components/PasswordEntry/PasswordEntry';
import PhoneEntrance from './components/PhoneEntrance/PhoneEntrance';
import RecoveryContactSelection from './components/RecoveryContactSelection/RecoveryContactSelection';
import RecoveryPasswordAndEmailEntry from './components/RecoveryPasswordAndEmailEntry/RecoveryPasswordAndEmailEntry';
import RegistrationInfoEntry from './components/RegistrationInfoEntry/RegistrationInfoEntry';
import {AuthNewPropsType} from './components/types';

const Auth: FC<AuthNewPropsType> = ({onClose}) => {
	const [nextAuthStep, setNextAuthStep] = useState<AUTH_NEW_ENTRANCE_ACTIONS | null>(AUTH_NEW_ENTRANCE_ACTIONS.authEntrance)

	/*
	* set next modal which should be opened 
	* @param nextStep next modal
	*/
	const determineNextStep = (nextStep: AUTH_NEW_ENTRANCE_ACTIONS | null) => {
		setNextAuthStep(nextStep);
	}

	switch (nextAuthStep) {
		case AUTH_NEW_ENTRANCE_ACTIONS.authEntrance:
			return <PhoneEntrance determineNextStep={determineNextStep} />
		case AUTH_NEW_ENTRANCE_ACTIONS.otpEntry:
			return <OtpEntry determineNextStep={determineNextStep} />
		case AUTH_NEW_ENTRANCE_ACTIONS.passwordEntry:
			return <PasswordEntry withOpt determineNextStep={determineNextStep} onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.cardInformationEntry:
			return <CardInformationEntry determineNextStep={determineNextStep} onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.registrationInfoEntry:
			return <RegistrationInfoEntry determineNextStep={determineNextStep} onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.recoveryContactSelection:
			return <RecoveryContactSelection determineNextStep={determineNextStep} onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.recoveryOtpEntry:
			return <OtpEntry isRecovery determineNextStep={determineNextStep} />
		case AUTH_NEW_ENTRANCE_ACTIONS.loginOtpEntry:
			return <OtpEntry withPassword onClose={onClose} determineNextStep={determineNextStep} />
		case AUTH_NEW_ENTRANCE_ACTIONS.passwordEntryWithoutLoginOtpLink:
			return <PasswordEntry determineNextStep={determineNextStep} onClose={onClose} />

		case AUTH_NEW_ENTRANCE_ACTIONS.recoveryPasswordEntry:
			return <RecoveryPasswordAndEmailEntry determineNextStep={determineNextStep} onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.recoveryPasswordAndEmailEntry:
			return <RecoveryPasswordAndEmailEntry determineNextStep={determineNextStep} withEmail onClose={onClose} />
		case AUTH_NEW_ENTRANCE_ACTIONS.createOrder:
		case AUTH_NEW_ENTRANCE_ACTIONS.showRegistrationWelcome:
		default:
			return null;
	}
}

export default Auth;