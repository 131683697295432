import {FORMS} from "js/forms/enums";
import {DATA_TYPE} from "@services/FormServices/constants";
import {checkPasswordIdentity, normalizeFields, validate} from '@services/FormServices/FormService'
import {PersonalInfoValuesType} from "./types";

export const fields = {
	firstName: {
		name: "firstName",
		dataType: DATA_TYPE.name
	},
	lastName: {
		name: "lastName",
		dataType: DATA_TYPE.name,
	},
	birthday: {
		name: "birthday",
		dataType: DATA_TYPE.birthday,
	},
	password: {
		name: "password",
		dataType: DATA_TYPE.password
	},
	passwordRe: {
		name: "passwordRe",
		dataType: DATA_TYPE.password
	},
	email: {
		name: "email",
		dataType: DATA_TYPE.email
	}
};

export const form = FORMS.REGISTRATION_INFO_FORM;

export const initialValues = {
	firstName: "",
	lastName: "",
	birthday: "",
	password: "",
	passwordRe: "",
	email: "",
	submitError: "",
	formError: ""
}

export const initialValid = (props) => {
	const errors = validate(props.initialValues, {fields: normalizeFields(fields, form), form, formValidate: checkPasswordIdentity});
	return Object.keys(errors).length === 0;
}

export const handleValidate = (values: PersonalInfoValuesType) =>
	validate(values, {
		fields: normalizeFields(fields, form),
		form,
		formValidate: checkPasswordIdentity
	})