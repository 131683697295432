import React from 'react'
import {Field} from 'formik'

import FormRow from '@components/Form/FormRow'
import FormField from '@components/Form/FormField'
import FormInput from '@components/Form/FormInput'

const UserBDayField = () => {
	return (
		<FormRow>
			<FormField>
				<Field
					name="birthday"
					type="date"
					placeholder="дд/мм/рррр"
					component={FormInput}
					autoComplete="off"
					label="Дата народження" />
			</FormField>
		</FormRow>
	)
}

export default UserBDayField