import React, {Component} from 'react';
import {Route, withRouter} from "react-router-dom";
import {compose} from "recompose";

import {getModalLocation, onRouteChange, setHistory} from "../../services/RouteService/RouteService";
import {PROJECT} from "../../services/EnvServices";
import WithContext from "../HOCs/WithContext/WithContext";
import {LayoutOutProps, LayoutProps, LayoutState} from "./types";
import UnauthorizedPage from "../../pages/UnauthorizedPage/UnauthorizedPage";
import InitializePage from "../../pages/InitializePage/InitializePage";
import Menu from "./Menu/Menu";
import Footer from "./Footer/Footer";
import HashModal from "../Modal/HashModal";
import Icon from "../UI/Icon/Icon";
import {ICONS} from "../UI/Icon/enums";
import {HASH_MODALS} from "../../constants";

import './Layout.styl'

const scrollHeight = 500;

const Layout = compose<LayoutProps, LayoutOutProps>(
	withRouter,
	WithContext(
		(state) => ({
			isLogin: state.isLogin,
			isInitialized: state.isInitialized,
			sighUpModal: state.sighUpModal
		})
	)
)(class Layout extends Component<LayoutProps & LayoutOutProps, LayoutState> {
	constructor(props) {
		super(props);
		this.state = {
			goTop: false
		};
	}

	componentWillReceiveProps(nextProps) {
		const {history} = this.props;
		if (this.props.location.pathname !== nextProps.location.pathname) {
			window.scrollTo({top: 0});
		}
		if (!this.props.sighUpModal && nextProps.sighUpModal) {
			history.push(getModalLocation(HASH_MODALS.WELCOME));
		}
	}

	componentDidMount(props = this.props): void {
		const {history} = props;
		history.listen(onRouteChange);
		setHistory(history);
		window.addEventListener("scroll", this.onScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
	}



	/***
	 * On scrollbar scroll event
	 */
	onScroll = () => {
		const {goTop} = this.state;
		const scrolled = window.pageYOffset || document.documentElement.scrollTop;
		if (scrolled > scrollHeight && !goTop) {
			this.setState({goTop: true})
		} else if (scrolled <= scrollHeight && goTop) {
			this.setState({goTop: false})
		}
	};

	/***
	 * Scroll to top with animation
	 */
	scrollToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	};

	render() {
		if (!this.props["isInitialized"]) {
			return <InitializePage/>
		}
		const {component: Component, history, location, match, ...rest} = this.props;
		if (!this.props["isLogin"]) {
			return <UnauthorizedPage />
		}
		const buttonClass = `back-to-top ${this.state.goTop ? "show" : ""}`;
		return (
			<Route {...rest} render={matchProps => (
				<div className={`layout ${PROJECT}-layout `}>
					<Menu history={history} location={location} match={match}/>
					<div className="container">
						<div className="content">
							<Component {...matchProps} />
						</div>
						<Footer/>
						<div className={buttonClass} onClick={this.scrollToTop}>
							<Icon icon={ICONS.arrowRight}/>
						</div>
					</div>
					<HashModal history={history} location={location} match={match}/>

				</div>
			)}/>
		)
	}
});

export default Layout;
