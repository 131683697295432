import {FormikErrors} from 'formik';
import moment from "moment";
import {ErrorResponse} from 'apollo-link-error';

import {IField, IFormProps} from "./interfaces";
import {FieldParamsType} from "./types";
import {DATA_TYPE, DATA_TYPE_RULES, RULE} from "./constants";
import {isFunction, isObject, isTargetProject} from "../../utils";
import PATTERN from "./patterns";
import getTemplateText from "../TextService";
import {PROJECT} from "../../constants";

const BARCODE_MIN_LENGTH = 9;
const BARCODE_MAX_LENGTH = 13;

const {
	appartmentPattern,
	appartmentSeparatorPattern,
	buildingPattern,
	buildingSeparatorPattern,
	emailPattern,
	fullnamePattern,
	hoursPattern,
	minutesPattern,
	namePattern,
	passwordPattern,
	pattern,
	patternValidate,
	phoneCodePattern,
	phonePattern,
	phoneTitlePattern,
	sameCharactersPattern
} = PATTERN;

const TIME_PLACEHOLDER = "––:––";

const DATE_FORMAT = "DD/MM/YYYY",
	DATE_FORMAT_PATTERN = /\d{1,2}\/\d{1,2}\/\d{4}/,
	QUERY_DATE_FORMAT = "DD-MM-YYYY";


const RULES = {
	[RULE.min_length]: (value = "", params: FieldParamsType = {}) => !notLessThan(value.length, params[RULE.min_length]) && RULE.min_length,
	[RULE.max_length]: (value = "", params: FieldParamsType = {}) => !notMoreThan(value.length, params[RULE.max_length]) && RULE.max_length,
	[RULE.no_numbers]: (value = "") => hasNumber(value) && RULE.no_numbers,
	[RULE.numbers_only]: (value = "") => !numbersOnly(value) && RULE.numbers_only,
	[RULE.has_number]: (value = "") => !hasNumber(value) && RULE.has_number,
	[RULE.has_character]: (value = "") => !hasChar(value) && RULE.has_character,
	[RULE.has_lowercase]: (value = "") => !hasLowercaseChar(value) && RULE.has_lowercase,
	[RULE.has_uppercase]: (value = "") => !hasUppercaseChar(value) && RULE.has_uppercase,
	[RULE.has_simple_combinations]: (value = "") => hasSimpleCombinations(value) && RULE.has_simple_combinations,
	[RULE.has_same_character]: (value = "") => hasSameCharacter(value) && RULE.has_same_character,
};

export const DATA_TYPE_PARAMS = {
	[DATA_TYPE.name]: {
		[RULE.min_length]: 2,
		[RULE.max_length]: 32,
		[RULE.invalid]: (value) => !(value && validateName(value)) && RULE.invalid
	},
	[DATA_TYPE.code]: {
		[RULE.has_simple_combinations]: (value = "") => hasSimpleCombinations(value) && RULE.has_simple_combinations,
		[RULE.has_same_character]: (value = "") => hasSameCharacter(value) && RULE.has_same_character,
		[RULE.min_length]: 4

	},
	[DATA_TYPE.number]: {
		[RULE.invalid]: (value) => !numbersOnly(value) && RULE.invalid
	},
	[DATA_TYPE.email]: {
		[RULE.invalid]: (value) => !(value && validateEmail(value)) && RULE.invalid,
		[RULE.max_length]: 64
	},
	[DATA_TYPE.phone]: {
		[RULE.invalid_format]: (value) => !(value && validateCodePhone(value)) && RULE.invalid_format,
		[RULE.invalid]: (value) => !(value && validatePhone(value)) && RULE.invalid
	},
	[DATA_TYPE.phoneTitle]: {
		[RULE.max_length]: 20,
		[RULE.invalid]: (value) => !(value && validatePhoneTitle(value)) && RULE.invalid
	},
	[DATA_TYPE.text]: {
		[RULE.min_length]: 10,
		[RULE.max_length]: 1000
	},
	[DATA_TYPE.barcode]: {
		[RULE.max_length]: BARCODE_MAX_LENGTH,
		[RULE.invalid]: (value = "", params: FieldParamsType = {}) => {
			if (value.length === BARCODE_MIN_LENGTH) {
				return false;
			}
			return (value.length !== params[RULE.max_length]) && !isValidBarcodePrefix(value) && RULE.invalid
		},
		[RULE.ean13]: (value = "") => !validateBarcode(barcodeConvertValue(value)) && RULE.ean13
	},
	[DATA_TYPE.birthday]: {
		format: DATE_FORMAT,
		formatPattern: DATE_FORMAT_PATTERN,
		[RULE.invalid_format]: (value = "", params: FieldParamsType = {}) => !validateDate(value, params.formatPattern, params.format) && RULE.invalid_format,
		[RULE.invalid]: (value = "", params: FieldParamsType = {}) => validateDate(value, params.formatPattern, params.format) && !validateBirthday(value, params.formatPattern, params.format) && RULE.invalid
	},
	[DATA_TYPE.oldPassword]: {
		[RULE.max_length]: 1000
	},
	[DATA_TYPE.password]: {
		[RULE.min_length]: 8,
		[RULE.invalid]: (value) => (value && !validatePassword(value)) && RULE.invalid
	},
	[DATA_TYPE.date]: {
		format: DATE_FORMAT,
		formatPattern: DATE_FORMAT_PATTERN,
		[RULE.invalid]: (value = "", params: FieldParamsType = {}) => !validateDate(value, params.formatPattern, params.format) && RULE.invalid
	},
	[DATA_TYPE.postIndex]: {
		[RULE.min_length]: 5,
		[RULE.max_length]: 5
	},
};

function getControlSum(barcode: string): number {
	let sum = {even: 0, odd: 0};
	// let even, odd;
	for (let i = 0; i < barcode.length; i++) {
		let method = (i % 2) ? "odd" : "even";
		sum[method] += Number(barcode.charAt(i));
	}
	return sum.even + sum.odd * 3;
}

function validateFullname(fullname) {
	return fullnamePattern.test(fullname) && !sameCharactersPattern.test(fullname);
}

function checkEan(eanCode: string): boolean {
	const ValidChars = "0123456789";
	for (let i = 0; i < eanCode.length; i++) {
		const digit = eanCode.charAt(i);
		if (ValidChars.indexOf(digit) === -1) {
			return false;
		}
	}
	// Add five 0 if the code has only 8 digits
	if (eanCode.length === 8) {
		eanCode = "00000" + eanCode;
	}
	// Check for 13 digits otherwise
	else if (eanCode.length !== 13) {
		return false;
	}

	// Get the check number
	const originalCheck = eanCode.substring(eanCode.length - 1);
	eanCode = eanCode.substring(0, eanCode.length - 1);
	let total = getControlSum(eanCode);
	// Calculate the checksum
	// Divide total by 10 and store the remainder
	let checksum = total % 10;
	// If result is not 0 then take away 10
	if (checksum !== 0) {
		checksum = 10 - checksum;
	}
	// Return the result
	return checksum === +originalCheck;

}

function isValidSilpoPrefix(_value) {
	const value = barcodeConvertValue(_value);
	const validPrefix = value.indexOf("0290") === 0 || value.indexOf("0291") === 0 || value.indexOf("024") === 0;
	const validLength = value.length === 13 || value.length === 0;
	return validPrefix && validLength;
}

function validateName(name: string): boolean {
	return namePattern.test(name) && !sameCharactersPattern.test(name);
}

function validateBarcode(v: string): boolean {
	return patternValidate(pattern.barcode, v) && checkEan(v);
}


function validateEmail(email: string): boolean {
	return emailPattern.test(email.trim());
}

function validateBuilding(building: string): boolean {
	if (buildingPattern.test(building)) {
		return building.length <= 5 && parseInt(building) < 1450;
	} else if (buildingSeparatorPattern.test(building)) {
		return building.length <= 7;
	} else {
		return false;
	}
}

function validateApartment(apartment: string): boolean {
	if (appartmentPattern.test(apartment)) {
		if (apartment.length === 5) {
			return !(/(\d)\1{4}/.test(apartment));
		}
		return apartment.length < 5;
	} else if (appartmentSeparatorPattern.test(apartment)) {
		return apartment.length <= 8;
	} else {
		return false;
	}
}


function validatePhone(phone: string): boolean {
	return phonePattern.test(phone);
}

function validatePhoneTitle(title: string): boolean {
	return phoneTitlePattern.test(title);
}

function validatePassword(password: string): boolean {
	return passwordPattern.test(password);
}

function validateTime(time = TIME_PLACEHOLDER): boolean {
	const hours = time.split(":")[0] || TIME_PLACEHOLDER.split(":")[0],
		minutes = time.split(":")[1] || TIME_PLACEHOLDER.split(":")[1];

	return hoursPattern.test(hours) && minutesPattern.test(minutes)
}

function moreThan(value: number, number: number = 0): boolean {
	return value > number
}

function notMoreThan(value: number, number: number = 0): boolean {
	return value <= number
}

function lessThan(value: number, number: number = 0): boolean {
	return value < number
}

function notLessThan(value: number, number: number = 0): boolean {
	return value >= number
}

function hasLowercaseChar(s: string): boolean {
	return s !== s.toUpperCase();
}

function hasUppercaseChar(s: string): boolean {
	return s !== s.toLowerCase();
}

function hasNumber(value: string): boolean {
	return /\d/.test(value);
}

function hasChar(value: string): boolean {
	return /[a-zA-Z]/.test(value);
}

function numbersOnly(value: string): boolean {
	return /^\d+$/.test(value);
}

function hasSameCharacter(value: string): boolean {
	return /((\d)\2)(?!\2)/.test(value);
}

function hasSimpleCombinations(value: string): boolean {
	return /^1234|2345|3456|4567|5678|6789|0123|9876|8765|7654|6543|5432|4321|3210$/.test(value);
}

function validateCodePhone(value: string): boolean {
	return phoneCodePattern.test(value);
}

function validateDate(dateString = "", formatPattern: RegExp = DATE_FORMAT_PATTERN, format = DATE_FORMAT) {
	let result = true;
	if (formatPattern && !formatPattern.test(dateString)) {
		result = false
	} else if (format) {
		if (!moment(dateString, format).isValid()) {
			result = false
		}
	} else if (moment(dateString).isValid()) {
		result = false
	}

	return result;
}

function validateBirthday(dateString = "", formatPattern: RegExp = DATE_FORMAT_PATTERN, format = DATE_FORMAT, checkAdult = true) {
	const isValidDate = validateDate(dateString, formatPattern, format);

	if (isValidDate) {
		const now = moment(new Date());
		const date = moment(dateString, format);
		const diffWithNow = now.diff(date, "years", true);

		const isAdult = checkAdult ? diffWithNow >= 18 : diffWithNow >= 0;
		const isNotTooOld = diffWithNow <= 100;

		return isAdult && isNotTooOld;
	}
}


/***
 * Fix barcode to correct
 * @param value
 * @returns {*}
 */
function barcodeConvertValue(value: string) {
	let clearNumber = value.replace(/^\D+/g, "");
	clearNumber = clearNumber.length > BARCODE_MAX_LENGTH ? clearNumber.replace(/^0202/, "02") : clearNumber;
	clearNumber = clearNumber.length > BARCODE_MAX_LENGTH ? clearNumber.substr(0, BARCODE_MAX_LENGTH) : clearNumber;
	if (isTargetProject(PROJECT.FORA) && clearNumber.length === BARCODE_MIN_LENGTH) {
		clearNumber = "0294" + clearNumber
	}
	return clearNumber;
}


/**
 * check whether code are same
 * @param values
 */
function checkCodeIdentity(values) {
	const {newCode, newCodeRe} = values;
	if (newCode && newCodeRe && newCode !== newCodeRe) {
		return {formError: getTemplateText("error.registration.new_code_identity")};
	}
}
/**
 * check whether password are same
 * @param values
 */
function checkPasswordIdentity(values) {
	const {password, passwordRe} = values;
	if (password && passwordRe && password !== passwordRe) {
		return {
			passwordRe: getTemplateText("error.registration.passwords_identity"),
			password: getTemplateText("error.registration.passwords_identity")};
	}
}


//TODO: for fora on prod delete extra masks
/**
 * check if barcodeprefix is correct
 * @param _value
 */
function isValidBarcodePrefix(_value: string) {
	const value = barcodeConvertValue(_value);
	let validPrefix = false;
	if (isTargetProject(PROJECT.SILPO)) {
		validPrefix = value.indexOf("0290") === 0 ||
			value.indexOf("0291") === 0 ||
			value.indexOf("024") === 0;
	} else if (isTargetProject(PROJECT.FORA)) {
		validPrefix = value.indexOf("0290") === 0 ||
			value.indexOf("0294") === 0 ||
			value.indexOf("0291") === 0 ||
			value.indexOf("024") === 0 ||
			value.indexOf("027") === 0;
	}
	const validLength = value.length === BARCODE_MAX_LENGTH || value.length === 0;
	return validPrefix && validLength;
}


/**
 * validates field, adds error rule if any
 * @param field
 * @param value
 */
function validateField(field: IField, value: string | number) {
	const {rules, name, messages, params} = field;
	if (!rules) return {};
	const trimmedValue = typeof value === "string" ? value.trim() : value;
	let errorRule = (!trimmedValue && rules.find(r => r === RULE.required)) || false;
	try {
		if (trimmedValue && !errorRule) {
			const newRules = rules.filter(r => r !== RULE.required);
			for (let i = 0; i < rules.length; i++) {
				const rule = newRules[i],
					ruleParam = params && params[rule],
					ruleFunction = (isFunction(ruleParam) && ruleParam) || RULES[rule];

				const isError = isFunction(ruleFunction) ? ruleFunction(value, params) : rule;
				errorRule = isError ? rule : false;

				if (errorRule) break
			}
		}

	} catch (err: any) {
		console.log("validate mistake", err);
	}

	if (!errorRule) {
		return {};
	} else {
		return {[name]: messages && messages[errorRule]};
	}

}




/**
 * get form error message for field
 * @param {string} messageId string
 * @param {string} defaultMessageId
 * @param {string} defaultFieldMessageId
 * @param {string} rule
 * @return error message
 */
function getFormErrorMessage(messageId: string = "", defaultMessageId: string = "", defaultFieldMessageId: string = "", rule: string = ""): string {
	if (getTemplateText(messageId) !== messageId) {
		return getTemplateText(messageId)
	} else if (getTemplateText(defaultFieldMessageId) !== defaultFieldMessageId) {
		return getTemplateText(defaultFieldMessageId)
	} else if (getTemplateText(defaultMessageId) !== defaultMessageId) {
		return getTemplateText(defaultMessageId)
	} else {
		return RULE[rule]
	}
}

/***
 * normalize field to add error messages to it
 * @param field
 * @param form
 */
function normalizeField(field: IField, form): IField {
	const {name, rules, dataType = "", excludeRules} = field;
	const formName = (form || "").split("--")[0] || "";
	const fieldName = name.includes(".") ? (name || "").split(".")[1] : name;
	let messages, allRules: RULE[] = [];
	if (dataType || rules) {
		allRules = [
			...(DATA_TYPE_RULES[dataType] || []),
			...(rules || [])]
			.filter((r: RULE) => !~(excludeRules || []).indexOf(r));
		messages = allRules.map(rule => {
			const messageId = ["error", formName, fieldName, rule].join(".");
			const defaultMessageId = ["error", rule].join(".");
			const defaultFieldMessageId = ["error", name, rule].join(".");
			const message = getFormErrorMessage(messageId, defaultMessageId, defaultFieldMessageId, rule);
			return {[rule]: message}
		})
			.reduce((res, item) => ({...res, ...item}), {});
	} else {
		messages = {}
	}
	return {
		...field,
		rules: allRules,
		params: {
			...(DATA_TYPE_PARAMS[dataType] || {}),
			...(field.params || {})
		},
		messages
	}
}

/**
 * normalizes field name
 * @param {string} value
 * @returns {string} value
 */
function normalizeName(value: string = ""): string {
	if (value) {
		value = value.replace(/"/g, "’");
	}
	return value;
}

/**
 * normalize password
 * @param {string} value
 * @returns {string} value
 */
function normalizePassword(value: string = ""): string {
	if (value) {
		value = value.replace(/\s/g, "");
	}
	return value;
}

/**
 * normalizes field value
 * @param {string} value
 * @param {string} oldValue
 * @param {string} fieldName
 * @returns {string} value
 */
function normalizeValue(value: string = "", oldValue: string = "", fieldName: string = ""): string {
	switch (fieldName) {
		case "password":
			return normalizePassword(value);
		case "name":
			return normalizeName(value);
		default:
			return value;
	}
}

/**
 * normalizes fields before form render
 * @param fields
 * @param {string} formName
 */
function normalizeFields(fields: Record<string, IField>, formName) {
	return Object.keys(fields)
		.map(key => ({[key]: normalizeField(fields[key], formName)}))
		.reduce((res, d) => ({...res, ...d}), {});
}



/**
 * validates form and returns errors
 * @param values
 * @param props
 * @return {object} errors
 */
function validate<valuesType, propsType extends IFormProps>(values: valuesType, props: propsType) {
	const errors: FormikErrors<valuesType> = Object.keys(props.fields)
		.map(f => props.fields[f])
		.map((f: IField) => {
			return validateField(f, values[f.name])
		})
		.reduce<FormikErrors<valuesType>>((res, err) => ({...res, ...err}), {});

	const formValidate = props["formValidate"];

	const formError = isFunction(formValidate) && formValidate(values);

	return {...errors, ...formError};
}

/**
 * get error depending of the language of the incoming error
 * @param errMessage
 */
const getFormattedError = (errMessage: string) => {
	return errMessage.match(/[a-z]/i) ? getTemplateText("defaultAPIError") : errMessage;
};

/***
 * Parse form error end return object with errors in fields or global error with name submitError
 * @param err
 * @param resetFunc
 * @param fieldModifierFunc
 */
function parseFormError(err: ErrorResponse, resetFunc?: any, fieldModifierFunc?: any): { [key: string]: string } | {} {
	const result = {};
	if (err["graphQLErrors"]) {
		for (const errElement of err["graphQLErrors"]) {
			if (errElement["fields"] && errElement["fields"].length > 0) {
				for (const fieldErr of errElement["fields"]) {
					result[fieldErr["field"]] = isFunction(fieldModifierFunc) ? fieldModifierFunc(fieldErr["field"]) : fieldErr["message"] || fieldErr["rule"] || "unknown";
				}
			} else if (errElement["message"]) {
				result["submitError"] = getFormattedError(errElement["message"]);
			}
		}
	} else if (err["networkError"]) {
		if (err["networkError"]["message"]) {
			result["submitError"] = getFormattedError(err["networkError"]["message"]);
		}
	}
	if (Object.keys(result).length === 0 && err["message"]) {
		result["submitError"] = getFormattedError(err["message"]);
	}
	if ((result["submitError"] || "").indexOf("API Key") !== -1) {
		if (typeof resetFunc === "function") {
			resetFunc();
		}
	}
	return result;
}

/***
 * parses user phone for code and number
 * @param phoneStr
 */
function parsePhone(phoneStr: string = "") {
	const phone = phoneStr.replace(/\+38| |\(|\)/g, "");
	return {
		code: phone.substr(0, 3),
		number: phone.substr(3),
	};
}

/**
 * normnalizes value to have only numbers
 * @param value
 * @param oldValue
 */
function normalizeOnlyNumbers(value, oldValue = "") {
	if (!value) {
		return value
	}
	const onlyNums = value.replace(/[^\d]/g, "");

	if (onlyNums.length <= 10) {
		return onlyNums
	}
	return oldValue;
}

/**
 * normalizes phone number
 * @param phoneCode
 * @param phoneNumber
 */
function normalizePhone(phoneCode = "", phoneNumber = "") {
	if (!phoneCode || !phoneNumber) return "";
	return [
		"+38",
		phoneCode,
		phoneNumber.substring(0, 3),
		phoneNumber.substring(3, 5),
		phoneNumber.substring(5, 7)].join(" ");
}

const validateFieldComponent = (field) => (value) => {
	if (isObject(value)) {
		const {region, city, street} = value;
		return (validateField(field, region || city || street) || {})[(field || {}).name];
	}
	return (validateField(field, value) || {})[(field || {}).name];
};

/***
 * function mast help solve autofill view problems
 */
function resetChromePasswordPlacer(): void {
	if ("createEvent" in document) {
		const evt = document.createEvent("HTMLEvents");
		evt.initEvent("change", false, true);
		const inputs = Array.from(document.getElementsByTagName("input"));
		if (inputs[0]) {
			inputs[0].dispatchEvent(evt);
		}
		for (const input of inputs) {
			setTimeout(() => {
				input.dispatchEvent(evt);

			}, 1000)
		}
	}
}

/**
 * parse and set form errors
 * @param err
 * @param props
 */
function handleFormErrors(err, props) {
	const errors = parseFormError(err);
	props.setSubmitting(false);
	if (Object.keys(errors).length) {
		props.setErrors(errors);
	}
}

export {
	QUERY_DATE_FORMAT,
	checkCodeIdentity,
	checkPasswordIdentity,
	lessThan,
	moreThan,
	isValidSilpoPrefix,
	normalizeFields,
	normalizeName,
	normalizeOnlyNumbers,
	normalizePhone,
	normalizeValue,
	parseFormError,
	parsePhone,
	barcodeConvertValue,
	validateEmail,
	resetChromePasswordPlacer,
	validate,
	validateApartment,
	validateBirthday,
	validateBuilding,
	validateDate,
	validateFieldComponent,
	validateFullname,
	validatePhone,
	validateTime,
	handleFormErrors
}