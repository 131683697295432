import React, {FC} from 'react'
import {Formik, FormikHelpers} from 'formik';
import {useMutation} from 'react-apollo';

import Mutation from '@graphql/Mutation';
import {ENUM} from '@constants';
import {barcodeConvertValue, handleFormErrors} from '@services/FormServices/FormService';
import {getResponseFields, getStoredAuthData} from '../helpers';
import {handleValidate, initialValid, initialValues} from './const';
import CardInformationEntryForm from './CardInformationEntryForm';

import {SetCardPresenceResponseType, SetCardPresenceVariablesType} from '@graphql/types';
import {AuthFormPropsType} from '../types';
import {CardModalFormValuesType} from './types';

import "./CardInformationEntry.styl";

const CardInformationEntry: FC<AuthFormPropsType> = ({determineNextStep}) => {
	const [setCardPresence] = useMutation<SetCardPresenceResponseType, SetCardPresenceVariablesType>(Mutation.setCardPresence.mutation);
	const {flowId, phone} = getStoredAuthData();

	const handleSubmit = (values: CardModalFormValuesType, formActions: FormikHelpers<CardModalFormValuesType>) => {
		return setCardPresence({
			variables: {
				flowId,
				card: values.hasCard === ENUM.YES ? barcodeConvertValue(values.barcode || "") : "",
			}
		}).then(({data = {}, errors}) => {
			if (errors) {
				throw errors;
			}
			const setCardPresence = data.setCardPresence;
			if (!errors && setCardPresence) {
				const {flowId, nextStep, contactType, contactValue} = getResponseFields(setCardPresence);
				localStorage.setItem("authData", JSON.stringify({flowId, nextStep, [contactType.toLowerCase()]: contactValue || phone, contactType}));
				formActions.setSubmitting(false);
				determineNextStep(nextStep);
			}
		})
			.catch((e) => {
				handleFormErrors(e, formActions);
			});
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={initialValid} validate={handleValidate}>
			<CardInformationEntryForm />
		</Formik>
	)
}

export default CardInformationEntry