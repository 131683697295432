import "./PersonalProposalsPage.styl";

import React, {Component} from 'react';
import moment from "moment";
import {graphql} from "react-apollo";
import {compose} from "recompose";

import {PersonalProposalsPageProps} from "./types";

import Mutation from "../../graphql/Mutation";
import Query from "../../graphql/Query";
import getTemplateText, {renderTemplateTextNode} from "../../services/TextService";
import {ROUTE} from "../../services/RouteService/enums";
import WithContext from "../../components/HOCs/WithContext/WithContext";

import Intro from "../../components/Intro/Intro";

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import LoadHolder from "../../components/UI/Loader/LoadHolder";
import PersonalProposalsBlock from "../../components/PersonalProposalsBlock/PersonalProposalsBlock";
import {isTargetProject} from "../../utils";
import {PROJECT} from "../../constants";
import NearestStore from "../../components/NearestStore/NearestStore";
import {setDocumentTitle} from "../../services/TitleService";


const PersonalProposalsPage = compose<PersonalProposalsPageProps, {}> (
	WithContext(({user}) => ({spawnNextCouponDate: user.spawnNextCouponDate})),
	graphql(Mutation.couponsActivate.mutation, {name: "couponsActivate"}),
	graphql(Query.coupons.query, {
		name: "couponsData",
		options: {
			fetchPolicy: "network-only",
			variables: Query.coupons.variables,
		}
	}),
)(class PersonalProposalsPage extends Component<PersonalProposalsPageProps, {}> {
	render() {
		const {couponsData: {coupons, loading, refetch}, spawnNextCouponDate, couponsActivate} = this.props;

		const introProps = {
			title: getTemplateText("specialProposals.title"),
			className: "personal-proposals-intro",
			hr: true
		};
		setDocumentTitle(getTemplateText("specialProposals.title"));

		return (
			<div className="personal-proposals-page">

				<BreadCrumbs labels={{
					[ROUTE.PERSONAL_PAGE]: "Персональна сторінка",
					[ROUTE.SPECIAL_PROPOSALS]: getTemplateText("specialProposals.title")}
				}/>
				<NearestStore isOpened={false}/>
				<Intro {...introProps}>
					<div className="intro-text personal-proposals__intro-text">
						{renderTemplateTextNode("specialProposals.intro.description")}
					</div>
					<div className="personal-proposals__intro-content">
						{isTargetProject(PROJECT.SILPO) ?
							<div>
								{renderTemplateTextNode("specialProposals.newProposals")}&nbsp;
								{moment(spawnNextCouponDate).format("DD.MM.YYYY")}
							</div> : null
						}
					</div>
				</Intro>
				<LoadHolder isLoading={loading}>
					<PersonalProposalsBlock
						list={coupons}
						couponsActivate={couponsActivate}
						couponsRefetch={refetch}
					/>
				</LoadHolder>

			</div>
		)
	}
});

export default PersonalProposalsPage;
